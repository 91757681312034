import { useEffect } from "react";
import Calory from "./components/Calory";
import MacroWeightChart from "./components/MacroWeightChart";
import Diet from "./components/Diet";
import Recipe from "./components/Recipe";
import SportTraining from "./components/SportTraining";
import { useBasicInfoStore } from "../../store/dashboard/basicInfo";
import { decrypt, isAdmin, isAdminDoctor, isCoach, isDoctor, isUser } from "../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const basicInfoLoading = useBasicInfoStore((state) => state.basicInfoLoading);

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log("Roles", decrypt(localStorage.ur).split(","));
    console.log("adminRole", isAdmin());
    console.log("doctorRole", isDoctor());
    console.log("userRole", isUser());
    console.log("admin-doctorRole", isAdminDoctor());
    console.log("coachRole", isCoach());
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {/* <Loading/> */}
      {/*  <Calender /> */}
      <Calory loading={basicInfoLoading} userInfo={basicInfo} />
      <MacroWeightChart loading={basicInfoLoading} userInfo={basicInfo} />
      <Diet userInfo={basicInfo} />
      <Recipe />
      {/*  <SportTraining /> */}
    </>
  );
}
