import React from "react";
import { baseFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ picture, title, text, showInfo, index, social }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section onClick={() => showInfo(index)} className={`relative shadow-xl  transition-all hover:shadow-lg hover:shadow-[#DDCEEE] hover:translate-y-[-10px]   cursor-pointer  w-full p-4 flex gap-3 xl:gap-2 flex-col h-auto xl:p-6 rounded-[20px]`}>
        <img src={picture} alt="" className={`w-auto h-[170px] xl:h-[270px] rounded-[10px] mx-auto  `} />
        <div className="w-[90%] mx-auto opacity-50 h-[1px] rounded-full xl:mt-5 bg-purple"></div>

        <h3 className={`text-[#3E4151] text-center xl:text-[18px] font-bold`}>{title}</h3>
        <p className={`text-[#5521B4] text-center font-bold text-sm`}>{text}</p>
        {social && (
          <section className="flex mt-auto justify-between justify-items-end xl:w-[208px]">
            <span className="rounded-full cursor-pointer xl:w-[40px] w-5 h-5 xl:h-[40px]  border-[#] hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
              <img src={baseFileUrl + "/images/svg/twitter.png"} alt="" />
            </span>
            <span className="rounded-full cursor-pointer xl:w-[40px] w-[18px] h-[18px]  xl:h-[40px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
              <img src={baseFileUrl + "/images/svg/instagram.svg"} alt="" />
            </span>
            <span className="rounded-full cursor-pointer xl:w-[40px] w-[14px] h-[14px] xl:h-[40px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
              <img src={baseFileUrl + "/images/svg/linkdin.svg"} alt="" className="w-full xl:w-[17px] xl:h-auto h-full" />
            </span>
            <span className="rounded-full cursor-pointer xl:w-[40px]  w-[14px] h-[14px]  xl:h-[40px]  border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
              <img src={baseFileUrl + "/images/svg/facebook.svg"} alt="" className="w-full xl:w-[11px] xl:h-auto h-full" />
            </span>
          </section>
        )}
      </section>
    </>
  );
}
