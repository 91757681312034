import { useEffect, useRef } from "react";
import "./style.css";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({
  setMuscle,
  style,
  onClick,
  front11,
  front7,
  setBack7,
  front12,
  front22,
  front16,
  setBack22,
  setBack16,
  setBack11,
  setBack12,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const n1 = useRef();
  const n3_1 = useRef();
  const n3_2 = useRef();
  const n6_1 = useRef();
  const n6_2 = useRef();
  const n7_1 = useRef();
  const n7_2 = useRef();
  const n9_1 = useRef();
  const n9_2 = useRef();
  const n10_1 = useRef();
  const n10_2 = useRef();
  const n11_1 = useRef();
  const n11_2 = useRef();
  const n12_1 = useRef();
  const n12_2 = useRef();
  const n13_1 = useRef();
  const n13_2 = useRef();
  const n13_3 = useRef();
  const n13_4 = useRef();
  const n14_1 = useRef();
  const n14_2 = useRef();
  const n14_3 = useRef();
  const n14_4 = useRef();
  const n15_1 = useRef();
  const n15_2 = useRef();
  const n16_1 = useRef();
  const n16_2 = useRef();
  const n17_1 = useRef();
  const n17_2 = useRef();
  const n18_1 = useRef();
  const n18_2 = useRef();
  const n19_1 = useRef();
  const n19_2 = useRef();
  const n20_1 = useRef();
  const n20_2 = useRef();
  const n20_3 = useRef();
  const n20_4 = useRef();
  const n21_1 = useRef();
  const n21_2 = useRef();
  const n22_1 = useRef();
  const n22_2 = useRef();

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const n1Enter = () => {
    n1.current.classList.add("activeMuscle");
  };
  const n1Leave = () => {
    n1.current.classList.remove("activeMuscle");
  };
  const n3Enter = () => {
    n3_1.current.classList.add("activeMuscle");
    n3_2.current.classList.add("activeMuscle");
  };
  const n3Leave = () => {
    n3_1.current.classList.remove("activeMuscle");
    n3_2.current.classList.remove("activeMuscle");
  };
  const n6Enter = () => {
    n6_1.current.classList.add("activeMuscle");
    n6_2.current.classList.add("activeMuscle");
  };
  const n6Leave = () => {
    n6_1.current.classList.remove("activeMuscle");
    n6_2.current.classList.remove("activeMuscle");
  };
  const n7Enter = () => {
    setBack7(true);
    n7_1.current.classList.add("activeMuscle");
    n7_2.current.classList.add("activeMuscle");
  };
  const n7Leave = () => {
    setBack7(false);
    n7_1.current.classList.remove("activeMuscle");
    n7_2.current.classList.remove("activeMuscle");
  };
  const n9Enter = () => {
    n9_1.current.classList.add("activeMuscle");
    n9_2.current.classList.add("activeMuscle");
  };
  const n9Leave = () => {
    n9_1.current.classList.remove("activeMuscle");
    n9_2.current.classList.remove("activeMuscle");
  };
  const n10Enter = () => {
    n10_1.current.classList.add("activeMuscle");
    n10_2.current.classList.add("activeMuscle");
  };
  const n10Leave = () => {
    n10_1.current.classList.remove("activeMuscle");
    n10_2.current.classList.remove("activeMuscle");
  };
  const n11Enter = () => {
    setBack11(true);
    n11_1.current.classList.add("activeMuscle");
    n11_2.current.classList.add("activeMuscle");
  };
  const n11Leave = () => {
    setBack11(false);
    n11_1.current.classList.remove("activeMuscle");
    n11_2.current.classList.remove("activeMuscle");
  };
  const n12Enter = () => {
    setBack12(true);
    n12_1.current.classList.add("activeMuscle");
    n12_2.current.classList.add("activeMuscle");
  };
  const n12Leave = () => {
    setBack12(false);
    n12_1.current.classList.remove("activeMuscle");
    n12_2.current.classList.remove("activeMuscle");
  };
  const n13Enter = () => {
    n13_1.current.classList.add("activeMuscle");
    n13_2.current.classList.add("activeMuscle");
    n13_3.current.classList.add("activeMuscle");
    n13_4.current.classList.add("activeMuscle");
  };
  const n13Leave = () => {
    n13_1.current.classList.remove("activeMuscle");
    n13_2.current.classList.remove("activeMuscle");
    n13_3.current.classList.remove("activeMuscle");
    n13_4.current.classList.remove("activeMuscle");
  };
  const n14Enter = () => {
    n14_1.current.classList.add("activeMuscle");
    n14_2.current.classList.add("activeMuscle");
    n14_3.current.classList.add("activeMuscle");
    n14_4.current.classList.add("activeMuscle");
  };
  const n14Leave = () => {
    n14_1.current.classList.remove("activeMuscle");
    n14_2.current.classList.remove("activeMuscle");
    n14_3.current.classList.remove("activeMuscle");
    n14_4.current.classList.remove("activeMuscle");
  };
  const n15Enter = () => {
    n15_1.current.classList.add("activeMuscle");
    n15_2.current.classList.add("activeMuscle");
  };
  const n15Leave = () => {
    n15_1.current.classList.remove("activeMuscle");
    n15_2.current.classList.remove("activeMuscle");
  };
  const n16Enter = () => {
    setBack16(true);
    n16_1.current.classList.add("activeMuscle");
    n16_2.current.classList.add("activeMuscle");
  };
  const n16Leave = () => {
    setBack16(false);
    n16_1.current.classList.remove("activeMuscle");
    n16_2.current.classList.remove("activeMuscle");
  };
  const n17Enter = () => {
    n17_1.current.classList.add("activeMuscle");
    n17_2.current.classList.add("activeMuscle");
  };
  const n17Leave = () => {
    n17_1.current.classList.remove("activeMuscle");
    n17_2.current.classList.remove("activeMuscle");
  };
  const n18Enter = () => {
    n18_1.current.classList.add("activeMuscle");
    n18_2.current.classList.add("activeMuscle");
  };
  const n18Leave = () => {
    n18_1.current.classList.remove("activeMuscle");
    n18_2.current.classList.remove("activeMuscle");
  };
  const n19Enter = () => {
    n19_1.current.classList.add("activeMuscle");
    n19_2.current.classList.add("activeMuscle");
  };
  const n19Leave = () => {
    n19_1.current.classList.remove("activeMuscle");
    n19_2.current.classList.remove("activeMuscle");
  };
  // const n21Enter = () => {
  //   n21_1.current.classList.add("activeMuscle");
  //   n21_2.current.classList.add("activeMuscle");
  // };
  // const n21Leave = () => {
  //   n21_1.current.classList.remove("activeMuscle");
  //   n21_2.current.classList.remove("activeMuscle");
  // };
  const n22Enter = () => {
    setBack22(true);
    n22_1.current.classList.add("activeMuscle");
    n22_2.current.classList.add("activeMuscle");
  };
  const n22Leave = () => {
    setBack22(false);
    n22_1.current.classList.remove("activeMuscle");
    n22_2.current.classList.remove("activeMuscle");
  };
  const n20Enter = () => {
    n20_1.current.classList.add("activeMuscle");
    n20_2.current.classList.add("activeMuscle");
    n20_3.current.classList.add("activeMuscle");
    n20_4.current.classList.add("activeMuscle");
  };
  const n20Leave = () => {
    n20_1.current.classList.remove("activeMuscle");
    n20_2.current.classList.remove("activeMuscle");
    n20_3.current.classList.remove("activeMuscle");
    n20_4.current.classList.remove("activeMuscle");
  };
  useEffect(() => {
    if (front11) {
      n11Enter();
    } else {
      n11Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [front11]);
  useEffect(() => {
    if (front7) {
      n7Enter();
    } else {
      n7Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [front7]);
  useEffect(() => {
    if (front12) {
      n12Enter();
    } else {
      n12Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [front12]);
  useEffect(() => {
    if (front22) {
      n22Enter();
    } else {
      n22Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [front22]);
  useEffect(() => {
    if (front16) {
      n16Enter();
    } else {
      n16Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [front16]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <svg
        version="1.1"
        id="womanFront"
        x="0px"
        y="0px"
        viewBox="0 0 1162.2 1692.28"
        onClick={onClick}
        style={style}
      >
        <path
          class="st0"
          d="M681.99,1544.95c-9.16,4.5-27.49,11.81-28.14,22.67c-0.59-27.1,4.13-61.66,4.29-62.82
	C663.27,1520.21,676.96,1533.97,681.99,1544.95z"
        />
        <path
          class="st1 n20"
          ref={n20_1}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M728.64,1324c-1.91,17-7.53,27.41-11.05,42.01c-10.53,43.8-26.95,105.58-23.48,173.19
	c-0.18,0.04-1.45-31.56-1.55-89.9c-0.05-31.4,0.95-64.71,2.7-103.47c1.21-26.84,2.43-68.83,6.77-97.99
	c2.75-18.47,7.93-32.54,7.93-32.54C720.57,1242.39,733.82,1277.86,728.64,1324z"
        />
        <path
          class="st1 n20"
          ref={n20_2}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M654.09,1504.67c2.96-112.89-31.51-171.94-31.51-216.95c0-47.82,17.69-73.53,9.81-93.21
	c0,0,0.75,1.59,1.88,4.13c0,0,1.59,1.93,3.19,3.6c0.87,0.91,2.38,2.18,2.75,2.72c1.36,1.96,2.12,3.65,4.46,8.03
	c4.74,8.86,10.36,24.11,11.95,34.81c0.7,4.72,1.3,8.82,1.62,13.24c2.2,30.48,4.23,62.27,4.58,93c0.21,18.65-0.22,37.06-0.95,53.81
	c-0.79,18.08-1.68,33.98-2.57,47.66C657.3,1486.04,654.09,1504.67,654.09,1504.67z"
        />
        <path
          class="st2 n21"
          ref={n21_1}
          /* onMouseEnter={n21Enter}
          onMouseLeave={n21Leave} */
          d="M709.46,1217.2c-10.19,26.92-11.21,59.1-15.22,152.64c-4.01,93.55-0.15,170.49-0.15,170.49
	s-6.19-4.12-14.59-5.03c-6.64-0.72-13.9,0.49-19.65,4.93c-4.05,3.13-5.68,7.14-7.29,10.39c-4.55,9.17-2.5,17.1-2.5,17.1
	c-3.22-35.85,4.29-53.56,7.97-97.75c2.49-29.98,4.12-64.04,4.6-97.3c0.09-6.04,0.09-12.05,0.03-18
	c-0.22-22.74-1.38-44.65-2.62-64.15c-1.06-16.71-1.64-31.37-3.76-43.79c-2.22-12.99-7.52-25.31-11.58-33.27
	c-2.61-5.12-4.61-8.32-4.65-8.41c0,0,17.63-19.57,24-9.43c0,0,7.73,25.13,18.95,25.13c8.43,0,14.5-12.1,14.5-12.1
	c4.33-3.56,5.46-1.77,8.22,0.53c2.92,2.44,3.95,4.95,4.26,6.73L709.46,1217.2z"
        />
        <path
          class="st3"
          d="M714.1,1171.66c2.44,20.63,3.91,22.62-4.14,43.9c0,0-0.36-3.27-6.27-8.14c-2.14-1.77-5.43-0.35-6.96,2.21
	c-10.77,17.98-23.67,9.38-32.63-14.05c0,0-5.14-10.61-23.9,9.39c-1.92-1.89-4.95-4.79-5.94-6.32
	c-4.58-10.74-15.34-27.05-10.68-55.95c0,0,3.83,9.77,14.03,16.64c8.12,5.47,17.52,6.17,23.23,3.35c5.52-2.73,9.73-7.65,11.79-14.98
	c0,0,4.85,3.17,8.04,3.48c5.21,0.51,11.98-5.59,11.98-5.59c-4.89,8.15,5.21,18.05,7.45,20.28c2.47,2.45,6.51,5.35,6.51,5.35
	C711.52,1173.86,714.1,1171.66,714.1,1171.66z"
        />
        <path
          class="st1"
          d="M707.48,1557.65c16.91,17.87,38.51,52.35,40.59,66.06c2.08,13.71-10,29.08-23.49,35.32
	c-13.49,6.23-26.19,0.83-33.76-3.32c-7.57-4.15-17.94-11.22-20.86-38.22c-2.92-27.01-3.33-30.75-12.47-38.22
	c-9.14-7.48-11.24-18.7-2.09-34.07C664.56,1529.81,698.05,1530.64,707.48,1557.65z"
        />
        <path
          class="st3"
          d="M701.98,1199.55c-4.72,10.88-10.2,21.11-18.83,21.11c-8.62,0-19.97-19.48-21.04-36.69
	c-0.31-5.06-0.99-14.9-1.01-21.33c0,0,8.79-3.71,11.79-14.98c0,0,3.53,3.04,9.13,3.52c4.24,0.36,10.71-5.57,10.71-5.57
	c-6.12,11.9,14.08,25.53,14.08,25.53C705.75,1181.34,703.43,1196.2,701.98,1199.55z"
        />
        <path
          class="st1 n16"
          ref={n16_2}
          onMouseEnter={n16Enter}
          onMouseLeave={n16Leave}
          onClick={() => setMuscle(16)}
          d="M680.9,749.38c-4.23,3.67-8.76,7.09-27.3,26.26c-35.02,36.2-56.06,70.27-56.06,70.27
	c-8.93,58.45,7.68,103.64,16.3,161.59c7.93,53.33,2.48,29.28,5.75,74.93c0,0,0.6-11.53,2.79-25.93
	c5.62-36.93,9.76-52.39,17.41-78.65c0,0,3.79-13.82,10.04-31.7c4.84-13.83,16-44.5,18.74-52.38c1-2.87,2.05-4.45,2.54-7.96
	c1.97-14.17,4.73-32.94,6.39-50.87C679.85,809.42,681.84,780.54,680.9,749.38z"
        />
        <path
          class="st1 n18"
          ref={n18_2}
          onMouseEnter={n18Enter}
          onMouseLeave={n18Leave}
          onClick={() => setMuscle(18)}
          d="M726.98,708.66c-1.91,7.86-28.31,26.42-28.31,26.42s1.74,6.05,5.02,17.57c3.87,13.57,9.65,34.95,14.94,64.27
	c3.01,16.68,6.36,35.88,8.81,57.68c1.78,15.82,2.83,33.04,4.09,51.53c0.63,9.21,0.64,18.38,0.67,27.51
	c0.09,29.48-1.86,57.95-4.77,80.48c-3.1,24-7.19,41.25-8.18,46.23c-4,19.97-11.86,40.4-16.86,50.83
	c-3.41,7.12-9.06,13.81-9.06,13.81c-1.98,2.53-2.52,6.96,0.07,11.89c1.89,3.59,4.28,6.26,6.54,8.44c2.32,2.25,4.51,3.98,6.94,5.75
	c0.95,0.7,9.45,4.65,10.31-5.67c1.62-19.33,10.37-42.58,26.76-104.88c20.45-77.69,21.91-144.87,20.48-193.51
	C763.68,841.71,753.83,758.77,726.98,708.66z"
        />
        <path
          class="st2 n17"
          ref={n17_1}
          onMouseEnter={n17Enter}
          onMouseLeave={n17Leave}
          onClick={() => setMuscle(17)}
          d="M621.57,1134.82c2.89,19.06,24.4,34.89,39.5,27.76c4.91-2.32,9.22-6.54,11.82-14.91c0,0-7.07-5.91-9.97-19.61
	c0,0-7.15-22.81-8.87-61.49c-1.07-24.22,0.17-53.59,3.57-84.7c2.7-24.78,9.09-66.23,13.4-96.08c0.88-6.13,1.63-11.55,2.39-16.57
	c0,0-1.36,10.85-2.3,16.6C668.74,900.32,606.36,1034.46,621.57,1134.82z"
        />
        <path
          class="st2 n19"
          ref={n19_1}
          onMouseEnter={n19Enter}
          onMouseLeave={n19Leave}
          onClick={() => setMuscle(19)}
          d="M663.74,1130.98c-20.95-78.97-4.76-160.16,7.37-245.38c5.31-37.31,9.93-75.49,10.17-114.43
	c0.05-7.24-0.06-14.5-0.33-21.8l17.71-14.29c60.39,203.28,25.37,357.04,1.86,399.65c-6.45,11.69-14.17,16.44-18.57,16.44
	C673.4,1151.17,666.8,1142.54,663.74,1130.98z"
        />
        <path
          class="st3"
          d="M532.68,274.35c-1.84,2.38-3.43,3.24-6.57,6.85c-1.55,1.78-4,3.62-5.3,4.92c-1.62,1.62-3.87,3.21-5.84,4.91
	c-1.25,1.08-2.65,2.07-4.06,3.14c-4.25,3.23-8.77,6.38-13.61,9.1c-13.6,7.64-21.5,10.44-21.5,10.44c-5.63,1.64-9.63,3.93-13.5,6.18
	c0,0-13,8.96-23.21,20.04c-6.26,6.79,5.48,15.88,2.62,34.96c-8.07,60.57,14,101.99,14,101.99c1.25,37.43,19.11,81.11,27.61,125.53
	c3.06,15.98-0.2,28.32-8.47,45.25c-8.27,16.92-30.17,39.33-37.12,59.23c-3.19,9.15,29.46,25.6,60.24,55.69
	c36.18,35.37,69.14,81.56,69.14,81.56s8.38,2.81,15.08,2.81c6.7,0,15.08-2.81,15.08-2.81s32.96-46.2,69.14-81.56
	c11.06-10.81,22.47-19.9,32.27-27.49c4.87-3.77,9.39-6.93,13.25-10.06c8.47-6.86,14.02-12.46,15.06-16.36c0,0-0.17-1.11-0.34-1.78
	c-0.72-2.07-1.46-4.21-2.48-6.33c-8.74-18.22-27.23-37.74-34.64-52.9c-8.27-16.92-11.52-29.27-8.47-45.25
	c8.5-44.42,26.27-86,27.52-123.43c0,0,5.46-9.77,9.54-26.9c7.49-31.47,28.51-86.46,7.39-112.62
	c-18.36-22.74-37.28-26.97-37.28-26.97c-20.65-8.3-42.11-21.84-55.86-39.17L532.68,274.35z"
        />
        <path
          class="st4 n10"
          ref={n10_2}
          onMouseEnter={n10Enter}
          onMouseLeave={n10Leave}
          onClick={() => setMuscle(10)}
          d="M778.84,601.97l6.51-24.81c0,0-29.07-55.99-33.79-127.17c0,0-8.25-4.3-20.25-15.53
	c-7.14-6.68-10.46-9.75-10.46-9.75c0.42,9.81,1.18,19.42,1.18,19.42C720.81,508.13,741.74,564.55,778.84,601.97z"
        />
        <g>
          <path
            class="st5 n14"
            ref={n14_1}
            onMouseEnter={n14Enter}
            onMouseLeave={n14Leave}
            onClick={() => setMuscle(14)}
            d="M594.11,673.92c-8.68,2.04-11.65-5.2-11.65-13.19c0-10.22,0-21.27,0-38.28c0.37-16.3,6.87-23.96,14.52-23.38
		c33.35,0,37.49,10.6,38.97,17.14C637.53,623.21,644.79,661.98,594.11,673.92z"
          />
          <path
            class="st5 n13"
            ref={n13_4}
            onMouseEnter={n13Enter}
            onMouseLeave={n13Leave}
            onClick={() => setMuscle(13)}
            d="M636.23,553.09c13.18,43.91-0.28,63.12-0.28,63.12s-5.62-17.14-38.97-17.14c-0.55-0.11-1.13-0.13-1.7-0.09
		c-0.01,0-0.05,0-0.06,0c-12.76-1.33-12.76-17.49-12.76-31.09c0-23.23,7.27-32.29,21.52-32.29
		C619.96,535.73,633.86,545.18,636.23,553.09z"
          />
          <path
            class="st5 n13"
            ref={n13_2}
            onMouseEnter={n13Enter}
            onMouseLeave={n13Leave}
            onClick={() => setMuscle(13)}
            d="M636.23,553.09c-5.17-6.44-16.27-17.36-32.25-17.49c-20.07-0.15-21.47,3.06-21.52-19.78c0-1.83,0-8.78,0-10.26
		c0-12.77,8.79-22.41,27.35-11.67C628.38,504.62,640.17,540.13,636.23,553.09z"
          />
          <path
            class="st5 n14"
            ref={n14_2}
            onMouseEnter={n14Enter}
            onMouseLeave={n14Leave}
            onClick={() => setMuscle(14)}
            d="M570.82,673.92c8.68,2.04,11.65-5.2,11.65-13.19c0-10.22,0-21.27,0-38.28c-0.37-16.3-6.87-23.96-14.52-23.38
		c-33.35,0-37.49,10.6-38.97,17.14C527.4,623.21,520.14,661.98,570.82,673.92z"
          />
          <path
            class="st5 n13"
            ref={n13_3}
            onMouseEnter={n13Enter}
            onMouseLeave={n13Leave}
            onClick={() => setMuscle(13)}
            d="M528.7,553.09c-13.18,43.91,0.28,63.12,0.28,63.12s5.62-17.14,38.97-17.14c0.55-0.11,1.13-0.13,1.7-0.09
		c0.02,0,0.05,0,0.06,0c12.76-1.33,12.76-17.49,12.76-31.09c0-23.23-7.27-32.29-21.52-32.29
		C544.97,535.73,531.07,545.18,528.7,553.09z"
          />
          <path
            class="st5 n13"
            ref={n13_1}
            onMouseEnter={n13Enter}
            onMouseLeave={n13Leave}
            onClick={() => setMuscle(13)}
            d="M528.7,553.09c5.17-6.44,16.27-17.36,32.25-17.49c20.07-0.15,21.47,3.06,21.52-19.78c0-1.83,0-8.78,0-10.26
		c0-12.77-8.79-22.41-27.35-11.67C536.55,504.62,524.76,540.13,528.7,553.09z"
          />
          <g>
            <path
              class="st5 n14"
              ref={n14_3}
              onMouseEnter={n14Enter}
              onMouseLeave={n14Leave}
              onClick={() => setMuscle(14)}
              d="M631.85,677.5c0.3,15.85-3.43,39.59-20.83,81.23c-17.4,41.65-28.56,40.74-28.56,26.09
			c0-23.25,0-70.09,0-85.97c0-15.87,7.17-21.37,14.76-24.87c7.6-3.5,9.04-3.09,20.79-10.02
			C629.75,657.05,631.56,662.33,631.85,677.5z"
            />
            <path
              class="st5 n14"
              ref={n14_4}
              onMouseEnter={n14Enter}
              onMouseLeave={n14Leave}
              onClick={() => setMuscle(14)}
              d="M533.08,677.5c-0.3,15.85,3.43,39.59,20.83,81.23c17.4,41.65,28.56,40.74,28.56,26.09
			c0-23.25,0-70.09,0-85.97c0-15.87-7.17-21.37-14.76-24.87c-7.6-3.5-9.04-3.09-20.79-10.02
			C535.18,657.05,533.37,662.33,533.08,677.5z"
            />
          </g>
        </g>
        <path
          class="st6 n1"
          ref={n1}
          onMouseEnter={n1Enter}
          onMouseLeave={n1Leave}
          onClick={() => setMuscle(1)}
          d="M632.4,208.71c-0.1,2.38-0.2,80.22,0,81.54c1.59,10.5,4.82,21.76,12.87,30.26c5.47,5.77,13.1,9.86,13.1,9.86
	c-9.39-2.16-26.96-3.66-37.66-3.38c-8.18,0.22-14.52,0.76-19.68,3.71c-3.11,1.78-6.03,3.77-7.96,8.94c-0.49,1.33-1.5,3.31-2.25,5.4
	c-0.54,1.5-1.21,3.17-1.75,4.7c-1.47,4.18-2.43,7.82-2.43,7.82c-0.85,3.18-1.73,6.95-2.26,10.51c-1.18,7.89-1.95,15.08-1.95,15.08
	s-1.84-16.59-4.26-25.09c0,0-4.87-16.35-7.5-20.12c0,0-4.17-4.96-7.64-6.75c-6.49-3.34-15.31-3.2-21.07-3.2
	c-9.61,0-17.52,0.77-23.36,1.48c-7.84,0.95-12.01,1.92-12.01,1.92c11.24-6.1,21.23-15.98,24.44-32.84c0.63-3.3,1.53-7.27,1.53-7.27
	c0.09-6.14-0.04-9,0.1-16.15l0.02-64.39c0,0,28.05,39.78,47.47,39.5C599.76,249.93,632.4,208.71,632.4,208.71z"
        />
        <path
          class="st4"
          d="M890.28,837.18c0.01,0.03,0.03,0.03,0.03,0.03c-9.26-5.96-34.85,16.84-34.85,16.84s0.01-0.02,0-0.05
	c-0.2-1.6-6.72-53.49-26.95-100.21C853.06,806.6,888.96,836.11,890.28,837.18z"
        />
        <path
          class="st4 n12"
          ref={n12_2}
          onMouseEnter={n12Enter}
          onMouseLeave={n12Leave}
          onClick={() => setMuscle(12)}
          d="M828.52,753.84c20.23,46.72,26.75,98.6,26.95,100.21c-12.91-40.64-113.7-146.51-70.11-276.88l21.14,43.1
	c0,0-12.55,30.6,10.3,103.41C820.18,734.44,824.17,744.5,828.52,753.84z"
        />
        <path
          class="st4"
          d="M854.75,863.12c0.13,9.54,3.11,23.09,9.73,34.11c0,0,0.01,0.03,0.05,0.11c0.89,2.22,13.37,30.39,27.97,47.6
	c15.05,17.77,52.09,19.66,51.6,3.63c-0.4-13.12-1.7-35.37-20.61-72.67l0,0c7.14-1.53,15.38,4.83,31.09,6.34
	c15.71,1.51,20.56-7.75,8.43-11.38c-12.13-3.65-19.18-5.85-30.99-17.47c-11.83-11.62-30.94-14.75-46.48-22.41
	c-10.43,0.97-22.72,11-28.75,23.75l-0.02-0.01C855.44,857.48,854.72,860.32,854.75,863.12z"
        />
        <path
          class="st5 n11"
          ref={n11_2}
          onMouseEnter={n11Enter}
          onMouseLeave={n11Leave}
          onClick={() => setMuscle(11)}
          d="M885.53,830.98c1.55,2.31-32.47-24.34-57.02-77.14c-4.34-9.34-8.34-19.4-11.71-30.16
	c-22.85-72.81-10.3-103.41-10.3-103.41l7-29.3c0,0,41.02,47.47,41.91,91.89C856.99,760.09,869.77,807.54,885.53,830.98z"
        />
        <path
          class="st7"
          d="M923.49,875.91c-12.93-0.75-32.42,4.21-49.13-18.58"
        />
        <g>
          <path
            class="st8 n9"
            ref={n9_1}
            onMouseEnter={n9Enter}
            onMouseLeave={n9Leave}
            onClick={() => setMuscle(9)}
            d="M806.61,620.26c-38.04-70.98-52.8-124.48-55.05-170.27c0,0,20.08-2.98,50.5,38.37
		C819.14,511.57,816.35,596.99,806.61,620.26z"
          />
        </g>
        <path
          class="st9"
          d="M-367.57,1561.41c19.73,20.85,44.95,61.1,47.37,77.1c2.42,16-11.67,33.94-27.41,41.22
	c-15.74,7.27-30.56,0.97-39.39-3.88c-8.83-4.85-20.94-13.09-24.34-44.61c-3.4-31.52-3.89-35.88-14.56-44.61
	c-10.67-8.73-13.12-21.82-2.44-39.76C-417.66,1528.92-378.57,1529.89-367.57,1561.41z"
        />
        <path
          class="st9"
          d="M-345.16,1210.35c0,0,29.33,84.37,12.84,149.83c-16.49,65.46-45.58,127.53-47.03,149.83
	c-1.45,22.3,11.79,51.4,11.79,51.4s-13.75-38.79-60.77-14.55l-2.9-17.48l51.97-288.49L-345.16,1210.35z"
        />
        <path
          class="st9"
          d="M-424.11,1228.28c0,0-18.77,62.08-13.92,96.99c4.85,34.91,17.17,126.23,13.92,144.82
	c-1.4,7.99-18.05,29.28-12.59,44.29c2.9,7.96,8.36,32.49,8.36,32.49s9.81-6.98,22.61-7.12s21.74-214.47,9.29-271.69
	C-415.34,1181.14-424.11,1228.28-424.11,1228.28z"
        />
        <path
          class="st9"
          d="M-330.2,1121.35c-6.77,20.21-8.42,92.88-26.85,119.55c0,0-17.94-49.94,0-86.31S-330.2,1121.35-330.2,1121.35z"
        />
        <path
          class="st9"
          d="M-446.29,1150.03c0,0-1.11,34.95,9.56,58.87c10.67,23.92,34.59,38.47,34.59,38.47s13.92-55.28-9.2-82.43
	C-434.47,1137.78-446.29,1150.03-446.29,1150.03z"
        />
        <path
          class="st10"
          d="M-408.89,1226.16c0,0-12.4,13.12-9.77,35.75c2.62,22.63,17.81,85.92,17.58,128.33
	c-0.57,103.44-10.26,150.07-10.26,150.07s10.26-1.4,18.42-0.71c6.14-12.61,13.58-138.2,19.4-184.75
	c5.82-46.55,0.38-76.13,8.51-87.44c8.14-11.31,14.01-42.31,1.7-52.84C-375.64,1204.05-408.89,1226.16-408.89,1226.16z"
        />
        <path
          class="st11"
          d="M-357.08,1177.25c3.34,20.07-6.14,50.23-12.6,67.53c-4.99,13.36-28.3,12.92-32.46,2.59
	c-5.89-14.65-10.7-30.08-8.74-58.36c1.96-28.28,12.18-62.53,33.31-62.54C-356.45,1126.45-360.42,1157.19-357.08,1177.25z"
        />
        <path
          class="st9"
          d="M-605.8,1561.41c-19.73,20.85-44.95,61.1-47.37,77.1c-2.42,16,11.67,33.94,27.41,41.22
	c15.74,7.27,30.56,0.97,39.39-3.88c8.83-4.85,20.94-13.09,24.34-44.61c3.4-31.52,3.89-35.88,14.56-44.61
	c10.67-8.73,13.12-21.82,2.44-39.76C-555.71,1528.92-594.8,1529.89-605.8,1561.41z"
        />
        <path
          class="st1"
          d="M-628.21,1210.35c0,0-29.33,84.37-12.84,149.83c16.49,65.46,45.58,127.53,47.03,149.83
	c1.45,22.3-11.79,51.4-11.79,51.4s13.75-38.79,60.77-14.55l2.9-17.48l-51.97-288.49L-628.21,1210.35z"
        />
        <path
          class="st12"
          d="M-549.26,1228.28c0,0,18.77,62.08,13.92,96.99c-4.85,34.91-17.17,126.23-13.92,144.82
	c1.4,7.99,18.05,29.28,12.59,44.29c-2.89,7.96-8.36,32.49-8.36,32.49s-9.81-6.98-22.61-7.12c-12.8-0.14-21.74-214.47-9.29-271.69
	C-558.03,1181.14-549.26,1228.28-549.26,1228.28z"
        />
        <path
          class="st9"
          d="M-643.17,1121.35c6.77,20.21,8.42,92.88,26.85,119.55c0,0,17.94-49.94,0-86.31
	C-634.26,1118.22-643.17,1121.35-643.17,1121.35z"
        />
        <path
          class="st9"
          d="M-527.08,1150.03c0,0,1.11,34.95-9.56,58.87c-10.67,23.92-34.59,38.47-34.59,38.47s-13.92-55.28,9.2-82.43
	C-538.9,1137.78-527.08,1150.03-527.08,1150.03z"
        />
        <path
          class="st10"
          d="M-564.48,1226.16c0,0,12.4,13.12,9.78,35.75c-2.62,22.63-17.81,85.92-17.58,128.33
	c0.57,103.44,10.26,150.07,10.26,150.07s-10.26-1.4-18.42-0.71c-6.14-12.61-13.58-138.2-19.4-184.75
	c-5.82-46.55-0.38-76.13-8.51-87.44c-8.14-11.31-14.01-42.31-1.7-52.84C-597.73,1204.05-564.48,1226.16-564.48,1226.16z"
        />
        <path
          class="st11"
          d="M-616.29,1177.25c-3.34,20.07,6.14,50.23,12.6,67.53c4.99,13.36,28.3,12.92,32.46,2.59
	c5.89-14.65,10.7-30.08,8.74-58.36c-1.96-28.28-12.18-62.53-33.31-62.54C-616.92,1126.45-612.95,1157.19-616.29,1177.25z"
        />
        <path
          class="st11"
          d="M-356.41,732.6c0,0,17.32,11.12,15.38,35.05c-1.94,23.92-2.91,53.82-2.42,65.46
	c0.48,11.64-34.04-59.23-34.04-59.23S-374.71,739.74-356.41,732.6z"
        />
        <path
          class="st11"
          d="M-618.03,732.6c0,0-17.32,11.12-15.38,35.05c1.94,23.92,2.91,53.82,2.42,65.46
	c-0.48,11.64,34.04-59.23,34.04-59.23S-599.73,739.74-618.03,732.6z"
        />
        <path
          class="st9"
          d="M-481.56,893.99c0,0,1.86,69.56,12.04,108.83c10.18,39.28,18.2,82,18.25,99.4
	c0.05,17.4,121.03-245.56,54.06-302.19S-481.56,893.99-481.56,893.99z"
        />
        <path
          class="st9"
          d="M-372.31,766.83c0,0,64.46,75.49,55.79,195.75l-70.97-179.07L-372.31,766.83z"
        />
        <path
          class="st9"
          d="M-324.22,933.8c0,0,29.45,82.11,8.5,142.23s-29.97,92.13-35.89,99.89c-5.91,7.76-23.37-8.24-18.03-39.76
	C-364.3,1104.65-324.22,933.8-324.22,933.8z"
        />
        <path
          class="st10"
          d="M-377.5,773.88c12.51,82.36,58.07,95.6,53.67,181.42c-4.4,85.83-42.89,165.83-45.81,180.86
	c-2.92,15.03-15.06,14.23-17.85,3.39c-3-11.64-40.34-153.22-19.97-227.41c20.37-74.19,10.24-112.11,10.24-112.11
	S-390.32,770.67-377.5,773.88z"
        />
        <path
          class="st10"
          d="M-390.21,1128.82c6.02,25.28-0.39,55.83-15.12,59.71c-14.74,3.88-48.68-11.15-45.77-81.46
	c2.91-70.31,32.13-158.49,42.25-189.31C-398.73,886.93-390.21,1128.82-390.21,1128.82z"
        />
        <path
          class="st9"
          d="M-492.88,893.99c0,0-1.86,69.56-12.04,108.83c-10.18,39.28-18.2,82-18.25,99.4s-121.03-245.56-54.06-302.19
	S-492.88,893.99-492.88,893.99z"
        />
        <path
          class="st11"
          d="M-663.21,473.88c0,91.49,29.5,110.91,38.82,130.05c9.33,19.14,13.82,36.81,10.1,49.09
	c-3.72,12.27-22.86,70,10.76,115.99C-569.9,815-519.77,877.5-508.27,888.3c11.5,10.8,21.09,10.47,21.09,10.47s9.59,0.33,21.09-10.47
	c11.5-10.8,61.63-73.3,95.25-119.29c33.62-45.99,14.48-103.72,10.76-115.99c-3.72-12.27,0.78-29.94,10.1-49.09
	c9.33-19.14,38.82-38.56,38.82-130.05C-311.14,382.39-663.21,382.39-663.21,473.88z"
        />
        <path
          class="st9"
          d="M-602.13,766.83c0,0-64.46,75.49-55.79,195.75l70.97-179.07L-602.13,766.83z"
        />
        <path
          class="st9"
          d="M-650.22,933.8c0,0-29.45,82.11-8.5,142.23s29.97,92.13,35.89,99.89c5.91,7.76,23.37-8.24,18.03-39.76
	C-610.14,1104.65-650.22,933.8-650.22,933.8z"
        />
        <path
          class="st10"
          d="M-596.95,773.88c-12.51,82.36-58.07,95.6-53.67,181.42c4.4,85.83,42.89,165.83,45.81,180.86
	c2.92,15.03,15.06,14.23,17.85,3.39c3-11.64,40.34-153.22,19.97-227.41c-20.37-74.19-10.24-112.11-10.24-112.11
	S-584.12,770.67-596.95,773.88z"
        />
        <path
          class="st10"
          d="M-584.24,1128.82c-6.02,25.28,0.39,55.83,15.12,59.71c14.74,3.88,48.68-11.15,45.77-81.46
	c-2.91-70.31-32.13-158.49-42.25-189.31C-575.71,886.93-584.24,1128.82-584.24,1128.82z"
        />
        <path
          class="st9"
          d="M-215.61,665.01c4.45-40.61-17.57-48.12-64.81-59.95h-0.01c0,0-7.14,72.65,24.27,84.8
	c31.41,12.15,42.98,14.78,50.21,26.33c0.01,0.02,0.02,0.03,0.04,0.05C-220.02,692.01-215.61,665.01-215.61,665.01z"
        />
        <path
          class="st9"
          d="M-198.73,727.74c-2.7-4.02-5.08-7.86-7.2-11.5c-0.01-0.02-0.02-0.03-0.04-0.05
	c-7.23-11.55-18.8-14.18-50.21-26.33c-5.74-2.22-10.55-6.29-14.59-11.43l0,0c0,0,4.04,40.5,28.97,60.81
	c24.93,20.31,67.37,97.87,71.18,112.07c0,0,4.89-2.98,13.98-2.48C-156.63,848.84-178.07,758.5-198.73,727.74z"
        />
        <path
          class="st9"
          d="M-280.43,605.06c-20.01-27.94-49.24-63.15-49.84-118.12c-0.67-60.94,46.61-64.99,52.39-2.84
	c3.32,35.66,49.31,91.28,47.57,114.27C-231.6,615.44-260.41,632.99-280.43,605.06z"
        />
        <path
          class="st10"
          d="M-215.95,586.94c0,0,44.97,35.88,56,86.06s26.26,150.61,26.26,150.61s-11.83,21.2-31.63,18.07
	c0,0-12.76-83.18-33.41-113.95c-20.66-30.76-16.89-62.73-16.89-62.73s-28.86-51.21-9.26-76.91L-215.95,586.94z"
        />
        <g>
          <path
            class="st9"
            d="M-173.78,843.16c1.96-21.05,40.09-19.54,40.09-19.54c17.68,7.6,39.11,9.96,52.92,22.19
		c13.81,12.23,21.76,14.26,35.45,17.6c13.69,3.33,9.67,18.54-7.85,17.78c-17.54-0.75-26.74-3.82-38.11-7.32l0,0c0,0,0,0,0,0
		c16.4,30,25.56,52.83,31.73,80.41c1.56,17.98-29.7,17.71-42.34,12.87c-24.04-8.23-44.12-49.82-55.47-61.91
		C-168.68,892.13-175.74,864.21-173.78,843.16z"
          />
          <path
            class="st9"
            d="M-91.28,873.87c-14.3-1.76-34.43,0.45-53.07-18.39"
          />
        </g>
        <path
          class="st12"
          d="M-432.7,260.2c-0.08,3.52,0.04,10.49,0,13.06v40.97c3.76,27.39,15.8,37.61,28.99,44.29
	c0,0,3.95,0.81,15.78,3.62c6.81,1.62,12.43,3.79,16.03,5.5c0,0-0.1-0.93,1.18-1.89c3.97-2.98,11.96-7.61,18.78-10.33
	c12.03-4.49,20.55-5.54,28.61-5.74c2.77-0.07,8.26,0.55,10.47,0.88c-1.18,0.03-3.88-1.28-4.64-1.96l-4.72-4.54
	c-2.91-2.7-7.46-6.53-7.46-6.53c-6.68-5.75-19.62-15.27-19.62-15.27c-16.02-12.28-68.74-44.64-75.48-51.96c0,0-2.6-2.28-3.86-3.78
	C-429.72,265.27-432.19,261.48-432.7,260.2z"
        />
        <path
          class="st12 n22"
          ref={n22_1}
          onMouseEnter={n22Enter}
          onMouseLeave={n22Leave}
          onClick={() => setMuscle(22)}
          d="M632.36,273.31c-0.14,5.51,0,12.72,0.04,16.94c0,0,1.9,9.74,3.1,13.41c2.21,6.73,5.29,11.59,8.77,15.76
	c4.75,5.7,13.87,10.94,14.09,10.95c3.08,0.22,6.82,0.54,11.47,1.19c7.39,1.03,11.26,2.09,11.26,2.09c1.81-0.01,3.72,0.19,5.64,0.27
	c11.69,0.51,24.68,2.71,24.68,2.71c2.62,0.46,5.83,0.92,9.15,1.47c1.72,0.29,3.36,0.92,4.94,1.35c-3.54-4.09-6.46-7.45-9.69-10.41
	c-15.16-13.88-26.65-16.33-26.65-16.33C654.29,299.57,632.6,273.93,632.36,273.31z"
        />
        <path
          class="st12 n22"
          ref={n22_2}
          onMouseEnter={n22Enter}
          onMouseLeave={n22Leave}
          onClick={() => setMuscle(22)}
          d="M532.73,274.31c0.14,5.51,0,12.72-0.04,16.94c0,0-1.9,9.74-3.1,13.41c-2.21,6.73-5.29,11.59-8.77,15.76
	c-4.75,5.7-13.87,10.94-14.09,10.95c-3.08,0.22-6.82,0.54-11.47,1.19c-7.39,1.03-11.26,2.09-11.26,2.09
	c-1.81-0.01-3.72,0.19-5.64,0.27c-11.69,0.51-24.68,2.71-24.68,2.71c-2.62,0.46-5.83,0.92-9.15,1.47c-1.72,0.29-3.36,0.92-4.94,1.35
	c3.54-4.09,6.46-7.45,9.69-10.41c15.16-13.88,26.65-16.33,26.65-16.33C510.8,300.57,532.49,274.93,532.73,274.31z"
        />
        <path
          class="st10"
          d="M-421.76,506.14c0,0-22.25-11.3-29.81-15.85c-2.43-1.47-5.56-3.13-8.94-5.2c-0.05-0.03-0.12-0.07-0.17-0.1
	c-13.73-8.13-26.54,12.08-26.54,29.86c0,17.78,14.63,22.63,20.12,21.49c5.5-1.14,30.71,1.46,45.58,8.25
	c14.87,6.79,17.18,2.35,16.19-11.15C-406.32,519.94-414.97,507.48-421.76,506.14z"
        />
        <path
          class="st13"
          d="M-421.76,506.14c0,0,20.53,6.58,20.5,6.57c-24.29-5.55-42.75-17.87-50.31-22.43c-2.43-1.47-5.56-3.13-8.94-5.2
	c-0.05-0.03-0.12-0.07-0.17-0.1c-13.73-8.13-26.54,12.08-26.54,29.86c0,17.78,14.63,22.63,20.12,21.49
	c5.5-1.14,30.71,1.46,45.58,8.25c14.87,6.79,17.18,2.35,16.19-11.15C-406.32,519.94-414.97,507.48-421.76,506.14z"
        />
        <path
          class="st10"
          d="M-415.65,547.06c-0.03-0.01-0.06-0.03-0.09-0.05c-1.66-0.61-3.55-1.39-5.78-2.41
	c-12.01-5.48-30.75-8.23-40.42-8.48c-8.4,0.28-25.28,6.23-25.28,24.32c0,18.1,5.25,39.76,9.13,45.9c3.88,6.14,12.61,6.14,25.54,2.26
	c12.93-3.88,32-7.11,42.35-4.85c10.34,2.26,13.9-11.64,9.05-31.68C-406,552.03-409.79,549.51-415.65,547.06z"
        />
        <path
          class="st13"
          d="M-415.65,547.06c-0.03-0.01-0.06-0.03-0.09-0.05c-1.66-0.61-3.55-1.39-5.78-2.41
	c-12.01-5.48-30.75-8.23-40.42-8.48c-8.4,0.28-25.28,6.23-25.28,24.32c0,18.1,5.25,39.76,9.13,45.9c3.88,6.14,12.61,6.14,25.54,2.26
	c12.93-3.88,32-7.11,42.35-4.85c10.34,2.26,13.9-11.64,9.05-31.68C-406,552.03-409.79,549.51-415.65,547.06z"
        />
        <path
          class="st10"
          d="M-408.45,603.98c-0.56-0.02-1.14-0.09-1.75-0.22c-1.74-0.15-4.13-0.44-6.95-0.73
	c-10.61-0.24-24.99,2.45-35.4,5.58c-2.41,0.72-4.62,1.26-6.74,1.7c-0.03,0.02-0.06,0.04-0.09,0.06
	c-10.96,3.41-27.87,9.23-27.87,23.46c0,14.22,0.02,11.41,0.02,21.75s11.72,39.99,24.97,32.56c13.25-7.43,36.21-16.16,44.93-18.1
	c8.73-1.94,13.65-10.04,16.16-35.56C-398.81,610.69-400.66,605.29-408.45,603.98z"
        />
        <path
          class="st10"
          d="M-417.32,670.02c-3,0.91-6.85,2.04-11.08,3.37c-10.57,3.7-24.58,9.53-33.86,14.73
	c-10.54,7.66-24.97,21.98-24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64,30.89,3.26,48.28-34.88c17.4-38.14,31.96-80.55,33.26-97.07
	C-404.39,675.19-406.34,666.68-417.32,670.02z"
        />
        <path
          class="st10"
          d="M-552.68,506.14c0,0,22.25-11.3,29.81-15.85c2.43-1.47,5.56-3.13,8.94-5.2c0.05-0.03,0.12-0.07,0.17-0.1
	c13.73-8.13,26.54,12.08,26.54,29.86c0,17.78-14.63,22.63-20.12,21.49c-5.5-1.14-30.71,1.46-45.58,8.25
	c-14.87,6.79-17.18,2.35-16.19-11.15C-568.12,519.94-559.48,507.48-552.68,506.14z"
        />
        <path
          class="st13"
          d="M-552.68,506.14c0,0-20.53,6.58-20.5,6.57c24.29-5.55,42.75-17.87,50.31-22.43c2.43-1.47,5.56-3.13,8.94-5.2
	c0.05-0.03,0.12-0.07,0.17-0.1c13.73-8.13,26.54,12.08,26.54,29.86c0,17.78-14.63,22.63-20.12,21.49
	c-5.5-1.14-30.71,1.46-45.58,8.25c-14.87,6.79-17.18,2.35-16.19-11.15C-568.12,519.94-559.48,507.48-552.68,506.14z"
        />
        <path
          class="st10"
          d="M-558.46,547.06c0.03-0.01,0.06-0.03,0.09-0.05c1.66-0.61,3.55-1.39,5.78-2.41
	c12.01-5.48,30.75-8.23,40.42-8.48c8.4,0.28,25.28,6.23,25.28,24.32c0,18.1-5.25,39.76-9.13,45.9c-3.88,6.14-12.61,6.14-25.54,2.26
	c-12.93-3.88-32-7.11-42.35-4.85c-10.34,2.26-13.9-11.64-9.05-31.68C-568.11,552.03-564.33,549.51-558.46,547.06z"
        />
        <path
          class="st13"
          d="M-558.79,547.06c0.03-0.01,0.06-0.03,0.09-0.05c1.66-0.61,3.55-1.39,5.78-2.41
	c12.01-5.48,30.75-8.23,40.42-8.48c8.4,0.28,25.28,6.23,25.28,24.32c0,18.1-5.25,39.76-9.13,45.9c-3.88,6.14-12.61,6.14-25.54,2.26
	c-12.93-3.88-32-7.11-42.35-4.85c-10.34,2.26-13.9-11.64-9.05-31.68C-568.44,552.03-564.66,549.51-558.79,547.06z"
        />
        <path
          class="st10"
          d="M-565.99,603.98c0.56-0.02,1.14-0.09,1.75-0.22c1.74-0.15,4.13-0.44,6.95-0.73
	c10.61-0.24,24.99,2.45,35.4,5.58c2.41,0.72,4.62,1.26,6.74,1.7c0.03,0.02,0.06,0.04,0.09,0.06c10.96,3.41,27.87,9.23,27.87,23.46
	c0,14.22-0.02,11.41-0.02,21.75s-11.72,39.99-24.97,32.56c-13.25-7.43-36.21-16.16-44.93-18.1c-8.73-1.94-13.65-10.04-16.16-35.56
	C-575.63,610.69-573.79,605.29-565.99,603.98z"
        />
        <path
          class="st10"
          d="M-557.13,670.02c3,0.91,6.85,2.04,11.08,3.37c10.57,3.7,24.58,9.53,33.86,14.73
	c10.54,7.66,24.97,21.98,24.97,44.61c0,22.63,0,63.29,0,90.93c0,27.64-30.89,3.26-48.28-34.88c-17.4-38.14-31.96-80.55-33.26-97.07
	C-570.06,675.19-568.1,666.68-557.13,670.02z"
        />
        <path
          class="st14"
          d="M-283.48,477.66c0,0-12.82,30.14,2.19,95.62s65.67,91.74,65.67,91.74S-166.49,512.88-283.48,477.66z"
        />
        <path
          class="st9"
          d="M-758.91,665.01c-4.45-40.61,17.57-48.12,64.81-59.95h0.01c0,0,7.14,72.65-24.27,84.8
	c-31.41,12.15-42.98,14.78-50.21,26.33c-0.01,0.02-0.02,0.03-0.04,0.05C-754.5,692.01-758.91,665.01-758.91,665.01z"
        />
        <path
          class="st9"
          d="M-775.79,727.74c2.7-4.02,5.08-7.86,7.2-11.5c0.01-0.02,0.02-0.03,0.04-0.05c7.23-11.55,18.8-14.18,50.21-26.33
	c5.74-2.22,10.55-6.29,14.59-11.43l0,0c0,0-4.04,40.5-28.97,60.81c-24.93,20.31-67.37,97.87-71.18,112.07c0,0-4.89-2.98-13.98-2.48
	C-817.89,848.84-796.45,758.5-775.79,727.74z"
        />
        <path
          class="st9"
          d="M-694.09,605.06c20.01-27.94,49.24-63.15,49.84-118.12c0.67-60.94-46.61-64.99-52.39-2.84
	c-3.32,35.66-49.31,91.28-47.57,114.27C-742.92,615.44-714.1,632.99-694.09,605.06z"
        />
        <path
          class="st10"
          d="M-758.57,586.94c0,0-44.97,35.88-56,86.06s-26.26,150.61-26.26,150.61s11.83,21.2,31.63,18.07
	c0,0,12.76-83.18,33.41-113.95c20.66-30.76,16.89-62.73,16.89-62.73s28.86-51.21,9.26-76.91L-758.57,586.94z"
        />
        <g>
          <path
            class="st9"
            d="M-800.74,843.16c-1.96-21.05-40.09-19.54-40.09-19.54c-17.68,7.6-39.11,9.96-52.92,22.19
		c-13.81,12.23-21.76,14.26-35.45,17.6c-13.69,3.33-9.67,18.54,7.85,17.78c17.54-0.75,26.74-3.82,38.11-7.32l0,0c0,0,0,0,0,0
		c-16.4,30-25.56,52.83-31.73,80.41c-1.56,17.98,29.7,17.71,42.34,12.87c24.04-8.23,44.12-49.82,55.47-61.91
		C-805.84,892.13-798.78,864.21-800.74,843.16z"
          />
          <path
            class="st9"
            d="M-883.24,873.87c14.3-1.76,34.43,0.45,53.07-18.39"
          />
        </g>
        <path
          class="st14"
          d="M-691.04,477.66c0,0,12.83,30.14-2.19,95.62s-65.67,91.74-65.67,91.74S-808.02,512.88-691.04,477.66z"
        />
        <path
          class="st14"
          d="M-477.09,369.3c5.08-0.33,14.41,0.32,23.26,4.84c6.65,3.4,17.06,9.06,26.8,15.3
	c14.59,9.35,25.78,18.44,39.72,28.25c10.44,7.35,30.05,12.55,38.5,16.26c10.67,4.69,15.55,4.69,15.55,4.69
	c6.8,10.78,25.97,26.59,25.97,26.59s-7.65,10.73-10.93,16.03c-2.1,3.4-4.46,8.22-6.77,6.33c-4.84-3.96-6.47-6.48-16.12-9.83
	c-10.51-3.65-21.32-5.61-30.17-7.25c-13.28-2.46-36.31-4.5-44.36-5.25c-10.77-1-32.29-1.81-39.15-1.98
	c-7.84-0.2-17.56,0.02-24.71-0.39c0,0-6.42-10.05-6.92-25.44c-0.2-6.17-0.17-13.49-0.22-22.83c-0.08-13.98,2.21-24.7,6.1-37.23
	C-476.29,363.65-477.09,369.3-477.09,369.3z"
        />
        <path
          class="st14"
          d="M-371.91,367.56c-5.35-2.34-8.6-3.35-13.74-4.79c-12.96-3.64-26.67-5.87-29.31-6.18
	c-5.03-0.59-17.94-1.36-25.72-1.36c-15.93,0-27-1.27-36.4,14.06c0,0,3.83,0.3,7.69,0.84c6.14,0.86,13.54,3.02,15.57,4
	c21.1,10.16,50.2,30.63,56.85,36.79c17.22,15.95,63.71,27.71,63.71,27.71c-4.52-6.02-10.06-13.01-15.43-21.81
	C-360.74,397.16-371.91,367.56-371.91,367.56z"
        />
        <path
          class="st14"
          d="M-477.2,463.01c8.87-0.16,38.34-0.11,75.07,3.56c43.95,4.39,77.82,14.31,77.82,23.25
	c0,4.61-8.1,9.99-13.54,14.35c-16.49,13.2-43.64,10.74-54.95,9.47c-19.45-2.19-46.98-16.03-62.52-25.96
	c-8.27-5.29-25.59-20.58-24.19-24.77C-479.51,462.9-478.27,463.03-477.2,463.01z"
        />
        <path
          class="st14"
          d="M-284.54,477.13c0,0-7.64-4.56-15.04-8.43c-0.15-0.08-12.83-5.96-22.59-16.7
	c-8.68-9.55-17.16-21.18-24.02-31.28c-13.1-19.29-24.94-50.54-25.58-52.26c-0.71-1.89,1.28-2.97,3.31-4.35
	c3.21-2.18,7.53-4.53,9.78-5.67c9.86-5.04,23.88-8.91,35.18-8.75c2.05,0.03,4.35-0.38,4.98,0.83c0.32,0.61,0.56,2.77,3.04,6.66
	c5.81,9.1,11.41,19.06,14.47,25.29c8.61,17.49,17.17,38.37,16.54,69.17c-0.15,7.5-0.78,15.12-0.82,19.32
	C-285.34,477.24-284.28,477.22-284.54,477.13z"
        />
        <path
          class="st14 n7"
          ref={n7_1}
          onMouseEnter={n7Enter}
          onMouseLeave={n7Leave}
          onClick={() => setMuscle(7)}
          d="M751.57,449.99c0,0,4.14-11.05-0.13-43.61c-7.68-58.62-29.12-67.44-29.12-67.44
	c7.42,0.86,27.7,5.56,45.28,23.47c15.3,15.58,21.19,25.42,25.05,39.45c4.51,16.37,6.09,23.05,8.97,46.03
	c2.83,22.61,0.45,40.48,0.45,40.48C777.93,453.83,760.99,450.03,751.57,449.99z"
        />
        <path
          class="st14 n6"
          ref={n6_1}
          onMouseEnter={n6Enter}
          onMouseLeave={n6Leave}
          onClick={() => setMuscle(6)}
          d="M751.37,450.23c-0.02,0.19-2.21-0.32-12.68-9.61c-15.03-13.33-24.95-28.46-26.41-30.77
	c-4.99-7.95-12.55-26.67-14.37-31.06c-4.8-11.57-6.83-25.05-7.78-34.94c-0.22-2.29-2.35-7.97-9.04-10.21c0,0,10.31,0.45,13.25,0.76
	c4.52,0.48,13.17,1.56,18.04,2.41c5.37,0.94,8.41,0.89,10.26,2.11C744,353,756.68,406.34,751.37,450.23z"
        />
        <path
          class="st14"
          d="M-284.54,477.13c-3.01-0.98,4.04-30.71-3.65-60.86c-10.91-42.8-37.13-67.21-28.25-66.19
	c6.77,0.78,37.98,5.47,55.64,18.96c16.62,12.69,23.3,26.55,26.74,44.77c4.18,22.19,0.09,47.86-3.67,67.1
	c-0.6,3.05-4.67,18.3-4.67,18.3C-259.77,484.34-276.72,479.68-284.54,477.13z"
        />
        <path
          class="st12"
          d="M-542.02,260.2c0.08,3.52-0.04,10.49,0,13.06v40.97c-3.76,27.39-15.8,37.61-28.99,44.29
	c0,0-3.95,0.81-15.78,3.62c-6.81,1.62-12.43,3.79-16.03,5.5c0,0,0.1-0.93-1.18-1.89c-3.97-2.98-11.96-7.61-18.78-10.33
	c-12.03-4.49-20.55-5.54-28.61-5.74c-2.77-0.07-8.26,0.55-10.47,0.88c1.18,0.03,3.88-1.28,4.64-1.96l4.72-4.54
	c2.91-2.7,7.46-6.53,7.46-6.53c6.68-5.75,19.62-15.27,19.62-15.27c16.02-12.28,68.74-44.64,75.48-51.96c0,0,2.6-2.28,3.86-3.78
	C-545,265.27-542.54,261.48-542.02,260.2z"
        />
        <path
          class="st14"
          d="M-599.35,401.24c-12.25,5.11-19.62,9.65-26.68,15.6c-9.23,7.79-13.66,22.21-20.54,28.21
	c-8.38,7.32-20.86,20.19-20.86,20.19s7.65,10.73,10.93,16.03c2.1,3.4,4.46,8.22,6.77,6.33c4.84-3.96,6.47-6.48,16.12-9.83
	c10.51-3.65,21.32-5.61,30.17-7.25c13.28-2.46,36.31-4.5,44.36-5.25c10.77-1,32.29-1.81,39.15-1.98c7.84-0.2,17.56,0.02,24.71-0.39
	c0,0,6.42-10.05,6.92-25.44c0.2-6.17,0.17-13.49,0.22-22.83c0.08-13.98-0.66-17.67-4.11-30.33
	C-492.19,384.31-565.4,387.09-599.35,401.24z"
        />
        <path
          class="st14"
          d="M-602.82,367.56c5.35-2.34,8.6-3.35,13.74-4.79c12.96-3.64,26.67-5.87,29.31-6.18
	c5.03-0.59,17.94-1.36,25.72-1.36c15.93,0,26.07,0.21,32.5,8.79c0,0,13.24,20.28,9.35,20.28c-3,0-30.25,3.1-32.5,3.32
	c-19.33,1.91-31.09,1.43-48.09,5.66c-13.78,3.42-18.34,5.57-30.04,9c-5.79,1.7-28.58,23.35-23.21,14.55
	C-613.99,397.16-602.82,367.56-602.82,367.56z"
        />
        <path
          class="st15"
          d="M-497.52,463.01c-8.87-0.16-38.34-0.11-75.07,3.56c-43.95,4.39-77.82,14.31-77.82,23.25
	c0,4.61,8.1,9.99,13.54,14.35c16.49,13.2,43.64,10.74,54.95,9.47c19.45-2.19,46.98-16.03,62.52-25.96
	c8.27-5.29,25.59-20.58,24.19-24.77C-495.22,462.9-496.45,463.03-497.52,463.01z"
        />
        <path
          class="st14"
          d="M-690.19,477.13c3.01-0.98-4.04-30.71,3.65-60.86c10.91-42.8,37.13-67.21,28.25-66.19
	c-6.77,0.78-37.98,5.47-55.64,18.96c-16.62,12.69-23.3,26.55-26.74,44.77c-4.18,22.19-0.09,47.86,3.67,67.1
	c0.6,3.05,4.67,18.3,4.67,18.3C-714.95,484.34-698.01,479.68-690.19,477.13z"
        />
        <path
          class="st14"
          d="M-690.19,477.13c0,0,7.64-4.56,15.04-8.43c0.16-0.08,12.83-5.96,22.59-16.7c8.68-9.55,17.16-21.18,24.02-31.28
	c13.1-19.29,24.94-50.54,25.58-52.26c0.71-1.89-1.28-2.97-3.31-4.35c-3.21-2.18-7.53-4.53-9.78-5.67
	c-9.86-5.04-23.88-8.91-35.18-8.75c-2.05,0.03-4.35-0.38-4.98,0.83c-0.32,0.61-0.56,2.77-3.04,6.66
	c-5.81,9.1-11.41,19.06-14.47,25.29c-8.61,17.49-17.17,38.37-16.54,69.17c0.15,7.5,0.78,15.12,0.82,19.32
	C-689.39,477.24-690.45,477.22-690.19,477.13z"
        />
        <path
          class="st12"
          d="M-644.09,498.17c1.33,1.1,2.51,2.08,3.93,3.15c2.07,1.57,4.72,2.73,6.32,4.78
	c16.04,20.55,56.23,14.12,65.68,38.99c0.99,2.62,5.51,3.33,5.51,3.33s-3.6,4.14-4.66,5.99c-2.24,3.9-3.61,9.25-5.26,14.73
	c-0.99,3.29-1.48,6.25-2.2,10.46c-1.93,4.17-2.88,8.63-4.28,13.04c-4.37,13.79-8,28.44-6.74,41.93
	c5.03,23.24,10.65,60.05,11.55,77.17c1.25,9.86-5.78,38.82-17.36,52.81c-5.36,4.91-7.5,4.9-10.28,4.83
	c-19.17-27.16-22.05-52.92-20.11-76.89c0.79-8.87,1.62-14.93,2.76-20.69c0.85-4.28,2.04-8.71,3.48-13.44
	c1-3.27,2.11-6.64,2.83-10.57c0.54-2.95,0.42-4.67,0.09-10.06c-0.79-5.61-2.08-11.51-3.92-16.82c-3.15-9.1-6.68-17.54-12.01-25.06
	c-2.16-3.05-4.24-6-6.4-9.26c-4.93-7.45-9.81-15.87-13.62-25.56c-1.33-3.39-2.58-6.53-3.43-9.27c-0.51-1.66-0.99-3.34-1.47-4.86
	c-0.66-2.08-1.17-3.99-1.24-5.65c0,0,3.86-9.69,5.46-16.22c0.74-2.43,1.7-7.04,2.75-11.72
	C-645.33,507.23-645.25,501.04-644.09,498.17z"
        />
        <path
          class="st12"
          d="M-329.29,497.71c-1.33,1.1-2.51,2.08-3.93,3.15c-2.07,1.57-4.72,2.73-6.32,4.78
	c-16.04,20.55-56.23,14.12-65.68,38.99c-0.99,2.62-5.51,3.33-5.51,3.33s3.6,4.14,4.66,5.99c2.24,3.9,3.61,9.25,5.26,14.73
	c0.99,3.29,1.48,6.25,2.2,10.46c1.93,4.17,2.88,8.63,4.28,13.04c4.37,13.79,8,28.44,6.74,41.93c-5.03,23.24-10.65,60.05-11.55,77.17
	c-1.25,9.86,5.78,38.82,17.36,52.81c5.36,4.91,7.5,4.9,10.28,4.83c19.17-27.16,22.05-52.92,20.11-76.89
	c-0.79-8.87-1.62-14.93-2.76-20.69c-0.85-4.28-2.04-8.71-3.48-13.44c-1-3.27-2.11-6.64-2.83-10.57c-0.54-2.95-0.42-4.67-0.09-10.06
	c0.79-5.61,2.08-11.51,3.92-16.82c3.15-9.1,6.68-17.54,12.01-25.06c2.16-3.05,4.24-6,6.4-9.26c4.93-7.45,9.81-15.87,13.62-25.56
	c1.33-3.39,2.58-6.53,3.43-9.27c0.51-1.66,0.99-3.34,1.47-4.86c0.66-2.08,1.17-3.99,1.24-5.65c0,0-3.86-9.69-5.46-16.22
	c-0.74-2.43-1.7-7.04-2.75-11.72C-328.05,506.77-328.13,500.58-329.29,497.71z"
        />
        <path
          class="st12 n15"
          ref={n15_2}
          onMouseEnter={n15Enter}
          onMouseLeave={n15Leave}
          onClick={() => setMuscle(15)}
          d="M693.87,552.97c0-0.01,3.64-15.7,7.83-31.12c5.82-21.45,6.79-43.89,6.79-43.89
	c-5.09,14.11-10.98,20.96-24.12,24.49c-12.7,3.41-27.31,9.01-33.96,18.61c-7.47,10.79-14.27,31.92-14.27,31.92
	c13.2,41.63,8.05,83.57,5.79,119.2c2.27,43.32,17.69,50.14,32.69,56.57c0.16,0.07,24.04,6.34,24.04,6.34
	c4.62-3.24,10.37-7.43,17.63-13.78c10.39-9.08,10.69-12.64,10.69-12.64c-1.12-7.5-9.06-18.98-13.41-25.45
	c-5.38-8-15.39-20.79-19.18-27.39c-7.24-12.64-8.58-15.72-8.62-15.8c-5.79-9.64-8.79-30.64,1.95-64.24
	C688.47,573.46,691.99,559.67,693.87,552.97z"
        />
        <path
          class="st6"
          d="M-432.73,223.84c-0.11,2.64-0.22,89.11,0,90.58c1.77,11.67,5.36,24.17,14.29,33.61
	c6.07,6.42,14.64,10.47,14.64,10.47c-10.43-2.4-30.04-3.58-41.92-3.27c-14.56,0.38-22.65,0.28-31.36,14.06
	c-3.15,4.99-6.71,19.87-6.71,19.87c-2.56,11.29-3.97,27.49-3.97,27.49s-1.08-19.93-3.18-27.58c0,0-3.94-16.45-7.13-20.51
	c0,0-4.9-6.96-8.03-8.92c-8-5.03-20.5-4.27-26.7-4.41c-24.75-0.54-38.2,3.29-38.2,3.29c12.79-6.18,22.65-16.32,27.27-34.91
	c0.9-3.62,1.72-9.37,1.72-9.37c0.1-6.82-0.13-11.2,0.03-19.14c0,0-0.01-20.33-0.01-27.89c0-12.07,0.01-43.36,0.01-43.36
	s29.64,46.42,51.22,46.1C-468.99,269.62-432.73,223.84-432.73,223.84z"
        />
        <path
          class="st16"
          d="M-443.14,250.74c-5.92,9.23-13.78,17.4-24.2,22.07c-5.79,2.61-12.4,4.13-19.9,4.13
	c-7.5,0-14.11-1.52-19.9-4.13c-10.44-4.67-18.32-12.86-24.22-22.11c0-0.02,0-0.02,0-0.02c-14.19-22.24-17.07-42.21-17.07-42.21
	s-15.74,3.77-21.93-26.78c-6.29-31.05,13.82-29.22,13.82-29.22s-6.46-19.67,0.29-50.03c6.75-30.37,36.24-50.73,69.01-50.73
	c32.74,0,62.25,20.36,69.01,50.73c6.73,30.37,0.27,50.03,0.27,50.03s20.13-1.83,13.84,29.22c-6.19,30.55-21.93,26.78-21.93,26.78
	S-428.92,228.5-443.14,250.74z"
        />
        <path
          class="st17"
          d="M-424.35,55.06C-439.65,35.49-463.97,40-463.97,40s-15.81-8.57-31.01-8.38
	c-20.39,0.26-43.02,12.52-55.23,23.44c-8.79,7.86-16.84,27.14-16.84,41.24c0,20.63,3.34,44.14,9.78,56.41
	c-1.25-27.31,5.97-31.25,6.43-40.04c0.38-7.27-1.27-15.28,2.11-22.02c5.77-11.49,21.88-7.77,31.29-5.2
	c15.22,4.16,28.94,5.65,44.68,3.88c12.59-1.42,32.11-13.13,43.84-2.7c6.19,5.51,5.14,15.13,5.1,22.77
	c-0.01,1.09-0.02,2.18,0.04,3.27c0.46,8.78,7.53,10.48,6.43,40.04c6.44-12.27,9.78-35.78,9.78-56.41
	C-407.56,82.21-417.01,64.45-424.35,55.06z"
        />
        <g>
          <path
            class="st3"
            d="M719.42,419.86c9.15,12.56-11.71,69.39-21.69,76.39c-29.39,20.62-89.84,7.36-99.91-36.26
		c-13.81-59.85,19.68-81.06,45.61-97.9C699.28,325.82,688.15,376.95,719.42,419.86z"
          />
        </g>
        <path
          class="st14 n3"
          ref={n3_1}
          onMouseEnter={n3Enter}
          onMouseLeave={n3Leave}
          onClick={() => setMuscle(3)}
          d="M669.26,350.14c3.75-0.51,6.79-0.39,9.44,0.43c9.56,2.95,12.89,13.06,15.63,18.2
	c-1.98-8.2-3.09-16.24-3.92-23.07c-0.68-5.59-2.78-8.58-6.56-10.83c-3.49-1.7-8.74-2.59-13.7-3.25c-3.47-0.46-5.65-0.8-11.8-1.24
	c0,0-19.29-4.16-41.31-3.28c-12.07,0.48-19.14,3.93-22.76,9.84c-15.43,33.5-10.93,51.5-10.48,92.87c0,6.86-0.43,24,6.57,37.43
	c3.43,7.29,11.28,17.42,26.93,28.93c18.73,14.57,41.53,11.06,61.55,7.61c-23.05,3.47-39.34-0.11-55.1-9.55
	c-18.67-12.55-21.72-22.04-26.34-35.74c-9.09-35.73,4.15-59.76,11.96-68.64c8.23-10.02,17.28-16.33,29.51-24.68
	C650.04,357.53,658.14,352.41,669.26,350.14z"
        />
        <path
          class="st14 n7"
          ref={n7_2}
          onMouseEnter={n7Enter}
          onMouseLeave={n7Leave}
          onClick={() => setMuscle(7)}
          d="M413.71,450.99c0,0-4.14-11.05,0.13-43.61c7.68-58.62,29.12-67.44,29.12-67.44
          c-7.42,0.86-27.7,5.56-45.28,23.47c-15.3,15.58-21.19,25.42-25.05,39.45c-4.51,16.37-6.09,23.05-8.97,46.03
          c-2.83,22.61-0.45,40.48-0.45,40.48C387.35,454.83,404.29,451.03,413.71,450.99z"
        />
        <path
          class="st14 n6"
          ref={n6_2}
          onMouseEnter={n6Enter}
          onMouseLeave={n6Leave}
          onClick={() => setMuscle(6)}
          d="M413.91,451.23c0.02,0.19,2.21-0.32,12.68-9.61c15.04-13.33,24.95-28.46,26.41-30.77
	c4.99-7.95,12.55-26.67,14.37-31.06c4.8-11.57,6.83-25.05,7.78-34.94c0.22-2.29,2.35-7.97,9.04-10.21c0,0-10.31,0.45-13.25,0.76
	c-4.52,0.48-13.17,1.56-18.04,2.41c-5.37,0.94-8.41,0.89-10.26,2.11C421.28,354,408.61,407.34,413.91,451.23z"
        />
        <g>
          <path
            class="st3"
            d="M445.86,420.86c-9.15,12.56,11.71,69.39,21.69,76.39c29.39,20.62,89.84,7.36,99.9-36.26
		c13.81-59.85-19.68-81.06-45.61-97.9C466,326.82,477.13,377.95,445.86,420.86z"
          />
        </g>
        <path
          class="st14 n3"
          ref={n3_2}
          onMouseEnter={n3Enter}
          onMouseLeave={n3Leave}
          onClick={() => setMuscle(3)}
          d="M496.02,351.14c-3.75-0.51-6.79-0.39-9.44,0.43c-9.56,2.95-12.89,13.06-15.63,18.2
	c1.98-8.2,3.09-16.24,3.92-23.07c0.68-5.59,2.78-8.58,6.56-10.83c3.49-1.7,8.74-2.59,13.7-3.25c3.47-0.46,5.65-0.8,11.8-1.24
	c0,0,19.29-4.16,41.31-3.28c12.07,0.48,19.14,3.93,22.76,9.84c15.43,33.5,10.93,51.5,10.48,92.87c0,6.86,0.43,24-6.57,37.43
	c-3.43,7.29-11.28,17.42-26.93,28.93c-18.73,14.57-41.53,11.06-61.55,7.61c23.05,3.47,39.34-0.11,55.1-9.55
	c18.67-12.55,21.72-22.04,26.34-35.74c9.09-35.73-4.15-59.76-11.96-68.64c-8.23-10.02-17.28-16.33-29.51-24.68
	C515.24,358.53,507.14,353.41,496.02,351.14z"
        />
        <path
          class="st1 n20"
          ref={n20_3}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M436.31,1325c1.91,17,7.53,27.41,11.05,42.01c10.53,43.8,26.95,105.58,23.48,173.19
	c0.18,0.04,1.45-31.56,1.55-89.9c0.05-31.4-0.95-64.71-2.7-103.47c-1.21-26.84-2.43-68.83-6.77-97.99
	c-2.75-18.47-7.93-32.54-7.93-32.54C444.39,1243.39,431.13,1278.86,436.31,1325z"
        />
        <path
          class="st1 n20"
          ref={n20_4}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M510.86,1505.67c-2.96-112.89,31.51-171.94,31.51-216.95c0-47.82-17.69-73.53-9.81-93.21
	c0,0-0.75,1.59-1.88,4.13c0,0-1.59,1.93-3.19,3.6c-0.87,0.91-2.38,2.18-2.75,2.72c-1.36,1.96-2.12,3.65-4.46,8.03
	c-4.74,8.86-10.36,24.11-11.95,34.81c-0.7,4.72-1.3,8.82-1.62,13.24c-2.2,30.48-4.23,62.27-4.58,93
	c-0.21,18.65,0.22,37.06,0.95,53.81c0.79,18.08,1.68,33.98,2.57,47.66C507.65,1487.04,510.86,1505.67,510.86,1505.67z"
        />
        <path
          class="st2 n21"
          ref={n21_2}
          /* onMouseEnter={n21Enter}
          onMouseLeave={n21Leave} */
          d="M455.5,1218.2c10.19,26.92,11.21,59.1,15.22,152.64c4.01,93.55,0.15,170.49,0.15,170.49s6.19-4.12,14.59-5.03
	c6.64-0.72,13.9,0.49,19.65,4.93c4.05,3.13,5.68,7.14,7.29,10.39c4.55,9.17,2.5,17.1,2.5,17.1c3.22-35.85-4.29-53.56-7.97-97.75
	c-2.49-29.98-4.12-64.04-4.6-97.3c-0.09-6.04-0.09-12.05-0.03-18c0.22-22.74,1.38-44.65,2.62-64.15
	c1.06-16.71,1.64-31.37,3.76-43.79c2.22-12.99,7.52-25.31,11.58-33.27c2.61-5.12,4.61-8.32,4.65-8.41c0,0-17.63-19.57-24-9.43
	c0,0-7.73,25.13-18.95,25.13c-8.43,0-14.5-12.1-14.5-12.1c-4.33-3.56-5.46-1.77-8.22,0.53c-2.92,2.44-3.95,4.95-4.26,6.73
	L455.5,1218.2z"
        />
        <path
          class="st3"
          d="M450.85,1172.66c-2.44,20.63-3.91,22.62,4.14,43.9c0,0,0.36-3.27,6.27-8.14c2.14-1.77,5.43-0.35,6.96,2.21
	c10.77,17.98,23.67,9.38,32.63-14.05c0,0,5.14-10.61,23.9,9.39c1.92-1.89,4.95-4.79,5.94-6.32c4.58-10.74,15.34-27.05,10.68-55.95
	c0,0-3.83,9.77-14.03,16.64c-8.12,5.47-17.52,6.17-23.23,3.35c-5.52-2.73-9.73-7.65-11.79-14.98c0,0-4.85,3.17-8.04,3.48
	c-5.21,0.51-11.98-5.59-11.98-5.59c4.89,8.15-5.21,18.05-7.45,20.28c-2.47,2.45-6.51,5.35-6.51,5.35
	C453.44,1174.86,450.85,1172.66,450.85,1172.66z"
        />
        <path
          class="st1"
          d="M457.48,1558.65c-16.91,17.87-38.51,52.35-40.59,66.06c-2.08,13.71,10,29.08,23.49,35.32
	c13.49,6.23,26.19,0.83,33.76-3.32c7.57-4.15,17.94-11.22,20.86-38.22c2.92-27.01,3.33-30.75,12.47-38.22
	c9.14-7.48,11.24-18.7,2.09-34.07C500.4,1530.81,466.91,1531.64,457.48,1558.65z"
        />
        <path
          class="st3"
          d="M462.98,1200.55c4.72,10.88,10.2,21.11,18.83,21.11c8.62,0,19.97-19.48,21.04-36.69
	c0.31-5.06,0.99-14.9,1.01-21.33c0,0-8.79-3.71-11.79-14.98c0,0-3.53,3.04-9.13,3.52c-4.24,0.36-10.71-5.57-10.71-5.57
	c6.12,11.9-14.08,25.53-14.08,25.53C459.2,1182.34,461.53,1197.2,462.98,1200.55z"
        />
        <path
          class="st1 n16"
          ref={n16_1}
          onMouseEnter={n16Enter}
          onMouseLeave={n16Leave}
          onClick={() => setMuscle(16)}
          d="M484.06,750.38c4.23,3.67,8.76,7.09,27.3,26.26c35.02,36.2,56.06,70.27,56.06,70.27
	c8.93,58.45-7.68,103.64-16.3,161.59c-7.93,53.33-2.48,29.28-5.75,74.93c0,0-0.6-11.53-2.79-25.93
	c-5.62-36.93-9.76-52.39-17.41-78.65c0,0-3.79-13.82-10.04-31.7c-4.84-13.83-16-44.5-18.74-52.38c-1-2.87-2.05-4.45-2.54-7.96
	c-1.97-14.17-4.73-32.94-6.39-50.87C485.1,810.42,483.11,781.54,484.06,750.38z"
        />
        <path
          class="st1 n18"
          ref={n18_1}
          onMouseEnter={n18Enter}
          onMouseLeave={n18Leave}
          onClick={() => setMuscle(18)}
          d="M437.98,709.66c1.91,7.86,28.31,26.42,28.31,26.42s-1.74,6.05-5.02,17.57c-3.87,13.57-9.65,34.95-14.94,64.27
	c-3.01,16.68-6.36,35.88-8.81,57.68c-1.78,15.82-2.83,33.04-4.09,51.53c-0.63,9.21-0.64,18.38-0.67,27.51
	c-0.09,29.48,1.86,57.95,4.77,80.48c3.1,24,7.19,41.25,8.18,46.23c4,19.97,11.86,40.4,16.86,50.83c3.41,7.12,9.06,13.81,9.06,13.81
	c1.98,2.53,2.52,6.96-0.07,11.89c-1.89,3.59-4.28,6.26-6.54,8.44c-2.32,2.25-4.51,3.98-6.94,5.75c-0.95,0.7-9.45,4.65-10.31-5.67
	c-1.62-19.33-10.37-42.58-26.76-104.88c-20.45-77.69-21.91-144.87-20.48-193.51C401.28,842.71,411.13,759.77,437.98,709.66z"
        />
        <path
          class="st2 n17"
          ref={n17_2}
          onMouseEnter={n17Enter}
          onMouseLeave={n17Leave}
          onClick={() => setMuscle(17)}
          d="M543.38,1135.82c-2.89,19.06-24.4,34.89-39.5,27.76c-4.91-2.32-9.22-6.54-11.82-14.91c0,0,7.07-5.91,9.97-19.61
	c0,0,7.15-22.81,8.87-61.49c1.07-24.22-0.17-53.59-3.57-84.7c-2.7-24.78-9.09-66.23-13.4-96.08c-0.88-6.13-1.63-11.55-2.39-16.57
	c0,0,1.36,10.85,2.3,16.6C496.21,901.32,558.6,1035.46,543.38,1135.82z"
        />
        <path
          class="st2 n19"
          ref={n19_2}
          onMouseEnter={n19Enter}
          onMouseLeave={n19Leave}
          onClick={() => setMuscle(19)}
          d="M501.22,1131.98c20.95-78.97,4.76-160.16-7.37-245.38c-5.31-37.31-9.93-75.49-10.17-114.43
	c-0.05-7.24,0.06-14.5,0.33-21.8l-17.71-14.29c-60.39,203.28-25.37,357.04-1.86,399.65c6.45,11.69,14.17,16.44,18.57,16.44
	C491.55,1152.17,498.15,1143.54,501.22,1131.98z"
        />
        <path
          class="st4 n10"
          ref={n10_1}
          onMouseEnter={n10Enter}
          onMouseLeave={n10Leave}
          onClick={() => setMuscle(10)}
          d="M386.11,602.97l-6.51-24.81c0,0,29.07-55.99,33.79-127.17c0,0,8.25-4.3,20.25-15.53
	c7.14-6.68,10.46-9.75,10.46-9.75c-0.42,9.81-1.18,19.42-1.18,19.42C444.15,509.13,423.22,565.55,386.11,602.97z"
        />
        <path
          class="st4"
          d="M274.67,838.18c-0.01,0.03-0.03,0.03-0.03,0.03c9.26-5.96,34.85,16.84,34.85,16.84s-0.01-0.02,0-0.05
	c0.2-1.6,6.72-53.49,26.95-100.21C311.9,807.6,275.99,837.11,274.67,838.18z"
        />
        <path
          class="st4 n12"
          ref={n12_1}
          onMouseEnter={n12Enter}
          onMouseLeave={n12Leave}
          onClick={() => setMuscle(12)}
          d="M336.44,754.84c-20.23,46.72-26.75,98.6-26.95,100.21c12.91-40.64,113.7-146.51,70.11-276.88l-21.14,43.1
	c0,0,12.55,30.6-10.3,103.41C344.78,735.44,340.78,745.5,336.44,754.84z"
        />
        <path
          class="st4"
          d="M308.19,855.73l-0.02,0.01c-6.03-12.76-18.32-22.79-28.75-23.75c-15.53,7.66-34.65,10.79-46.48,22.41
	c-11.81,11.62-18.86,13.82-30.99,17.47c-12.13,3.63-7.28,12.89,8.43,11.38c15.71-1.51,23.95-7.87,31.09-6.34l0,0
	c-18.91,37.3-20.21,59.55-20.61,72.67c-0.49,16.03,36.55,14.13,51.6-3.63c14.59-17.22,27.08-45.38,27.97-47.6
	c0.03-0.08,0.05-0.11,0.05-0.11c6.62-11.02,9.6-24.57,9.73-34.11C310.24,861.32,309.51,858.48,308.19,855.73z"
        />
        <path
          class="st5 n11"
          ref={n11_1}
          onMouseEnter={n11Enter}
          onMouseLeave={n11Leave}
          onClick={() => setMuscle(11)}
          d="M279.42,831.98c-1.55,2.31,32.47-24.34,57.02-77.14c4.34-9.34,8.34-19.4,11.71-30.16
	c22.85-72.81,10.3-103.41,10.3-103.41l-7-29.3c0,0-41.02,47.47-41.91,91.89C307.97,761.09,295.19,808.54,279.42,831.98z"
        />
        <path
          class="st7"
          d="M241.47,876.91c12.93-0.75,32.42,4.21,49.13-18.58"
        />
        <g>
          <path
            class="st8 n9"
            ref={n9_2}
            onMouseEnter={n9Enter}
            onMouseLeave={n9Leave}
            onClick={() => setMuscle(9)}
            d="M358.34,621.26c38.04-70.98,52.8-124.48,55.05-170.27c0,0-20.08-2.98-50.5,38.37
		C345.81,512.57,348.6,597.99,358.34,621.26z"
          />
        </g>
        <path
          class="st12 n15"
          ref={n15_1}
          onMouseEnter={n15Enter}
          onMouseLeave={n15Leave}
          onClick={() => setMuscle(15)}
          d="M471.08,553.97c0-0.01-3.64-15.7-7.83-31.12c-5.82-21.45-6.79-43.89-6.79-43.89
	c5.09,14.11,10.98,20.96,24.12,24.49c12.7,3.41,27.31,9.01,33.96,18.61c7.47,10.79,14.27,31.92,14.27,31.92
	c-13.2,41.63-8.05,83.57-5.79,119.2c-2.27,43.32-17.69,50.14-32.69,56.57c-0.16,0.07-24.04,6.34-24.04,6.34
	c-4.62-3.24-10.37-7.43-17.63-13.78c-10.39-9.08-10.69-12.64-10.69-12.64c1.12-7.5,9.06-18.98,13.41-25.45
	c5.38-8,15.39-20.79,19.18-27.39c7.24-12.64,8.58-15.72,8.62-15.8c5.79-9.64,8.79-30.64-1.95-64.24
	C476.49,574.46,472.97,560.67,471.08,553.97z"
        />
        <path
          class="st18"
          d="M551.44,234.38c3.96,13.11,4.31,27.87-2.03,40c-3.3,6.3-8.99,12.04-16.08,12.61
	c-8.49,0.69-15.7-6.02-20.81-12.84c-12.63-16.86-19.93-37.66-20.6-58.72c-0.22-6.83,0.43-14.18,4.75-19.46
	c9.44-11.53,25-5.44,34.65,2.2C541.46,206.22,547.82,222.36,551.44,234.38z"
        />
        <path
          class="st18"
          d="M619.1,207.93c2.58-4.86,5.54-9.65,9.74-13.19c4.55-3.84,10.24-5.98,15.82-8.05
	c9.25-3.44,19.59-6.88,28.71-3.1l-4.59-8.21c15.44,18.51,21.43,44.5,15.65,67.9c-4.13,16.71-15.77,33.12-32.68,36.27
	c-3.92,0.73-7.94,0.72-11.92,0.48c-3.49-0.21-7-0.61-10.3-1.75c-8.51-2.95-14.72-10.66-17.77-19.13
	C605.73,242.42,610.78,223.62,619.1,207.93z"
        />
        <g class="st19">
          <path
            class="st20"
            d="M659.54,203.17l-0.97-1.64C659.12,201.9,659.53,202.49,659.54,203.17z"
          />
          <path
            class="st20"
            d="M658.56,201.53c-0.12-0.08-0.24-0.14-0.36-0.2c-0.38-0.83-0.78-1.65-1.21-2.46L658.56,201.53z"
          />
        </g>
        <path
          class="st21"
          d="M641.97,243.24c-0.02,0.55-0.14,1.1-0.33,1.61c0.52-1.21,1.12-2.39,1.86-3.48c-1.73,1.37-3.64,2.53-5.66,3.42
	c0.56-1.56,5.21-6.86,4.25-8.21c-2.86,3.72-7.2,6.27-11.85,6.95c4.05-1.66,5.36-6.58,6.98-10.64c2.47-6.2,6.75-11.75,7.91-18.32
	c0.92-5.19-0.22-10.49-0.53-15.76c-0.03-0.5-0.03-1.06,0.29-1.44c0.25-0.29,0.64-0.41,1-0.51c2.14-0.61,4.42-1.14,6.58-0.6
	c2.16,0.54,4.14,2.48,3.96,4.7c0.55-0.28,1.19-0.23,1.74,0.02c2.19,4.82,3.47,10.05,3.82,15.33c1.02,4.9,1.17,10.05,0.64,15.01
	c-0.5,4.68-1.65,9.27-2.79,13.84c-0.65,2.6-1.31,5.23-2.48,7.64c-3.45,7.11-10.83,11.41-18.15,14.4
	c7.53-3.92,12.98-11.6,14.18-20.01c-2.94,5.04-7.19,9.32-12.21,12.31c3.22-2.3,5.74-5.57,7.16-9.27c-2.82,2.9-6.12,5.34-9.72,7.18
	c4.21-3.08,7.57-7.23,9.85-11.91c-3.73,3.72-8.75,6.14-13.99,6.12C638.03,250.07,640.84,246.93,641.97,243.24z"
        />
        <g class="st19">
          <path
            class="st20"
            d="M662.59,206.71c0,0,0.07,0.31,0.2,0.9c0.15,0.63,0.39,1.49,0.77,2.56c0.37,1.07,0.89,2.32,1.45,3.73
		c0.55,1.42,1.16,2.97,1.54,4.75c0.38,1.73,0.56,3.73,0.56,5.84c0.01,2.12-0.14,4.38-0.27,6.73c-0.28,4.69-0.62,9.68-1.78,14.52
		c-0.57,2.42-1.36,4.77-2.4,6.98c-1.04,2.2-2.31,4.26-3.66,6.17c-1.35,1.91-2.76,3.7-4.16,5.33c-1.4,1.63-2.84,3.07-4.3,4.24
		c-2.91,2.38-5.9,3.55-8.05,3.95c-1.08,0.21-1.94,0.26-2.53,0.26c-0.59,0-0.9-0.02-0.9-0.02s0.31,0.03,0.9,0.04
		c0.59,0.02,1.46,0,2.55-0.19c1.09-0.18,2.39-0.53,3.81-1.14c1.41-0.61,2.93-1.48,4.44-2.64c1.51-1.15,3-2.57,4.47-4.17
		c1.46-1.61,2.92-3.36,4.34-5.26c1.42-1.9,2.77-3.95,3.9-6.2c1.12-2.23,2.01-4.64,2.66-7.1c1.32-4.92,1.8-9.99,2.21-14.68
		c0.2-2.34,0.41-4.62,0.46-6.82c0.05-2.2-0.07-4.29-0.44-6.26c-0.36-1.91-0.97-3.62-1.49-5.06c-0.53-1.45-1-2.68-1.32-3.7
		c-0.33-1.01-0.53-1.82-0.64-2.34c-0.11-0.57-0.17-0.87-0.17-0.87L662.59,206.71z"
          />
        </g>
        <g class="st19">
          <path
            class="st20"
            d="M661.91,201.53c0,0,0.19,0.23,0.54,0.66c0.36,0.45,0.79,1.01,1.35,1.87c1.1,1.66,2.44,4.23,3.48,7.57
		c0.52,1.66,0.96,3.51,1.31,5.5c0.18,1.01,0.32,1.99,0.43,3.07c0.11,1.07,0.27,2.17,0.29,3.25c0.28,4.37,0.06,9.13-0.71,13.78
		c-0.76,4.66-2.07,9.23-3.72,13.35c-0.44,1.02-0.83,2.04-1.29,3c-0.47,0.96-0.93,1.9-1.37,2.82c-0.5,0.89-0.98,1.75-1.45,2.59
		c-0.46,0.84-1,1.6-1.46,2.36c-0.24,0.38-0.47,0.74-0.7,1.1c-0.25,0.35-0.49,0.69-0.73,1.01c-0.47,0.66-0.93,1.29-1.35,1.87
		c-0.92,1.12-1.69,2.12-2.37,2.91c-1.4,1.54-2.21,2.42-2.21,2.42s0.83-0.86,2.28-2.36c0.7-0.77,1.51-1.75,2.46-2.84
		c0.44-0.58,0.91-1.19,1.41-1.84c0.25-0.32,0.5-0.66,0.76-1c0.24-0.36,0.49-0.72,0.74-1.09c0.49-0.75,1.05-1.5,1.54-2.33
		c0.49-0.83,1.01-1.69,1.53-2.57c0.48-0.91,0.97-1.85,1.47-2.8c0.49-0.96,0.92-1.98,1.39-3c1.79-4.12,3.25-8.71,4.16-13.42
		c0.92-4.72,1.29-9.53,1.13-14.07c0-1.15-0.13-2.23-0.2-3.3c-0.08-1.06-0.19-2.17-0.35-3.18c-0.3-2.06-0.7-3.99-1.2-5.75
		c-0.98-3.53-2.33-6.33-3.48-8.17c-0.55-0.91-1.1-1.66-1.42-2.09c-0.34-0.44-0.52-0.68-0.52-0.68L661.91,201.53z"
          />
        </g>
        <path
          class="st21"
          d="M522.34,240.91c-2.09-1.9-4.18-3.8-6.26-5.69c0.66,0.64,1.32,1.29,1.98,1.93c-0.76-0.39-1.51-0.77-2.27-1.16
	c1.42,2.04,2.84,4.07,4.25,6.11c-1.19-0.84-2.38-1.67-3.56-2.51c5.22,9.07,11.58,17.43,18.87,24.79
	c-6.58-4.34-12.72-9.42-18.29-15.11c2.16,2.65,4.41,5.21,6.76,7.67c-1.39-0.89-2.83-1.68-4.31-2.39c3.31,4.75,6.62,9.49,9.93,14.23
	c-6.25-4.18-11.99-9.21-17.01-14.93c1.36,2.17,2.72,4.35,4.08,6.53c-6.23-4.73-11.47-11.23-13.66-18.95
	c-0.83-2.91-1.2-5.94-1.84-8.91c-1.72-8.06-5.35-15.9-4.79-24.13c0.05-0.67,0.13-1.36,0.47-1.93c0.37-0.61,0.98-1,1.57-1.36
	c1.04-0.64,2.09-1.29,3.13-1.93c0.05-0.9,0.12-1.84,0.64-2.57c0.51-0.72,1.62-1.04,2.23-0.41c0.22,0.23,0.34,0.55,0.45,0.85
	c1.44,3.9,3.09,7.79,4.26,11.79c0.87,2.96,1.11,6.04,1.87,9.02C512.7,229.11,517.85,235.29,522.34,240.91z"
        />
        <path
          class="st18"
          d="M657.28,200.8c0,0,0.15,0.34,0.42,0.97c0.27,0.63,0.7,1.57,1.14,2.74c0.48,1.16,1.01,2.61,1.61,4.27
	c0.58,1.67,1.2,3.57,1.81,5.66c0.29,1.05,0.59,2.15,0.9,3.28c0.26,1.14,0.57,2.32,0.82,3.54c0.52,2.44,0.98,5.04,1.32,7.74
	c0.32,2.7,0.58,5.51,0.59,8.37c0.03,2.85-0.08,5.76-0.48,8.63c-0.4,2.86-0.96,5.73-1.89,8.42c-0.92,2.7-2.08,5.27-3.51,7.58
	c-1.47,2.29-3.13,4.34-4.94,6.07c-1.84,1.68-3.72,3.15-5.63,4.23c-0.47,0.28-0.94,0.55-1.39,0.81c-0.47,0.23-0.94,0.45-1.39,0.67
	c-0.45,0.22-0.89,0.43-1.32,0.63c-0.44,0.17-0.87,0.33-1.29,0.49c-1.65,0.69-3.17,1.07-4.4,1.44c-1.24,0.35-2.24,0.5-2.91,0.66
	c-0.68,0.15-1.04,0.22-1.04,0.22s0.36-0.07,1.04-0.19c0.67-0.14,1.68-0.26,2.94-0.56c1.25-0.33,2.78-0.66,4.47-1.29
	c0.43-0.15,0.87-0.3,1.32-0.46c0.44-0.19,0.89-0.39,1.36-0.59c0.46-0.2,0.94-0.41,1.43-0.62c0.47-0.25,0.95-0.51,1.44-0.78
	c1.98-1.02,3.94-2.45,5.89-4.11c1.91-1.71,3.7-3.76,5.29-6.07c1.55-2.34,2.85-4.95,3.89-7.7c1.06-2.75,1.74-5.68,2.26-8.62
	c0.52-2.95,0.74-5.93,0.81-8.87c0.09-2.94-0.08-5.83-0.31-8.62c-0.25-2.79-0.63-5.46-1.08-7.99c-0.21-1.26-0.49-2.48-0.72-3.66
	c-0.28-1.17-0.54-2.31-0.8-3.4c-0.55-2.17-1.12-4.14-1.66-5.88c-0.56-1.73-1.06-3.24-1.52-4.48c-0.44-1.26-0.82-2.17-1.06-2.82
	c-0.25-0.64-0.38-0.99-0.38-0.99L657.28,200.8z"
        />
        <path
          class="st22"
          d="M496.33,207.82c0,0,0.09,1.25,0.24,3.44c0.12,1.11,0.22,2.48,0.43,4.01c0.1,0.77,0.21,1.58,0.32,2.45
	c0.14,0.86,0.29,1.76,0.45,2.7c0.34,1.88,0.76,3.9,1.28,6.02c0.26,1.06,0.58,2.14,0.87,3.25c0.34,1.1,0.67,2.22,1.06,3.35
	c0.78,2.25,1.67,4.55,2.73,6.82c1.07,2.26,2.23,4.53,3.61,6.67c1.37,2.14,2.85,4.22,4.53,6.08c1.66,1.87,3.46,3.57,5.34,5.04
	c1.9,1.45,3.88,2.64,5.85,3.57c1.96,0.96,3.96,1.54,5.79,1.98c0.93,0.18,1.82,0.37,2.68,0.44c0.43,0.05,0.84,0.1,1.25,0.13
	c0.41,0.01,0.8,0.03,1.19,0.04c0.77,0.05,1.48,0,2.15-0.02c0.66-0.01,1.27-0.08,1.81-0.15c1.1-0.1,1.94-0.28,2.52-0.39
	c0.58-0.11,0.88-0.17,0.88-0.17s-0.31,0.05-0.89,0.13c-0.58,0.09-1.43,0.23-2.52,0.29c-0.55,0.05-1.15,0.09-1.81,0.07
	c-0.66,0-1.37,0.02-2.13-0.07c-0.38-0.03-0.77-0.06-1.17-0.09c-0.4-0.05-0.81-0.12-1.23-0.18c-0.84-0.1-1.71-0.33-2.61-0.54
	c-1.79-0.51-3.7-1.16-5.58-2.17c-1.88-0.99-3.76-2.22-5.54-3.7c-1.76-1.5-3.43-3.22-4.97-5.09c-1.55-1.87-2.9-3.95-4.14-6.07
	c-1.25-2.12-2.29-4.37-3.24-6.6c-0.93-2.24-1.7-4.51-2.37-6.72c-0.33-1.11-0.61-2.21-0.9-3.29c-0.24-1.09-0.51-2.14-0.72-3.18
	c-0.42-2.08-0.75-4.06-1-5.89c-0.11-0.92-0.22-1.8-0.33-2.64c-0.08-0.84-0.15-1.64-0.22-2.39c-0.14-1.5-0.18-2.79-0.25-3.86
	c-0.06-2.19-0.1-3.44-0.1-3.44L496.33,207.82z"
        />
        <path
          class="st22"
          d="M648.39,199c0,0,0.16,0.35,0.47,1.01c0.3,0.66,0.76,1.63,1.29,2.86c1.08,2.45,2.58,6,4.14,10.34
	c1.56,4.34,3.19,9.48,4.37,15.1c0.59,2.81,1.05,5.74,1.28,8.73c0.24,2.99,0.2,6.05-0.31,9.01c-0.26,1.48-0.64,2.93-1.23,4.29
	c-0.56,1.36-1.32,2.64-2.3,3.69c-1.92,2.18-4.68,3.38-7.48,3.78c-0.73,0.14-1.4,0.16-2.09,0.22c-0.69-0.01-1.39,0.04-2.06-0.05
	c-1.36-0.09-2.67-0.38-3.9-0.81c-1.23-0.41-2.38-0.99-3.41-1.65c-1.04-0.65-1.99-1.4-2.82-2.19c-1.69-1.56-2.98-3.27-4.02-4.83
	c-1.02-1.58-1.79-3.04-2.36-4.27c-1.14-2.46-1.56-3.96-1.56-3.96s0.37,1.51,1.43,4.02c0.53,1.25,1.25,2.74,2.23,4.37
	c1,1.61,2.24,3.39,3.92,5.04c0.83,0.84,1.77,1.63,2.83,2.35c1.05,0.73,2.22,1.36,3.5,1.84c1.27,0.49,2.65,0.85,4.08,0.99
	c0.71,0.11,1.44,0.09,2.18,0.12c0.74-0.04,1.51-0.05,2.21-0.16c2.94-0.31,6.09-1.52,8.31-3.89c1.14-1.15,2.02-2.54,2.69-4
	c0.7-1.46,1.16-3.01,1.48-4.58c0.65-3.14,0.79-6.33,0.65-9.44c-0.13-3.11-0.52-6.14-1.02-9.04c-1.02-5.81-2.51-11.1-3.95-15.57
	c-1.44-4.47-2.85-8.12-3.87-10.67c-0.51-1.29-0.93-2.26-1.21-2.93c-0.28-0.67-0.43-1.03-0.43-1.03L648.39,199z"
        />
        <path
          class="st23"
          d="M602.25,65.24c37.03,13.02,37.35,38.04,41.92,54.19l1.82,27.93c1.57,18.72,4.21,33.27,4.21,55.36l-3.98,18.19
	c-3.23,9.33-24.42,44.72-33.27,49.1l-16.43,8.42c-4.29,1.66-27.25,6.19-44.48-9.85l-13.03-12.51c-11.17-7.73-24.51-31.73-26.9-50.38
	l-1.85-31.96l-3.21-37.7c-2.62-21.35,3.38-41.39,14.98-52.65l6.97-6.74c13.26-10.22,43.14-19.76,62.26-13.83L602.25,65.24z"
        />
        <path
          class="st24"
          d="M510.4,163.36c-6.59-7.1-16.43-9.62-15.63,1.16c0.8,10.79,5.47,14.96,6.74,26.97
	c1.27,12.02,6.4,27.54,12.65,22.38C520.39,208.71,510.4,163.36,510.4,163.36z"
        />
        <path
          class="st24"
          d="M644.76,153.38c5.47-8,14.83-11.95,15.63-1.16c0.8,10.79-3.2,15.6-2.68,27.67c0.52,12.07-2.26,28.19-9.2,24
	C641.57,199.71,644.76,153.38,644.76,153.38z"
        />
        <path
          class="st25"
          d="M533.89,88.98c0,0-0.88,1.83-2.3,5.04c-0.71,1.61-1.54,3.57-2.35,5.86c-0.81,2.29-1.6,4.91-2.08,7.83
	c-0.54,2.89-0.45,6.15-0.67,9.68c-0.04,0.44-0.08,0.89-0.12,1.34l-0.09,0.71l-0.11,0.66c-0.18,0.89-0.24,1.76-0.53,2.68
	c-0.45,1.85-1.05,3.66-1.79,5.44c-2.94,7.17-7.81,13.74-11.57,20.84c-1.89,3.54-3.44,7.27-4.27,11.12
	c-0.82,3.85-0.93,7.77-0.99,11.56c-0.04,3.79-0.08,7.47-0.41,10.95c-0.3,3.47-0.76,6.74-1.32,9.73c-1.12,5.98-2.6,10.84-3.76,14.18
	c-0.58,1.68-1.08,2.97-1.43,3.86c-0.35,0.88-0.54,1.35-0.54,1.35s0.18-0.47,0.52-1.36c0.34-0.89,0.81-2.19,1.36-3.88
	c1.11-3.36,2.5-8.24,3.51-14.21c0.51-2.99,0.91-6.25,1.15-9.71c0.26-3.46,0.25-7.12,0.22-10.92c0-3.79,0.04-7.76,0.81-11.73
	c0.78-3.97,2.31-7.82,4.17-11.44c3.7-7.26,8.44-13.91,11.15-20.89c0.69-1.73,1.23-3.49,1.62-5.19c0.25-0.83,0.31-1.77,0.47-2.64
	l0.1-0.66l0.06-0.6c0.03-0.43,0.06-0.86,0.09-1.28c0.16-3.4,0.01-6.76,0.53-9.87c0.45-3.08,1.23-5.82,2.03-8.19
	c0.8-2.38,1.62-4.39,2.31-6.03c1.4-3.29,2.24-5.12,2.24-5.12L533.89,88.98z"
        />
        <path
          class="st25"
          d="M538.29,87.82c0,0-0.01,0.16-0.02,0.47c-0.03,0.32,0,0.74-0.09,1.4c-0.11,1.32-0.44,3.11-1.2,5.32
	c-0.76,2.19-1.95,4.73-3.49,7.46c-0.77,1.37-1.62,2.8-2.5,4.31c-0.44,0.75-0.9,1.54-1.32,2.3c-0.43,0.81-0.88,1.64-1.33,2.48
	c-0.23,0.42-0.45,0.85-0.68,1.28c-0.21,0.44-0.42,0.87-0.63,1.32c-0.42,0.89-0.85,1.79-1.29,2.71c-0.78,1.89-1.68,3.82-2.41,5.87
	c-1.58,4.06-2.95,8.47-4.19,13.07c-2.5,9.21-4.21,19.29-5.68,29.39c-0.69,5.06-1.38,10.13-2.06,15.11
	c-0.67,4.99-1.43,9.92-2.84,14.55c-1.4,4.62-3.29,8.86-4.79,12.98c-1.54,4.1-2.61,8.12-2.99,11.93c-0.41,3.8-0.3,7.32-0.07,10.42
	c0.24,3.1,0.63,5.79,0.97,8c0.7,4.42,1.18,6.93,1.18,6.93s-0.51-2.5-1.27-6.91c-0.37-2.2-0.79-4.89-1.08-8
	c-0.27-3.1-0.43-6.64-0.07-10.47c0.33-3.84,1.36-7.92,2.85-12.06c1.45-4.15,3.29-8.42,4.6-13c1.33-4.58,2.01-9.48,2.62-14.46
	c0.61-5,1.24-10.07,1.86-15.14c1.34-10.14,2.91-20.29,5.31-29.62c1.19-4.66,2.51-9.14,4.06-13.28c0.71-2.09,1.6-4.06,2.37-6
	c0.44-0.95,0.87-1.89,1.29-2.8c0.21-0.46,0.42-0.91,0.63-1.37c0.22-0.43,0.45-0.86,0.67-1.29c0.44-0.85,0.87-1.69,1.29-2.5
	c0.45-0.85,0.89-1.61,1.32-2.38c0.87-1.52,1.69-2.95,2.42-4.31c1.48-2.7,2.57-5.12,3.25-7.17c0.67-2.03,0.95-3.73,1.02-4.8
	c0.08-0.52,0.05-1.03,0.07-1.33c0.01-0.31,0.01-0.47,0.01-0.47L538.29,87.82z"
        />
        <path
          class="st22"
          d="M532.7,90.21c-0.06,7.07-0.61,14.3-3.47,20.76c-2.87,6.5-7.85,11.78-12.25,17.36
	c-4.4,5.58-8.41,11.96-8.73,19.05c-0.64-5.45-0.93-10.95-0.87-16.44c0.04-3.67,0.25-7.38,1.3-10.9c1.1-3.7,3.09-7.06,5.22-10.28
	c5.55-8.36,12.19-16.01,19.69-22.67c0.48,4.54-0.26,9.2-2.13,13.36L532.7,90.21z"
        />
        <path
          class="st18"
          d="M533.91,89.78c-12.52,22.24,1.27,54.24,41.32,71.51c40.05,17.27,70.03,48.06,92.92,47.96
	c0,0,15.51,36.66-0.56,57.03c-16.06,20.37-42.5,15.41-47.4,7.25c0,0,10.09,21.09,38.14,11.55c28.05-9.54,20.36-27.08,34.66-42.52
	c14.3-15.44,4.84-28.14,7.74-39.23c2.9-11.09-6.55-23.71-8.07-36.91c-1.51-13.2-2.28-30.72-20.59-54.93
	c-18.31-24.21-29.65-47.86-50.88-61.2c-21.23-13.34-54.92-15.1-77,3.06c0,0-19.86-9.18-33.84,10.5
	c-13.98,19.68-17.12,34.82-25.83,46.66c-8.71,11.83-20.33,63.29-10.3,105.16c10.03,41.86,25.32,82.8,77.05,69.38
	c0,0-44.18-6.84-48.31-48.08c-4.13-41.24,8.58-76.5,7.83-87.38c-0.75-10.89,4.13-29.76,11.57-38.69
	C529.81,101.95,526.88,98.28,533.91,89.78z"
        />
        <path
          class="st22"
          d="M536.2,107.31c0.1,2.15,0.19,4.31,0.29,6.46c-0.73-1.14-1.45-2.28-2.18-3.42c0.4,2.23,0.8,4.46,1.2,6.68
	c-3.6-5.01-5.47-11.22-5.3-17.38c0.37-1.75,0.86-3.46,1.49-5.14c0.82-1.48,1.76-2.94,2.2-4.74c3.06-12.41,2.21-32.71,14.75-39.74
	c6.6-3.7,13.71-5.07,20.45-5.75c9.48-0.96,19.76,3.62,26.88,4.35c-6.76-0.69-7.12,0.76-17.82-0.14c5.86,0.49,11.73,2.27,16.07,6.24
	c-7.92-2.95-16.44-4.26-24.87-3.84c11.31,1.43,22.03,7.15,29.52,15.74c-12.83-8.78-29.28-12.07-44.5-8.9
	c8.03-0.61,16.25,1.88,22.59,6.84c-10.41-4.46-16.11-2.58-18.99-2.43c11.47,3.93,21.88,10.88,29.9,19.98
	c-10.36-5.93-21.63-10.25-33.28-12.85c2.89,0.86,5.56,2.17,7.58,4.38c-2.02-0.42-4.03-0.85-6.05-1.27c1.72,1.28,3.44,2.56,5.16,3.84
	c-2.97-1.23-6.13-2-9.33-2.26c11.18,8.39,21.23,18.3,29.76,29.36c-9.72-9.09-21.34-16.13-33.9-20.52
	c4.61,4.32,8.73,9.14,12.28,14.36c-3.06-3.32-8.78-8.06-12.47-10.64c-0.12,1.1,2.07,3.97,1.96,5.07c-1.98-1.49-3.97-2.98-5.95-4.47
	c0.88,6.48,3.22,12.76,6.8,18.23c-3.12-3.74-6.23-7.48-9.35-11.22c1.92,4.75,3.84,9.5,5.76,14.25c1.88,4.65,3.81,9.39,7.05,13.22
	c3.89,4.6,8.13,10.5,13.45,13.32c-20.64-10.82-21.67-18.11-28.41-29.88c0.72,3.25,1.44,6.5,2.16,9.75
	C539.96,112.03,538.28,109.48,536.2,107.31z"
        />
        <g>
          <g>
            <path
              class="st22"
              d="M541.67,68.11c-1.04,7.61-4.79,6.1-6.27,13.61c-0.49,2.49-1.25,5.62-2.2,8.96
			c-6.11,7.87-3.65,11.59-10.82,20.2c-2.94,3.53-5.47,8.61-7.42,14.14c-0.76,1.55-1.31,3.19-1.56,4.94
			c-0.01,0.04-0.02,0.08-0.04,0.13c-1.66-2.81-0.59-6.38,0.59-9.42c2.19-5.65,4.66-11.18,7.4-16.58c-3.6,1.96-6.8,4.65-9.35,7.86
			c0.69-3.31,2.08-6.47,4.07-9.21c-0.75,0.58-1.51,1.15-2.26,1.73c1.82-5.23,5.01-9.98,9.17-13.63c-5.68,1.29-9.61,6.3-13.07,10.98
			c2.14-7.54,7.76-14.02,14.93-17.21c-3.44-0.92-7.15-0.7-10.51,0.5c3.39-1.94,6.67-4.06,9.81-6.38c-1.8,0.08-3.6,0.16-5.4,0.23
			c0.74-0.54,1.33-1.29,1.69-2.14c-0.85,0.48-1.69,0.97-2.54,1.45c0.8-1.45,1.6-2.9,2.4-4.35c-4.1,1.7-8.1,3.64-11.97,5.82
			c5.76-5.65,12.68-10.12,20.2-13.05c-3.56-2.11-8-2.69-11.99-1.56c2.77-1.73,5.78-3.07,8.91-3.98c-0.86-0.04-1.72-0.09-2.58-0.13
			c2.97,0.07,5.95-0.32,8.79-1.16c-2.79-2.12-6.29-3.3-9.8-3.29c3.94-0.83,8.03-0.89,11.99-0.18c-2.53-1.41-5.06-2.83-7.59-4.25
			c9.65-2.61,17.95,1.22,17.95,1.22c1.41-1.16,2.87-2.23,4.37-3.23c-0.88,0.63-4.14,4.64-3.85,4.35
			C541.9,58.66,542.37,62.97,541.67,68.11z"
            />
            <path
              class="st22"
              d="M532.87,91.79c-0.81,2.75-1.74,5.6-2.75,8.35C530.67,97.26,531.58,94.46,532.87,91.79z"
            />
          </g>
        </g>
        <g>
          <path
            class="st18"
            d="M657.77,162.82c-0.61-1.24-1.04-2.5-1.23-3.79C656.92,160.27,657.33,161.54,657.77,162.82z"
          />
          <path
            class="st18"
            d="M689.38,222.77c0.91,1.44,1.81,2.88,2.72,4.32c-1.35-5.07-3.24-9.99-5.63-14.66
		c1.44,1.61,2.89,3.22,4.33,4.84c-3.04-7.04-7.17-13.61-12.2-19.4c2.45,2.13,4.91,4.26,7.36,6.39c-4.18-6.52-9.41-12.37-15.43-17.24
		c-4.42-3.57-9.41-6.8-12.12-11.79c3.51,3,7.01,5.99,10.52,8.99c-4.19-3.68-7.53-8.33-9.67-13.48c2.22,2.08,4.43,4.17,6.65,6.25
		c-3.98-3.74-6.36-8.97-8.14-14.15c2.53,5.18,8.09,9.82,11.9,13.29c5.09,4.62,10.15,8.92,13.9,13.91c2.14,1.83,4.07,3.9,5.73,6.18
		c-1.39-4.69-3.65-9.12-6.64-12.99c5.03,5.09,9.28,10.94,12.56,17.3c-0.75-1.08-1.51-2.16-2.26-3.23c3.32,5.66,6.21,11.69,8,17.98
		c0.89,8.2,1.94,17.29-7.61,27.61c-0.62,0.67-1.18,1.34-1.72,2.01C691.9,237.44,691.16,229.98,689.38,222.77z"
          />
        </g>
        <path
          class="st18"
          d="M626.35,162.43c-3.82,0.54-7.75,0.24-11.45-0.86c2.73,1,5.46,1.99,8.2,2.99c-0.9,0.22-1.88,0.13-2.72-0.26
	c8.86,4.52,18.29,7.92,28,10.09c-6.26,0.02-12.53-0.59-18.66-1.85c3.88,1.78,7.76,3.56,11.63,5.33c-1.74-0.39-3.48-0.78-5.22-1.17
	c9.73,3.81,18.81,9.27,26.74,16.08c-5.23-1.95-10.46-3.9-15.7-5.85c5.21,3.22,10.21,6.88,14.16,11.56
	c-14.58-6.56-29.16-13.12-43.74-19.67c-7.29-3.28-14.63-6.59-21.13-11.23c-4.29-3.06-8.47-6.81-11.34-11.27
	c-4.16-6.46,2.47-3.69,6.24-2.2C602.55,158.52,614.52,160.49,626.35,162.43z"
        />
        <path
          class="st18"
          d="M587.15,65.53c7.17,3.02,18.34,10.06,21.72,13c4.56,3.96,8.7,8.38,12.64,12.96
	c7.99,9.29,15.22,19.27,24.03,27.79c-4.21-2.28-8.14-5.07-11.7-8.28c0.53,0.5,1.06,1,1.59,1.5c-1.33-0.49-2.59-1.18-3.72-2.04
	c7.54,9.83,16.56,18.52,26.65,25.69c-7.6-2.83-14.62-7.21-20.52-12.78c9.18,10.5,18.35,21,27.53,31.51
	c-7.59-7.6-15.19-15.19-22.78-22.79c0.5,0.82,0.99,1.65,1.49,2.47c-2.31-1.71-4.51-3.56-6.59-5.54c0.27,0.57,0.54,1.13,0.81,1.7
	c-11.15-8.33-21.38-17.88-30.47-28.42c4.81,1.65,9.28,4.27,13.11,7.61c-6.01-5.64-12.19-11.1-18.54-16.35
	c1.79,0.28,3.54,0.81,5.2,1.52c-0.28-0.23-0.54-0.48-0.78-0.75c2.35,1.02,4.71,2.04,7.06,3.06c-5.84-5.74-11.68-11.47-17.52-17.21
	c4.81,1.48,9.42,3.61,13.67,6.3c-4.62-3.67-9.08-7.54-13.36-11.6c1.35,0.13,2.67,0.58,3.82,1.29c-0.87-0.75-1.74-1.5-2.6-2.25
	C597.07,73.22,589.8,66.55,587.15,65.53z"
        />
        <path
          class="st18"
          d="M616.04,55.3c-5.74-2.76-11.48-5.52-17.22-8.28c2.02-0.19,4.06-0.05,6.05,0.37c-2.03-0.79-4.01-1.72-5.88-2.85
	c5.68,0.71,11.2,2.65,16.11,5.61c7.23,3.72,13.74,8.86,18.95,15.1c-3.53-2.01-7.07-4.01-10.6-6.02c0.84,0.57,1.68,1.15,2.51,1.72
	c-0.92-0.6-2-0.96-3.1-1.02c2.5,1.12,4.75,2.79,6.53,4.87c-2.18-0.44-4.23-1.51-5.83-3.05c10.59,8.34,18.72,19.32,26.73,30.16
	c-5.92-4.9-11.85-9.8-17.77-14.69c11.29,9.67,21.44,20.68,30.14,32.73c-17.53-17.21-35.4-34.08-53.6-50.58
	c3.52,1.48,7.05,2.96,10.57,4.44c-2.59-1.53-5.08-3.23-7.46-5.06c1.31,0.25,2.62,0.49,3.93,0.73c-3.75-2.04-7.51-4.09-11.26-6.13
	C608.64,53.44,612.43,54.1,616.04,55.3z"
        />
        <path
          class="st18"
          d="M602,68.59c5.28,3.93,10.42,8.06,15.39,12.37c15.58,13.52,29.51,28.83,43.3,44.18
	c2.06,2.29,4.13,4.6,5.86,7.15c2.81,4.15,4.68,8.86,6.52,13.53c2.89,7.32,5.77,14.65,8.66,21.98c-2.76-6.47-4.77-13.26-5.98-20.19
	c-1.13-6.5-1.91-13.81-6.95-18.08c1.35,1.05,2.7,2.1,4.06,3.15c-2.44-2.96-5.98-8.02-10.96-12.55c-0.8-0.73-12.16-12.07-20.66-21.78
	c3.13,1.05,6.06,2.71,8.58,4.84c-3.26-2.46-6.51-4.92-9.77-7.37c-0.46-0.35-0.97-0.82-0.85-1.38c0.11-0.56,1.25-0.43,1.01,0.08
	c-4.79-3.76-9.59-7.51-14.38-11.27c1.32,0.24,2.55,0.96,3.41,1.99c-7.69-6.24-15.39-12.48-23.09-18.72
	c2.88-0.08,5.79,0.61,8.32,1.98c-3.93-0.67-6.91-3.78-9.6-6.72c-0.36-0.4-0.74-0.95-0.47-1.42c0.27-0.46,1.17,0.23,0.67,0.43
	c-2.27-0.16-4.49-0.87-6.43-2.06c0.76,0.1,1.53,0.2,2.29,0.29c-5.28-4.08-10.92-7.82-17.2-10.04c-6.29-2.22-13.31-2.84-19.62-0.68
	c-0.63,1.25-0.03,2.84,1.03,3.76c4.28,3.75,12.69,3.92,18.06,5.87C590.01,60.43,596.24,64.19,602,68.59z"
        />
        <path
          class="st26"
          d="M586.27,113.98c2.44,1.47,4.84,3.01,7.2,4.6c-4.3-3.12-7.92-7.16-10.56-11.76
	c6.21,4.06,12.78,7.56,19.61,10.46c-3.14-1.63-5.97-3.84-8.32-6.48c0.77,0.32,1.54,0.63,2.31,0.95c-1.88-1.18-3.64-2.54-5.26-4.06
	c1.57,0.6,3.13,1.19,4.7,1.79c-2.68-0.75-5.11-2.4-6.8-4.61c1.69,0.43,3.3,1.17,4.72,2.17c-4.92-3.31-9.24-7.49-12.71-12.31
	c5,2.72,9.83,5.72,14.57,8.88c-1.6-1.33-3.4-2.62-4.8-4.1c10.44,5.05,18.91,15.56,26.39,24.07c-2.28-2.55-5.17-4.55-8.35-5.79
	c1.19,0.97,2.37,1.93,3.56,2.9c-1.35-0.36-2.69-0.72-4.04-1.09c9.24,8.99,18.48,17.99,27.72,26.98
	c-9.36-5.64-18.31-11.99-26.73-18.96c8.73,8.16,17.66,16.1,26.78,23.82c0.42,0.35,0.88,0.88,0.65,1.38
	c-0.23,0.5-1.19-0.21-0.67-0.39c2.33,1.66,4.66,3.33,6.99,4.99c-9.32-3.15-18.07-7.99-25.7-14.2c2.56,1.89,4.86,4.14,6.81,6.65
	c-3.25-2.17-6.5-4.34-9.75-6.5c1.08,1.49,2.16,2.97,3.24,4.46c-2.45-1.31-4.91-2.61-7.36-3.92c2.2,1.73,3.86,4.14,4.7,6.8
	c-13.4-8.74-26.39-18.12-38.9-28.09c4.56,2.68,9.37,4.96,14.34,6.78c-1.6-0.41-3.13-1.09-4.51-2.01c1.42-0.12,2.88,0.44,3.84,1.49
	c-7-5.1-13.24-11.25-18.44-18.18c6.64,3.77,13.29,7.53,19.93,11.3c-5.89-3.3-11.02-7.94-14.88-13.48
	C579.84,110.23,583.09,112.05,586.27,113.98L586.27,113.98z"
        />
        <path
          class="st18"
          d="M662.4,204.47c0,0,0.31,0.34,0.9,0.98c0.61,0.64,1.41,1.57,2.41,2.76c1,1.21,2.17,2.73,3.43,4.53
	c1.21,1.83,2.57,3.91,3.79,6.31c1.22,2.4,2.39,5.08,3.3,8.03c0.91,2.94,1.54,6.15,1.7,9.5c0.04,0.84,0.02,1.68,0.03,2.53
	c-0.02,0.85-0.11,1.7-0.16,2.57c-0.08,0.79-0.24,1.75-0.36,2.61c-0.2,0.84-0.34,1.68-0.58,2.53c-0.92,3.41-2.38,6.73-4.29,9.8
	c-1.91,3.06-4.3,5.83-6.94,8.22c-2.65,2.38-5.59,4.41-8.63,5.9c-3.11,1.53-6.21,2.57-9.29,3.27c-3.07,0.69-6.04,1.01-8.79,1.08
	c-2.75,0.09-5.28-0.13-7.5-0.42c-2.23-0.28-4.13-0.73-5.7-1.11c-1.55-0.42-2.77-0.78-3.58-1.08c-0.82-0.28-1.25-0.43-1.25-0.43
	s0.43,0.16,1.24,0.47c0.8,0.32,2.01,0.72,3.56,1.19c1.56,0.43,3.46,0.93,5.69,1.27c2.22,0.35,4.76,0.64,7.55,0.63
	c2.78,0,5.8-0.23,8.94-0.85c3.12-0.61,6.42-1.62,9.56-3.07c3.21-1.46,6.27-3.45,9.09-5.84c2.8-2.4,5.36-5.2,7.44-8.34
	c2.08-3.14,3.72-6.58,4.79-10.16c0.28-0.89,0.47-1.83,0.7-2.74c0.16-0.91,0.32-1.72,0.45-2.69c0.08-0.91,0.2-1.82,0.25-2.72
	c0.01-0.9,0.06-1.8,0.04-2.69c-0.07-3.57-0.64-6.99-1.52-10.13c-0.87-3.14-2.02-6-3.24-8.57c-1.21-2.57-2.57-4.78-3.79-6.73
	c-1.27-1.92-2.45-3.54-3.46-4.85c-1.04-1.31-1.87-2.32-2.44-2.95c-0.57-0.65-0.87-1-0.87-1L662.4,204.47z"
        />
        <path
          class="st18"
          d="M665.26,204.29c0,0,0.41,0.27,1.19,0.78c0.7,0.48,1.77,1.27,3.01,2.39c2.49,2.21,5.65,5.96,8.16,11.27
	c1.26,2.64,2.36,5.65,3.23,8.92c0.87,3.26,1.49,6.82,1.88,10.51c0.38,3.67,0.44,7.51-0.43,11.21c-0.21,0.95-0.49,1.79-0.84,2.75
	c-0.34,0.87-0.72,1.74-1.18,2.6c-0.9,1.71-2,3.34-3.24,4.87c-2.48,3.06-5.43,5.76-8.46,8.29c-3.02,2.5-6.15,4.81-9.35,6.73
	c-3.2,1.92-6.48,3.49-9.74,4.57c-3.25,1.11-6.46,1.69-9.44,1.88c-2.99,0.17-5.75-0.05-8.15-0.52c-2.4-0.46-4.42-1.17-6.02-1.9
	c-1.6-0.72-2.78-1.45-3.57-1.96c-0.77-0.54-1.18-0.83-1.18-0.83s0.4,0.3,1.16,0.86c0.77,0.54,1.94,1.3,3.53,2.06
	c1.58,0.78,3.6,1.54,6.01,2.07c2.41,0.54,5.19,0.83,8.23,0.74c3.04-0.11,6.33-0.62,9.66-1.66c3.34-1.01,6.72-2.52,10.03-4.39
	c3.31-1.88,6.54-4.13,9.68-6.6c3.11-2.46,6.21-5.14,8.88-8.27c1.33-1.57,2.54-3.25,3.56-5.07c0.51-0.89,0.96-1.85,1.36-2.82
	c0.35-0.91,0.73-1.94,0.97-2.92c1.05-4,1.08-8.08,0.79-11.9c-0.31-3.83-0.85-7.48-1.68-10.93c-0.82-3.43-1.9-6.62-3.17-9.46
	c-2.52-5.7-5.86-9.89-8.56-12.41c-1.35-1.27-2.48-2.16-3.33-2.76c-0.79-0.54-1.2-0.83-1.2-0.83L665.26,204.29z"
        />
        <path
          class="st18"
          d="M663.37,200.42c0,0,0.18,0.41,0.51,1.17c0.34,0.77,0.8,1.88,1.35,3.29c0.55,1.42,1.19,3.17,1.86,5.2
	c0.64,2.04,1.34,4.34,1.96,6.9c1.25,5.11,2.32,11.18,2.7,17.77c0.36,6.57,0.28,13.68-1.37,20.49c-0.21,0.84-0.43,1.7-0.7,2.51
	c-0.27,0.85-0.54,1.7-0.85,2.49c-0.59,1.62-1.34,3.2-2.12,4.73c-1.62,3.05-3.56,5.86-5.72,8.35c-2.17,2.49-4.55,4.64-6.98,6.43
	c-2.44,1.78-4.93,3.28-7.35,4.31c-2.42,1.07-4.79,1.68-6.92,1.99c-2.13,0.29-4.03,0.2-5.56-0.06c-1.54-0.24-2.67-0.72-3.44-1.02
	c-0.75-0.37-1.14-0.57-1.14-0.57s0.39,0.21,1.13,0.6c0.76,0.33,1.89,0.84,3.43,1.13c1.53,0.31,3.45,0.45,5.62,0.22
	c2.16-0.25,4.59-0.8,7.08-1.82c2.52-0.98,5.05-2.42,7.6-4.16c2.53-1.76,5.02-3.88,7.31-6.36c2.28-2.48,4.36-5.31,6.12-8.4
	c0.85-1.56,1.67-3.15,2.34-4.84c0.36-0.85,0.66-1.68,0.95-2.52c0.32-0.87,0.56-1.73,0.81-2.6c0.95-3.49,1.5-7.08,1.79-10.61
	c0.29-3.54,0.33-7.02,0.23-10.39c-0.19-6.74-1.11-13-2.24-18.29c-0.57-2.64-1.21-5.04-1.81-7.15c-0.63-2.11-1.23-3.93-1.76-5.42
	c-0.54-1.5-0.98-2.67-1.3-3.43c-0.31-0.77-0.47-1.19-0.47-1.19L663.37,200.42z"
        />
        <path
          class="st18"
          d="M674.38,205.26c0,0,0.33,0.28,0.96,0.79c0.62,0.52,1.48,1.29,2.53,2.37c2.08,2.13,4.75,5.58,6.74,10.34
	c2.03,4.74,3.26,10.73,3.66,17.2c0.11,1.62,0.17,3.27,0.21,4.94c0.05,1.69,0.07,3.34-0.19,5.02c-0.47,3.35-1.47,6.69-2.64,9.94
	c-0.59,1.63-1.23,3.23-2.09,4.71c-0.85,1.49-1.86,2.87-2.96,4.17c-2.21,2.58-4.76,4.79-7.31,6.81c-5.11,4.03-10.2,7.39-14.93,9.59
	c-2.36,1.11-4.6,1.93-6.63,2.52c-2.04,0.57-3.86,0.91-5.37,1.08c-1.51,0.17-2.7,0.23-3.52,0.21c-0.82,0-1.25-0.01-1.25-0.01
	s0.43,0.01,1.25,0.02c0.81,0.03,2.01-0.02,3.52-0.18c1.51-0.16,3.34-0.48,5.39-1.03c2.04-0.57,4.3-1.37,6.68-2.45
	c4.76-2.15,9.92-5.48,15.08-9.46c2.58-2,5.17-4.19,7.44-6.79c1.13-1.3,2.17-2.7,3.06-4.21c0.9-1.51,1.57-3.14,2.17-4.77
	c1.21-3.27,2.25-6.62,2.77-10.05c0.29-1.71,0.28-3.46,0.25-5.13c-0.02-1.68-0.07-3.34-0.17-4.97c-0.35-6.53-1.53-12.63-3.57-17.51
	c-2-4.9-4.72-8.49-6.84-10.71c-1.06-1.12-1.97-1.94-2.6-2.48c-0.62-0.52-0.96-0.8-0.96-0.8L674.38,205.26z"
        />
        <path
          class="st18"
          d="M659.19,201.8c0,0,1.01,1.37,2.77,3.76c0.38,0.62,0.8,1.32,1.26,2.07c0.44,0.77,1.01,1.54,1.43,2.48
	c0.46,0.92,0.94,1.9,1.46,2.93c0.5,1.04,0.89,2.19,1.38,3.35c0.51,1.16,0.86,2.43,1.26,3.73c0.2,0.65,0.4,1.31,0.61,1.99
	c0.15,0.69,0.31,1.39,0.46,2.09c0.16,0.71,0.32,1.42,0.48,2.15c0.13,0.73,0.22,1.48,0.33,2.23c0.11,0.75,0.21,1.52,0.32,2.29
	c0.08,0.77,0.11,1.56,0.17,2.35c0.05,0.79,0.1,1.59,0.16,2.39c-0.01,0.8-0.02,1.61-0.03,2.43c-0.02,0.82,0,1.64-0.05,2.46
	c-0.07,0.82-0.15,1.65-0.22,2.47c-0.09,1.66-0.41,3.31-0.69,4.97c-0.22,1.67-0.74,3.29-1.12,4.94c-0.18,0.83-0.47,1.62-0.74,2.42
	l-0.81,2.4c-0.6,1.57-1.32,3.09-1.96,4.63c-0.8,1.46-1.55,2.94-2.38,4.34c-0.91,1.35-1.76,2.73-2.7,4
	c-3.81,5.08-8.47,8.96-12.95,11.35c-2.24,1.21-4.45,1.99-6.45,2.5c-0.5,0.11-0.99,0.23-1.46,0.34c-0.48,0.07-0.94,0.13-1.38,0.19
	c-0.88,0.16-1.71,0.12-2.45,0.18c-1.48-0.01-2.65-0.09-3.43-0.24c-0.79-0.12-1.21-0.19-1.21-0.19s0.42,0.07,1.21,0.2
	c0.78,0.16,1.95,0.25,3.44,0.27c0.74-0.05,1.57,0,2.46-0.16c0.44-0.06,0.91-0.12,1.39-0.18c0.47-0.1,0.96-0.21,1.47-0.32
	c2.01-0.5,4.24-1.26,6.5-2.45c4.53-2.36,9.26-6.21,13.14-11.29c0.96-1.28,1.82-2.66,2.76-4.01c0.85-1.41,1.62-2.89,2.44-4.35
	c0.67-1.54,1.41-3.06,2.03-4.65l0.84-2.41c0.28-0.81,0.58-1.6,0.77-2.44c0.4-1.66,0.95-3.29,1.18-4.97c0.3-1.67,0.63-3.34,0.75-5.02
	c0.08-0.84,0.16-1.67,0.25-2.5c0.06-0.83,0.05-1.66,0.07-2.48c0.02-0.82,0.03-1.64,0.05-2.46c-0.04-0.81-0.09-1.62-0.13-2.42
	c-0.05-0.8-0.07-1.59-0.15-2.38c-0.1-0.78-0.2-1.55-0.3-2.32c-0.11-0.76-0.19-1.53-0.31-2.27c-0.16-0.74-0.31-1.47-0.46-2.19
	c-0.15-0.72-0.3-1.43-0.45-2.13c-0.2-0.69-0.4-1.36-0.59-2.02c-0.39-1.33-0.74-2.62-1.25-3.8c-0.48-1.19-0.87-2.36-1.36-3.42
	c-0.51-1.05-0.99-2.05-1.45-3c-0.42-0.96-1-1.77-1.44-2.56c-0.47-0.78-0.9-1.5-1.28-2.15c-1.74-2.41-2.73-3.79-2.73-3.79
	L659.19,201.8z"
        />
        <path
          class="st18"
          d="M596.44,162.88c0,0,1.21,0.91,3.32,2.5c1.13,0.68,2.47,1.6,4.09,2.5c0.82,0.43,1.69,0.89,2.61,1.38
	c0.92,0.49,1.93,0.88,2.97,1.36c1.03,0.48,2.14,0.91,3.29,1.34c1.14,0.45,2.33,0.9,3.57,1.3c2.46,0.86,5.09,1.68,7.82,2.45
	c2.73,0.76,5.56,1.54,8.43,2.34c2.87,0.8,5.77,1.61,8.64,2.53c2.88,0.88,5.73,1.87,8.47,2.98c2.75,1.1,5.37,2.36,7.82,3.74
	c4.92,2.74,9.06,6.04,12.24,9.24c3.18,3.2,5.49,6.2,6.97,8.39c0.73,1.1,1.3,1.97,1.66,2.58c0.37,0.61,0.56,0.93,0.56,0.93
	s-0.18-0.33-0.51-0.96c-0.33-0.63-0.84-1.54-1.54-2.66c-1.41-2.24-3.56-5.4-6.71-8.7c-1.57-1.65-3.38-3.35-5.42-4.99
	c-2.04-1.64-4.3-3.26-6.8-4.64c-2.48-1.42-5.14-2.72-7.91-3.86c-2.77-1.15-5.63-2.17-8.53-3.07c-2.9-0.91-5.82-1.7-8.7-2.47
	c-2.87-0.77-5.7-1.53-8.44-2.26c-2.73-0.76-5.36-1.49-7.83-2.29c-1.24-0.37-2.43-0.79-3.57-1.21c-1.15-0.4-2.26-0.8-3.29-1.25
	c-1.04-0.44-2.06-0.82-2.98-1.27c-0.93-0.44-1.81-0.87-2.64-1.26c-1.64-0.83-3-1.68-4.15-2.31
	C597.7,163.73,596.44,162.88,596.44,162.88z"
        />
        <path
          class="st18"
          d="M577.42,155.19c0,0,0.31,0.23,0.89,0.65c0.57,0.43,1.43,1.04,2.57,1.76c1.14,0.72,2.54,1.55,4.2,2.43
	c1.66,0.87,3.56,1.8,5.69,2.68c4.25,1.81,9.33,3.57,14.79,5.37c5.47,1.78,11.21,3.86,16.84,6.25c5.66,2.33,11.23,4.87,16.43,7.32
	c5.18,2.48,10.1,4.65,14.16,6.77c2.03,1.07,3.84,2.13,5.37,3.19c1.53,1.05,2.79,2.09,3.74,3.02c0.96,0.93,1.66,1.72,2.11,2.28
	c0.44,0.57,0.67,0.87,0.67,0.87s-0.22-0.32-0.63-0.91c-0.42-0.58-1.07-1.42-2-2.39c-0.93-0.98-2.15-2.07-3.66-3.2
	c-1.5-1.13-3.29-2.28-5.31-3.4c-2.01-1.13-4.25-2.24-6.61-3.42c-2.36-1.18-4.84-2.42-7.44-3.66c-5.18-2.49-10.75-5.09-16.41-7.47
	c-5.68-2.36-11.49-4.41-16.97-6.14c-5.47-1.76-10.6-3.32-14.85-5.03c-4.27-1.66-7.7-3.37-10.01-4.73c-0.58-0.34-1.09-0.65-1.54-0.91
	c-0.44-0.28-0.81-0.52-1.11-0.72C577.74,155.4,577.42,155.19,577.42,155.19z"
        />
        <path
          class="st18"
          d="M568.83,147.63c0,0,6.92,2.55,17.29,6.38c5.19,1.91,11.2,4.23,17.61,6.79c6.41,2.57,13.17,5.48,19.81,8.66
	c6.65,3.15,13.15,6.59,19.07,10.13c2.94,1.79,5.79,3.53,8.41,5.3c2.64,1.74,5.06,3.49,7.29,5.12c2.2,1.67,4.19,3.22,5.94,4.63
	c1.73,1.43,3.22,2.7,4.42,3.77c1.22,1.06,2.13,1.93,2.77,2.51c0.64,0.59,0.97,0.9,0.97,0.9s-0.32-0.33-0.93-0.94
	c-0.61-0.61-1.48-1.53-2.67-2.63c-1.16-1.12-2.62-2.44-4.3-3.93c-0.86-0.73-1.77-1.51-2.73-2.32c-0.98-0.79-2.02-1.62-3.1-2.49
	c-4.38-3.39-9.66-7.15-15.58-10.74c-5.92-3.6-12.44-7.1-19.09-10.31c-6.68-3.15-13.49-6.03-19.93-8.56
	c-6.44-2.53-12.54-4.66-17.76-6.49c-5.22-1.82-9.61-3.23-12.67-4.24C570.59,148.2,568.83,147.63,568.83,147.63z"
        />
        <path
          class="st18"
          d="M575.23,161.28c37.06,15.98,65.5,43.53,87.69,47.47c1.73,0.03,3.36,0.25,5.05,0.48c0.06,0,0.12,0.01,0.18,0.01
	c0,0,0.01,0.02,0.01,0.02c4.98,0.7,13.43,1.56,29.22-2.91c-3.2,0.9-19.62-0.17-22.66-1.51c0,0,4.25,0.25,7.54-0.03
	c-5.17-1.85-7.69-2.76-11.54-4.13c5.62,1.69,11.56,2.26,17.39,1.68c-9.29-0.44-18.47-3.12-26.54-7.75
	c4.98,2.54,10.71,3.56,16.26,2.91c-23.71-2.97-44.97-19.45-65.1-32.32c1.01,1.13,2.19,2.09,3.47,2.89
	c-14.42-7.56-27.58-13.75-39.5-24.85c2.79,4.07,6.13,7.76,9.91,10.94c-5.02-3.12-10.03-6.24-15.05-9.36
	c0.21,0.23,0.44,0.45,0.67,0.66c-2.51-1.56-5.02-3.12-7.53-4.68c1.47,1.59,2.93,3.18,4.4,4.78c-3.49-2.79-6.98-5.59-10.48-8.38
	c4.29,7.55,9.92,14.34,16.53,19.97c-14.64-7.97-25.69-21.05-36.39-33.83c2,5.24,5.23,10.01,9.36,13.8c-1-0.6-1.96-1.27-2.88-2
	c-2.83-3.31-6.12-6.21-9.76-8.59c1,0.65,1.65,2.87,2.78,3.78c-1.34-1.12-2.58-2.37-3.77-3.66
	C541.26,139.87,554.83,152.49,575.23,161.28z M545.2,135.1c-1.14-0.89-2.21-1.87-3.2-2.92C543.07,133.15,544.14,134.12,545.2,135.1z
	"
        />
        <path
          class="st27"
          d="M590.54,124.47c2.2,0.38,4.34,1.12,6.29,2.2c-4.01-3.24-7.79-6.75-11.32-10.5c0.93-0.23,1.86,0.31,2.67,0.82
	c2.82,1.79,5.63,3.57,8.45,5.36c-1.85-1.74-3.7-3.47-5.55-5.21c1.75,0.03,3.49,0.67,4.85,1.78c-1.02-1.27-2.04-2.53-3.06-3.8
	c2.74,1.32,5.28,3.04,7.52,5.09l-5.3-3.35c5.43,4.78,10.86,9.57,16.28,14.35c-3.31-1.23-6.47-2.88-9.37-4.89
	c1.87,1.65,3.73,3.3,5.6,4.94c-1.46-0.33-2.91-0.65-4.37-0.98c3.36,3.84,6.99,7.44,10.86,10.76c-8.87-4.4-17.29-9.7-25.08-15.82
	c1.54,1.28,3.31,2.29,5.2,2.97C593.22,126.76,591.97,125.49,590.54,124.47z"
        />
        <path
          class="st18"
          d="M625.83,101.94c-2.73-2.08-5.45-4.17-8.17-6.25c2.35,1.73,4.54,3.67,6.54,5.8c-0.98-0.07-1.96-0.14-2.93-0.22
	c4.61,5.49,9.23,10.98,13.84,16.46c-4.69-3-9.2-6.29-13.49-9.84c4.45,5.08,8.9,10.16,13.36,15.23
	c-8.59-6.43-15.31-14.98-21.93-23.42c1.27,0.63,2.53,1.26,3.8,1.89c-0.87-0.5-1.55-1.31-1.89-2.25c0.87,0.12,1.74,0.24,2.61,0.36
	c-3.9-3.82-7.61-8.11-10.42-12.8c2.61,1.97,5.16,4.04,7.97,5.73c-0.26-0.43-2.52-4.49-2.72-4.87
	C615.87,89.28,623.21,99.94,625.83,101.94z"
        />
        <g>
          <path
            class="st18"
            d="M568.35,116.64c-0.72-0.45-1.42-0.93-2.11-1.42c-1.27-1.36-2.51-2.74-3.73-4.15L568.35,116.64z"
          />
          <path
            class="st18"
            d="M554.11,116.79c5.97,3.55,11.85,7.26,17.62,11.13c-4.22-3.07-7.95-6.81-11-11.04
		c1.71,0.98,3.41,1.95,5.12,2.93c-1.11-0.89-2.22-1.77-3.33-2.66c2.37,1.05,4.63,2.36,6.72,3.9c-4.13-3.05-7.47-7.18-9.59-11.86
		c1.93,2.27,4.15,4.3,6.57,6.03c8.05,8.64,17.13,16.32,27,22.8c-4.72-2.25-9.44-4.51-14.16-6.76c2.54,1.21,4.91,2.77,7.02,4.62
		c-0.75-0.18-1.49-0.36-2.24-0.53c8.33,7.35,17.04,14.25,26.1,20.68c-13.45-6.09-27.09-12.31-38.45-21.71
		C564.7,129.61,558.76,123.64,554.11,116.79z"
          />
        </g>
        <g>
          <path
            class="st18"
            d="M603.65,162.76c-1.9-0.89-3.8-1.78-5.7-2.67c-0.51-0.38-1.02-0.77-1.54-1.15L603.65,162.76z"
          />
          <path
            class="st18"
            d="M579.69,154.5c2.79,1.17,5.57,2.35,8.36,3.52c-0.6-0.36-1.15-0.78-1.64-1.28c2.73,1.86,5.89,3.07,9.17,3.51
		c-1.57-0.62-2.95-1.72-3.91-3.11c2.09,0.98,4.18,1.96,6.28,2.94c3.21,2.39,6.4,4.81,9.56,7.27c-3.35-1.27-6.7-2.55-10.05-3.82
		c3.55,1.84,6.91,4.06,9.98,6.62c-1.85-0.55-3.7-1.11-5.55-1.66c2.31,1.37,4.61,2.74,6.92,4.1c-0.71,0.33-1.54,0.02-2.26-0.28
		c-7.58-3.22-15.28-6.5-21.6-11.78c-1.66-1.39-3.22-2.91-4.98-4.17c-1.38-0.99-2.86-1.81-4.34-2.63
		C576.99,153.4,578.4,153.96,579.69,154.5z"
          />
        </g>
        <path
          class="st18"
          d="M700.17,214.35c-8.33-15.32-17.47-30.2-27.36-44.56c3.43,2.72,6.86,5.44,10.3,8.17
	c-3.81-3.01-6.95-6.87-9.11-11.22c0.51,0.65,1.01,1.3,1.52,1.95c-1.53-1.84-2.68-4-3.33-6.3c3.17,2.41,6.09,5.14,8.72,8.13
	c-3.57-4.07-12.26-20.41-12.74-25.84c4.76,9.01,9.67,16.85,12.01,19.93c3.55,4.64,7.31,9.12,10.77,13.83
	c4.32,5.89,8.21,12.48,10.32,19.41c0.09,1.86-0.06,3.68-0.52,5.47c-0.89,3.4-0.62,6.96-0.22,10.7
	C700.4,214.13,700.28,214.24,700.17,214.35z"
        />
        <g>
          <path
            class="st18"
            d="M679.73,143.89c0.45,0.5,0.88,1.02,1.3,1.56C680.47,145.05,680,144.52,679.73,143.89z"
          />
          <path
            class="st18"
            d="M675.71,121.77c-0.92-0.71-1.84-1.42-2.75-2.13C674.08,120,675.06,120.78,675.71,121.77z"
          />
          <path
            class="st18"
            d="M684.99,164.46c-0.61-0.82-1.23-1.64-1.84-2.46c0.89,0.24,1.67,0.84,2.14,1.63
		c-0.94-1.59-1.88-3.19-2.81-4.78c1.52,1.14,3.04,2.28,4.56,3.42c-4.4-4.39-7.89-9.68-10.21-15.44c2.9,3.09,6.03,5.96,9.37,8.57
		c-1.07-3.61-2.84-7-5.16-9.96c0.34,0.24,0.7,0.44,1.09,0.55c-0.26-0.69-0.51-1.38-0.77-2.07c1.31,1.04,2.63,2.08,3.94,3.12
		c-0.94-1.34-1.88-2.68-2.82-4.02c1.75,1.66,3.51,3.32,5.26,4.98c-4.06-9.22-9.62-17.78-16.39-25.24c2.44,2.04,4.88,4.09,7.33,6.13
		c-1.07-1.46-2.14-2.93-3.22-4.39c-0.34-0.46-0.69-0.98-0.64-1.54c0.05-0.57,0.75-1.07,1.2-0.71c-0.09-0.17-0.2-0.31-0.3-0.47
		c1.01,0.78,2.02,1.56,3.03,2.34c-4.74-5.44-8.94-11.34-12.53-17.6c-1.17-2.04-4.91-6.9-4.73-9.23c0.01-0.2,0.04-0.36,0.07-0.52
		c3.31,4.83,6.77,9.77,10.53,14.73c18.31,24.2,19.08,41.73,20.59,54.93c1.39,12.12,9.46,23.74,8.54,34.14
		c-0.88-0.53-1.68-1.51-2.21-2.42c-6.7-11.51-12.59-23.49-17.59-35.83C682.6,163.02,683.79,163.74,684.99,164.46z"
          />
        </g>
        <g>
          <path
            class="st18"
            d="M698.13,216.7c-2.03-5.65-4.74-11.05-8.04-16.07c2.36,2.27,4.73,4.53,7.09,6.8c-0.65-1.38-1.3-2.77-1.94-4.15
		c0.61,0.8,1.22,1.6,1.82,2.41c-0.85-2.11-1.69-4.23-2.54-6.34c-0.12-0.31-0.23-0.7,0.01-0.93c0.05-0.05,0.1-0.06,0.16-0.06
		c-0.67-1.14-1.34-2.29-2.01-3.43c1.36,1.43,2.73,2.85,4.09,4.28c-3.57-6.65-7.72-12.99-12.39-18.91
		c3.45,4.25,7.49,8.02,11.98,11.16c-1.41-2.65-2.81-5.29-4.22-7.94c0.47,0.35,0.94,0.7,1.41,1.06c-1.1-1.96-2.19-3.93-3.29-5.89
		c0.8,0.73,1.61,1.46,2.41,2.19c-1.86-3.4-3.73-6.8-5.59-10.21c2.39,3.9,5.61,7.28,9.39,9.85c2.17,6,4.48,11.78,4.76,17.26
		c0.01,0.03,0.02,0.06,0.03,0.09c0.01,0.19,0,0.38,0.01,0.57c0,0.1,0,0.21,0.01,0.31c0.01,1.56-0.14,3.09-0.54,4.59
		c-0.86,3.3-0.63,6.75-0.26,10.36c0.17,0.52,0.33,1.04,0.48,1.57c0.8,7.44,1.74,15.6-5.2,24.74c1.69-6.17,1.2-12.92-1.46-18.74
		c0.61,0.35,1.22,0.69,1.83,1.04c-0.92-1.84-1.85-3.68-2.77-5.53c0.87,1.31,1.74,2.63,2.61,3.94c-1.59-6.06-4.1-11.88-7.43-17.18
		C691.74,207.91,694.93,212.3,698.13,216.7z"
          />
          <path
            class="st18"
            d="M692.69,166.52c-0.65-1.48-1.29-2.97-1.94-4.45c0.44-0.21,0.96-0.24,1.42-0.07c-0.5-1.06-1-2.12-1.49-3.19
		c0.46,0.72,0.93,1.43,1.39,2.15c0.22,1.89,0.4,3.71,0.6,5.45C692.68,166.45,692.68,166.48,692.69,166.52z"
          />
          <path
            class="st18"
            d="M690.7,153.63c0.16,0.17,0.31,0.37,0.47,0.55c0.38,2.37,0.66,4.62,0.91,6.77
		C691.09,159.36,689.96,155.22,690.7,153.63z"
          />
          <path
            class="st18"
            d="M689.53,164.33c0.94,1.47,2.1,2.8,3.41,3.95c0.64,3.8,1.86,7.54,3.17,11.2
		C693.63,174.56,691.43,169.51,689.53,164.33z"
          />
        </g>
        <g>
          <path
            class="st18"
            d="M608.04,177.09c-1.75-0.97-3.5-1.94-5.24-2.92C604.65,174.96,606.39,175.95,608.04,177.09z"
          />
          <path
            class="st18"
            d="M634.98,191.11c-0.75-0.31-1.5-0.63-2.24-0.98C633.59,190.09,634.43,190.48,634.98,191.11z"
          />
          <path
            class="st18"
            d="M611.84,180.16c-1.18-1.13-2.46-2.15-3.8-3.07c9.06,5.04,18.18,9.95,27.38,14.73
		c-0.11-0.26-0.26-0.5-0.44-0.71c2.81,1.17,5.72,2.08,8.69,2.71c-7.12-4.74-14.23-9.48-21.35-14.21
		c10.07,6.5,20.81,11.98,31.99,16.31c-3.31-2.41-6.63-4.83-9.94-7.24c1.35,1.07,3.13,1.57,4.84,1.36c-0.89-0.49-1.77-0.99-2.66-1.48
		c2.82,1.42,5.94,2.25,9.09,2.44c7.18,3.19,14.57,5.56,22.21,6.52c-5.55,0.65-11.28-0.37-16.26-2.91c8.07,4.62,17.25,7.3,26.54,7.75
		c-5.83,0.58-11.78,0.01-17.39-1.68c3.85,1.38,6.37,2.28,11.54,4.13c-3.29,0.28-7.54,0.03-7.54,0.03
		c3.04,1.34,19.47,2.42,22.66,1.51c-15.79,4.47-24.24,3.61-29.22,2.91c0,0-0.01-0.02-0.01-0.02c-0.06,0-0.12-0.01-0.18-0.01
		c-1.69-0.24-3.32-0.45-5.05-0.48c-15.14-2.69-33.21-16.38-54.93-29.88C609.26,179.32,610.55,179.76,611.84,180.16z"
          />
          <path
            class="st18"
            d="M653.98,190.25c-3.33-1.19-7.92-3.56-11.22-5.87C646.44,186.5,650.18,188.49,653.98,190.25z"
          />
        </g>
        <path
          class="st18"
          d="M541.34,135.07c0,0,1.12,1.2,3.18,3.25c2.06,2.03,5.08,4.86,8.85,8.08c3.76,3.23,8.28,6.83,13.16,10.63
	c4.87,3.79,10.02,7.97,15.75,11.32c5.72,3.35,11.81,5.94,17.5,8.36c5.7,2.4,11.13,4.4,15.89,5.77c2.38,0.68,4.58,1.24,6.55,1.64
	c1.97,0.41,3.7,0.69,5.13,0.89c1.43,0.17,2.56,0.31,3.33,0.36c0.77,0.06,1.18,0.1,1.18,0.1s-0.41-0.04-1.18-0.11
	c-0.77-0.06-1.89-0.2-3.32-0.39c-1.43-0.22-3.15-0.51-5.12-0.94c-1.96-0.42-4.15-1-6.52-1.7c-4.74-1.42-10.13-3.47-15.8-5.93
	c-5.66-2.47-11.71-5.12-17.35-8.51c-5.65-3.38-10.73-7.6-15.57-11.44c-4.83-3.85-9.3-7.5-13.02-10.75
	c-3.72-3.24-6.69-6.08-8.72-8.12c-2.01-2.04-3.13-3.26-3.13-3.26L541.34,135.07z"
        />
        <path
          class="st18"
          d="M687.62,208.67c0,0-1.21,0.3-3.34,0.84c-2.12,0.5-5.2,1.22-8.92,1.24c-1.86,0.01-3.87-0.19-5.98-0.53
	c-1.05-0.17-2.14-0.39-3.22-0.62c-1.1-0.25-2.23-0.51-3.37-0.77c-1.13-0.3-2.27-0.6-3.43-0.91c-1.15-0.35-2.31-0.71-3.48-1.07
	c-1.16-0.4-2.33-0.81-3.5-1.22c-1.15-0.45-2.31-0.91-3.47-1.36c-1.15-0.49-2.29-0.98-3.43-1.47c-1.12-0.53-2.24-1.06-3.35-1.59
	c-1.09-0.57-2.17-1.13-3.23-1.69c-1.05-0.6-2.08-1.18-3.09-1.76c-0.99-0.61-1.97-1.22-2.93-1.8c-0.94-0.62-1.86-1.22-2.76-1.81
	c-1.76-1.23-3.42-2.4-4.88-3.56c-1.5-1.13-2.79-2.26-3.98-3.21c-1.14-1.01-2.13-1.9-2.95-2.62c-1.59-1.51-2.5-2.37-2.5-2.37
	s0.86,0.92,2.35,2.52c0.78,0.77,1.74,1.7,2.83,2.77c1.14,1.01,2.39,2.22,3.87,3.39c1.44,1.22,3.07,2.45,4.81,3.74
	c0.89,0.62,1.79,1.26,2.72,1.91c0.96,0.61,1.94,1.23,2.93,1.87c1.02,0.6,2.06,1.2,3.1,1.81c1.07,0.57,2.16,1.15,3.25,1.74
	c1.11,0.54,2.24,1.09,3.36,1.64c1.15,0.51,2.3,1.01,3.46,1.52c1.18,0.45,2.35,0.9,3.53,1.35c1.19,0.4,2.37,0.8,3.55,1.2
	c1.19,0.35,2.37,0.7,3.53,1.04c1.18,0.3,2.35,0.6,3.49,0.89c1.15,0.24,2.28,0.48,3.38,0.72c1.13,0.21,2.22,0.39,3.3,0.53
	c2.15,0.29,4.21,0.42,6.11,0.36c1.9-0.05,3.62-0.33,5.12-0.66c1.5-0.32,2.79-0.68,3.84-0.99
	C686.43,209.08,687.62,208.67,687.62,208.67z"
        />
        <path
          class="st28"
          d="M570.59,158.77c0,0,0.28,0.29,0.79,0.84c0.53,0.54,1.24,1.38,2.28,2.36c0.51,0.5,1.07,1.05,1.69,1.65
	c0.63,0.57,1.32,1.19,2.05,1.85c1.48,1.3,3.2,2.72,5.13,4.19c1.92,1.48,4.07,2.99,6.38,4.54c2.33,1.51,4.8,3.09,7.42,4.6
	c5.21,3.07,10.94,6.05,16.67,8.97c5.74,2.92,11.49,5.85,17.04,8.24c2.77,1.21,5.51,2.24,8.12,3.16c2.64,0.86,5.14,1.63,7.51,2.13
	c2.35,0.55,4.55,0.88,6.5,1.11c1.95,0.19,3.66,0.31,5.07,0.29c1.41,0.05,2.52-0.05,3.27-0.09c0.75-0.04,1.15-0.07,1.15-0.07
	s-0.4,0.02-1.16,0.05c-0.76,0.03-1.86,0.12-3.27,0.06c-1.41,0-3.12-0.13-5.06-0.35c-1.94-0.25-4.13-0.6-6.47-1.18
	c-2.36-0.52-4.85-1.32-7.47-2.2c-2.6-0.94-5.31-2-8.07-3.23c-5.51-2.44-11.22-5.43-16.93-8.41c-5.7-2.99-11.38-6.02-16.54-9.13
	c-2.59-1.54-5.03-3.12-7.33-4.66c-2.27-1.56-4.39-3.09-6.28-4.57c-1.9-1.48-3.58-2.91-5.03-4.21c-0.72-0.66-1.39-1.28-2.01-1.85
	c-0.59-0.59-1.14-1.14-1.64-1.63c-1-0.96-1.72-1.83-2.24-2.37c-0.51-0.56-0.78-0.85-0.78-0.85L570.59,158.77z"
        />
        <path
          class="st28"
          d="M575.35,161.4c0,0,0.29,0.15,0.84,0.44c0.54,0.29,1.36,0.7,2.35,1.27c2,1.1,4.83,2.78,8.13,4.95
	c3.31,2.16,7.09,4.8,11.17,7.59c4.09,2.79,8.49,5.72,13.13,8.28c2.3,1.29,4.68,2.48,7.04,3.57c2.36,1.1,4.74,2.06,7.05,2.95
	c2.32,0.86,4.59,1.63,6.76,2.29c2.17,0.66,4.25,1.2,6.17,1.66c1.93,0.45,3.71,0.82,5.29,1.11c1.58,0.29,2.97,0.51,4.11,0.67
	c1.14,0.18,2.04,0.26,2.66,0.35c0.61,0.07,0.94,0.11,0.94,0.11s-0.33-0.04-0.94-0.13c-0.61-0.09-1.51-0.19-2.65-0.38
	c-1.14-0.17-2.52-0.42-4.1-0.72c-1.58-0.32-3.35-0.7-5.27-1.18c-1.92-0.48-3.98-1.05-6.14-1.74c-2.16-0.68-4.41-1.47-6.72-2.37
	c-2.29-0.91-4.65-1.9-6.98-3.03c-2.34-1.12-4.67-2.32-6.96-3.64c-4.57-2.6-8.92-5.58-12.97-8.42c-4.04-2.84-7.8-5.54-11.09-7.75
	c-3.29-2.22-6.11-3.95-8.13-5.1c-1.01-0.59-1.81-1.01-2.35-1.3c-0.54-0.29-0.83-0.45-0.83-0.45L575.35,161.4z"
        />
        <g>
          <path
            class="st18"
            d="M504.81,98.91c0.42-1.83,1.11-3.6,2.07-5.2C506.19,95.45,505.5,97.18,504.81,98.91z"
          />
          <path
            class="st18"
            d="M487.43,131.35c-0.15,0.1-0.3,0.21-0.45,0.31C486.51,131.53,487.08,131.27,487.43,131.35z"
          />
          <path
            class="st18"
            d="M478.49,160.4c-0.03,2.2-0.97,4.36-2.53,5.82c2.67-11.19,6.17-22.17,10.44-32.8
		c0.27-0.68,1.15-0.99,1.27-1.71c0.04-0.22-0.08-0.32-0.24-0.36c0.81-0.55,1.62-1.11,2.43-1.66c2.53-7.25,5.7-14.25,9.47-20.87
		c-2.07,3.5-3.17,7.62-3.13,11.74c2.87-7.22,5.74-14.43,8.61-21.65c-0.29,1.25-0.45,2.52-0.47,3.81c1.04-0.87,2.09-1.75,3.13-2.62
		c-1.57,3.11-2.48,6.57-2.69,10.09c0.1-0.05,0.2-0.06,0.32,0.01c0.59,0.35,0.51,1.25,0.33,1.94c-0.86,3.44-1.73,6.88-2.59,10.31
		c2.86-2.83,5.03-6.39,6.27-10.3c-1.46,4.39-2.58,9.02-2.97,13.65c0.95-1.91,1.75-3.91,2.68-5.84c1.66,12.75-2.95,26.18-4.15,38.85
		c-0.42-1.74-1.83-5.35-1.56-7.15c-0.49,3.25-0.59,6.58-0.29,9.86c-1.74-2.95-2.72-6.37-2.82-9.83
		c-0.28,12.21-0.56,24.41-0.84,36.62c0.02-3.44-0.74-6.89-2.21-9.98c-0.14,3.22-0.28,6.43-0.42,9.65c-0.59-1.55-1.18-3.11-1.77-4.66
		c0.78,6.05,0.83,12.19,0.15,18.24c-0.55-1.66-1.11-3.32-1.66-4.99c0.11,8.43,0.21,16.87,0.32,25.3c-3.6-9.3-6.09-19.05-7.39-28.97
		c-0.27,2.33-0.54,4.67-0.81,7c0.15-2.91-0.41-5.86-1.61-8.5c-0.53,2.52-1.05,5.04-1.58,7.56c0.08-1.94,0.77-14.14-0.14-15.84
		c0.06,1.17-1.53,2.82-2.2,8.63c-0.79-5.7-1.46-9.97,0.61-20.73c-0.89,1.81-1.77,3.62-2.66,5.43
		C477.44,173.03,478.85,163.83,478.49,160.4z"
          />
        </g>
        <g>
          <path
            class="st18"
            d="M483.38,231.78c-1.33-4.08-2.45-8.22-3.3-12.43C481.54,223.39,482.64,227.55,483.38,231.78z"
          />
          <path
            class="st18"
            d="M480.32,237.79c0.56-1.33,0.64-2.86,0.25-4.25c0.38,0.82,0.95,1.56,1.68,2.1c-0.05-1.2-0.1-2.4-0.15-3.6
		c0.85,0.73,1.46,1.73,1.77,2.8c-0.14-1.02-0.3-2.04-0.47-3.06c2.76,8.48,6.49,16.65,11.15,24.25c-2.13-6.17-3.57-12.58-4.29-19.06
		c1.54,5.82,4.32,11.3,8.1,15.99c-0.32-2.09-0.65-4.19-0.98-6.28c0.69,1.08,1.38,2.16,2.08,3.25c-0.12-1.71-0.25-3.42-0.37-5.13
		c1.65,4.59,3.3,9.18,4.96,13.77c-2.32-6.45-1.44-16-1.19-23.11c0.04,0.5,0.08,1,0.13,1.5c4.13,41.24,48.31,48.08,48.31,48.08
		c-42.46,11.02-60.37-14.59-70.97-47.26C480.31,237.77,480.32,237.78,480.32,237.79z"
          />
        </g>
        <path
          class="st20"
          d="M505.87,256.68c0,0-0.05,0.59,0.18,1.57c0.1,0.49,0.3,1.07,0.52,1.74c0.24,0.65,0.55,1.37,0.93,2.14
	c0.4,0.76,0.86,1.56,1.43,2.35c0.55,0.81,1.23,1.6,1.91,2.41c1.43,1.6,3.08,3.19,4.83,4.69c1.73,1.52,3.52,3,5.18,4.39
	c1.67,1.38,3.24,2.64,4.66,3.63c1.42,0.98,2.64,1.76,3.53,2.25c0.88,0.5,1.4,0.76,1.4,0.76s-0.14-0.05-0.39-0.14
	c-0.26-0.09-0.64-0.22-1.1-0.43c-0.93-0.41-2.27-1.01-3.77-1.93c-1.49-0.91-3.2-2.05-4.93-3.39c-1.73-1.33-3.52-2.8-5.29-4.32
	c-0.88-0.77-1.74-1.57-2.56-2.38c-0.83-0.8-1.58-1.67-2.29-2.51c-1.4-1.71-2.46-3.51-3.17-5.13c-0.17-0.41-0.32-0.81-0.46-1.19
	c-0.11-0.39-0.21-0.76-0.31-1.11c-0.15-0.69-0.28-1.3-0.33-1.81C505.73,257.26,505.87,256.68,505.87,256.68z"
        />
        <path
          class="st20"
          d="M496.04,256.34c0,0,0.3,0.39,0.83,1.07c0.51,0.69,1.25,1.67,2.13,2.85c0.86,1.2,1.91,2.54,3.01,4.01
	c1.12,1.44,2.32,2.98,3.59,4.45c0.64,0.73,1.26,1.48,1.93,2.16c0.65,0.7,1.3,1.37,1.95,2.01c0.67,0.62,1.29,1.24,1.92,1.79
	c0.63,0.55,1.22,1.07,1.81,1.51c0.57,0.45,1.11,0.86,1.59,1.2c0.48,0.35,0.9,0.66,1.27,0.88c0.72,0.47,1.13,0.74,1.13,0.74
	s-0.45-0.2-1.23-0.56c-0.4-0.18-0.86-0.42-1.38-0.72c-0.53-0.28-1.12-0.62-1.73-1.05c-0.63-0.4-1.27-0.88-1.95-1.39
	c-0.68-0.51-1.37-1.09-2.07-1.7c-0.68-0.63-1.4-1.27-2.06-1.99c-0.69-0.69-1.33-1.44-1.99-2.18c-0.63-0.76-1.26-1.52-1.85-2.3
	c-0.61-0.76-1.14-1.55-1.69-2.3c-1.05-1.52-1.96-2.98-2.74-4.23c-0.78-1.25-1.37-2.33-1.8-3.07
	C496.28,256.77,496.04,256.34,496.04,256.34z"
        />
        <path
          class="st20"
          d="M495.9,259.21c0,0,0.2,0.29,0.52,0.8c0.32,0.52,0.8,1.23,1.38,2.09c0.29,0.42,0.61,0.88,0.95,1.37
	c0.33,0.49,0.72,0.97,1.09,1.5c0.78,1.02,1.62,2.1,2.51,3.14c0.88,1.04,1.81,2.04,2.7,2.98c0.46,0.45,0.88,0.91,1.3,1.31
	c0.43,0.41,0.83,0.8,1.2,1.15c0.75,0.71,1.38,1.3,1.84,1.69c0.46,0.4,0.71,0.64,0.71,0.64s-0.31-0.16-0.83-0.47
	c-0.52-0.32-1.27-0.76-2.12-1.38c-0.86-0.6-1.8-1.38-2.78-2.26c-0.47-0.45-0.98-0.9-1.45-1.41c-0.48-0.49-0.94-1.02-1.41-1.54
	c-0.89-1.08-1.74-2.2-2.46-3.29c-0.71-1.1-1.33-2.16-1.79-3.1c-0.48-0.93-0.8-1.75-1.03-2.31
	C496.01,259.54,495.9,259.21,495.9,259.21z"
        />
        <path
          class="st18"
          d="M496.34,188.81c-0.49-4.89-0.97-9.79-1.46-14.68c-0.28,1.58-0.73,3.11-1.32,4.52
	c-0.23-1.08-0.45-2.17-0.68-3.25c-0.64,3.32-1.29,6.64-1.93,9.96c-0.25-8.65,0.28-17.28,1.56-25.68c-1.01,1.5-1.68,3.35-2.77,5.39
	c-0.18-4.64,0.37-8.42,1.1-12.19c-0.38,1.12-1.27,3.04-2.08,5.03c3.07-9.97,5.65-21.98,8.72-31.96c-1.13,3.03-1.71,6.41-1.69,9.82
	c1.65-1.48,2.67-4.01,2.68-6.63c-0.16,0.8-0.33,1.6-0.49,2.4c0.47-3.02,1.74-5.79,3.55-7.76c-0.85,5.8-1.69,11.59-2.54,17.39
	c0.7-3.41,1.58-6.96,2.9-10.01c0.07,1.24,0.14,2.5,0.22,3.75c0.09-0.66,0.5-1.37,0.66-2.02c1.02,0.15,1.62-0.82,1.92-2.18
	c-0.61,5.33-2.1,10.7-3.05,15.96c-0.15-1.09-0.59-2.21-0.77-3.28C497.16,156.5,494.93,174.59,496.34,188.81z"
        />
        <path
          class="st22"
          d="M470.39,177.08c0.47-30.37,8.02-58.27,14.14-66.58c3.53-4.8,6.14-10.14,8.94-16.1
	c-0.01,5.78-5.61,14.78-6.79,19.1c0.32-1.17,0.64-2.34,0.96-3.52c-1.67,3.07-3.05,6.3-4.11,9.64c0.24-0.41,0.48-0.81,0.71-1.22
	c-3.16,5.86-4.86,12.5-4.93,19.16c-0.09-1.52-0.19-3.03-0.28-4.54c-0.74,2.22-1.47,4.44-2.21,6.66c-0.09,0.28-0.28,0.61-0.56,0.61
	C473.76,152.46,471.81,164.74,470.39,177.08z"
        />
        <g>
          <path
            class="st18"
            d="M486.31,140.25c0.12-0.47,0.31-0.93,0.6-1.32C486.71,139.37,486.51,139.81,486.31,140.25z"
          />
          <path
            class="st18"
            d="M488.08,143.24c0.05-0.32,0.11-0.65,0.16-0.97c0.29-1.16,0.6-2.32,0.93-3.46L488.08,143.24z"
          />
          <path
            class="st18"
            d="M480.57,155.29c0.39-3.19,1.73-6.17,3.05-9.09c0.9-1.98,1.79-3.97,2.69-5.95c-0.2,0.76-0.21,1.58,0.05,2.32
		c0.4-3.75,1.64-7.41,3.6-10.63c-0.57,3.44-1.15,6.88-1.72,10.32c-0.9,3.59-1.58,7.24-2.04,10.91c-0.06-1.21-0.12-2.41-0.18-3.62
		c-1.96,5.89-2.77,12.15-2.38,18.34c-0.58-3.2-0.85-6.47-0.79-9.72c-0.44,1.22-0.89,2.44-1.33,3.66c-0.29-2.58-0.45-5.18-0.49-7.78
		C481,154.5,480.85,154.94,480.57,155.29z"
          />
        </g>
        <path
          class="st18"
          d="M501.98,93.76c-0.1,0.81-0.43,1.59-0.96,2.22c-0.05-0.28-0.1-0.56-0.14-0.84c-0.62,0.74-1.19,1.53-1.69,2.36
	c0.05-0.39,0.1-0.79,0.15-1.18c-2.81,5.69-5.62,11.38-8.44,17.07c-1.12,2.26-2.25,4.56-2.62,7.06c2.16-6.93,4.15-13.92,5.97-20.95
	c-0.3,0.93-0.72,1.81-1.25,2.63c1.87-5.28,4.49-10.3,7.75-14.85c-0.64,0.76-1.23,1.8-0.81,2.69c2.44-2.86,4.87-5.72,7.31-8.57
	c-0.77,1.54-1.6,3.06-2.31,4.63c2.37-1.68,1.62,0.88,1.02,2.25c-1.19,2.71-2.92,5.17-4.39,7.73
	C501.99,95.23,501.88,94.57,501.98,93.76z"
        />
        <path
          class="st18"
          d="M489.69,232.28c-1.38-3.07-2.77-6.14-4.15-9.21c0.03,0.98,0.06,1.96,0.09,2.95c-0.12-0.7-0.36-1.38-0.7-2
	c0.26,3.71,1.22,7.38,2.83,10.74c-4.13-6.51-6.94-13.85-8.21-21.46c0.06,1.88,0.12,3.75,0.18,5.63c-2.23-8.97-3.4-18.21-3.47-27.46
	c1.25,5.38,2.72,10.71,4.39,15.97c-0.15-1.45-0.29-2.91-0.44-4.36c0.22,0.83,0.44,1.66,0.67,2.48c0.1-0.8,0.19-1.59,0.29-2.39
	c-0.25,2.07,0.98,5.04,2.13,6.69c-0.02-0.35,0.06-0.73,0.02-1.09c1.26,3.42,1.51,7.56,2.44,11.15
	C486.84,224.11,488.16,228.24,489.69,232.28z"
        />
        <path
          class="st29"
          d="M621.86,154.31c3.51,0.91,6.94,2.14,10.23,3.67c-2.88-1.44-5.52-3.37-7.76-5.68c3.24,1.32,6.47,2.64,9.71,3.95
	l-6.24-2.06c5.75,3.4,11.66,6.54,17.69,9.41c-1.94-0.66-3.88-1.33-5.82-1.99c1.31,0.86,2.61,1.72,3.92,2.58
	c-0.88-0.26-1.76-0.52-2.64-0.78c5.38,4.15,10.76,8.31,16.14,12.46c-9.84-4.4-19.52-9.14-29.04-14.22c1.36,0.42,2.72,0.84,4.07,1.26
	c-1.03-0.74-2.06-1.48-3.09-2.22c1.07,0.16,2.12,0.41,3.15,0.72C628.37,159.68,624.85,157.26,621.86,154.31z"
        />
        <g class="st30">
          <path
            class="st18"
            d="M640.23,139.59c-0.51-0.55-1.01-1.11-1.52-1.66c-0.1-0.13-0.21-0.27-0.31-0.4L640.23,139.59z"
          />
          <path
            class="st18"
            d="M627.72,130.61c3.76,2.97,7.19,6.35,10.21,10.05c-2-3.21-4-6.43-5.99-9.64c2.31,2.25,4.57,4.56,6.77,6.91
		c5.51,7.28,11.16,14.47,16.94,21.54c-1.99-1.9-3.99-3.8-5.98-5.7c0.78,0.95,1.56,1.9,2.34,2.86c-0.9-0.69-1.8-1.39-2.7-2.08
		c2.59,4.92,5.44,9.7,8.53,14.32c-5.04-4.21-9.65-8.92-13.75-14.05c0.81,0.65,1.62,1.29,2.43,1.94c-1.59-3.1-3.17-6.21-4.76-9.31
		c0.96,0.76,1.92,1.52,2.88,2.28C638.19,144.14,632.49,137.69,627.72,130.61z"
          />
        </g>
        <g>
          <path
            class="st18"
            d="M568.56,69.09l-3.86-2.41C565.99,67.47,567.28,68.27,568.56,69.09z"
          />
          <path
            class="st18"
            d="M571.8,71.11c-0.24-0.12-0.47-0.24-0.71-0.37c-0.84-0.56-1.68-1.11-2.53-1.66L571.8,71.11z"
          />
          <path
            class="st18"
            d="M542.57,71.91c0.17-0.86,0.71-1.61,1.31-2.25c3.44-3.65,8.97-4.72,13.93-3.95c4.7,0.73,9.04,2.86,13.27,5.04
		c7.61,5.04,14.86,10.63,21.67,16.71c-2.85-2.16-5.92-4.02-9.16-5.54c1.22,0.72,2.44,1.45,3.66,2.17c-0.9-0.31-1.8-0.61-2.69-0.92
		c3.39,3.4,6.79,6.8,10.18,10.19c-7.1-6.45-15.97-10.56-24.69-14.56c2.92,1.28,5.62,3.06,7.96,5.23c-2.19-1.04-4.37-2.09-6.56-3.13
		c1.23,0.99,2.46,1.99,3.69,2.98c-0.99-0.36-1.97-0.72-2.96-1.09c2.36,1.72,4.72,3.45,7.09,5.18c-5.75-2.97-11.5-5.93-17.25-8.9
		c3.63,2.56,7.07,5.4,10.25,8.5c-3.12-2.36-6.49-4.38-10.03-6.04c1.87,1.82,3.75,3.64,5.62,5.46c-5.83-4.27-12.39-7.52-19.31-9.58
		c-1.43-0.43-2.91-0.82-4.13-1.69C543.2,74.86,542.29,73.37,542.57,71.91z"
          />
        </g>
        <g>
          <path
            class="st20"
            d="M538.07,81.94c0.13,0.27,0.23,0.55,0.28,0.85C538.26,82.51,538.17,82.22,538.07,81.94z"
          />
          <path
            class="st20"
            d="M551.36,48.67c0.01,0,0.02-0.01,0.03-0.01c-0.89,0.56-1.87,1.06-2.87,1.55c0.11-0.1,0.23-0.19,0.34-0.28
		C549.69,49.47,550.53,49.05,551.36,48.67z"
          />
        </g>
        <g>
          <path
            class="st20"
            d="M530.82,74.69c-0.68,0.62-1.35,1.24-2,1.89C529.3,75.78,530,75.12,530.82,74.69z"
          />
          <path
            class="st20"
            d="M530.93,79.14c-0.06,0.37-0.13,0.75-0.2,1.12c-0.26,0.41-0.53,0.81-0.79,1.21
		C529.93,80.6,530.3,79.74,530.93,79.14z"
          />
          <path
            class="st20"
            d="M534.4,70.87c-0.34,0.65-0.77,1.25-1.26,1.8c-0.79,0.66-1.57,1.33-2.33,2.02c0.25-0.13,0.5-0.25,0.77-0.34
		c-0.98,2.03-2.24,3.92-3.74,5.61c2.06-0.78,3.95-2.04,5.46-3.65c-0.86,1.32-1.72,2.63-2.58,3.95c-0.28,1.41-0.68,2.8-1.2,4.14
		c-0.12,0.32-0.34,0.69-0.68,0.71c0.1,1.45-0.11,2.92-0.64,4.27c1.26-1.34,2.52-2.68,3.78-4.02c-0.57,2.18-1.35,4.31-2.33,6.34
		c1.36-0.98,2.72-1.97,4.07-2.95c0.7-2.61,1.28-5.03,1.67-7.03c0,0,0-0.01,0-0.01c-0.43,2.89-0.88,5.63-1.48,8.07
		c-0.02,0.08-0.05,0.15-0.07,0.23c0.88,0.66,1.5,1.66,1.65,2.75c1.08-1.82,1.42-4.06,0.93-6.11c0.65,0.66,1.31,1.33,1.97,1.99
		c0.1-1.15-0.17-2.32-0.71-3.33c-0.08-1.67-0.38-3.32-0.92-4.9c0.58,0.36,1.03,0.91,1.32,1.53c-0.52-1.55-1.03-3.09-1.55-4.64
		c1.88,2.23,3.99,2.09,5.87,4.32c-0.41-4.35-0.59-4.26-2.57-8.15c1.43,0.69,4.87,1.59,6.1,2.59c-0.21-0.49-0.42-0.97-0.64-1.46
		c1.71,1.05,4.98,0.89,6.69,1.94c-0.77-1.96-7.05-4.51-8.76-5.74c1.06,0.7,3.07-0.41,4.13,0.29c-0.44-2.23,7.61,1.74,5.84,0.31
		c1.41,0.43,2.82,0.85,4.22,1.28c-1.48-1.32-3.1-2.47-4.83-3.43c2.58,0.55,5.03,1.75,7.05,3.45c-1.62-2.32-3.85-4.21-6.41-5.41
		c2.99,1,5.98,2.01,8.97,3.01c-2.3-1.61-6.25-4.17-8.55-5.78c7.56,2.42-2.26-1.94-0.03-1.43c-1.6-0.78-5.07-1.85-6.66-2.63
		c1.33,0.69,4.9,0.19,6.4,0.11c-3.16-0.97-5.64-2.8-10.77-3.52c0-0.05,0.01-0.1,0.01-0.16c3.04-0.71,6.15-1.1,9.26-1.14
		c-2.17-0.68-4.29-1.48-6.37-2.4c1-1.3,2.52-2.18,4.14-2.4c-0.88-0.25-1.87-0.05-2.59,0.52c0.55-0.43,1.01-0.87,1.48-1.27
		c-0.25,0.12-0.5,0.25-0.75,0.37c-1.99,1.28-3.6,2.92-4.95,4.81c0.16-0.64,0.41-1.25,0.84-1.78c0.09-0.12,0.2-0.24,0.3-0.36
		c-0.54-0.23-2.11-0.83-4.37-1.33c0.64,0.49,1.35,0.93,1.84,1.32c-0.66,0.04-1.31-0.01-1.97-0.04c0.78,0.51,1.31,1.4,1.36,2.33
		c-0.36-0.12-0.83-0.14-1.03,0.18c-0.13,0.2,0.06,0.46,0.25,0.54c-1.36,0.51-2.73,1.02-4.09,1.52c1.21,0,2.41,0.31,3.47,0.89
		c-2.04,0.03-4.07,0.07-6.11,0.1c2.03,0.58,4.06,1.16,6.09,1.75c-1.08,0.03-2.15,0.06-3.23,0.09c0.53,0.49,1.17,0.87,1.85,1.11
		c-0.86,0.11-1.72,0.21-2.58,0.32c0.56,0.1,1.08,0.44,1.38,0.92c-1.61,0.14-3.15,0.97-4.15,2.25c1.21-0.14,2.42-0.28,3.63-0.42
		c-1.82,0.3-3.5,1.38-4.54,2.91c1.53-0.23,3.07-0.46,4.6-0.69c-3.71,2.31-7.42,4.63-11.13,6.94C528.5,73.98,531.53,72.4,534.4,70.87
		z M536.74,84c0.29,0.24,0.52,0.54,0.68,0.87C537.22,84.56,536.99,84.27,536.74,84z M532.95,72.86c-0.43,0.45-0.89,0.87-1.4,1.22
		C532.02,73.67,532.49,73.27,532.95,72.86z"
          />
          <path
            class="st20"
            d="M528.8,84.53c0.02,0.19,0.02,0.38,0.04,0.58c-0.01,0-0.02,0.01-0.03,0.01
		C528.45,85.11,528.48,84.37,528.8,84.53z"
          />
        </g>
      </svg>
    </>
  );
}
