//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full  py-[27px] px-[16px] xl:px-4 items-center min-h-[94px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
      <span className="w-[109px] text-[13px] xl:text-base xl:w-[130px] text-[#3E4151] flex justify-center items-center font-bold absolute top-[-12px] bg-white right-4">سوالات پزشکی</span>
      <section className="grid grid-cols-2 overflow-hidden xl:grid-cols-4 justify-between xl:gap-1 gap-2 xl:justify-between items-center xl:h-auto w-full mx-auto xl:w-full">
        {data?.answerOutputDTOs &&
          data?.answerOutputDTOs
            ?.filter((el) => el.questionType !== "MEDICINE")
            .map((item, index) => (
              <>
                {item?.question ? (
                  <>
                    <section className="xl:w-auto relative xl:h-auto text-center pt-[10px]">
                      <span className="text-[#5408A9]  h-[40px] overflow-clip flex justify-center  font-medium   text-xs xl:text-[14px]">{item?.question?.persianName}</span>
                      <span className=" mt-[10px] flex h-[20px] overflow-clip justify-center items-center  text-xs ">{item?.answer ? "بلی" : "خیر"}</span>

                      {index >= 4 && <span className="w-[266px] absolute left-[5%]  top-[-10%] h-[1px] block bg-[#E6E8E9]"></span>}
                      <span
                        className="  flex justify-center 
                     xl:mb-8 h-[40px] overflow-clip items-center  text-xs "
                      >
                        {item?.description}
                      </span>
                    </section>
                  </>
                ) : (
                  ""
                )}
              </>
            ))}
      </section>
    </section>
  );
}
