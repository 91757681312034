import { baseFileUrl } from "../../../../config";
import { Button } from "../../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:hidden block h-[582px] mt-[30px] xl:mt-[100px] mb-[100px] rounded-[50px] overflow-hidden relative">
        <img
          src={baseFileUrl + "/images/section6-bg-7.png"}
          alt=""
          className="w-full h-full"
        />
        <section className="absolute flex flex-col justify-center items-center top-0 left-0  mt-[100px] px-[15px]">
          <section className="text-[30px] text-center font-bold">
            اپلیکیشن بادینس رو از اینجا دانلود کن!
          </section>
          <Button className=" h-[40px] rounded-full  font-semibold  mt-5">
            به زودی
          </Button>
        </section>
      </section>
      <section className="hidden w-[95%] mx-auto  xl:block h-[351px] mt-[100px] mb-[100px] rounded-[50px] overflow-hidden relative">
        <img
          src={baseFileUrl + "/images/section7.png"}
          alt=""
          className="w-full"
        />
        <section className="absolute flex flex-col justify-center items-start w-[506px] h-full top-0 left-0  ml-40">
          <section className="text-[50px] font-bold">
            اپلیکیشن بادینس رو از
            <br />
            اینجا دانلود کن!
          </section>
          <Button className=" w-[250px] transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px] h-[60px] text-xl rounded-full  mt-10 font-bold">
            به زودی
          </Button>
        </section>
      </section>
    </>
  );
}
