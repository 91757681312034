import React from "react";
import { imagesFileUrl } from "../../../../../../../../config";
import InfoInputBmi from "./InfoInputBm";
import Item from "./Item";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[1053px] h-auto xl:h-[1842px] pt-[20px] xl:pt-[44px] px-[14px] xl:pr-[36px] pb-[30px] xl:pb-[61px] xl:pl-[33px] mt-8 xl:mt-[30px] mb-[20px] xl:mb-[45px] mx-auto bg-[#FFFFFF] shadow-[0px_6px_14px_0px_#0000000f] rounded-[10px] flex flex-col xl:grid xl:grid-cols-[509px_427px] justify-between ">
        <section className="w-[95%] xl:w-[509px] h-auto xl:h-[300px] mt-12 xl:mt-[12px]">
          <h2 className="text-[18px] leading-[24.8px] font-extrabold text-[#3E4151]">شاخص توده بدنی (BMI)</h2>
          <p className="text-[16px] leading-[24.8px] mt-[20px] font-normal text-[#3E4151] text-justify">
            مخفف عبارت انگلیسی Body Mass Index است که برای تعیین رده وزنی افراد مختلف به‌کار گرفته می‌شه. فرمول این شاخص از تقسیم وزن فرد برحسب کیلوگرم بر مجذور قد برحسب متر به‌دست می‌آید. BMI .یک روش غربال‌گری ارزان و آسان برای دسته‌بندی وزن در رده‌های کم‌وزنی، وزن سالم، اضافه‌وزن و چاقی هست. BMI به‌طورمستقیم چربی بدن رو اندازه‌گیری نمی‌کنه اما با معیارهای مستقیم‌تر اندازه‌گیری چربی بدن همبستگی متوسطی داره. بهترین وضعیت BMI نرمال چند است؟ BMI ایده‌آل برای اغلب بزرگ‌سالان درمحدوده 18.5 تا 24.9 هست، اما درمورد کودکان و جوانان 2 تا 18 ساله برای محاسبه BMI
            باید سن، جنسیت، قد و وزن درنظر گرفته بشه. به‌طورکلی، BMI اغلب افراد در این محدوده‌ها قرار می‌گیره.
          </p>
          <section className="grid grid-cols-1  xl:grid-cols-2 gap-3 mt-2">
            <Link to="/meal-plan">
              <Button className="h-[35px] w-full">دریافت برنامه غذایی</Button>
            </Link>
            <Link to="/exercise-plan">
              <Button className="h-[35px] w-full">دریافت برنامه ورزشی</Button>
            </Link>
          </section>
        </section>
        <InfoInputBmi />
        <section className=" w-full xl:w-[984px] h-[277.11px] xl:h-[60.38px] xl:col-span-full flex flex-col gap-[20px] xl:flex-row-reverse xl:gap-[6.5px] mx-[auto] mt-[56px]">
          <section className="flex xl:flex-col gap-[10px] justify-end">
            <span className="w-[72.63px] rounded-[24px] bg-[#2249E0] h-[21.41px] xl:w-[77.68px] "></span>
            <span className="text-[#0000004D] text-left order-first xl:-order-none">15</span>
          </section>
          <section className="flex xl:flex-col gap-[10px] justify-end">
            <span className="w-[100px] xl:w-[106.82px] rounded-[24px] bg-[linear-gradient(270deg,_#1680FD_0%,_#6BA0FF_100%)] h-[21.41px]"></span>
            <span className="text-[#0000004D] order-first xl:-order-none text-left">16</span>
          </section>
          <section className="flex xl:flex-col gap-[10px] justify-end">
            <span className="w-[227px] xl:w-[242.76px]  rounded-[24px] bg-[radial-gradient(37.67%_827.64%_at_55%_29.69%,_#0FC3E1_0%,_#2BDBCE_100%)] h-[21.41px]"></span>
            <span className="text-[#0000004D] order-first xl:-order-none  text-left">18.5</span>
          </section>
          <section className="flex xl:flex-col gap-[10px] justify-end">
            <span className="w-[163.5px] xl:w-[174.8px] rounded-[24px] bg-[linear-gradient(270deg,_#FDCC19_15.74%,_#FEE276_87.96%)] h-[21.41px]"></span>
            <span className="text-[#0000004D] order-first xl:-order-none text-left">25</span>
          </section>
          <section className="flex xl:flex-col gap-[10px] justify-end">
            <span className="w-[163.5px] xl:w-[174.8px] rounded-[24px] bg-[linear-gradient(270deg,_#FF940A_16.67%,_#FFC072_80.56%)] h-[21.41px]"></span>
            <span className="text-[#0000004D] order-first xl:-order-none text-left">30</span>
          </section>
          <section className="flex xl:flex-col gap-[10px] justify-end">
            <span className="w-[163.5px] xl:w-[174.8px] rounded-[24px] bg-[linear-gradient(270deg,_#FE0E3D_0%,_#FF5C79_87.96%)] h-[21.41px]"></span>
            <span className="text-[#0000004D] order-first xl:-order-none text-left">35</span>
          </section>
        </section>
        <section className="xl:w-[311px] w-[247.82px] h-[89.79px] xl:h-[111px] mt-[23px] mx-[auto] col-span-full gap-1 flex xl:grid xl:grid-cols-[157px_132px] justify-between items-center">
          <ul className=" xl:text-[18px] text-sm text-[#3E4151] font-semibold flex flex-col gap-[12px]">
            <li>محدوده کمبود وزن</li>
            <li>محدوده وزن سالم</li>
            <li>محدوده اضافه وزن</li>
          </ul>
          <ul className=" text-[#3E4151] text-sm xl:text-normal font-normal flex flex-col gap-[12px]">
            <li className="flex gap-[9px] items-center">
              <span className="bg-[#358CFE] rounded-full w-[17px] h-[17px]"></span>
              <span>زیر ۱۸.۵</span>
            </li>
            <li className="flex gap-[9px] items-center">
              <span className="bg-[#44DFD3] rounded-full w-[17px] h-[17px]"></span>
              <span>بین ۱۸.۵ و ۲۴.۹</span>
            </li>
            <li className="flex gap-[9px] items-center">
              <span className="bg-[#FEDF6B] rounded-full w-[17px] h-[17px]"></span>
              <span>بین ۲۵ و ۲۹.۹</span>
            </li>
          </ul>
        </section>
        <section className="w-full h-auto xl:h-[462px] xl:py-[46px] xl:pl-[143px] xl:pr-[24px] mt-[48.62px] mx-[auto] flex flex-col col-span-full rounded-[30px] bg-[linear-gradient(9.19deg,_rgba(214,_99,_226,_0.12)_25.45%,_rgba(239,_195,_244,_0.12)_56.27%,_rgba(255,_255,_255,_0.12)_95.33%)] xl:bg-gradient-to-l xl:from-[#d663e21f] xl:via-[#efc3f41f] xl:to-[#ffffff1f] relative">
          <img className="xl:w-[456.04px] w-full xl:h-[284.26px]  xl:absolute  xl:bottom-[36.74px] xl:left-[58.52px]" src={imagesFileUrl + "bg-BmiMeterMax.png"} alt="" />
          <section className="px-4 xl:px-0 pb-[70px] xl:pb-0 xl:w-[817px] xl:h-[370px] mt-[42px] xl:mt-0 ">
            <h2 className="text-[18px] font-bold text-[#3E4151]">عوارض بالا بودن BMI چیست؟</h2>
            <p className="text-[#3E4151] font-normal leading-[23.68px] mt-[10px]">افرادی که طبق معیار BMI دچار اضافه‌وزن هستند (با BMI بیش از 25 کیلوگرم بر مترمربع)، رژیم غذایی مناسبی رعایت نمی‌کنند و فعالیت و تحرک بدنی کافی ندارند، درمعرض خطرات زیر قرار دارند:</p>
            <ul className=" text-[#3E4151] font-normal mt-[10px] flex flex-col gap-[8px]">
              <Item text="کلسترول خون بالا یا سایر اختلالات چربی" />
              <Item text="دیابت نوع ۲" />
              <Item text="بیماری قلبی" />
              <Item text="سکته مغزی یا قلبی" />
              <Item text="فشارخون بالا سرطان‌های خاص" />
              <Item text="بیماری کیسه صفرا" />
              <Item text="آپنه خواب و خروپف" />
              <Item text="مرگ زودرس" />
              <Item text="آرتروز و بیماری مفصلی" />
            </ul>
          </section>
        </section>
        <section className="w-full xl:h-[498px] bg-[linear-gradient(9.19deg,_rgba(214,_99,_226,_0.12)_25.45%,_rgba(239,_195,_244,_0.12)_56.27%,_rgba(255,_255,_255,_0.12)_95.33%)] h-auto mt-[34px] xl:mb-[61px] mx-[auto] col-span-full rounded-[30px] xl:bg-gradient-to-r xl:from-[#d663e21f] xl:via-[#ecb7f20f] xl:to-[#FFFFFF00] relative">
          <img className="xl:w-[489.81px] w-full xl:h-[285.26px] xl:absolute xl:bottom-[42.74px] xl:right-[20.61px]" src={imagesFileUrl + "bg-BmiMeterMin.png"} alt="" />
          <section className="xl:w-[839px] px-[24px]  w-full h-auto xl:h-[96.96px] mt-[53px] xl:ml-[121px] xl:mr-[24px]">
            <h2 className="text-[18px] font-bold text-[#3E4151]">عوارض پایین بودن BMI چیست؟</h2>
            <p className="text-[#3E4151] font-normal leading-[24.8px] mt-[12px]">افرادی که دچار کمبودوزن هستند ( BMI کمتر از 18.5 کیلوگرم در مترمربع)، موادمغذی کافی به بدن‌شون وارد نمی‌شه و اهل ورزش هم نیستن، ممکنه دچار سوءتغذیه بشن و به موارد زیر مبتلا بشن:</p>
          </section>

          <ul className=" text-[#3E4151] px-[28px] pb-[40px] font-normal mt-[27px] xl:mt-[63.04px] xl:mr-[524px] xl:ml-[165px]  flex flex-col gap-[8px]">
            <Item text="خطر ضعف عملکرد ایمنی بدن" />
            <Item text="بیماری‌های تنفسی" />
            <Item text="بیماری‌های گوارشی" />
            <Item text="سرطان" />
            <Item text="پوکی استخوان" />
            <Item text="نازایی" />
            <Item text="افسردگی" />
          </ul>
        </section>
      </section>
    </>
  );
}
