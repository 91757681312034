import { baseFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:h-[360px] mx-auto relative">
      <section className="w-[90%] xl:w-[1128px]  mx-auto pt-6 flex">
        <span className=" text-[#5408A9]">خانه</span>
        <span className="text-[#A6A9BD]">/ مربیان بادینس</span>
      </section>
      <img
        src={baseFileUrl + "/images/heroNew.png"}
        alt=""
        className="absolute hidden xl:block xl:top-0 left-0 -z-10 w-full "
      />
      <img
        src={baseFileUrl + "/images/heroNewMobile.png"}
        alt=""
        className="xl:hidden absolute top-0 left-0 -z-10 w-full "
      />
      {/* <img
        src={baseFileUrl + "/Vector-bg.png"}
        alt=""
        className="absolute -bottom-[40px] xl:top-[110px] right-8 xl:right-[177px] w-[30%] xl:w-[311.86px]"
      /> */}
      <h2 className="xl:text-[50px] text-[32px] text-center mt-[65px] xl:mt-[108px] leading-[46.5px] xl:leading-[77.5px] font-bold text-[#191919]">
        مربیان بادینس
      </h2>
      <p className="text-center mb-[80px] w-[90%] mx-auto xl:w-full xl:mb-0 xl:mt-5 mt-4 leading-[21.7px] text-lg xl:text-balance xl:leading-[24.8px] text-[#3E4151]">
        یه مربی خوب ، یه برنامه تمرین اختصاصی ، یه بدن عالی
      </p>
    </section>
  );
}
