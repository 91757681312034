import { Progress } from "antd";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ weight, title, color, ...rest }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex justify-between items-center w-full" {...rest}>
      <section className="w-[218px]">
        <section className="forn-normal text-sm text-left">
          {weight} گرم
        </section>
        <Progress
          strokeColor={color}
          showInfo={false}
          style={{ direction: "ltr" }}
          percent={60}
        />
      </section>
      <span
        className={`block w-[80px] font-bold text-sm mt-4 text-[${String(
          color
        )}]`}
      >
        {title}
      </span>
    </section>
  );
}
