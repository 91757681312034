import { useEffect, useState } from "react";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, exerciseTarget, setExerciseTarget }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [activeButton, setActiveButton] = useState("");
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setExerciseTarget({ ...exerciseTarget, description: activeButton });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeButton]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full">
      <section className="w-full text-[#3E4151] text-xs text-right xl:text-[20px] font-semibold ">
        <span className="text-red-600 ml-2">*</span>
        <span>{title}</span>
      </section>
      <section className="w-full flex mt-4 gap-[11px] justify-between">
        <section onClick={() => setActiveButton("تناسب اندام")} className="xl:w-[212px] w-[136%] h-10 xl:h-[56px] relative cursor-pointer">
          <section className="absolute top-[50%] right-[6px] xl:right-[16px] translate-y-[-50%] ">
            <div className={` border border-[#E5E7EB] w-3 xl:w-4 relative h-3 xl:h-4 rounded-full`}>
              <div className={`${activeButton === "تناسب اندام" ? "bg-[#5521B4]" : "bg-white"} transition-all duration-300  xl:w-[7px] w-[6px] h-[6px] xl:h-[7px]  rounded-full absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]`}></div>
            </div>
          </section>
          <section className="border flex justify-center items-center border-[#DDCEEE]  w-full xl:w-[212px] h-[40px] xl:h-[56px]  text-xs xl:text-base  leading-[12px] xl:leading-[20px] pr-4   xl:pr-5 rounded-xl">تناسب اندام</section>
        </section>
        <section onClick={() => setActiveButton("کاهش وزن")} className=" w-[90%] xl:w-[141px] h-[40px] xl:h-[56px] relative cursor-pointer">
          <section className="absolute top-[50%] right-[6px] xl:right-[16px] translate-y-[-50%] ">
            <div className={` border border-[#E5E7EB] w-3 xl:w-4 relative h-3 xl:h-4 rounded-full`}>
              <div className={`${activeButton === "کاهش وزن" ? "bg-[#5521B4]" : "bg-white"} transition-all duration-300 xl:w-[7px] w-[6px] h-[6px] xl:h-[7px]  rounded-full absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]`}></div>
            </div>
          </section>
          <section className="border flex justify-center items-center border-[#DDCEEE] outline-none w-full xl:w-[141px] h-[40px] xl:h-[56px]  text-xs xl:text-base  leading-[12px] xl:leading-[20px]  pr-4   xl:pr-5 rounded-xl">کاهش وزن</section>
        </section>
        <section onClick={() => setActiveButton("افزایش وزن")} className="w-[90%] xl:w-[144px] h-[40px] xl:h-[56px] relative cursor-pointer">
          <section className="absolute top-[50%] right-[6px] xl:right-[16px] translate-y-[-50%] ">
            <div className={` border border-[#E5E7EB] w-3 xl:w-4 relative h-3 xl:h-4 rounded-full`}>
              <div className={`${activeButton === "افزایش وزن" ? "bg-[#5521B4]" : "bg-white"} transition-all duration-300 xl:w-[7px] w-[6px] h-[6px] xl:h-[7px]  rounded-full absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]`}></div>
            </div>
          </section>
          <section className="border flex justify-center items-center border-[#DDCEEE] w-full xl:w-[144px] h-[40px] xl:h-[56px]  text-xs xl:text-base  leading-[12px] xl:leading-[20px]  pr-4   xl:pr-5 rounded-xl">افزایش وزن</section>
        </section>
      </section>
    </section>
  );
}
