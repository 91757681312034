//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <h2 className="text-[18px] font-bold">درباره محسن زیوری </h2>
      <p className="text-justify font-normal mt-[20px]">
        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده
        از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
        متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه
        درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد
        {/*  دکتر ستاره بخشنده یکی از مجرب‌ترین و شناخته‌شده‌ترین دکترهای تغذیه در کشور است. با بیش از دو دهه تجربه در زمینه تغذیه و رژیم‌درمانی، او توانسته است به صدها نفر کمک کند تا به اهداف سلامتی و تناسب اندام خود برسند.
                دکتر بخشنده با استفاده از جدیدترین یافته‌های علمی و روش‌های نوین تغذیه‌ای، برنامه‌های منحصر به فرد و متناسب با نیازهای فردی هر بیمار ارائه می‌دهد. او همچنین در بسیاری از همایش‌ها و کنفرانس‌های ملی و بین‌المللی به عنوان سخنران و کارشناس حضور داشته و مقالات متعددی در مجلات معتبر علمی منتشر کرده است. مهارت‌های بین‌المللی و دلسوزی بی‌نظیر او باعث شده تا مراجعان زیادی از سراسر کشور به دنبال مشاوره‌های تخصصی و حرفه‌ای او باشند */}
      </p>
    </>
  );
}
