import { svgFileUrl } from "../../../../../../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ index, item, deleteFood }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid grid-cols-5 text-xs xl:text-base xl:gap-12 gap-[10px] mt-[25px] items-center  border-b py-2 border-slate-200">
      <section className="flex xl:w-full justify-between col-span-4">
        <span className="block w-[10%] ">{index}</span>
        <span className="block text-purple w-[70%] font-semibold">
          {item?.mainFoodDescription}
        </span>
        <span className="block w-[20%] xl:text-start text-end">
          {item?.energyKcal}کالری
        </span>
      </section>

      <section className="flex justify-end xl:ml-8 mb-5 xl:mb-0">
        <button onClick={() => deleteFood(item.foodNutritionId)}>
          <img
            src={svgFileUrl + "trash.svg"}
            alt=""
            className="block w-4 h-auto xl:w-6"
          />
        </button>
      </section>
    </section>
  );
}
