import { useState } from "react";
import { baseFileUrl, imagesFileUrl, svgFileUrl } from "../../../../config";
import moment from "moment-jalaali";
import { useBasicInfoStore } from "../../../../store/dashboard/basicInfo";
import MobileMenu from "./components/MobileMenu";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [menuOpen, setMenuOpen] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="fixed w-full h-[64px] bg-[#ffffffbd] top-0 left-0 xl:hidden flex justify-between items-center px-[18px] z-[1000]">
        <img
          className="mobileMenuBtnD w-[28px] h-[28px]"
          onClick={() => setMenuOpen(!menuOpen)}
          src={svgFileUrl + "mobileMenuGray.svg"}
          alt=""
        />
        <img
          className="w-[46px] h-[46px]"
          src={imagesFileUrl + "dashboard/whiteLogo.png"}
          alt=""
        />
      </section>
      <MobileMenu open={menuOpen} setOpen={setMenuOpen} />
      <section className="w-full h-[54px]  flex justify-between items-center">
        <section className="w-[135px]">
          <section className="w-full flex justify-between items-center">
            <section className="text-[14px] font-bold hiText">
              وقت بخیر، {basicInfo.firstName}
            </section>
            <img src={baseFileUrl + "/images/dashboard/hi.png"} alt="" />
          </section>
          <span className="text-[12px] font-normal text-[#333333]">
            بریم که باهم شروع کنیم
          </span>
        </section>
        {/*  <section className="w-[432px] flex justify-between">
      <section className="w-[368px] relative">
        <input
          placeholder="جستجو"
          type="text"
          className="w-full h-[40px] pr-[45px] rounded-[172px] shadow-md"
        />
        <img
          src={baseFileUrl + '/images/dashboard/search.svg'}
          className="absolute top-3 right-5"
          alt=""
        />
      </section>
      <section className="w-[44px] h-[44px] bg-white shadow-lg rounded-full relative flex justify-center items-center cursor-pointer">
        <section className="w-[14px] h-[14px] bg-white flex justify-center rounded-full items-center absolute top-0 right-0">
          <span className="block w-[10px] h-[10px] rounded-full bg-[#DC1B1B]"></span>
        </section>
        <img src={baseFileUrl+"/images/dashboard/ring.svg"} alt=''/>
      </section>
    </section> */}
        <section className="w-[122px] flex justify-center ">
          <span className="text-purple font-extrabold">
            {moment().format("jYYYY/jMM/jDD")}
          </span>
          <img
            src={svgFileUrl + "calender.svg"}
            className="w-[35px] h-[20px] mr-1"
            alt=""
          />
        </section>
      </section>
    </>
  );
}
