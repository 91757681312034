import { imagesFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%] group mx-auto mb-[50px] xl:mb-[150px] h-auto">
      <section className="grid xl:grid-cols-2  mt-[40px] w-full  xl:w-[1128px]  mx-auto gap-6">
        {/* <Item id={1} src={imagesFileUrl + "coach/zivari-p.png"} color="text-[#5521B4]" price="۲،۵۰۰،۰۰۰" title="پکیج آقای زیوری " backGround="bg-white" textColor="text-[#5521B4]" bgColorTag="bg-[#F9F5FE]" details={["این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."]} />
         */}
        <Item id={7} btnColor="#5521B4" src={imagesFileUrl + "coach/nazari-p.jpg"} color="text-[#5521B4]" price="۲،۵۰۰،۰۰۰" title="مربی خانم مهدیه نظری" backGround="bg-white" textColor="text-[#5521B4]" bgColorTag="bg-[#F9F5FE]" details={["دکترای تخصصی فیزیولوژی (قلب، عروق و تنفس)", "مربی بدنسازی تیم ملی موی تای", "مربی تیم ملی مچ‌اندازی", "مربی تیم ملی فیتنس چلنج", "مدرس فدراسیون‌های بدنسازی، سه‌گانه و همگانی", "نایب‌رئیس کمیته فیتنس ایران", "رئیس کمیته بانوان فدراسیون بدنسازی", "رئیس دپارتمان فیزیولوژی وزنه‌های تمرینی فدراسیون"]} />
        <Item id={6} btnColor="#F3509D" src={imagesFileUrl + "coach/mohsenzadeh-p.png"} color="text-[#5521B4]" price="۲،۵۰۰،۰۰۰" title="مربی آقای مهدی محسن زاده" backGround="bg-white" textColor="text-[#5521B4]" bgColorTag="bg-[#F9F5FE]" details={["مربی عالی بدنساز آکادمی المپیک", "مدرک مربیگری شخصی از آکادمی ملی پزشکی ورزشی آمریکا NASM", "کارشناس ارشد فیزیولوژی ورزشی دانشگاه شهید بهشتی", "مترجم، مولف و ویراستار 8 عنوان کتاب در زمینه علوم ورزشی", "مربی درجه سه فدراسیون بدنسازی", "مدرس دوره های تخصصی موبیلیتی"]} />
        <Item
          id={4}
          src={imagesFileUrl + "coach/tarverdi-p.png"}
          btnColor="#5521B4"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="مربی خانم نگار تارویردی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          details={["رئیس کمیته کار با دستگاه هیأت آمادگی جسمانی شمیرانات", "دارای مدارک درجه ۲ و ۳ فدراسیون بدنسازی و پرورش اندام", "مربی درجه ۳ هيات آمادگی جسمانی", "دارای مدرک TRX (تمرینات معلق با وزن بدن)، از هیأت آمادگی جسمانی", "دارای مدرک cxworks (ترکیبی از تمرینات قدرتی و هوازی جهت تقویت عضلات مرکزی)", "۱۱ سال سابقه ورزشی و ۷ سال سابقه مربیگری"]}
        />
        <Item id={5} btnColor="#F3509D" src={imagesFileUrl + "coach/karimi-p.png"} color="text-[#5521B4]" price="۲،۵۰۰،۰۰۰" title="مربی آقای محمد کریمی زند " backGround="bg-white" textColor="text-[#5521B4]" bgColorTag="bg-[#F9F5FE]" details={["کارشناس ارشد فیزیولوژی ورزشی", "دارای مدرک تغذیه پیشرفته از سازمان NSCA", "دارای مدارک درجه ۳ فدراسیون بدنسازی و پرورش اندام", "سابقه فعالیت 10 ساله"]} />
        <Item id={2} btnColor="#5521B4" src={imagesFileUrl + "coach/hosseini-p.png"} color="text-[#5521B4]" price="۲،۵۰۰،۰۰۰" title="مربی خانم بیتا حسینی " backGround="bg-white" textColor="text-[#5521B4]" bgColorTag="bg-[#F9F5FE]" details={["دارای مربیگری درجه ۲ بدنسازی و کار با دستگاه از فدراسیون پرورش اندام", "مدرک مربیگری بین المللی  WNBF", "مدرک مربیگری بین المللی درجه ۱ از آکادمی اتریش و آلمان IFPE", "گواهینامه تغذیه کاربردی و روش‌های نوین طراحی تمرین", "گواهینامه حرکات اصلاحی و آسیب شناسی", "گواهینامه ارزیابی وضعیت بدنی و حرکات اصلاحی"]} />
        {/*         <Item id={3} src={imagesFileUrl + "coach/ganji-p.png"} color="text-[#5521B4]" price="۲،۵۰۰،۰۰۰" title="مربی خانم فاطمه گنجی" backGround="bg-white" textColor="text-[#5521B4]" bgColorTag="bg-[#F9F5FE]" details={["کارشناس تربیت بدنی", "دارای مدارک درجه ۲ و ۳ فدراسیون بدنسازی و پرورش اندام", "دارای مدرک تغذیه پیشرفته از سازمان NSCA", "مربی درجه ۳ هيات آمادگی جسمانی"]} />
         */}
        {/*<Item
          id={4}
          src={imagesFileUrl + "coach/tarverdi2.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم تارویردی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        /> */}
        {/*<Item
          id={4}
          src={imagesFileUrl + "coach/tarverdi3.png"}
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج خانم تارویردی "
          backGround="bg-white"
          textColor="text-[#5521B4]"
          bgColorTag="bg-[#F9F5FE]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        /> */}
        {/*<Item
          src="/pic-5.png"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج عادی/ شماره ۱"
          backGround="bg-[#A6A9BD1A]"
          textColor="text-[#3E4151]"
          bgColorTag="bg-[#FFFFFF]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        />
        <Item
          src="/pic-9.png"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج عادی/ شماره 2"
          backGround="bg-[#A6A9BD1A]"
          textColor="text-[#3E4151]"
          bgColorTag="bg-[#FFFFFF]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        /> */}
        {/*  <Item
          src="/pic-6.png"
          color="text-[#5521B4]"
          price="۲،۵۰۰،۰۰۰"
          title="پکیج عادی/ شماره 3"
          backGround="bg-[#A6A9BD1A]"
          textColor="text-[#3E4151]"
          bgColorTag="bg-[#FFFFFF]"
          text="این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد."
        /> */}
      </section>
    </section>
  );
}
