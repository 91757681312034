import { imagesFileUrl } from "../../../../../config";
import InfoInput from "./components/InfoInput";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-auto xl:h-[987px] py-5 xl:py-[44px] xl:px-[40px] mt-[30px] xl:mt-[40px] mb-[100px] mx-auto rounded-[10px] bg-[#FEF4F9]">
        <section className="w-[95%] xl:w-[1053px] h-auto xl:h-[899px] xl:pr-[32px] xl:pl-[33px] mx-auto bg-[#FFFFFF] shadow-[0px_6px_14px_0px_#0000000f] rounded-[10px] flex flex-col xl:grid xl:grid-cols-[514px_426px] xl:justify-between">
          <section className="w-[90%] xl:w-full h-auto xl:h-[419px] mt-10 xl:mt-[56px] mx-auto order-2 xl:-order-none">
            <h2 className="text-[18px] leading-[27.9px] font-bold text-[#3E4151]">توده بدون چربی (LBM)</h2>
            <p className="text-[16px] leading-[24.8px] text-justify font-normal text-[#3E4151] mt-[20px]">
              وزن یا توده بدون چربی بدن شامل همه اجزا بدن، به جز چربی هست. یعنی تمام اعضا، استخوانها، عضلات، خون، پوست و همه چیزهایی که چربی ندارن.چه اهمیتی داره؟ اگر در حال کاهش وزن هستی خیلی مهمه که بدونی LBM شما قبل و بعد از کاهش وزن چقدره. با محاسبه LBM می‌تونی مطمئن شی در حال از دست دان چربی هستی یا اینکه عضلات خودت را می‌سوزونی.
              <br />
              اگر به جای چربی،عضلات و سایر اجزا بدنت کاهش وزن پیدا کنه، ظاهر شما متناسب به نظر نمی‌رسه و گذشته از اینها ممکنه با خطرات جدی سلامتی مواجه بشی.این یعنی رژیم یا ورزش شما کارآیی لازم رو نداره.
              <br />
              به طور کلی، مردان نسبت به زنان نسبت LBM بالاتری دارن. دوز برخی از عوامل بیهوشی، به ویژه داروهای محلول در آب، به طور معمول بر اساس LBM هست. برخی از معاینات پزشکی هم از مقادیر LBM استفاده می کنن. برای تناسب اندام بدن و زندگی روزمره، افراد معمولاً بیشتر از LBM به درصد چربی بدن اهمیت می دهن.
              <br />
              فرمول های متعددی برای محاسبه LBM هست که ماشین حساب ما نتایج را برای همه فرمول ها ارائه می کن
            </p>

            <img src={imagesFileUrl + "bg-lbmPlanner.png"} className="w-[90%] xl:w-[381.98px] xl:h-[287px] mx-auto mb-[66px] mt-[40px] xl:mt-[22px]" alt="" />
          </section>

          <InfoInput />
        </section>
      </section>
    </>
  );
}
