import { pdf } from "@react-pdf/renderer";
/* import PDFDocument from "./PDFSample"; */
import BodyNessPdf from "../../../../../../../../../components/PDF/ExercisePlan";
/* import PDFTable from "./PDFTable"; */

const MyPDFPage = ({ data }) => {
  const DownloadPDF = async () => {
    const blob = await pdf(<BodyNessPdf data={data} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "example.pdf";
    link.click();
  };
  return (
    <div>
      <button className={`w-[148px] h-[30px] border border-[#F3509D]  rounded-[100px] flex justify-center items-center hover:text-white hover:bg-[#F3509D] text-[#F3509D]  text-sm`} onClick={DownloadPDF}>
        دانلود PDF
      </button>
    </div>
  );
};

export default MyPDFPage;
