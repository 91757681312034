import { useState } from "react";
import SignIn from "./components/SignIn";
import SendActiveCode from "./components/SendActiveCode";
import { imagesFileUrl } from "../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [panelMode, setPanelMode] = useState("login");
  const [mobileNumber, setMobileNumber] = useState("");
  const [activeCode, setActiveCode] = useState("");
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-screen w-full bg-gradient-to-l from-[#f02483] to-[#5408aa] relative">
      <img src={imagesFileUrl + "halfHeart.png"} className="absolute w-[50%] right-[25%] top-0" alt="" />
      <section className="flex h-screen w-full items-center justify-center">{panelMode === "login" ? <SignIn setPanelMode={setPanelMode} mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} /> : panelMode === "activeCode" ? <SendActiveCode setPanelMode={setPanelMode} mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} activeCode={activeCode} setActiveCode={setActiveCode} /> : ""}</section>
    </section>
  );
}
