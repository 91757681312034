import { useEffect, useState } from "react";
import ExerciseList from "./components/ExerciseList";
import ExerciseListView from "./components/ExerciseListView";
import { svgFileUrl } from "../../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [finalList, setFinalList] = useState([]);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log("finalList", finalList);
  }, [finalList]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className=" h-auto mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
        <ExerciseList finalList={finalList} setFinalList={setFinalList} />
      </section>
      {/* <section className=" h-auto mt-[40px] p-[24px] bg-white rounded-[16px] shadow-md">
        <section className="flex justify-between">
          <section className="text-lg font-bold">برنامه تمرینی نهایی</section>
          <button onClick={() => setFinalList([])}>
            <img src={svgFileUrl + "trash.svg"} alt="" />
          </button>
        </section>
          <ExerciseListView finalList={finalList} />
      </section> */}
    </>
  );
}
