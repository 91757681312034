import { useEffect, useState } from "react";
import { TextBox } from "../../../../../../components/common";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ item, label, active, setAnswers, answers }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [activeButton, setActiveButton] = useState(false);
  const [description, setDescription] = useState("");

  // ─── States ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const handleOnChange = (checked, value) => {
    setActiveButton(checked);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let arr = [];
    arr = [...answers];
    arr.forEach((item1, index) => {
      if (item1.questionId === item.id) {
        arr[index].description = description;
        arr[index].answer = activeButton;
      }
    });
    setAnswers(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, activeButton]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className=" w-full flex justify-between items-center">
        <h3 className="text-[#3E4151] text-xs xl:text-[20px] leading-[12px] xl:leading-5 font-semibold">{item.persianName}</h3>
        <section className="flex items-center gap-1 xl:gap-0 xl:w-[120px] justify-between tracking-[0.5%] text-[#6B7280] text-sm xl:text-[20px] leading-[14px] xl:leading-5 font-semibold xl:font-medium">
          <div onClick={() => handleOnChange(!activeButton, item.id)} className={`${activeButton ? "bg-[#5521B4]" : "bg-[#DDCEEE]"} xl:w-[56px] w-[35px] relative h-5 xl:h-8 rounded-full`}>
            <div className={`${activeButton ? "right-1" : "xl:right-7 right-[17px]"} transition-all duration-300 w-[15px] h-[15px] xl:w-6 xl:h-6 bg-white rounded-full absolute top-[50%] translate-y-[-50%]`}></div>
          </div>
          {activeButton ? (active ? active : "دارم") : label}
        </section>
      </section>
      <TextBox onChange={(e) => setDescription(e.target.value)} className={`mt-2 ${activeButton ? "block" : "hidden"}`} placeholder="توضیحات ..." />
    </>
  );
}
