import { useState, useContext, useEffect } from "react";
import Step from "../../../assets/images/basicInfo/step1L.png";
import Progress from "../components/Progress";
import HeightPicker from "../components/HeightPicker";
import NumberPicker from "../../../components/common/NumberPicker";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, setHeight, defaultValue }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <section className="h-[78%] w-full flex flex-col xl:block justify-between items-center">
        <section className="w-full">
          <span className="block text-center font-bold text-xl xl:text-3xl mt-7">قدتو وارد کن</span>
          <section className="hidden xl:block xl:h-[340px] 2xl:h-[470px]">
            <HeightPicker setHeight={setHeight} defaultValue={defaultValue} />
          </section>
          <section className="w-full xl:hidden">
            <NumberPicker value={defaultValue} setValue={setHeight} type="height" />
          </section>
        </section>
        <button className="text-[20px] bg-purple xl:m-auto xl:mt-10 block p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl" onClick={() => setStep(4)}>
          مرحله بعد
        </button>
      </section>

      {/* border-radius: 16px;
    font-size: 20px;
    font-family: YekanBakh;
    background-color: #5408aa;
    display: block;
    padding: 10px;
    width: 430px;
    max-width: 100%;
  
    color: white;
    margin-top: 60px;
    transition: all 0.5s linear; */}
    </>
  );
}
