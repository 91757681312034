import { useState, useEffect } from "react";
import { imagesFileUrl } from "../../../../config";
import { useAxiosWithToken } from "../../../../hooks";
import { Button } from "../../../../components/common";
import api from "../../../../api";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const Item = ({ children }) => {
  return <span className="bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center">{children}</span>;
};
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(1);
  const [price, setPrice] = useState("1,200,000");
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  /*   const rquestPayment = () => {
    let params = {
      requestProgramInputDto: {
        requestProgramType: "COACH",
        userCoach: "09125307703",
        namePackaging: "برنامه بدنسازی خفن علی خان مشیری امین",
        packageInfoId: "1",
      },
    };
    useAxiosWithToken
      .post(api.payment.request, params)
      .then((res) => {
        window.location.href = res?.data?.url;
      })
      .catch((err) => {});
  }; */
  const getPackageInfo = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.packageInfo.getPackageInfo)
      .then((res) => {
        navigation(`/shopping-cart?amount=${res?.data?.amount}&name=${res.data.name}&id=${res.data.id}`);
      })
      .catch((err) => {});
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {}, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="min-h-[400px] bg-[#F1E8FC] rounded-[10px] mt-[64px] relative p-[1px]">
      <section className="w-full absolute top-0 z-0">
        <img className="m-auto" src={imagesFileUrl + "doctor-profile-pattern.png"} alt="" />
      </section>
      <section className="z-[1] relative mt-[64px] px-[28px] pb-[28px]">
        <section className="w-full overflow-auto">
          <section className="flex gap-4 w-[150%] xl:w-full">
            <button
              onClick={() => {
                setActive(1);
                setPrice("1,200,000");
              }}
              className={`w-[237px] h-[40px] rounded-full bg-white border border-[#DDCEEE] shadow-[0px_13px_20px_-11.96px_#5408A933] ${active === 1 ? "text-purple" : "text-black"}`}
            >
              دریافت برنامه ورزشی عادی
            </button>
            <button
              onClick={() => {
                setPrice("3 ماهه 4.5 تا 6 میلیون");
                setActive(2);
              }}
              className={`w-[237px] h-[40px] rounded-full bg-white border border-[#DDCEEE] shadow-[0px_13px_20px_-11.96px_#5408A933] ${active === 2 ? "text-purple" : "text-black"}`}
            >
              دریافت برنامه ورزشی VIP
            </button>
          </section>
        </section>
        {active === 1 && (
          <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
            <ul className="list-disc pr-[15px] mt-[10px]">
              <li>تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر (چربی‌سوزی، افزایش حجم عضلات، تمرینات مخصوص سلامتی و غیره.</li>
              <li>ارائه تمرین‌های عمومی و پایه</li>
            </ul>
          </section>
        )}
        {active === 2 && (
          <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
            <ul className="list-disc pr-[15px] mt-[10px]">
              <li>گزارش‌های پیشرفت منظم: تحلیل پیشرفت کاربر در تمرینات و ارائه بازخوردهای شخصی‌سازی‌شده به طور ماهانه یا هفتگی.</li>
              <li>پشتیبانی اختصاصی : ارتباط مستقیم با مربیان از طریق چت آنلاین</li>
              <li>امکان سفارشی‌سازی تمرینات: کاربران می‌توانند به‌راحتی برنامه‌ها را بر اساس نیازهای خود تغییر دهند یا درخواست تغییرات ویژه‌ای در تمرینات خود داشته باشند.</li>
              <li>امکان ثبت‌نام در چالش‌های خاص: چالش‌های فصلی یا مناسبتی (مانند چالش‌های ۳۰ روزه یا چالش‌های کاهش وزن) فقط برای کاربران VIP فعال است. </li>
              <li>ایونت های ورزشی : امکان حضور در ایونت های ورزشی با حضور مربی</li>
              <li>سمینار های آنلاین : شرکت در سمینار های آموزشی و رفع اشکال</li>
              <li>ثبت و مشاهده رکوردها: امکان ثبت رکوردهای شخصی و مشاهده دستاوردها و نمودارهای پیشرفت در هر بخش با استفاده از بادینس پلنر</li>
              <li>در نسخه بعدی اپ : امکان برگزاری کلاس آنلاین با دوربین های 360 درجه</li>
              <li className="font-bold">قیمت پایه : 3 ماهه 4.5 تا 6 میلیون / 6 ماهه : 10 میلیون</li>
            </ul>
          </section>
        )}
      </section>
      <section className="relative mt-[32px] px-[28px] pb-[28px]">
        <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
          <span className="text-[#3E4151] font-bold">این بسته برای چه افرادی مناسب است:</span>
          <ul className="list-disc pr-[15px] mt-[10px]">
            <li>افراد با تحرک کم</li>
            <li>افراد گیاه خوار</li>
          </ul>
          <span className="text-[#3E4151] font-bold mt-[50px] block">ویژگی‌های بسته VIP</span>
          {/*    <section className="flex gap-3 flex-wrap mt-[20px]">
            <Item>ویدئو کال</Item>
           
            <Item>تمرین</Item>
          
          </section> */}
          <section className="flex items-center  mt-[23px] gap-2">
            <img src={imagesFileUrl + "doctor-profile-timer.png"} alt="" />
            <span className="font-bold text-[#3E4151] block">مدت زمان برنامه : 3 روز</span>
          </section>
        </section>
      </section>
      <section className="relative mt-[32px] px-[28px] pb-[28px]">
        <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
          <section className="flex items-center justify-between">
            <span className="font-bold">قیمت بسته:</span>
            <section className="flex items-center gap-2">
              <span className="text-[24px] font-bold">{price}</span>
              <span className="font-bold">تومان</span>
            </section>
          </section>
          <section className="text-left">
            <Button loading={loading} onClick={getPackageInfo} className="w-[188px] rounded-full mt-[32px] h-[30px] mr-auto bg-purple text-white">
              خرید پکیچ
            </Button>
          </section>
        </section>
      </section>
    </section>
  );
}
