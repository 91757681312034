import { baseFileUrl } from "../../../../config";
import { imagesFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%] xl:mb-[80px] mb-[50px] py-8  xl:py-[28px] xl:px-[58px] relative h-a xl:h-[230px] gap-[34px] items-center flex flex-col xl:flex-row rounded-[15px]  mx-auto">
      <img
        src={baseFileUrl + "/pic (8)-section8.png"}
        alt=""
        className="xl:w-[240px] w-full"
      />
      <section className=" xl:h-[102px] px-8 xl:px-0 h  xl:w-[615px] ">
        <h3 className="text-white font-bold text-[28px] xl:text-[32px] leading-[34.1px]">
          دریافت برنامه تناسب اندام از بادینس
        </h3>
        <p className="  font-normal mt-[15px] text-base xl:text-[22px]  leading-[33px] text-white">
          برای رسیدن به تناسب اندام، برنامه غذایی مخصوص خودت رو از ما دریافت کن.
        </p>
      </section>
      <Link
        to="/exercise-plan"
        className="text-white w-[137px] h-[49px] flex justify-center items-center bg-[#5521B4] font-bold text-[18px] rounded-[150px] "
      >
        بزن بریم
      </Link>
      <img
        src={baseFileUrl + "/Section-8.png"}
        alt=""
        className="h-full  hidden xl:block absolute w-full -z-10  left-0 "
      />
      <img
        src={imagesFileUrl + "/Section-6.png"}
        alt=""
        className="h-full  xl:hidden  absolute w-full -z-10  left-0 "
      />
    </section>
  );
}
