import React from 'react'
import { imagesFileUrl } from '../../../../../config'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <>
            <section className="mb-[19.11px] flex justify-center items-center gap-[8.99px]">
                <span className="text-white bg-[#7639BA] rounded-full h-[29.98px] w-[29.98px]  flex justify-center items-center">1</span>
                <span className='text-[22px] font-bold'>خانم هستی یا آقا؟</span>
            </section>
            <section className='flex justify-center gap-[31px]'>

                <section className='w-[198px] flex flex-col justify-center items-center h-[383px] shadow-sm bg-white rounded-[22px]'>
                    <span className='text-[24px] font-bold'>آقا</span>
                    <img alt='' className='mt-[21px]' src={imagesFileUrl + "slider-male.png"} />
                </section>
                <section className='w-[198px] flex flex-col justify-center items-center h-[383px] shadow-sm bg-white rounded-[22px]'>
                    <span className='text-[24px] font-bold'>خانم</span>
                    <img className='mt-[21px]' alt='' src={imagesFileUrl + "slider-female.png"} />
                </section>
            </section>
        </>

    )
}
