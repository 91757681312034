//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ img, title }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-[130px] ">
      <section className="w-[69px] h-[69px] mt-4  border-[2px] border-[silver] hover:last:text-[#F02484] rounded-full m-auto hover:scale-125 hover:border-[#F3509D] hover:shadow-[0px_11.04px_18.14px_-7.1px_#F67CB5] transition-all cursor-pointer">
        <img src={img} alt="" />
        <span className=" text-[11px] block  mt-[10px]">{title}</span>
      </section>
    </section>
  );
}
