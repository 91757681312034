import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [show, setShow] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className={`w-full fixed h-[50px] left-0  ${show ? "bottom-[0px]" : "bottom-[-50px]"}  z-50 bg-[#5606a8e0] transition-all duration-300`}>
      <button onClick={() => setShow(!show)} className="px-2 text-white h-[50px] absolute left-0 top-[-50px] rounded-tr-lg bg-purple">
        صفحات سایت
      </button>
      <section className="w-full h-full">
        <section className="flex h-full gap-3  items-center">
          <span className="h-full flex rounded-l-full justify-center items-center px-2 text-[#d1d1d1] border-l border-white">داشبورد کاربر</span>
          <Link className="text-white" to="/dashboard">
            داشبورد
          </Link>
          <Link className="text-white" to="/dashboard/basicInfo">
            پروفایل
          </Link>
          <Link className="text-white" to="/dashboard/recipe">
            رسپی
          </Link>
          <Link className="text-white" to="/dashboard/ticket">
            ارسال تیکت
          </Link>
          <span className="h-full flex rounded-l-full  justify-center items-center px-2 text-[#d1d1d1] border-l border-white">داشبورد دکتر</span>
          <Link className="text-white" to="/doctor-dashboard/food-package">
            پکیج ها
          </Link>
          <Link className="text-white" to="/doctor-dashboard/without-plan-user">
            افراد بدون برنامه
          </Link>
        </section>
      </section>
    </section>
  );
}
