function Target({ title, exerciseTarget }) {
  return <span className={`xl:w-[93px] h-[40px] xl:h-12 border border-[#DDCEEE] rounded-xl flex justify-center items-center ${exerciseTarget === title ? `bg-purple text-white` : `bg-white text-purple`}`}>{title}</span>;
}
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const exerciseHistory = data?.answerOutputDTOs[0]?.description?.split("&");
  const exerciseCapacity = data?.answerOutputDTOs[1]?.description?.split("&");
  const exerciseTarget = data?.answerOutputDTOs[2]?.description;
  const exerciseFun = data?.answerOutputDTOs[5]?.description;
  const homeExercise = data?.answerOutputDTOs[4];
  const job = data?.answerOutputDTOs[3]?.description;
  // ─── States ─────────────────────────────────────────────────────────────────────
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full  py-[27px] xl:px-[16px] items-center min-h-[94px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
      <span className="w-[109px] text-[13px] xl:text-base xl:w-[130px] text-[#3E4151] flex justify-center items-center font-bold absolute top-[-12px] bg-white right-4">اطلاعات ورزشی</span>
      <section className="flex xl:flex-row flex-col items-center gap-5 h-auto xl:h-[108px] w-full">
        <section className="xl:w-[344px] order-last xl:order-none xl:h-[88px] text-center">
          <span className="text-[#5408A9] font-medium block text-xs xl:text-[14px]">سابقه تمرینی :</span>
          <section className="grid grid-cols-3 gap-4 xl:gap-2 mt-4 xl:mt-6 text-xs xl:text-sm">
            <span className="text-[#A6A9BD]">رشته ورزشی</span>
            <span className="text-[#A6A9BD]">چه زمانی؟</span>
            <span className="text-[#A6A9BD]">چه مدت؟</span>
            <span className="text-[#191919]">{exerciseHistory[0]}</span>
            <span className="text-[#191919]">{exerciseHistory[1]}</span>
            <span className="text-[#191919]">{exerciseHistory[2]}</span>
          </section>
        </section>
        <span className="block h-[1px] xl:h-[88px] bg-[#E6E8E9] w-[80%] xl:w-[1px]"></span>
        <section className="w-auto xl:h-[88px] text-center">
          <section className="xl:w-[270px] xl:h-[88px] text-center">
            <span className="text-[#5408A9]  font-medium block text-xs xl:text-[14px]">توانایی ورزش در هفته :</span>
            <section className="grid grid-cols-2 gap-4 xl:gap-2 mt-6 text-xs xl:text-sm">
              <span className="text-[#A6A9BD]">روز</span>
              <span className="text-[#A6A9BD]">ساعت</span>
              <span className="text-[#191919]">{exerciseCapacity[0]}</span>
              <span className="text-[#191919]">{exerciseCapacity[1]}</span>
            </section>
          </section>
        </section>
        <span className="block h-[1px] xl:h-[88px]  bg-[#E6E8E9] w-[80%] xl:w-[1px]"></span>
        <section className="xl:w-[311px]  w-[80%] order-first xl:order-last h-auto xl:h-[88px]  text-center">
          <span className="text-[#5408A9] font-medium block text-xs xl:text-[14px]">هدف از تمرین :</span>
          <section className="grid grid-cols-3 gap-2 mt-4 xl:mt-8 text-xs xl:text-sm">
            <Target title="تناسب اندام" exerciseTarget={exerciseTarget} />
            <Target title="کاهش وزن" exerciseTarget={exerciseTarget} />
            <Target title="افزایش اندام" exerciseTarget={exerciseTarget} />
          </section>
        </section>
      </section>
      <span className="h-[1px] w-[80%] mx-auto xl:w-full bg-[#E6E8E9] block my-5 xl:my-[32px]"></span>
      <section className="flex flex-col xl:flex-row  items-center gap-5 xl:h-[78px] w-full">
        <section className="xl:w-[344px] xl:h-[78px] text-center">
          <span className="text-[#5408A9] font-medium block text-xs xl:text-[14px]">فعالیت های تفریحی :</span>
          <section className="grid  mt-6 text-xs xl:text-sm">
            <span className="text-[#191919]">{exerciseFun}</span>
          </section>
        </section>
        <span className="block h-[1px] xl:h-[78px] bg-[#E6E8E9] w-[80%] xl:w-[1px]"></span>
        <section className="w-auto xl:h-[78px] text-center">
          <section className="xl:w-[270px] xl:h-[78px] text-center">
            <span className="text-[#5408A9]  font-medium block text-xs xl:text-[14px]">شغل :</span>
            <section className="grid  mt-6 text-xs xl:text-sm">
              <span className="text-[#191919]">{job}</span>
            </section>
          </section>
        </section>
        <span className="block h-[1px] xl:h-[78px] bg-[#E6E8E9] w-[80%] xl:w-[1px]"></span>
        <section className="xl:w-[311px] xl:h-[78px]  text-center xl:mr-10">
          <span className="text-[#5408A9] font-medium block text-xs xl:text-[14px]">تمرین در باشگاه یا در منزل؟</span>
          <section className="flex xl:w-[260px] gap-6 mt-2 text-xs xl:text-sm">
            <span className="w-auto   flex justify-center items-center font-bold mt-4 text-[#F02484]">{homeExercise ? "منزل" : "باشگاه"}</span>
            <span className="block h-[46px] bg-[#E6E8E9] mt-4 w-[1px]"></span>
            <section className="flex flex-col gap-1 mt-4">
              <span className="text-[#A6A9BD] text-start">تجهیزات :</span>
              <span className="text-[#191919]">{homeExercise?.description}</span>
            </section>
          </section>
        </section>
      </section>
      <span className="h-[1px] bg-[#E6E8E9] w-[80%] mx-auto xl:w-full block my-5 xl:my-[32px]"></span>
      {/*    <section className="flex flex-col items-center  justify-center  h-auto w-full">
        <span className="text-[#5408A9] font-medium block text-[14px]">
          عکس اندام :
        </span>
        <section className="flex gap-4 mt-6 ">
          <img
            src={imagesFileUrl + "/dashboard/doctordoc.png"}
            alt=""
            className="w-[56px] h-[56px] xl:w-[72px] xl:h-[72px]"
          />
          <img
            src={imagesFileUrl + "/dashboard/doctordoc.png"}
            alt=""
            className="w-[56px] h-[56px] xl:w-[72px] xl:h-[72px]"
          />
          <img
            src={imagesFileUrl + "/dashboard/doctordoc.png"}
            alt=""
            className="w-[56px] h-[56px] xl:w-[72px] xl:h-[72px]"
          />
          <img
            src={imagesFileUrl + "/dashboard/doctordoc.png"}
            alt=""
            className="w-[56px] h-[56px] xl:w-[72px] xl:h-[72px]"
          />
          <img
            src={imagesFileUrl + "/dashboard/doctordoc.png"}
            alt=""
            className="hidden xl:block"
          />
        </section>
      </section> */}
    </section>
  );
}
