import { useState, useEffect } from "react";
import Header from "./components/Header";
import DietList from "./components/DietList";
import { Button } from "../../../../../../../components/common";
import { useSearchParams } from "react-router-dom";
import { useAxiosWithToken } from "../../../../../../../hooks";
import api from "../../../../../../../api";

import { notify } from "../../../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ activeTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  // ─── States ─────────────────────────────────────────────────────────────────────

  const [packageList, setPackageList] = useState([]);
  const [finalPackageList, setFinalPackageList] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const saveData = () => {
    let params = {
      requestId: searchParams.get("userId"),
      mobileNumber: searchParams.get("mobileNumber"),
      packageListInputDTOS: finalPackageList,
    };
    setLoading(true);
    useAxiosWithToken
      .post(api.foodProgramPackage.addPackageToUserDiet, params)
      .then((res) => {
        setLoading(false);

        notify.Success("پکیچ با موفقیت افزوده شد");

        setPackageList([]);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const deleteFromPackage = (id) => {
    let arr = [...finalPackageList];
    let result = arr.filter((el) => el.packageId !== id);
    setFinalPackageList(result);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let arr = [...packageList];
    let finalArr = [];
    for (var i = 0; i < arr.length; i++) {
      let foods = [];
      for (
        var j = 0;
        j < arr[i].foodProgramPackageDetailOutputDTO.length;
        j++
      ) {
        foods.push({
          dairyId:
            arr[i].foodProgramPackageDetailOutputDTO[j]
              .foodNutritionValuesOutputDto.id,
          consumedGram: "",
        });
      }
      finalArr.push({
        packageId: arr[i].id,
        typeOfMeal: activeTab,
        description: "",
        dairyListWithConsumedInputDTOS: foods,
      });
    }
    setFinalPackageList(finalArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageList]);
  useEffect(() => {
    console.log("finalPackageList:", finalPackageList);
  }, [finalPackageList]);
  useEffect(() => {
    console.log("packageList:", packageList);
  }, [packageList]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Header
        activeTab={activeTab}
        finalPackageList={finalPackageList}
        setFinalPackageList={setFinalPackageList}
        packageList={packageList}
        setPackageList={setPackageList}
      />
      {finalPackageList?.map((item, index) => (
        <>
          <DietList
            deleteFromPackage={deleteFromPackage}
            data={item}
            finalPackageList={finalPackageList}
            setFinalPackageList={setFinalPackageList}
          />
        </>
      ))}
      {finalPackageList.length > 0 && (
        <Button
          loading={loading}
          onClick={saveData}
          className="bg-purple m-auto mt-[35px] mb-5 xl:mb-0 text-white w-[164px] h-[40px] rounded-full text-sm flex justify-center gap-1 items-center"
        >
          ثبت نهایی
        </Button>
      )}
    </>
  );
}
