"use client";
import { useState, useEffect } from "react";
import { Select } from "antd";
import "./style.css";
import axios from "axios";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({
  title,
  dark,
  api,
  optionTitle,
  optionValue,
  disabled,
  setState,
  options,
  value,
  // condition,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ********************************
  useEffect(() => {
    if (api) {
      setLoading(true);
      axios(api, {
        headers: {
          Authorization: localStorage.token,
        },
      })
        .then((res) => {
          setLoading(false);
          let response = res.data.elements;
          let arr = [];
          response.forEach((element) => {
            arr.push({
              value: element[optionValue],
              label: element[optionTitle],
            });
          });

          setData(arr);
        })
        .catch((e) => {
          setLoading(false);
          // alert("Error !!!!!!")
        });
    }
    if (options) {
      setData(options);
    }
  }, [api, optionTitle, optionValue]);

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Select
        lang="fa"
        disabled={disabled}
        placeholder={title ? title : "انتخاب کنید"}
        className={dark ? "darkSelect" : ""}
        loading={loading}
        style={{
          width: "100%",
          height: 40,
        }}
        options={data}
        value={value}
        // {...rest}
        /* defaultValue={{
        value: 'lucy',
        label: 'Lucy (101)',
        }} */
        onChange={(value) => setState(value)}
      />
    </>
  );
}
