import { useEffect, useState } from "react";
import { useAxiosWithToken } from "../../../../../hooks";
import { Button, ComponentLoading } from "../../../../../components/common";
import api from "../../../../../api";
import Target from "./components/Target";
import ExerciseHistory from "./components/ExerciseHistory";
import ExerciseCapacity from "./components/ExerciseCapacity";
import ExerciseJob from "./components/Job";
import ToggleDescription from "./components/ToggleDescription";
import { notify } from "../../../../../helper";
import { NotifyMessage } from "../../../../../enums";
import { useSearchParams } from "react-router-dom";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const requestProgramId = searchParams.get("requestProgramId");
  // ─── States ─────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [exerciseHistory, setExerciseHistory] = useState({
    answer: false,
    description: "",
    questionType: "SPORT",
    questionId: 11,
  });
  const [exerciseCapacity, setExerciseCapacity] = useState({
    answer: true,
    description: "",
    questionType: "SPORT",
    questionId: 12,
  });
  const [exerciseTarget, setExerciseTarget] = useState({
    answer: true,
    description: "",
    questionType: "SPORT",
    questionId: 14,
  });
  const [job, setJob] = useState({
    answer: true,
    description: "",
    questionType: "SPORT",
    questionId: 13,
  });
  const [answers, setAnswers] = useState([]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.question.getExerciseQuestion)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        let arr = [];
        res.data.forEach((item, index) => {
          if (index > 3) {
            arr.push({
              answer: false,
              description: "",
              questionType: "SPORT",
              questionId: item.id,
            });
          }
        });
        setAnswers(arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const saveAnswer = () => {
    let obj = {
      answerQuestionsInputDTOS: [exerciseHistory, exerciseCapacity, exerciseTarget, job, ...answers],
      answerDiseasesInputDTOS: [],
      requestProgramId: parseInt(requestProgramId),
    };

    if (exerciseCapacity.description.length === 3 && exerciseTarget.description.length > 0 && job.description.length > 0) {
      setActionLoading(true);
      useAxiosWithToken
        .post(api.question.addAnswer, obj)
        .then((res) => {
          setActionLoading(false);
          notify.Success(NotifyMessage.SUCCESS_ACTION);
          setActiveTab(2);
        })
        .catch((err) => {
          setActionLoading(false);
        });
    } else {
      notify.Error("لطفا فیلدهای ستاره دار را پر کنید");
    }
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getList();
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[578px] relative w-[90%] mx-auto flex flex-col gap-12 justify-center mt-10  xl:mt-[20] items-center xl:h-auto">
      <h2 className="text-center text-[#3E4151] xl:text-black text-[20px] xl:text-[48px] font-bold leading-[21px] xl:leading-[74px]">تکمیل اطلاعات ورزشی</h2>
      <ComponentLoading loading={loading} />
      {/*  آیا سابقه تمرینی دارید؟ */}
      <ExerciseHistory exerciseHistory={exerciseHistory} setExerciseHistory={setExerciseHistory} title={data[0]?.persianName} />
      {/*   چند روز و چند ساعت در هفته امکان ورزش کردن دارید؟ */}
      <ExerciseCapacity title={data[1]?.persianName} exerciseCapacity={exerciseCapacity} setExerciseCapacity={setExerciseCapacity} />
      {/* هدف شما از تمرین چیست؟ */}
      <Target exerciseTarget={exerciseTarget} setExerciseTarget={setExerciseTarget} title={data[3]?.persianName} />
      {/*  شغل شما چیست؟ */}
      <ExerciseJob job={job} setJob={setJob} title={data[2]?.persianName} />
      {data.map(
        (item, index) =>
          index > 3 && (
            <section className="w-full">
              <ToggleDescription answers={answers} setAnswers={setAnswers} item={item} label="خیر" active="بلی" />
            </section>
          )
      )}
      <Button loading={actionLoading} onClick={saveAnswer} className="xl:w-[427px] w-full bg-[#5521B4] xl:text-[20px] rounded-xl -tracking-[2%] leading-[30.2px] font-bold text-white h-[50px] xl:h-[56px]">
        مرحله بعد
      </Button>
    </section>
  );
}
