import { baseFileUrl, imagesFileUrl, svgFileUrl } from "../../../../../config";
import { Link } from "react-router-dom";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ src, title, color, backGround, btnColor, details, id }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`relative  transition-all hover:shadow-lg hover:shadow-[#DDCEEE] hover:translate-y-[-10px] group ${backGround} border-[#A6A9BD4D] w-full items-center justify-between flex xl:flex-row flex-col relative border h-auto p-4  xl:h-[430px] rounded-[20px] border-solid `}>
        <section className="w-[190px] h-[236px] bg-silver rounded-[14px] overflow-hidden relative">
          <img className="absolute top-[10px] right-[10px]" src={imagesFileUrl + "icons/vip-badge.png"} alt="" />
          <img src={src} alt="" className={`xl:w-[190px]  w-full rounded-lg`} />
        </section>
        <section className="relative">
          {/*   <section className="absolute opacity-0 group-hover:opacity-100 transition-all duration-500 top-1 left-0 bg-white w-full h-full px-3">
            <section className="mt-[54px] flex justify-between items-center ">
              <section>
                <span className="text-[24px] font-semibold">2,500,000</span>
                <span className="text-[14px] font-bold mr-1">تومان</span>
              </section>
              <section className='flex items-center justify-center text-[14px] gap-2'>
                <img src={imagesFileUrl + "doctor-profile-timer.png"} alt='' />
                <span className='font-bold text-[#3E4151] block'>45 روزه</span>
              </section>
            </section>
            <Link to="/doctor-profile" className="rounded-full flex h-[30px] justify-center items-center mt-[20px] border border-purple text-purple w-full">مشاهده صفحه مربی</Link>
            <section className="flex justify-between items-center mt-[10px]">
              <button className="w-[135px] h-[30px] text-[14px] flex justify-center items-center bg-purple text-white rounded-full">خرید برنامه ورزشی</button>
              <button className="w-[135px] h-[30px] text-[14px] flex justify-center items-center bg-[#F3509D] text-white rounded-full">خرید برنامه ترکیبی</button>
            </section>
          </section> */}
          <section className="xl:w-[307px] w-full flex flex-col gap-5">
            <section className="flex justify-between items-center">
              <h3 className={`${color} leading-[34.1px]  text-[18px] font-bold`}>{title}</h3>
              <img src={baseFileUrl + "/Rate-section3.png"} alt="" className="w-[49px] " />
            </section>
            {/* <p className="text-[#3E4151] text-sm">{text}</p> */}
            <section className="p-3 pr-10 h-[200px] flex items-center">
              <ul className="list-disc ">
                {details?.map((item, index) => (
                  <li className="text-[12px] text-justify">{item}</li>
                ))}
              </ul>
            </section>
            {/*   <section className=" flex items-start gap-[6px]">
              <span className={`${textColor} ${bgColorTag}  px-3 py-2 font-bold text-sm rounded-[150px]`}>ویدیوکال</span>
              <span className={`${textColor} ${bgColorTag}  px-3 py-2 font-bold text-sm rounded-[150px]`}>چت</span>
              <span className={`${textColor} ${bgColorTag}  px-3 py-2 font-bold text-sm rounded-[150px]`}>تمرین</span>
              <span className={`${textColor} ${bgColorTag}  px-3 py-2 font-bold text-sm rounded-[150px]`}>تغذیه</span>
            </section> */}
            <section className="flex justify-between items-center">
              <section className="font-semibold text-sm text-[#191919]">
                {/*<span className="text-[24px] leading-[37.2px]">{price}</span>
                تومان */}
              </section>
              <section className="flex justify-center items-center gap-1">
                <img alt="" className="w-[17px] h-[17px]" src={svgFileUrl + "timer1.svg"} />
                <span> 3 روزه </span>
              </section>
              {/*   <img src={baseFileUrl + "/time-section3.png"} alt="" className="w-[65px] " /> */}
            </section>
          </section>
          <Link to={`/coach-profile/${id ? id : 1}`} className={`rounded-full flex h-[30px] transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-4px] justify-center items-center mt-[20px] border bg-[${btnColor}] border-${btnColor} text-white w-full`}>
            مشاهده صفحه مربی و دریافت برنامه
          </Link>
          {/* <section className="flex justify-between items-center mt-[10px]">
            <button className="w-[135px] h-[30px] text-[14px] flex justify-center items-center bg-purple text-white rounded-full">خرید برنامه ورزشی</button>
            <button className="w-[135px] h-[30px] text-[14px] flex justify-center items-center bg-[#F3509D] text-white rounded-full">خرید برنامه ترکیبی</button>
          </section> */}
        </section>
      </section>
    </>
  );
}
