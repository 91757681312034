import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-[1126px] w-[95%]  mx-auto rounded-[15px] pb-8   h-[367px] xl:mt-[140px] bg-[linear-gradient(83.31deg_,#0e54dd12_20.72%_,#0eddc41a_91.65%)]">
        <h3 className="text-center leading-[26px] text-lg xl:text-[22px] pt-11">
          انواع رژیم‌های تناسب اندام و درمانی
        </h3>
        <section className="xl:mx-[60px] w-[90%] mx-auto  grid mt-[30px] gap-3 relative grid-cols-3 xl:grid-cols-8 xl:self-start xl:gap-[18px]">
          {/*   <div className="w-11 h-11 flex justify-center items-center rounded-full bg-[#0000001F]  absolute right-[35%] -bottom-[40%] xl:top-[40%] xl:-right-[23px] active:bg-[#F1E8FC]">
            <img src={imagesFileUrl + "/svg/Icon-arrow-r.svg"} alt="" className="w-[8px] h-auto" />
          </div> */}
          <Item
            display="hidden xl:flex"
            img="Calorie-counting.svg"
            title="کالری شماری"
          />
          <Item img="23-Diet.svg" title="کاهش وزن" />
          <Item img="kettlebell.svg" title="افزایش وزن" />
          <Item img="teenager.svg" title="نوجوانان" />
          <Item img="Athletes.svg" title="ورزشکاران" />
          <Item display="hidden xl:flex" img="weight.svg" title="تثبیت وزن" />
          <Item img="pregnant.svg" title="بارداری" />
          <Item img="vegetarianism.svg" title="ویژه گیاهخواری" />
          <Item
            display="hidden xl:flex"
            img="epilepsy.svg"
            title="ویژه بیماری صرع"
          />
          <Item
            display="hidden xl:flex"
            img="kidney-disease.svg"
            title="ویژه بیمار کلیوی"
          />
          <Item
            display="hidden xl:flex"
            img="Migraine.svg"
            title="ویژه میگرن"
          />
          <Item
            display="hidden xl:flex"
            img="Liver-disorder.svg"
            title="ویژه گوارش"
          />
          <Item
            display="hidden xl:flex"
            img="Cardiovascular.svg"
            title="ویژه قلب و عروق"
          />
          <Item
            display="hidden xl:flex"
            img="blood-pressure.svg"
            title="ویژه فشار خون"
          />
          <Item
            display="hidden xl:flex"
            img="Liver-disorder.svg"
            title="ویژه اختلال کبد"
          />
          <Item display="hidden xl:flex" img="pms.svg" title="ویژه PMS" />

          {/*   <div className="w-11 h-11  flex justify-center items-center rounded-full bg-[#0000001F] absolute -bottom-[40%] xl:top-[40%] left-[35%] xl:-left-[23px] active:bg-[#F1E8FC]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto rotate-180 "
            />
          </div> */}
        </section>
      </section>
    </>
  );
}
