import { Link, useSearchParams } from "react-router-dom";
import { correctPersianDate, numberWithCommas } from "../../helper";
import { svgFileUrl } from "../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const actionType = searchParams.get("success");
  const purchaseId = searchParams.get("purchaseId");
  const amount = searchParams.get("amount");
  const date = searchParams.get("date");
  const time = searchParams.get("time");
  const requestProgramId = searchParams.get("requestProgramId");
  const requestProgramType = searchParams.get("requestProgramType");

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-screen bg-[#F6F6FB] flex  justify-center items-center ">
        <section className="w-[718px] pb-[50px] bg-white rounded-[24px] px-8">
          <img src={svgFileUrl + `${actionType === "true" ? `callbackCheck.svg` : `callbackError.svg`}`} className="m-auto mt-[32px]" alt="" />
          <span className={`block text-xl font-semibold text-center mt-5 ${actionType === "true" ? `text-[#1CAE81]` : `text-red-700`}  `}>{actionType === "true" ? "پرداخت با موفقیت انجام شد" : "خطا در پرداخت"}</span>
          <section className="border-t-[2px] border-[#b9bbc0] mt-[32px] border-dashed h-2"></section>
          <section className="mt-[24px]">
            {actionType === "true" && (
              <>
                <section className="flex justify-between items-center px-[24px] py-[18px]">
                  <span className="text-[#9295A9] text-lg font-semibold">تاریخ و ساعت</span>
                  <span className="text-[#3E4151] text-lg font-semibold">
                    {time?.substring(0, 5)} - {correctPersianDate(date)}
                  </span>
                </section>
                <section className="flex justify-between bg-[#F6F6FB] rounded-lg items-center px-[24px] py-[18px]">
                  <span className="text-[#9295A9] text-lg font-semibold">مبلغ</span>
                  <span className="text-[#3E4151] text-lg font-semibold">{numberWithCommas(Math.trunc(amount))} ریال</span>
                </section>
                <section className="flex justify-between items-center px-[24px] py-[18px]">
                  <span className="text-[#9295A9] text-lg font-semibold">کدپیگیری</span>
                  <span className="text-[#3E4151] text-lg font-semibold">{purchaseId}</span>
                </section>
              </>
            )}
            <section className="flex justify-between bg-[#F6F6FB] rounded-lg items-center px-[24px] py-[18px]">
              <span className="text-[#9295A9] text-lg font-semibold">وضعیت</span>
              <span className={`${actionType === "true" ? `text-[#1CAE81]` : `text-red-700`} text-lg font-semibold`}>{actionType === "true" ? "پرداخت شده" : "خطا در پرداخت"}</span>
            </section>
            {actionType === "true" && (
              <Link to={requestProgramType === "EXERCISE" ? `/exercise-question?requestProgramId=${requestProgramId}` : `/sickness-question?requestProgramId=${requestProgramId}`} className="h-[48px] m-auto mt-[48px] rounded-lg flex justify-center items-center w-[280px] bg-purple text-white ">
                بازگشت به وب سایت
              </Link>
            )}
            {actionType === "false" && (
              <Link to="/" className="h-[48px] m-auto mt-[48px] rounded-lg flex justify-center items-center w-[280px] bg-purple text-white ">
                بازگشت به وب سایت
              </Link>
            )}
          </section>
        </section>
        {/* <section className='w-[718px] h-[615px] bg-white rounded-[24px] px-8'>
        <Image src={Error} className='m-auto mt-[32px]' alt='' />
        <span className='block text-xl font-semibold text-center mt-5 text-[#E14856]'>پرداخت ناموفق</span>
        <section className='border-t-[2px] border-[#b9bbc0] mt-[32px] border-dashed h-2'></section>
        <p className='text-lg font-semibold text-[#909090] mt-[32px] text-justify'>
          پرداخت ناموفق بوده است؛ در صورت کسر، مبلغ تا 72 ساعت آینده به حساب شما برمی‌گردد.
        </p>
        <section className='mt-[24px]'>
          <section className='flex justify-between bg-[#F6F6FB] rounded-lg items-center px-[24px] py-[18px]'>
            <span className='text-[#9295A9] text-lg font-semibold'>افزایش اعتبار جهت</span>
            <span className='text-[#3E4151] text-lg font-semibold'>استعلام خلافی با جزئیات سواری</span>
          </section>

          <section className='flex justify-between items-center px-[24px] py-[18px]'>
            <span className='text-[#9295A9] text-lg font-semibold'>کدپیگیری</span>
            <span className='text-[#3E4151] text-lg font-semibold'>71521354654</span>
          </section>
          <section className='flex justify-between bg-[#F6F6FB] rounded-lg items-center px-[24px] py-[18px]'>
            <span className='text-[#9295A9] text-lg font-semibold'>وضعیت</span>
            <span className='text-[#E14856] text-lg font-semibold'>پرداخت نشده</span>
          </section>
          <Link href='' className='bg-blue block w-[280px] text-white rounded-lg m-auto mt-[48px] px-[68.5px] py-[10px]'>
            بازگشت به وب سایت
          </Link>
        </section>
      </section> */}
      </section>
    </>
  );
}
