import { useState } from "react";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ index, item, finalPackageList, setFinalPackageList, packageId }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [value, setValue] = useState("");
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const handleChange = (value) => {
    setValue(value);
    let arr = [...finalPackageList];
    arr.forEach((element, index) => {
      if (element?.packageId === packageId) {
        element?.dairyListWithConsumedInputDTOS?.forEach((element1, index1) => {
          if (element1?.dairyId === item?.dairyId) {
            arr[index].dairyListWithConsumedInputDTOS[index1].consumedGram = value;
          }
        });
      }
    });

    setFinalPackageList(arr);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid xl:grid-cols-5 xl:gap-12 gap-[10px] mt-[25px] items-center  border-b py-2 border-slate-200">
      <section className="flex w-full justify-between col-span-4">
        <span className="block w-[10%] ">{index + 1}</span>
        <span className="block text-purple w-[70%] font-semibold">{item?.mainFoodDescription}</span>
        <span className="block w-[20%] xl:text-start text-end">
          {item?.energyKcal}کالری <span className="text-[10px] text-purple">(به ازای صد گرم)</span>
        </span>
      </section>
      <section className="flex justify-between items-center gap-1">
        <input
          type="text"
          className="w-full rounded-md border border-slate-300"
          value={value}
          onChange={(e) => {
            let value = e.target.value.replace(/\D/g, "");
            handleChange(value);
          }}
        />
        <span>گرم</span>
      </section>
    </section>
  );
}
