//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.46311 9.38889H6.79644C7.03219 9.38889 7.25828 9.29524 7.42498 9.12854C7.59168 8.96184 7.68533 8.73575 7.68533 8.5V1.38889C7.68533 1.15314 7.59168 0.927048 7.42498 0.760349C7.25828 0.59365 7.03219 0.5 6.79644 0.5H1.46311C1.22736 0.5 1.00127 0.59365 0.834568 0.760349C0.667869 0.927048 0.574219 1.15314 0.574219 1.38889V8.5C0.574219 8.73575 0.667869 8.96184 0.834568 9.12854C1.00127 9.29524 1.22736 9.38889 1.46311 9.38889ZM0.574219 15.6111C0.574219 15.8469 0.667869 16.073 0.834568 16.2397C1.00127 16.4064 1.22736 16.5 1.46311 16.5H6.79644C7.03219 16.5 7.25828 16.4064 7.42498 16.2397C7.59168 16.073 7.68533 15.8469 7.68533 15.6111V12.0556C7.68533 11.8198 7.59168 11.5937 7.42498 11.427C7.25828 11.2603 7.03219 11.1667 6.79644 11.1667H1.46311C1.22736 11.1667 1.00127 11.2603 0.834568 11.427C0.667869 11.5937 0.574219 11.8198 0.574219 12.0556V15.6111ZM9.46311 15.6111C9.46311 15.8469 9.55676 16.073 9.72346 16.2397C9.89016 16.4064 10.1162 16.5 10.352 16.5H15.6853C15.9211 16.5 16.1472 16.4064 16.3139 16.2397C16.4806 16.073 16.5742 15.8469 16.5742 15.6111V9.38889C16.5742 9.15314 16.4806 8.92705 16.3139 8.76035C16.1472 8.59365 15.9211 8.5 15.6853 8.5H10.352C10.1162 8.5 9.89016 8.59365 9.72346 8.76035C9.55676 8.92705 9.46311 9.15314 9.46311 9.38889V15.6111ZM10.352 6.72222H15.6853C15.9211 6.72222 16.1472 6.62857 16.3139 6.46187C16.4806 6.29517 16.5742 6.06908 16.5742 5.83333V1.38889C16.5742 1.15314 16.4806 0.927048 16.3139 0.760349C16.1472 0.59365 15.9211 0.5 15.6853 0.5H10.352C10.1162 0.5 9.89016 0.59365 9.72346 0.760349C9.55676 0.927048 9.46311 1.15314 9.46311 1.38889V5.83333C9.46311 6.06908 9.55676 6.29517 9.72346 6.46187C9.89016 6.62857 10.1162 6.72222 10.352 6.72222Z"
        fill={fill}
      />
    </svg>
  );
}
