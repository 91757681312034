import { Fragment } from "react";
import { imagesFileUrl, svgFileUrl } from "../../../../config";
import MenuItem from "./components/MenuItem";
import Profile from "./svg/Profile";
import Target from "./svg/Target";
import Restaurant from "./svg/Restaurant";
import Exit from "./svg/Exit";
import { Link, useLocation } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const location = useLocation();
  const menuItems = [
    {
      link: "/doctor-dashboard/food-package",
      active: location.pathname === "/doctor-dashboard/food-package",
      title: "پکیج ها",
      className: "menuItem",
      icon: Restaurant,
    },
    {
      link: "/doctor-dashboard/without-plan-user",
      active: location.pathname === "/doctor-dashboard/without-plan-user",
      title: "افراد بدون برنامه",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/",
      title: "بازگشت به سایت",
      className: "menuItem",
      icon: Target,
    },
    {
      title: "خروج",
      mode: "logOut",
      className: "menuItem",
      icon: Exit,
    },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="fixed left-0 bottom-0 w-full h-[64px]  bg-white  shadow-[0px_-1px_9px_0px_#5408A926] rounded-t-[20px] z-[1000] px-[10%] flex justify-between xl:hidden ">
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img
            src={svgFileUrl + "mobileMenuPeople.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          پروفایل
        </span>
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img
            src={svgFileUrl + "mobileMenuTarget.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          هدف
        </span>
        <span className="flex w-[56px] h-[56px] cursor-pointer  bg-[#5408A9] mt-[-18px] rounded-full shadow-[0px_14px_23px_-6px_#5408A980] justify-center items-center">
          <img
            src={svgFileUrl + "mobileMenu.svg"}
            alt=""
            className="w-[20px] h-[20px]"
          />
        </span>
        <span className="flex flex-col justify-center cursor-pointer  items-center">
          <img
            src={svgFileUrl + "mobileMenuSport.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          ورزش
        </span>
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img
            src={svgFileUrl + "mobileMenuKnife.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          آشپزی
        </span>
      </section>
      <aside className="w-[199px] hidden xl:block">
        <Link to="/">
          <header className="flex w-[160px] h-[36px] justify-between items-center pr-7">
            <section className="w-[60px] h-[60px] p-1 bg-white rounded-[6px] ml-1">
              <img src={imagesFileUrl + "logoWhite.png"} alt="" />
            </section>
            <span className="font-bold text-[15px] mr-1">Bodyness</span>
          </header>
        </Link>
        <nav className="mt-10">
          {menuItems.map((item, index) => (
            <Fragment key={index}>
              <MenuItem
                title={item.title}
                link={item.link}
                active={item.active}
                Icon={item.icon}
                mode={item.mode}
              />
            </Fragment>
          ))}
        </nav>
      </aside>
    </>
  );
}
