import { useEffect, useState } from "react";
import Header from "./components/Header";
import UserInfo from "./components/UserInfo";
import SpecialSickness from "./components/SpecialSickness";
import SpecialSicknessDtl from "./components/SpecialSicknessDtl";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { ComponentLoading } from "../../../../../components/common";
import { useSearchParams } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  /*   const [searchParams] = useSearchParams(); */
  /*   const mobileNumber = searchParams.get("mobileNumber");
  const requestId = searchParams.get("userId"); */
  // ─── States ─────────────────────────────────────────────────────────────────────
  /*  const [data, setData] = useState([]); */
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  /*  const getDataInfo = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.userInformation.getAdditionalInformation + `?mobileNumber=${mobileNumber}&requestProgramId=${requestId}`)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }; */
  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {
    /* getDataInfo(); */
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="min-h-[400px] h-auto mt-[40px] relative py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
      <ComponentLoading loading={loading} />
      <Header data={data} />
      <UserInfo data={data?.userOutputDTO} />
      <SpecialSickness data={data} />
      <SpecialSicknessDtl data={data} />
    </section>
  );
}
