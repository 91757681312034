import Step from "../../../assets/images/basicInfo/step78910L.png";
import Progress from "../components/Progress";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  setStep,
  activeStep,
  setLastDiet,
  defaultValue,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const setMainLastDiet = (value) => {
    setLastDiet(value);
    setStep(9);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <span className="block text-center font-bold text-xl xl:text-3xl mt-7">
        آخرین رژیمتان چه تاریخی بود؟
      </span>
      <section className="mt-10 w-[90%] xl:w-[500px] m-auto">
        <button
          className="bg-[#f4f4f4] rounded-[16px] h-[70px] w-full mt-2"
          onClick={() => setMainLastDiet("SEVERAL_MONTHS_AGO")}
        >
          چند ماه گذشته
        </button>
        <button
          className="bg-[#f4f4f4] rounded-[16px] h-[70px] w-full mt-2"
          onClick={() => setMainLastDiet("ONE_TO_TWO_YEARS")}
        >
          یک تا دو سال پیش
        </button>
        <button
          className="bg-[#f4f4f4] rounded-[16px] h-[70px] w-full mt-2"
          onClick={() => setMainLastDiet("MORE_THAN_THREE_YEARS")}
        >
          بیش از سه سال پیش
        </button>
      </section>
    </>
  );
}
