import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full bg-gradient-to-br to-[#85119d] from-[#4c7ed6] flex flex-col items-center text-white relative   overflow-hidden mb-[50px] xl:mb-[150px] mx-auto h-auto xl:h-[968px]">
      <img className="absolute top-0 w-full z-0 opacity-45" src={imagesFileUrl + "my-coaches-1.png"} alt="" />
      <img className="absolute bottom-0 w-full z-0 opacity-50" src={imagesFileUrl + "my-coaches-2.png"} alt="" />
      <section className="z-10">
        <h3 className="xl:leading-[93px] leading-[60px] mt-[30px] xl:mt-[83px] text-center text-[22px] xl:text-[60px] font-bold">مربیان تیم ما</h3>
        <p className="leading-[24.8px] w-[90%] mx-auto xl:w-full xl:text-base text-[12px] text-center  xl:mt-10 font-semibold ">مربیان تیم ما با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.</p>
      </section>

      <section className="grid grid-cols-1 xl:grid-cols-3 gap-10 py-[30px] px-[60px] items-center z-10">
        <section className="flex flex-col items-center">
          {/* <img src={imagesFileUrl + "coach/tarverdi1.png"} alt="" /> */}
          <section className="w-[200px] rounded-full overflow-hidden h-auto p-4 border-[4px] border-silver">
            {" "}
            <img src={imagesFileUrl + "coach/tarverdi-p.png"} alt="" className="w-full rounded-full h-auto" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">خانم تارویردی</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
        <section className="flex flex-col items-center">
          {/* <img src={imagesFileUrl + "coach/hosseini1.png"} alt="" /> */}
          <section className="w-[200px] rounded-full overflow-hidden h-auto p-4 border-[4px] border-silver">
            {" "}
            <img src={imagesFileUrl + "coach/nazari-p.jpg"} alt="" className="w-full rounded-full h-auto" />
          </section>

          <span className="text-[20px] font-bold text-white mt-[17px]">خانم نظری</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
        {/* <section className="flex flex-col items-center">
        
          <section className="w-[200px] rounded-full overflow-hidden h-auto p-4 border-[4px] border-silver">
            {" "}
            <img
              src={imagesFileUrl + "coach/ganji-p.png"}
              alt=""
              className="w-full rounded-full h-auto"
            />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">
            خانم گنجی
          </span>
          <span className="text-[15px] font-normal text-white">
            مربی بدنسازی
          </span>
        </section> */}
        <section className="flex flex-col items-center">
          {/* <img src={imagesFileUrl + "coach/tarverdi1.png"} alt="" /> */}
          <section className="w-[200px] rounded-full overflow-hidden h-auto p-4 border-[4px] border-silver">
            {" "}
            <img src={imagesFileUrl + "coach/karimi-p.png"} alt="" className="w-full rounded-full h-auto" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">آقای کریمی زند</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
        <section className="flex flex-col items-center">
          {/* <img src={imagesFileUrl + "coach/tarverdi1.png"} alt="" /> */}
          <section className="w-[200px] rounded-full overflow-hidden h-auto p-4 border-[4px] border-silver">
            {" "}
            <img src={imagesFileUrl + "coach/mohsenzadeh-p.png"} alt="" className="w-full rounded-full h-auto" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">آقای محسن زاده</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>{" "}
        <section className="flex flex-col items-center">
          {/* <img src={imagesFileUrl + "coach/tarverdi1.png"} alt="" /> */}
          <section className="w-[200px] rounded-full overflow-hidden h-auto p-4 border-[4px] border-silver">
            {" "}
            <img src={imagesFileUrl + "coach/hosseini-p.png"} alt="" className="w-full rounded-full h-auto" />
          </section>
          <span className="text-[20px] font-bold text-white mt-[17px]">خانم حسینی</span>
          <span className="text-[15px] font-normal text-white">مربی بدنسازی</span>
        </section>
      </section>
      {/* <div className="w-8 h-8 flex top-[80%] right-[40%] xl:top-[60%] xl:right-[10%] absolute justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
        <img
          src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
          alt=""
          className="w-[8px] h-auto"
        />
      </div>
      <div className="w-8 h-8 flex absolute top-[80%] xl:top-[60%] left-[40%]  xl:left-[10%]  justify-center items-center rounded-full bg-[#0000001F] active:bg-[#BB9CDD]">
        <img
          src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
          alt=""
          className="w-[8px] h-auto rotate-180"
        />
      </div> */}
      {/*   <img
        src={baseFileUrl + "/section3-profile-exer.png"}
        alt=""
        className="w-full xl:w-[1078.74px] mb-[80px] xl:mb-0 xl:mt-100 h-auto xl:h-[433px]"
      /> */}
    </section>
  );
}
