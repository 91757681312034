import { useState } from "react";

import InnerTabs from "../BmiContent/components/InnerTabs";
import InnerContent from "../BmiContent/components/InnerContent";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeBtn, setActiveBtn] = useState(1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-auto xl:h-[1991px] pb-[20px] xl:pb-[45px] px-[8px] xl:px-[40px] mt-[30px] xl:mt-[40px] mx-auto rounded-[10px] bg-[#F3E7F5] ">
        <InnerTabs activeBtn={activeBtn} setActiveBtn={setActiveBtn} />
        <InnerContent activeBtn={activeBtn} />
      </section>
    </>
  );
}
