import React from "react";
import { imagesFileUrl } from "../../../../../config";

import CatItem from "./components/CatItem";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

// const SamplePrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "flex",
//         justifyContent: "center",
//         marginTop: "-8px",
//         alignItems: "center",
//         width: 32,
//         height: 32,
//         borderRadius: 100,
//         background: " #0000001F",
//         color: "red",
//       }}
//       onClick={onClick}
//     />
//   );
// };

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // var settings = {
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 5,
  //   initialSlide: 0,
  //   adaptiveHeight: false,
  //   nextArrow: <SamplePrevArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 1920,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 5,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         initialSlide: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className='w-full h-[333px] pt-[48px] m-auto bg-[url("/public/images/excategorybgdash.png")] bg-contain bg-no-repeat max-w-full mt-[18px]'>
      <section className="w-[full] h-[225px]  m-auto">
        <span className="font-bold text-[22px] m-auto text-white block text-center">
          دسته‌بندی تمرینات
        </span>
        <section className="w-full flex justify-around items-center mt-[38px]">
          <CatItem
            title="Recover Well"
            img={imagesFileUrl + "exercise/ex1.png"}
          />
          <CatItem
            title="Yoga for Everyone"
            img={imagesFileUrl + "exercise/ex2.png"}
          />
          <CatItem
            title="HIIT Cardio Blasts"
            img={imagesFileUrl + "exercise/ex3.png"}
          />
          <CatItem
            title="Stretch & Strengthen"
            img={imagesFileUrl + "exercise/ex4.png"}
          />
          <CatItem
            title="Full-Body Burn"
            img={imagesFileUrl + "exercise/ex5.png"}
          />
          {/* <CatItem title="Full-Body Kettlebell" img={imagesFileUrl + "exercise/ex6.png"} />
          <CatItem title="Every Day Stretch" img={imagesFileUrl + "exercise/ex7.png"} />
          <CatItem title="Dumbbell Only Routines" img={imagesFileUrl + "exercise/ex8.png"} /> */}
        </section>
        {/*   <section className="w-[776px] h-[235px] mt-[62px] bg-white rounded-3xl m-auto shadow-[0px_11px_24px_0px_#6B9DCB5C] p-[1px]">
          <span className="text-lg font-bold block text-center mt-[24px]">انتخاب مربی</span>
          <section className="w-[600px] h-[200px] m-auto">
            <Slider {...settings}>
              <SlickItem img={imagesFileUrl + "coach/ch1.png"} title="مربی شماره 1" />
              <SlickItem img={imagesFileUrl + "coach/ch2.png"} title="مربی شماره 2" />
              <SlickItem img={imagesFileUrl + "coach/ch3.png"} title="مربی شماره 3" />
              <SlickItem img={imagesFileUrl + "coach/ch4.png"} title="مربی شماره 4" />
              <SlickItem img={imagesFileUrl + "coach/ch5.png"} title="مربی شماره 5" />
              <SlickItem img={imagesFileUrl + "coach/ch1.png"} title="مربی شماره 6" />
            </Slider>
          </section>
        </section> */}
      </section>
    </section>
  );
}
