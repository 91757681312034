import { useState, useEffect } from "react";
import { baseFileUrl } from "../../../../config";
import FoodBox from "./components/FoodBox";
import { Link } from "react-router-dom";
import { useAxios } from "../../../../hooks";
import api from "../../../../api";
import Loading from "../../../../components/common/componentLoading";
import { Button } from "../../../../components/common";
//
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [recipeList, setRecipeList] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getRecipe = () => {
    setLoading(true);
    useAxios
      .get(`${api.recipe.searchRecipe}?language=PERSIAN&pageSize=4&pageNo=0`)
      .then((res) => {
        setLoading(false);
        setRecipeList(res.data.elements);
        /*  setTotalElements(res.data.totalElements); */
      })
      .catch((e) => {
        setLoading(false);
        /* hideLoading(); */
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getRecipe();
  }, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:hidden block relative h-[831px] mt-[30px] xl:mt-[100px] rounded-[50px]">
        <Loading loading={loading} />
        <img
          src={baseFileUrl + "/images/section5-bg-mobile.png"}
          className="h-full w-full"
          alt=""
        />
        <section className="w-full h-full absolute px-[18px] flex flex-col justify-center items-center mt-10 top-0 left-0 rounded-[50px]">
          <h3 className="text-white text-center leading-[46px] mb-8  text-[30px] font-semibold">
            کلی پیشنهاد غذایی اینجاست
          </h3>
          <p className="text-white leading-[26px] tracking-tighter text-center font-normal text-[16px]">
            انواع صبحانه ، نهار، شام ، وعده های قبل و بعد از تمرین به همراه
            میزان کالری و درشت مغذی هاش
          </p>
          <Link to="/recipe">
            <Button
              light={true}
              className=" w-[138px] h-[44px] font-semibold rounded-full  mt-8"
            >
              بزن بریم
            </Button>
          </Link>
          <section className="w-full mt-10 overflow-x-scroll rtl">
            <section className="flex h-[360px] justify-center w-[810px] gap-4  pr-[100px]">
              {recipeList.map((item, index) => (
                <section className="mt-[80px]">
                  <FoodBox
                    key={index}
                    id={item.id}
                    calories={item.calories}
                    carbohydrate={item.carbohydrate}
                    protein={item.protein}
                    fat={item.fat}
                    duration={item.duration}
                    title={item.foodName}
                    image={item.photoUrl}
                  />
                </section>
              ))}
            </section>
          </section>
        </section>
      </section>
      <section className="hidden w-[95%] mx-auto  xl:block relative h-[678px] mt-[100px] rounded-[50px]">
        <Loading loading={loading} />
        <img
          src={baseFileUrl + "/images/section6.png"}
          className="w-full"
          alt=""
        />
        <section className="w-full h-full absolute flex flex-col justify-center items-center top-[-30px] left-0 rounded-[50px]">
          <h3 className="text-white text-[40px] font-semibold">
            کلی پیشنهاد غذایی اینجاست
          </h3>
          <p className="text-white text-[22px]">
            انواع صبحانه ، نهار، شام ، وعده های قبل و بعد از تمرین به همراه
            میزان کالری و درشت مغذی هاش
          </p>
          <Link to="/recipe">
            <Button
              light={true}
              className=" w-[250px] transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px] h-[60px] text-xl  font-bold rounded-full  mt-10"
            >
              بزن بریم
            </Button>
          </Link>

          <section className="mt-24 grid grid-cols-4  justify-between gap-6 w-[958px]">
            {recipeList.map((item, index) => (
              <FoodBox
                key={index}
                id={item.id}
                calories={item.calories}
                carbohydrate={item.carbohydrate}
                protein={item.protein}
                fat={item.fat}
                duration={item.duration}
                title={item.foodName}
                image={item.photoUrl}
              />
            ))}
          </section>
        </section>
      </section>
    </>
  );
}
