import { useState, useEffect } from "react";
import { imagesFileUrl } from "../../../config";

import BodyItem from "./components/BodyItem";
import BasicInfoItem from "./components/BasicInfoItem";
import TargetCalories from "./components/TargetCalories";
import WeightSelector from "./components/WeightSelector";
import BMI from "./components/Bmi";
import { useBasicInfoStore } from "../../../store/dashboard/basicInfo";
import Loading from "../../../components/common/componentLoading";

import api from "../../../api";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useAxiosWithToken } from "../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const basicInfoLoading = useBasicInfoStore((state) => state.basicInfoLoading);
  const reloadBasicInfo = useBasicInfoStore((state) => state.reloadBasicInfo);

  const { showLoading, hideLoading } = useGlobalContext();
  // ─── States ─────────────────────────────────────────────────────────────────────

  const [age, setAge] = useState();
  const [height, setHeight] = useState();
  const [goalWeight, setGoalWeight] = useState();
  const [weight, setWeight] = useState(basicInfo?.kgWeight);
  const [gender, setGender] = useState();
  const [activityLevel, setActivityLevel] = useState();
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setAge(basicInfo?.age);
    setHeight(basicInfo?.cmHeight);
    setGoalWeight(basicInfo?.goalKgWeight);
    setWeight(basicInfo?.kgWeight);
    setGender(basicInfo?.gender);
    setActivityLevel(basicInfo?.activityEnum);
  }, [basicInfo]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  // const generateActivity = (value) => {
  //   switch (value) {
  //     case 1:
  //       return "NONE";
  //     case 2:
  //       return "VERY_LOW";
  //     case 3:
  //       return "LOW";
  //     case 4:
  //       return "MEDIUM";
  //     case 5:
  //       return "ACTIVE";
  //     case 6:
  //       return "VERY_ACTIVE";
  //     default:
  //     // code block
  //   }
  // };
  const editInfo = () => {
    showLoading();
    useAxiosWithToken
      .put(api.user.editUserInfo + localStorage.userId, {
        id: localStorage.userId,
        firstName: basicInfo?.firstName,
        lastName: basicInfo?.lastName,
        mobileNumber: "0" + basicInfo?.mobileNumber,
        kgWeight: weight,
        cmHeight: height,
        gender: gender,
        bodyFatLevel: basicInfo?.bodyFatLevel,
        goal: basicInfo?.goal,
        goalKgWeight: goalWeight,
        birthDate: "1990-02-22",
        activityEnum: activityLevel,
        dietBeforeEnum: basicInfo?.dietBeforeEnum,
        lossPerWeekEnum: "GRAM_500",
        typeBodybuildingEnum: "CUT",
        duration: 12,
        profileImageUrl: basicInfo?.profileImageUrl,
      })
      .then((res) => {
        hideLoading();
        reloadBasicInfo();
      })
      .catch((e) => {
        hideLoading();
      });
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[90%] relative mx-auto xl:w-[784px] h-auto rounded-[18px]  xl:px-[68px] bg-[#FFFFFF] border-[0.81px] border-solid border-[#FFFFFF] box-shadow-[0px_4px_9px_0px_#0000000D]">
      <Loading loading={basicInfoLoading} />
      <section className="w-full mx-auto flex items-center justify-between pt-8  mb-[48px] px-2 xl:px-0">
        <span className="text-base xl:text-lg text-[#3E4151] font-bold ">اطلاعات اولیه</span>
        <button onClick={editInfo} className="rounded-[5px] border border-solid border-[#5408A9] leading-[26px] text-[#5408A9]  w-[107px] h-[40px] flex justify-center items-center gap-[6.5px]">
          <img src={imagesFileUrl + "svg/VectorTICK.svg"} className="w-[11px] h-[8px]" alt="" />
          ذخیره
        </button>
      </section>
      <section className="h-auto w-[100%] px-2 xl:px-0 xl:w-full mx-auto xl:h-[200px] mb-[40px] xl:mb-[60px] gap-[10px] grid grid-cols-6 xl:flex xl:gap-7">
        <BodyItem
          editMode={true}
          RenderInput={
            <select value={gender} onChange={(e) => setGender(e.target.value)} className="xl:w-[95px] text-xs  w-[90%] m-auto h-[38px] xl:h-[40px] xl:px-2 border border-[#A6A9BD] rounded-full">
              <option value="MALE">مرد</option>
              <option value="FEMALE">زن</option>
            </select>
          }
          textColor="#191919"
          title="جنسیت"
          value="مرد"
          color="#A6A9BD"
          imgUrl={imagesFileUrl + "svg/male-and-female.svg"}
        />
        <BodyItem
          editMode={true}
          RenderInput={
            <input
              type="text"
              value={age}
              className=" xl:w-[95px] text-xs   text-center w-[90%] m-auto h-[38px] xl:h-[40px] xl:px-2 border border-[#A6A9BD] rounded-full"
              maxLength={3}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, "");
                setAge(value);
              }}
            />
          }
          textColor="#0097C1"
          title="سن"
          value="27"
          color="#4cc9ec"
          imgUrl={imagesFileUrl + "svg/Layer 93.svg"}
        />
        <BodyItem
          editMode={true}
          RenderInput={
            <input
              type="text"
              value={weight}
              maxLength={3}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, "");
                setWeight(value);
              }}
              className=" w-[90%] xl:w-[95px] text-xs   text-center m-auto h-[38px] xl:h-[40px] xl:px-2 border border-[#A6A9BD] rounded-full"
            />
          }
          textColor="#FA6900"
          title="وزن فعلی"
          value="80"
          color="#fb8733"
          imgUrl={imagesFileUrl + "svg/Group.svg"}
        />
        <BodyItem
          editMode={true}
          RenderInput={
            <input
              type="text"
              value={height}
              maxLength={3}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, "");
                setHeight(value);
              }}
              className="xl:w-[95px] w-[90%] text-xs  m-auto h-[38px] xl:h-[40px] text-center xl:px-2 border border-[#A6A9BD] rounded-full"
            />
          }
          textColor="#5408A9"
          title="قد"
          value="180"
          color="#7639ba"
          imgUrl={imagesFileUrl + "svg/height.svg"}
        />
        <BodyItem
          editMode={true}
          RenderInput={
            <select value={activityLevel} onChange={(e) => setActivityLevel(e.target.value)} className="xl:w-[95px] text-xs  w-[90%] m-auto h-[38px] xl:h-[40px] xl:px-2 border border-[#A6A9BD] rounded-full">
              <option value="NONE">بدون تحرک</option>
              <option value="VERY_LOW">خیلی کم</option>
              <option value="LOW">کم</option>
              <option value="MEDIUM">متوسط</option>
              <option value="ACTIVE">زیاد</option>
              <option value="VERY_ACTIVE">حرفه ای</option>
            </select>
          }
          textColor="#F02484"
          title="سطح فعالیت"
          value="کم"
          color="#f02484"
          imgUrl={imagesFileUrl + "svg/sitting.svg"}
        />
      </section>
      <h2 className="mb-[38px] w-[90%] mx-auto font-bold text-lg text-[#3E4151] ">تعیین وزن هدف</h2>
      <section className="w-[90%] xl:w-full mx-auto h-auto xl:h-[343px] flex xl:flex-row flex-col gap-[70px] mb-[45px]">
        <WeightSelector state={goalWeight} setState={setGoalWeight} />
        <section className="w-[90%] mx-auto xl:w-[254px] flex flex-row xl:flex-col  h-auto justify-center gap-[16px]  xl:gap-[34px] rounded-[18px] ">
          <BasicInfoItem src={imagesFileUrl + "Groupweight.png"} bg1="#FFF0E6" bg2="#FEE1CC" border="#FCA566" title1="وزن هدف" title2={`${goalWeight} کیلوگرم`} color="#FCA566" />
          <BasicInfoItem src={imagesFileUrl + "target 2.png"} bg1="#F1E8FC" bg2="#ddceee83" border="#5408A9" title1="نوع رژیم" title2={goalWeight > weight ? "افزایش وزن" : goalWeight < weight ? "کاهش وزن" : goalWeight === weight ? "تثبیت وزن" : ""} color="#5408A9" />
          {/*  <BasicInfoItem
            src={imagesFileUrl + "Layer 931.png"}
            bg1="#21b24a11"
            bg2="#21B2491A"
            border="#21B249"
            title1="مدت دوره"
            title2="۲۸ روز"
            color="#21B249"
          /> */}
        </section>
      </section>
      <TargetCalories basicInfo={basicInfo?.currentMonthInfoList} />
      <BMI bmi={Math.floor(basicInfo?.bmi)} />
    </section>
  );
}
