import { useState, useEffect } from "react";
import { imagesFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeSection, setActiveSection] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log(activeSection);
  }, [activeSection]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        className="xl:h-[416px] h-auto px-[6px] pb-[3px] pt-[17px] rounded-[60px] w-full mt-[106px] mb-[167px] rotate-180 bg-[linear-gradient(82.8deg,_#f0248480_1.56%,_#f0248480_48.34%,_#4cc9ec80_99.14%)]
"
      >
        <section className="w-full xl:pb-0 xl:pt-0 pb-[60px] pt-[80px] flex items-center justify-center rounded-[60px] rotate-180 bg-white h-full">
          <section className="xl:w-full w-[90%] mx-auto flex xl:flex-row flex-col i xl:justify-between xl:pt-[84px] xl:px-[94px] relative h-auto  xl:h-[384px]">
            <section className="xl:w-[327px]  w-full h-auto xl:h-[182px] ">
              <h3 className="text-[#191919] text-[28px] xl:text-[32px] xl:leading-[49.6px] font-bold">
                خدمات ویژه
              </h3>
              <p className="text-[#1B185B] xl:leading-[26.82px] text-[22px] mt-[24px]">
                در اینجا می توانید خدمات ویژه ما را بیابید.
              </p>
              <section className="flex gap-6 mt-[36px]">
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#0000001F] active:bg-[#F67CB5]">
                  <img
                    src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
                    alt=""
                    className="w-[8px] h-auto"
                  />
                </div>
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#0000001F] active:bg-[#F67CB5]">
                  <img
                    src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
                    alt=""
                    className="w-[8px] h-auto rotate-180"
                  />
                </div>
              </section>
            </section>
            <section className="grid h-auto justify-center  xl:h-[260px] xl:mt-0 mt-10 w-full xl:w-[506px] xl:grid-cols-2 gap-x-[34px] gap-y-6">
              <section
                onClick={() => {
                  setActiveSection(1);
                }}
              >
                <Item
                  activeSection={activeSection}
                  index={1}
                  src="/wIcon.svg"
                  title="کالری شمار "
                />
              </section>

              <section
                onClick={() => {
                  setActiveSection(3);
                }}
              >
                <Item
                  title="تغذیه"
                  activeSection={activeSection}
                  index={3}
                  src="/diet.svg"
                />
              </section>
              <section
                onClick={() => {
                  setActiveSection(2);
                }}
              >
                <Item
                  title="چربی سوزی"
                  activeSection={activeSection}
                  index={2}
                  src="/dumbbell.svg"
                />
              </section>

              <section
                onClick={() => {
                  setActiveSection(4);
                }}
              >
                <Item
                  title="پیلاتس"
                  activeSection={activeSection}
                  index={4}
                  src="/pilates-ball.svg"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
    </>
  );
}
