import Header from "./components/Header";
import Description from "./components/Description";
import Video from "./components/Video";
import Rating from "./components/Rating";
import Expertise from "./components/Expertise";
import Activity from "./components/Activity";
import Document from "./components/Document";
import FoodPackage from "./components/FoodPackage";
import Gallery from "./components/Gallery";
import { imagesFileUrl } from "../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[1130px] max-w-full min-h-[900px]  pt-[20px] mb-[40px] px-[32px] xl:m-auto xl:px-0">
      <Header />
      <section className="grid grid-cols-1 xl:grid-cols-3 gap-[64px] mt-[200px]">
        <section className="xl:col-span-2">
          <Description />
          <Video />
          <Gallery />
          <FoodPackage />
        </section>
        <section>
          <Rating />
          <Expertise />
          <Activity />
          <Document />
          <section className="mt-[32px]">
            <img src={imagesFileUrl + "doctor-profile-package.png"} alt="" />
          </section>
        </section>
      </section>
    </section>
  );
}
