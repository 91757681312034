//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ state, setState, type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section>
        <button
          onClick={() => setState(2)}
          className={`w-full xl:w-[200px] h-[30px]  transition-all font-normal text-sm border border-purple  ${
            state === 2 ? "bg-purple text-white" : "text-purple bg-white"
          } rounded-full`}
        >
          {type === "BREAKFAST"
            ? "افزودن صبحانه"
            : type === "SNACK_BEFORE_LUNCH"
            ? "افزودن میان وعده صبح"
            : type === "LUNCH"
            ? "افزودن ناهار"
            : type === "SNACK_BEFORE_DINNER"
            ? "افزودن میان وعده عصر"
            : type === "DINNER"
            ? "افزودن شام"
            : ""}
        </button>
        <button
          onClick={() => setState(1)}
          className={`w-full xl:w-[200px] h-[30px] mt-2 xl:mt-0 xl:mr-[20px] border transition-all border-purple font-normal text-sm ${
            state === 1 ? "bg-purple text-white" : "text-purple bg-white"
          } rounded-full`}
        >
          انتخاب از برنامه پزشک
        </button>
        <button
          onClick={() => setState(3)}
          className={`w-full xl:w-[180px] h-[30px] mt-2 xl:mt-0 xl:mr-[20px] border transition-all border-purple font-normal text-sm ${
            state === 3 ? "bg-purple text-white" : "text-purple bg-white"
          } rounded-full`}
        >
          لیست غذاها
        </button>
      </section>
    </>
  );
}
