import { useState, useEffect } from "react";
import NumberPicker from "../../../../components/common/NumberPicker";
import { calcBMI } from "../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  defaultValue,
  defaultGoalValue,
  setWeight,
  setGoalWeight,
  weight,
  goalWeight,
  height,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  const [bmi, setBmi] = useState(0);
  const [goalBmi, setGoalBmi] = useState(0);
  const [targetInfo, setTargetInfo] = useState("");
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (weight > goalWeight) {
      setTargetInfo("هدف شما کاهش وزن است");
    } else if (weight < goalWeight) {
      setTargetInfo("هدف شما افزایش وزن است");
    } else if (weight === goalWeight) {
      setTargetInfo("هدف شما تثبیت وزن است");
    }
  }, [weight, goalWeight]);
  useEffect(() => {
    setBmi(calcBMI(height, weight));
    setGoalBmi(calcBMI(height, goalWeight));
  }, [weight, height, goalWeight]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <span className="block text-center mt-4">وزن فعلی شما</span>
      <NumberPicker type="weight" setValue={setWeight} value={weight} />
      <section className="text-center mt-2 font-bold text-lg">
        <span>BMI شما:</span>
        <span className="text-purple ">{bmi}</span>
      </section>
      <span className="block text-center mt-4">وزن هدف شما</span>
      <NumberPicker type="weight" setValue={setGoalWeight} value={goalWeight} />
      <section className="text-center mt-2 font-bold text-lg">
        <span>BMI هدف شما:</span>
        <span className="text-purple ">{goalBmi}</span>
      </section>
      <section
        className={`flex justify-center mt-10 mb-2 text-lg  ${
          weight > goalWeight
            ? "text-green-500"
            : weight === goalWeight
            ? "text-purple"
            : "text-blue-500"
        } font-bold`}
      >
        {targetInfo}
      </section>
    </>
  );
}
