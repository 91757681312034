import { useState, useEffect } from "react";
import { baseFileUrl, svgFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const teamList = [
    {
      name: "عرفان نوخیز",
      role: "مدیر تیم",
      picture: "/images/erfan-nokhiz-1.jpg",
      description:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    },
    {
      name: "علی مشیری",
      role: "برنامه نویس ارشد backend",
      picture: "/images/alimoshiri.jpg",
      description:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    },
    {
      name: " عاطفه قدوسی",
      role: " فرانت اند",
      picture: "/images/atigh-bg.jpg",
      description:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    },
    {
      name: " راضیه اخوان",
      role: "طراح UI,UX",
      picture: "/images/raziye-1.jpg",
      description: (
        <>
          <p>
            من یک طراح محصول هستم که از سال ۲۰۲۰ مسیر حرفه‌ای خود را با تمرکز بر طراحی تجربه‌های کاربری هدفمند و رابط‌های بصری جذاب آغاز کرده‌ام. در این مسیر، ترکیب خلاقیت و تفکر استراتژیک به من امکان داده تا محصولاتی طراحی کنم که فراتر از زیبایی بصری، ارزش واقعی برای کاربران و کسب‌وکارها خلق کنند. پیش از ورود به دنیای طراحی، مدرک کارشناسی خود را در رشته مهندسی نرم‌افزار و مدرک کارشناسی ارشد را در رشته هوش مصنوعی دریافت کردم و حدود ۸ سال فعالیت آکادمیک، به عنوان مدرس دروس کامپیوتر در مقطع کارشناسی در دانشگاه‌های غیرانتفاعی و علمی کاربردی تدریس کردم. این
            تجربه به من مهارت‌های تحلیلی و تفکر سیستمی بخشید که پایه‌های محکمی برای ورودم به دنیای طراحی محصول شد. در سال ۲۰۱۹، علاقه‌ام به حل مسائل پیچیده و خلق راه‌حل‌های نوآورانه، مرا به سمت یادگیری طراحی تجربه کاربری (UX) و رابط کاربری (UI) سوق داد و از سال ۲۰۲۰ فعالیت حرفه‌ای خود را در این حوزه آغاز کردم. تخصص من در تحلیل نیازها و رفتار کاربران، شناسایی چالش‌ها و تبدیل آن‌ها به راه‌حل‌های طراحی کاربردی است. اعتقاد دارم که هر جزئیات کوچک می‌تواند تأثیری بزرگ بر تجربه کلی بگذارد. یکی از پروژه‌های ارزشمندی که افتخار همکاری در آن را داشتم، پروژه
            "بادینس" بود. این پروژه که به ارائه رژیم‌های غذایی و برنامه‌های ورزشی برای بهبود سلامت و تناسب اندام کاربران اختصاص دارد، فرصتی فوق‌العاده برای یادگیری و کسب تجربیات جدید برای من فراهم کرد. در این پروژه، توانستم نیازهای کاربران را به‌طور عمیق‌تر درک کرده و راه‌حل‌های طراحی کاربردی و موثر ارائه دهم. همچنین، این تجربه به من کمک کرد تا به اهمیت تطبیق طراحی با نیازهای خاص کاربران در حوزه سلامت و تناسب اندام پی ببرم. طراحی برای من، چیزی فراتر از خلق یک محصول است؛ این یک فرآیند پویا برای ارتباط با انسان‌ها، حل مشکلات آن‌ها و ایجاد تجربیاتی است که
            هم‌زمان ساده و تأثیرگذار باشند. هدف من همیشه ساخت محصولاتی هست که علاوه‌ بر تحقق اهداف کسب‌و‌کارها، زندگی کاربران را ساده‌تر کرده و تجربه‌ای معنادار برای آن‌ها ایجاد کند.
          </p>
          <p>یش از ورود به دنیای طراحی، مدرک کارشناسی خود را در رشته مهندسی نرم‌افزار و مدرک کارشناسی ارشد را در رشته هوش مصنوعی دریافت کردم و حدود ۸ سال فعالیت آکادمیک، به عنوان مدرس دروس کامپیوتر در مقطع کارشناسی در دانشگاه‌های غیرانتفاعی و علمی کاربردی تدریس کردم. این تجربه به من مهارت‌های تحلیلی و تفکر سیستمی بخشید که پایه‌های محکمی برای ورودم به دنیای طراحی محصول شد.</p>
          <p>در سال ۲۰۱۹، علاقه‌ام به حل مسائل پیچیده و خلق راه‌حل‌های نوآورانه، مرا به سمت یادگیری طراحی تجربه کاربری (UX) و رابط کاربری (UI) سوق داد و از سال ۲۰۲۰ فعالیت حرفه‌ای خود را در این حوزه آغاز کردم.</p>
          <p>تخصص من در تحلیل نیازها و رفتار کاربران، شناسایی چالش‌ها و تبدیل آن‌ها به راه‌حل‌های طراحی کاربردی است. اعتقاد دارم که هر جزئیات کوچک می‌تواند تأثیری بزرگ بر تجربه کلی بگذارد.</p>
          <p>یکی از پروژه‌های ارزشمندی که افتخار همکاری در آن را داشتم، پروژه "بادینس" بود. این پروژه که به ارائه رژیم‌های غذایی و برنامه‌های ورزشی برای بهبود سلامت و تناسب اندام کاربران اختصاص دارد، فرصتی فوق‌العاده برای یادگیری و کسب تجربیات جدید برای من فراهم کرد. در این پروژه، توانستم نیازهای کاربران را به‌طور عمیق‌تر درک کرده و راه‌حل‌های طراحی کاربردی و موثر ارائه دهم. همچنین، این تجربه به من کمک کرد تا به اهمیت تطبیق طراحی با نیازهای خاص کاربران در حوزه سلامت و تناسب اندام پی ببرم.</p>
          <p>طراحی برای من، چیزی فراتر از خلق یک محصول است؛ این یک فرآیند پویا برای ارتباط با انسان‌ها، حل مشکلات آن‌ها و ایجاد تجربیاتی است که هم‌زمان ساده و تأثیرگذار باشند. هدف من همیشه ساخت محصولاتی هست که علاوه‌ بر تحقق اهداف کسب‌و‌کارها، زندگی کاربران را ساده‌تر کرده و تجربه‌ای معنادار برای آن‌ها ایجاد کند.</p>
        </>
      ),
    },
    {
      name: "دکتر مهاجرانی",
      role: "پزشک",
      picture: "/images/doctor1.jpg",
      description:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    },
    {
      name: "دکتر صادقی",
      role: "پزشک",
      picture: "/images/doctor3.jpg",
      description: (
        <>
          <p>من سارا صادقی هستم، متخصص تغذیه. در سال ۱۴۰۰، مدرک لیسانس علوم تغذیه را از دانشگاه علوم پزشکی تبریز دریافت کردم و همان سال با کسب رتبه ۳ کشوری وارد مقطع فوق‌لیسانس رشته تغذیه بالینی در دانشگاه علوم پزشکی تهران شدم. امسال نیز افتخار کسب رتبه یک کتبی آزمون دکتری تخصصی را داشتم.</p>
          <p>تحصیلات و تجربیاتم در زمینه تغذیه بالینی، به من این امکان را داده است که مسائل تغذیه‌ای را از دیدگاه علمی و جامع بررسی کنم. در طول دوران تحصیل، تجربیات ارزشمندی در بیمارستان شریعتی دانشگاه تهران کسب کردم. در این بیمارستان، در بخش‌های مختلف و کلینیک سرپایی، در فرآیند درمان تغذیه‌ای بیماران نقش داشتم. این تجربیات به من کمک کرد تا با چالش‌های واقعی در زمینه تغذیه و سلامت روبه‌رو شوم و راه‌حل‌های کاربردی و مؤثرتری برای آن‌ها ارائه دهم.</p>
          <p>علاوه بر این، علاقه من به فعالیت‌های تخصصی باعث شد که رژیم‌درمانی آنلاین را برای مراجعین با اهداف متنوع مانند کاهش و افزایش وزن، مدیریت بیماری‌های تغذیه‌ای و رژیم‌های مخصوص ورزشکاران آغاز کنم. این تجربه به من نشان داد که چگونه می‌توان از تکنولوژی برای ارائه خدمات تغذیه‌ای گسترده و در دسترس استفاده کرد.</p>
          <p>من عمیقاً به تأثیر تغذیه سالم بر بهبود کیفیت زندگی باور دارم و همواره تلاش کرده‌ام با ارائه راهکارهای علمی و عملی، به مراجعینم کمک کنم تا به اهداف سلامتی خود برسند. اکنون بسیار خوشحالم که از طریق اپلیکیشن بادینس، که بستری جامع و در دسترس برای افراد با اهداف مختلف است، می‌توانم همراه شما در مسیر سلامتی و دستیابی به اهداف تغذیه‌ای‌تان باشم. امیدوارم بتوانم گامی مثبت در بهبود کیفیت زندگی شما بردارم</p>
        </>
      ),
    },
    {
      name: " دکتر لاجوردی",
      role: "پزشک",
      picture: "/images/doctor2.jpg",
      description:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    },
    {
      name: " محسن زیوری",
      role: "مربی",
      picture: "/images/zivariinter1.jpg",
      description: (
        <section>
          <p>من محسن زیوری هستم، فارغ‌التحصیل رشته تکنولوژی شبکه‌های کامپیوتری و فردی که همواره در مسیر تحقق رویاها و اهدافش قدم برداشته است. از نوجوانی، علاقه‌ام به ورزش باعث شد در رشته کیوکوشین کاراته فعالیت کنم و به موفقیت‌های ارزشمندی دست یابم، از جمله کسب عنوان تکنیکی‌ترین فایتر مسابقات قهرمان قهرمانان تیم ملی امید.</p>
          <p>با وجود این موفقیت‌ها، به دلیل برخی چالش‌ها و ناملایمات، چند سالی از دنیای ورزش فاصله گرفتم و به دنیای کسب‌وکار بین‌المللی وارد شدم. در این دوران، تجربه‌های گرانبهایی کسب کردم و علاقه‌ام به کارآفرینی بیشتر شد. این علاقه باعث شد اولین استارتاپ خود را در حوزه گردشگری سلامت راه‌اندازی کنم. هرچند این پروژه در زمان رشد با بحران جهانی کرونا مواجه شد و به سرانجام نرسید، اما تجربه آن برایم بسیار ارزشمند بود و مسیر آینده‌ام را روشن‌تر کرد.</p>
          <p>علاقه شخصی‌ام به ورزش و سلامت و پیشنهادات دوستان و همکارانم، من را به بازگشت به دنیای ورزش ترغیب کرد. در این مسیر، دوره‌های مختلف مربیگری و تغذیه ورزشی را در داخل و خارج از کشور گذراندم و موفق به دریافت مدارک معتبری از جمله:</p>
          <ul>
            <li>IFBB</li>
            <li>NSCA</li>
            <li>ISSA</li>
            <li>Advanced Fitness Nutrition Coach</li>
            <li>International Advanced Coach Update 2023</li>
          </ul>
          <p>نتیجه این تلاش‌ها، شکل‌گیری ایده اپلیکیشن بادینس بود؛ پروژه‌ای که پس از یک سال و نیم کار مداوم، به ثمر نشسته است. بادینس به‌عنوان یک مرجع در زمینه سلامت، تغذیه و ورزش طراحی شده است و اهداف متعددی را دنبال می‌کند، از جمله:</p>
          <ul>
            <li>آموزش صحیح حرکات ورزشی مانند فیتنس و بدن‌سازی.</li>
            <li>ارائه دوره‌های تخصصی مانند آموزش حرکات بدنسازی، چربی‌سوزی، و دوره‌های مخصوص سالمندان.</li>
            <li>ارزیابی اولیه مخاطبان برای انتخاب بهترین برنامه ورزشی و تغذیه‌ای.</li>
          </ul>
          <p>هدف اصلی من از راه‌اندازی این اپلیکیشن، ایجاد بستری جامع برای ارتقای سلامتی و کیفیت زندگی مردم عزیز کشورم و در آینده‌ای نزدیک، ارائه این خدمات به مخاطبان بین‌المللی است. امیدوارم بتوانم تخصص و تجربه خود را در خدمت به مردم قرار دهم و سلامتی همراه با دانش و مهارت برای همه به ارمغان بیاورم.</p>
        </section>
      ),
    },
    {
      name: " نگار تارویردی",
      role: "مربی",
      picture: "/images/morabi3.png",
      description: (
        <>
          <p>متولد ۲۱ تیر ۱۳۶۹ و فارغ‌التحصیل رشته فیزیک، با افتخار ۱۲ سال تجربه در حوزه ورزشی را با خود همراه دارم. به‌عنوان مربی و راهنمای کسانی که سلامتی و زیبایی بدن را جزو اولویت‌های زندگی خود می‌دانند، تلاش می‌کنم تا مسیری هموار برای دستیابی به این اهداف فراهم کنم. این مسیر، نیازمند علم و تجربه کافی است و من با تمام توان، مخاطبانم را با انگیزه و انرژی بیشتر تا رسیدن به مقصد همراهی می‌کنم.</p>
          <p>تمرین اصولی و بدون نگرانی از آسیب‌های احتمالی، یکی از اولویت‌های اصلی من است. آموزش تکنیک‌های صحیح و اجرای درست حرکات ورزشی، مهم‌ترین بخش از کار من محسوب می‌شود.</p>
          <p>
            سوابق من شامل:
            <br />
            <p>دریافت مدارک تخصصی از فدراسیون‌های مرتبط (پرورش اندام و همگانی) ریاست کمیته کار با دستگاه در هیأت آمادگی جسمانی شمیرانات فعالیت در باشگاه‌ها و مجموعه‌های ورزشی معتبر و عالی‌رتبه تهران ورزش برای من همیشه با عشق و تعهد همراه بوده است. سبک زندگی‌ام به‌عنوان یک مربی و ورزشکار، نه تنها حرفه‌ای بلکه منبع الهام من برای تشویق دیگران است. با من می‌توانید تجربه‌ای لذت‌بخش، علمی و ایمن از تمرینات ورزشی داشته باشید. امیدوارم بتوانم همراه مناسبی برای شما در این مسیر باشم.</p>
          </p>
        </>
      ),
    },
    {
      name: "بیتا حسینی",
      role: "مربی",
      picture: "/images/morabi2.jpg",
      description: (
        <>
          <p>من بیتا حسینی هستم، مربی فیتنس و بدنسازی با بیش از 12 سال سابقه مربیگری و دارای مدرک بین‌المللی در فیتنس و حرکات اصلاحی. هدف من در این مسیر، ایجاد انگیزه، ترویج فرهنگ سالم زیستی و کمک به داشتن لایف استایلی سالم و زیبا برای همه افراد است.</p>
          <p>اولویت اصلی من در حرفه‌ام، تاکید بر قدرت برتر بدنی و ذهنی و ارتقای آمادگی جسمانی در تمام سنین است. باور دارم که این موارد، همراه با حس خوب و انرژی مثبت، می‌تواند تغییرات بزرگی در کیفیت زندگی افراد ایجاد کند.</p>
          <p>رای دستیابی به این هدف، همواره در حال یادگیری و به‌روزرسانی دانش و مهارت‌های خود هستم. روش‌های من در طراحی تمرینات و برنامه‌های تغذیه، بر اساس شرایط فیزیکی، اقلیمی و حتی ژنتیکی هر فرد تنظیم می‌شوند تا تجربه‌ای کاملاً شخصی‌سازی‌شده و موثر را ارائه کنم.</p>
          <p>پکیج‌های تمرینی من، چه به صورت حضوری و چه آنلاین، به گونه‌ای طراحی می‌شوند که همیشه جذاب و متنوع باشند. این تنوع نه تنها از تمرین‌زدگی جلوگیری می‌کند، بلکه باعث پیشرفت مستمر و ایجاد تغییرات قابل مشاهده در شاگردانم می‌شود.</p>
          <p>برای افرادی که به دنبال تجربه‌ای خاص‌تر هستند، پکیج‌های VIP طراحی کرده‌ام که شامل امتیازات و برنامه‌های جذاب‌تر است و تاثیرگذاری تمرینات را چند برابر می‌کند.</p>
          <p>افرادی که به جمع ارتش فیتنس من می‌پیوندند، سفری هیجان‌انگیز را آغاز می‌کنند که در آن، گام‌به‌گام همراهشان خواهم بود تا به اهدافشان برسند. امیدوارم شما هم به این گروه بپیوندید و همراه من، مسیر داشتن زندگی سالم و بدنی قدرتمند را تجربه کنید.</p>
        </>
      ),
    },
    /*  {
      name: "گنجی",
      role: "مربی",
      picture: "/images/morabi1.png",
      description:
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    }, */
    {
      name: "علی درگاهی",
      role: "برنامه نویس ارشد frontend",
      picture: "/images/001.jpg",
      description: (
        <>
          <p>من یک توسعه‌دهنده فرانت‌اند با ۸ سال سابقه کار حرفه‌ای هستم. تجربه من در طراحی و توسعه رابط‌های کاربری باعث شده تا پروژه‌های مختلفی را در حوزه‌های گوناگون با موفقیت به سرانجام برسانم. تخصص اصلی من در استفاده از فناوری‌های مدرن نظیر React، Next.js و TypeScript است و در کنار آن‌ها از معماری کلین (Clean Architecture) برای ایجاد ساختاری منظم و قابل نگهداری بهره می‌برم.</p>
          <p>در طول دوران کاری‌ام، بر روی پروژه‌های بزرگ و تاثیرگذاری مانند نرم‌افزارهای سازمانی پخش دارو، پروژه تاکسی اینترنتی هیثرو و همچنین پروژه‌های مرتبط با آموزش و پرورش کار کرده‌ام. این تجربیات به من کمک کرده‌اند تا مهارت‌های خود را در طراحی سیستم‌های پیچیده و ارائه تجربه‌های کاربری بهینه تقویت کنم.</p>
          <p>تمرکز من همیشه بر ارائه راه‌حل‌های سریع، کارآمد و زیبا برای نیازهای مشتریان است. همچنین با ابزارهای مدیریت وظایف مانند Trello و سیستم‌های کنترل نسخه نظیر Git به خوبی آشنا هستم.</p>
          <p>علاقه‌مند به چالش‌های جدید هستم و از مشارکت در پروژه‌هایی که خلاقیت، دانش و مهارت‌های من را به چالش می‌کشند، لذت می‌برم.</p>
        </>
      ),
    },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [open, setOpen] = useState(false);
  const [closeLeft, setCloseLeft] = useState(0);
  const [closeTop, setCloseTop] = useState(0);
  const [teamInfo, setTeamInfo] = useState();
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {
    const div = document.getElementById("myDiv");
    if (!div) {
      console.warn("Element with id 'myDiv' not found");
      return;
    }
    const handleMouseMove = (event) => {
      const rect = div.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCloseLeft(x - 40);
      setCloseTop(y - 40);
    };
    div.addEventListener("mousemove", handleMouseMove);
    return () => {
      div.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const showInfo = (index) => {
    setOpen(true);
    setTeamInfo(teamList[index]);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%]  mx-auto mt-[20px] mb-[50px] xl:mb-[150px] h-auto ">
      <section className="grid xl:grid-cols-4 grid-cols-2  relative w-full  xl:w-[1128px]  mx-auto gap-6">
        <section className="hidden xl:block"></section>
        <Item social={true} index={7} showInfo={showInfo} picture={baseFileUrl + "/images/mohsenzivaribg.png"} title=" آقای محسن زیوری" text="بنیان گذار" />
        <Item social={true} index={0} showInfo={showInfo} picture={baseFileUrl + "/images/erfannokhiz.png"} title="آقای عرفان نوخیز" text="هم بنیان گذار" />
        <section className="xl:col-span-4 mt-4 xl:mt-8 col-span-2 flex items-center xl:justify-between gap-3 xl:mb-8  w-full">
          <img src={baseFileUrl + "/images/VectorTeam-r.png"} alt="" className="xl:w-[400px] w-[30%] " />
          <h2 className="text-purple  text-sm xl:text-[20px] font-bold"> تیم برنامه نویسان</h2>
          <img src={baseFileUrl + "/images/VectorTeam-l.png"} alt="" className="xl:w-[400px] w-[30%]" />
        </section>
        {/*<section className="absolute left-0 top-[800px] w-full flex justify-center items-center">
          <section className="w-[600px] h-[600px] rounded-full  bg-[#fcfaff]"></section>
        </section> */}
        <Item social={true} index={1} showInfo={showInfo} picture={baseFileUrl + "/images/moshiribg.png"} title="آقای علی مشیری امین" text="برنامه نویس ارشد backend" />
        <Item social={true} index={11} showInfo={showInfo} picture={baseFileUrl + "/images/amooooo aliiiiiii.png"} title="  آقای علی درگاهی" text="   برنامه نویس  ارشد frontend" />
        <Item social={true} index={2} showInfo={showInfo} picture={baseFileUrl + "/images/ghoddusi.png"} title=" خانم عاطفه قدوسی" text="   برنامه نویس frontend" />
        <Item social={true} index={3} showInfo={showInfo} picture={baseFileUrl + "/images/raziye1.jpg"} title="خانم راضیه اخوان" text="طراح UI,UX" />
        <section className="xl:col-span-4  mt-4 xl:mt-8 col-span-2 flex items-center xl:justify-between gap-3 xl:mb-8  w-full">
          <img src={baseFileUrl + "/images/VectorTeam-r.png"} alt="" className="xl:w-[400px] w-[30%] " />
          <h2 className="text-purple  text-sm xl:text-[20px] font-bold"> تیم پزشکان</h2>
          <img src={baseFileUrl + "/images/VectorTeam-l.png"} alt="" className="xl:w-[400px] w-[30%]" />
        </section>
        <Item index={4} showInfo={showInfo} picture={baseFileUrl + "/images/doctor1.png"} title="خانم ملیکه مهاجرانی" text=" متخصص تغذیه" />
        <Item index={5} showInfo={showInfo} picture={baseFileUrl + "/images/doctor5.jpg"} title="  خانم سارا صادقی" text="  کارشناس ارشد  تغذیه بالینی" />
        <Item index={6} showInfo={showInfo} picture={baseFileUrl + "/images/doctor4.jpg"} title="آقای ابوالفضل لاجوردی" text=" مشاور تغذیه" />
        <section className="xl:col-span-4 mt-4 xl:mt-8 col-span-2 flex items-center xl:justify-between gap-3 xl:mb-8  w-full">
          <img src={baseFileUrl + "/images/VectorTeam-r.png"} alt="" className="xl:w-[400px] w-[30%] " />
          <h2 className="text-purple  text-sm xl:text-[20px] font-bold"> تیم مربیان</h2>
          <img src={baseFileUrl + "/images/VectorTeam-l.png"} alt="" className="xl:w-[400px] w-[30%]" />
        </section>
        <Item index={8} showInfo={showInfo} picture={baseFileUrl + "/images/morabi3.png"} title="خانم نگار تارویردی" text="مربی" />
        <Item index={9} showInfo={showInfo} picture={baseFileUrl + "/images/morabi2.png"} title="  خانم بیتا حسینی" text="مربی" />
        {/* <Item index={10} showInfo={showInfo} picture={baseFileUrl + "/images/morabi1.png"} title="خانم فاطمه گنجی" text="مربی" /> */}
      </section>
      <section id="myDiv" className={`fixed flex w-full h-full bg-slate-200 transition-all duration-500 top-[9%] xl:top-0 ${open ? "right-0" : "-right-[100%] xl:right-[-110%]"} z-[1000]`}>
        {/*  <section onClick={() => setOpen(false)} className={`w-[80px] absolute xl:flex justify-center hidden items-center h-[80px] rounded-full border cursor-pointer border-black`} style={{ left: closeLeft, top: closeTop }}>
          <img className="w-10 h-10" alt="" width={40} height={40} src={svgFileUrl + "/close.svg"} />
        </section>
        <section onClick={() => setOpen(false)} className={`w-[40px] absolute left-4 top-4 flex justify-center xl:hidden items-center h-[40px] rounded-full border cursor-pointer border-black`}>
          <img alt="" width={20} height={20} src={svgFileUrl + "/close.svg"} />
        </section> */}
        <button className="absolute rounded-full border border-silver p-3 top-5 left-5" onClick={() => setOpen(false)}>
          <img alt="" width={20} height={20} src={svgFileUrl + "/close.svg"} />
        </button>
        <section className="w-[35%] hidden h-full xl:flex justify-center items-center bg-slate-300">
          <img width={500} height={400} className="rounded-lg h-[80%] w-auto" src={teamInfo?.picture} alt="" />
        </section>
        <section className="w-[100%] xl:w-[65%] p-3 xl:py-[40px] xl:px-[80px] flex h-full overflow-auto flex-col justify-center xl:bg-slate-100">
          <section className="w-[700px] max-w-full xl:mr-[100px]">
            <h2 className="text-2xl xl:text-[30px] text-right font-bold">{teamInfo?.name}</h2>
            <h3 className="xl:text-[22px]">{teamInfo?.role}</h3>
            <p className="text-justify text-sm xl:text-base leading-7 h-[400px] overflow-auto px-5 font-light xl:leading-8 mt-8">{teamInfo?.description}</p>
          </section>
        </section>
      </section>
    </section>
  );
}
