import React from "react";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveBtn, activeBtn }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-[1053px] w-full pt-8 xl:pt-[44px] flex justify-center xl:justify-start md:justify-start gap-5 mx-auto">
        <button
          onClick={() => {
            setActiveBtn(1);
          }}
          className={`${activeBtn === 1 ? "text-white bg-[#5408A9] " : "text-[#5408A9] border border-solid border-[#5408A9]"} w-[131px] h-[30px] rounded-full  font-bold text-sm `}
        >
          آب مصرفی روزانه
        </button>

        {/*   <button onClick={() => {
          setActiveBtn(2)
        }} className={`${activeBtn === 2 ? "text-white bg-[#5408A9] " : "text-[#5408A9] border border-solid border-[#5408A9]"}  w-[131px] h-[30px] rounded-full font-bold text-sm `}>
          آب مصرفی در ورزش
        </button> */}
      </section>
    </>
  );
}
