"use client";
import React from "react";
import "./style.css";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  children,
  outlined,
  disabled,
  light,
  className,
  loading,
  secondary,
  onClick,
  type,
  ...rest
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getColor = () => {
    if (outlined) {
      if (secondary) {
        return "border border-[#F02484] hover:border-[#F67CB5] hover:bg-[#F67CB5] text-white bg-[#F02484]";
      } else if (light) {
        return "";
      } else {
        return "border border-purple  text-purple hover:bg-[#DDCEEE] bg-none ";
      }
    } else {
      if (secondary) {
        return "border border-[#F02484] hover:border-[#F67CB5] hover:bg-[#F67CB5] text-white bg-[#F02484]";
      } else if (light) {
        return " hover:bg-[#FFFFFFB2] bg-white text-purple";
      } else {
        return "bg-purple hover:bg-[#7639BA] border-purple text-white ";
      }
    }
  };
  const getLoaderColor = () => {
    if (outlined) {
      if (secondary) {
        return "orangeLoader";
      }
      if (light) {
        return "grayLoader";
      } else {
        return "purpleLoader";
      }
    } else {
      return "whiteLoader";
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <button
      type={
        type === "submit" ? "submit" : type === "button" ? "button" : "button"
      }
      disabled={disabled}
      {...rest}
      onClick={onClick}
      className={`relative flex h-12  items-center justify-center rounded-[10px] px-[35px]  ${getColor()} px-5  transition-all duration-700 disabled:opacity-30 ${className}`}
    >
      {children}
      <section className="absolute flex justify-center items-center h-full  w-10 left-[2px]">
        <div
          className={`btnLoader ${getLoaderColor()} ${
            loading ? "opacity-1" : "opacity-0"
          }`}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
    </button>
  );
}
