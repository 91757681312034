import React from "react";
import Section1 from "./components/Section1";
import Section3 from "./components/Section3";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Section1 />
      <section className="absolute left-0 w-full flex justify-center items-center">
        <section className="w-[600px] h-[600px] rounded-full bg-[#fcfaffc5]"></section>
      </section>
      <Section3 />
    </>
  );
}
