import { imagesFileUrl } from "../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
// const Item = ({ children }) => {
//   return <span className="bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center">{children}</span>;
// };
export default function Index({ docs }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="rounded-[10px] border border-[#E6E8E9] p-[20px]  mt-[32px]">
      <h2 className="text-[18px] font-bold ">مدارک</h2>
      <section>
        {docs ? (
          docs?.map((item, index) => (
            <section className="mt-2">
              <img onClick={() => window.open(item)} className="w-[125px] cursor-pointer" src={item} alt="" />
            </section>
          ))
        ) : (
          <span className="font-semibold text-[12px]">مدرکی ثبت نشده</span>
        )}
      </section>
      {/*  <section className="mt-[32px]">
        <section className="flex gap-2 mt-[12px] items-center">
          <img className="w-[125px]" src={imagesFileUrl + "doctor-profile-doc1.png"} alt="" />
          <section>
            <span className="block text-[14px] font-bold">مدرک بین المللی صنایع غذایی</span>
            <span className="block text-[12px] font-normal">سال 1398</span>
          </section>
        </section>
        <section className="flex gap-2 mt-[12px] items-center">
          <img className="w-[125px]" src={imagesFileUrl + "doctor-profile-doc2.png"} alt="" />
          <section>
            <span className="block text-[14px] font-bold">مدرک بین المللی صنایع غذایی</span>
            <span className="block text-[12px] font-normal">سال 1398</span>
          </section>
        </section>
        <section className="flex gap-2 mt-[12px] items-center">
          <img className="w-[125px]" src={imagesFileUrl + "doctor-profile-doc3.png"} alt="" />
          <section>
            <span className="block text-[14px] font-bold">مدرک بین المللی صنایع غذایی</span>
            <span className="block text-[12px] font-normal">سال 1398</span>
          </section>
        </section>
      </section> */}
    </section>
  );
}
