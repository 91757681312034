import { useEffect, useState } from "react";
import Progress from "./components/Progress";
import DocumentUpload from "./components/DocumentUpload";
import DocumentUploadSickness from "./components/DocumentUploadSickness";
import ExerciseQuestion from "./components/ExerciseQuestion";
import SpecialSickness from "./components/SpecialSickness";
import CompletionMedical from "./components/CompletionMedical";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "../../../components/common";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  const [searchParams] = useSearchParams();
  const requestProgramId = searchParams.get("requestProgramId");

  // ─── States ─────────────────────────────────────────────────────────────────
  const [specialSicknessList, setSpecialSicknessList] = useState([]);
  const [completionMedicalList, setCompletionMedicalList] = useState([]);
  const [finalAnswer, setFinalAnswer] = useState({});
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let obj = {
      answerQuestionsInputDTOS: [...completionMedicalList],
      answerDiseasesInputDTOS: [...specialSicknessList],
      requestProgramId: parseInt(requestProgramId),
    };
    setFinalAnswer(obj);
  }, [completionMedicalList, specialSicknessList]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-[90%] w-[1360px] m-auto mt-[100px]  xl:mt-[100px] 2xl:mt-[120px] mb-[100px]">
      {/*  <h2 className="text-center text-[#3E4151] xl:text-black text-[20px] xl:text-[48px] font-bold leading-[21px] xl:leading-[74px]">تکمیل اطلاعات ورزشی</h2> */}
      <Progress activeStep={activeTab} setStep={setActiveTab} />
      <section className={`${activeTab === 1 ? `block` : `hidden`}`}>
        <ExerciseQuestion setActiveTab={setActiveTab} />
      </section>
      <section className={`${activeTab === 2 ? `block` : `hidden`}`}>
        <SpecialSickness setSpecialSicknessList={setSpecialSicknessList} setActiveTab={setActiveTab} />
      </section>
      <section className={`${activeTab === 3 ? `block` : `hidden`}`}>
        <CompletionMedical finalAnswer={finalAnswer} setCompletionMedicalList={setCompletionMedicalList} setActiveTab={setActiveTab} />
      </section>
      <section className={`${activeTab === 4 ? `block` : `hidden`}`}>
        <section className="grid grid-cols-1 xl:grid-cols-2">
          <DocumentUpload />
          <DocumentUploadSickness />
        </section>
        <section className="flex justify-center items-center">
          <Link to="/dashboard">
            <Button>اتمام فرآیند</Button>
          </Link>
        </section>
      </section>
    </section>
  );
}
