//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  imgUrl,
  textColor,
  color,
  title,
  value,
  editMode,
  RenderInput,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section
      className={`text-[${textColor}] col-span-2 h-[200px] items-center flex  flex-col bg-[#F7F9FE] overflow-hidden w-full xl:w-[112px]  border border-solid border-[${textColor}] rounded-[18px] relative`}
    >
      <section className="flex justify-center">
        <section
          style={{ backgroundColor: color }}
          className={`w-[141px]  mt-[-50px] h-[140px] rounded-full absolute  z-40`}
        ></section>
        <section
          style={{ backgroundColor: color }}
          className={`w-[141px]  mt-[-40px] h-[140px] rounded-full absolute  opacity-20`}
        ></section>
        <img
          alt=""
          src={imgUrl}
          className="w-[48px] h-[48px] z-50 mt-[24px] "
        />
      </section>

      <span className="mt-[50px]">{title}</span>
      {editMode ? (
        RenderInput
      ) : (
        <span className="xl:mt-[5px] flex justify-center items-center  xl:text-[22px] font-bold">
          {value}
        </span>
      )}
    </section>
  );
}
