import React from "react";
import { imagesFileUrl } from "../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[50px] mb-[50px]">
      <section className="flex justify-between items-center">
        <h2 className="text-[18px] font-bold">گالری تصاویر</h2>
        <section className="flex gap-2">
          <img src={imagesFileUrl + "doctor-profile-right.png"} alt="" />
          <img src={imagesFileUrl + "doctor-profile-left.png"} alt="" />
        </section>
      </section>
      <section className="grid grid-cols-2 xl:grid-cols-3 mt-[42px] justify-between gap-7">
        <img src={imagesFileUrl + "doctor-profile-gallery1.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery2.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery3.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery4.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery1.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery2.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery3.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery4.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-gallery1.png"} alt="" />
      </section>
    </section>
  );
}
