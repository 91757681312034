import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../_Dashboard/components/SideBar/svg/Dashboard";
import ExitIcon from "../_Dashboard/components/SideBar/svg/Exit";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ mode }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [showMenu, setShowMenu] = useState(false);
  // ─── Refs ───────────────────────────────────────────────────────────────────────

  const menuBtnRef = useRef(null);
  const menuContainerRef = useRef(null);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  const handleClickOutside = useCallback((event) => {
    if (
      menuBtnRef.current &&
      !menuBtnRef.current.contains(event.target) &&
      menuContainerRef.current &&
      !menuContainerRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const logOut = () => {
    localStorage.clear();
    navigation("/login");
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section
      className="relative border rounded-full bg-[#ff008a] transition-all *:
      
      hover:bg-[#F02484] border-none p-2 px-4  cursor-pointer"
      onClick={() => setShowMenu(!showMenu)}
    >
      {mode === "mobile" ? (
        <span ref={menuBtnRef} className="text-white ">
          {localStorage.mobileNumber} جان خوش اومدی!
        </span>
      ) : mode === "firstName" ? (
        <span ref={menuBtnRef} className="text-white">
          {localStorage.firstName} جان خوش اومدی!{" "}
        </span>
      ) : (
        ""
      )}
      <section
        ref={menuContainerRef}
        className={`min-h-10 h-auto w-[100%] top-[45px] ${
          showMenu ? "block" : "hidden"
        } right-0 bg-white shadow-xl absolute rounded-md overflow-hidden`}
      >
        <ul>
          {localStorage.firstName && (
            <li
              onClick={() => navigation("/dashboard")}
              className="w-full h-10  flex items-center pr-2 font-semibold text-sm transition-all hover:bg-slate-200"
            >
              <DashboardIcon fill="#5606a8" />
              <span className="mr-3">داشبورد</span>
            </li>
          )}

          <li
            onClick={logOut}
            className="w-full h-10  flex items-center pr-2 font-semibold text-sm transition-all hover:bg-slate-200"
          >
            <ExitIcon fill="#5606a8" />
            <span className="mr-3">خروج</span>
          </li>
        </ul>
      </section>
    </section>
  );
}
