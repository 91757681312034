import { useEffect, useState } from "react";

import NumberPicker from "../../../../../../../../components/common/NumberPicker";

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────

  const [weight, setWeight] = useState(85);
  const [height, setHeight] = useState(165);
  const [bmi, setBmi] = useState();
  const [result, setResult] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setResult(false);
    let hei = height / 100;
    setBmi(Math.floor(weight / (hei * hei)));
  }, [weight, height]);
  return (
    <>
      <section className="order-first xl:order-none w-[100%] xl:w-[427px] h-[444px] px-[30px] py-[32px] bg-[#F7F9FE] xl:mt-[44px] rounded-[15px]">
        <span className="xl:w-full font-bold text-lg block text-center ">
          ورودی اطلاعات
        </span>
        <section className="w-[95%] h-[85px]">
          <span className="block color-[#3E4151] mt-[24px] font-normal text-[16px] mr-2">
            وزن شما
          </span>
          <NumberPicker type="weight" setValue={setWeight} value={weight} />
          <span className="block color-[#3E4151] mt-[24px] font-normal text-[16px] mr-2">
            قد شما
          </span>
          <NumberPicker type="height" setValue={setHeight} value={height} />
          <section className="mt-10">
            <section className="text-left mt-10">
              <button
                onClick={() => setResult(true)}
                className="w-full h-[39px] rounded-full font-bold text-white bg-purple"
              >
                مشاهده نتایج
              </button>
            </section>
          </section>
          {result && (
            <section className="mt-[34px] text-center">
              <span className="font-bold text-lg">BMI شما:</span>
              <span className="font-bold mr-1 text-lg text-[#8C109A]">
                {bmi}
              </span>
            </section>
          )}
        </section>
      </section>
    </>
  );
}
