import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="max-w-full w-[90%] mx-auto xl:w-[1133px] m-auto px-2 mt-[80px] xl:mt-[60px] 2xl:mt-[120px] ">
        <span className="font-normal text-[16px] text-purple">خانه</span>
        <span> / </span>
        <span className="font-normal text-[16px] text-[#A6A9BD]">
          تمرینات ورزشی
        </span>
      </section>
      <Section1 />
      <Section2 />
    </>
  );
}
