import { useEffect, useState } from "react";
import Step from "../../../assets/images/basicInfo/step78910L.png";
import Progress from "../components/Progress";
import { useTranslation } from "react-i18next";
import Yes from "../../../assets/images/basicInfo/yes.png";
import No from "../../../assets/images/basicInfo/no.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, setResistanceExercises, defaultValue, saveBasicInfo }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const saveInfo = (value) => {
    /* Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'اطلاعات شما با موفقیت ثبت شد',
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(() => {
      navigation("/dashboard")
    }, 2000); */
    setResistanceExercises(value);
    saveBasicInfo();
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <span className="block text-center font-bold text-xl xl:text-3xl mt-7">همراه با رژیمت تمرینات مقاومتی انجام می دادی؟</span>
      <section className="flex  gap-3 mt-[140px] xl:mt-[152px] m-auto xl:w-[500px]">
        <button className="flex w-full justify-center gap-2 items-center bg-[#f4f4f4] xl:w-[325px] h-[70px] rounded-[16px]" onClick={() => saveInfo(true)}>
          <img src={Yes} alt="" />
          <span>بله</span>
        </button>
        <button className="flex w-full justify-center gap-2 items-center bg-[#f4f4f4] xl:w-[325px] h-[70px] rounded-[16px]" onClick={() => saveInfo(false)}>
          <img src={No} alt="" />
          <span>نه</span>
        </button>
      </section>
    </>
  );
}
