import { useEffect, useState } from "react";
import SportTimeSelector2 from "./SportTimeSelector2";
import NumberPicker from "../../../../../../../../components/common/NumberPicker";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [weight, setWeight] = useState(70);
  const [activity, setActivity] = useState(0.3);
  const [result, setResult] = useState();
  const [showResult, setShowResult] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let res = (weight * activity) / 28.3;
    setResult(res.toFixed(3));
  }, [activity, weight]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {
    setShowResult(false);
  }, [weight, activity]);
  useEffect(() => {
    console.log(weight);
    console.log(activity);
  }, [weight, activity]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-[100%] xl:w-full h-auto xl:h-[444px] bg-[#F7F9FE] rounded-[15px] order-first xl:order-none">
        <span className="xl:w-full block text-center font-bold text-[20px] mt-[32px]">ورودی اطلاعات</span>
        <section className="w-[95%] xl:w-[340px] m-auto flex flex-col justify-center items-center">
          <section className=" w-full h-[135px]">
            <span className="w-full block text-[14px] font-normal  mt-[32px]">شدت ورزش شما</span>
            {/* <SportTimeSelector
              color="#009CC8"
              state={activity}
              setState={setActivity}
            /> */}
            <section className="mt-2">
              <SportTimeSelector2 state={activity} setState={setActivity} />
            </section>
          </section>
          <span className="w-full block text-[14px] font-normal  mt-[24px]">وزن شما</span>
          <section className=" w-full">
            <NumberPicker value={weight} setValue={setWeight} type="weight" />
          </section>
          <button onClick={() => setShowResult(true)} class="w-full h-[39px] mt-[24px] rounded-full font-bold text-white bg-purple">
            مشاهده نتایج
          </button>
          <section className="flex text-sm xl:text-xl justify-center mt-[34px] font-bold xl:w-[450px]">
            {showResult && (
              <>
                <span>میزان آب مصرفی روزانه:</span>
                <span className="block text-center text-2xl w-[80px] text-[#009cc8]">{result}</span>
                <span>لیتر</span>
              </>
            )}
          </section>
        </section>
      </section>
    </>
  );
}
