//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ activeSlide }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="swiperInfo mt-3">
      <span className="title">
        {activeSlide === 1
          ? "فعالیت خیلی سبک"
          : activeSlide === 2
          ? "فعالیت سبک"
          : activeSlide === 3
          ? "فعالیت متوسط"
          : activeSlide === 4
          ? "فعالیت سنگین"
          : activeSlide === 5
          ? "فعالیت بسیار سنگین"
          : ""}
      </span>
      <span className="text">
        {activeSlide === 1
          ? "خانم ها و آقایان مسن و افراد دارای محدودیت حرکتی"
          : activeSlide === 2
          ? "کارمندان، مغازه داران و خانم های خانه دار بدون ورزش"
          : activeSlide === 3
          ? "افرادی که سه روز در هفته ورزش می کنند. (زمان ورزش بین 45 دقیقه تا 1 ساعت)"
          : activeSlide === 4
          ? "ورزشکاران نیمه حرفه ای که هرروز به مدت 1 ساعت تمرین می کنند"
          : activeSlide === 5
          ? "ورزشکاران حرفه ای با روزی 2 نوبت تمرین"
          : ""}
      </span>
    </section>
  );
}
