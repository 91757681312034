import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1250_662)">
    <path d="M15.8335 14.1039L9.55956 7.83L2.22956 0.5H1.65672L1.53975 0.797219C1.23103 1.58172 1.09913 2.38409 1.15835 3.1175C1.22585 3.9535 1.54344 4.67728 2.07685 5.21069L7.79903 10.9329L8.55378 10.1781L13.8439 16.0935C14.3702 16.6198 15.276 16.651 15.8335 16.0935C16.382 15.545 16.382 14.6525 15.8335 14.1039Z" fill={fill}/>
    <path d="M5.14688 9.60547L0.665281 14.0871C0.116781 14.6356 0.116781 15.5281 0.665281 16.0766C1.18634 16.5977 2.08775 16.6437 2.65481 16.0766L7.13644 11.595L5.14688 9.60547Z" fill={fill}/>
    <path d="M15.5711 3.16447L13.0288 5.70666L12.3656 5.04347L14.9078 2.50125L14.2447 1.83806L11.7025 4.38025L11.0393 3.71706L13.5815 1.17488L12.9183 0.511719L9.60241 3.82766C9.19675 4.23331 8.95453 4.77194 8.92028 5.34438C8.91163 5.48941 8.87875 5.63207 8.82422 5.76775L10.9782 7.92179C11.1139 7.86719 11.2566 7.83435 11.4016 7.82569C11.9741 7.79154 12.5127 7.54925 12.9183 7.1436L16.2343 3.82769L15.5711 3.16447Z" fill={fill}/>
    </g>
    <defs>
    <clipPath id="clip0_1250_662">
    <rect width="16" height="16" fill="white" transform="translate(0.25 0.5)"/>
    </clipPath>
    </defs>
    </svg>
  );
}
