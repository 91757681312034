import { useState } from "react";
import ChooseItem from "./componenets/ChooseItem";
import OfferPlan from "./componenets/OfferPlan";
import { notify } from "../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeBtn, setActiveBtn] = useState(1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="relative">
      <section className="dietFoodBox mt-4 w-full flex flex-col  h-auto px-[26px] py-[30px] bg-[#FEF4F9]  border border-[#FCD3E6] rounded-xl ">
        <section className="flex gap-5">
          <span onClick={() => setActiveBtn(1)} className={`${activeBtn === 1 ? "bg-[#F3509D] text-white" : "text-[#F3509D] border border-[#F3509D]"} w-[118px] cursor-pointer h-[30px] rounded-[100px] flex justify-center items-center  text-sm`}>
            انتخاب از بسته
          </span>
          <span
            onClick={() => {
              setActiveBtn(2);
            }}
            className={`${activeBtn === 2 ? "bg-[#F3509D] text-white" : "text-[#F3509D] border border-[#F3509D]"} w-[118px] cursor-pointer h-[30px] rounded-[100px] flex justify-center items-center text-[#F3509D]  text-sm`}
          >
            انتخاب موردی
          </span>
        </section>
        {activeBtn === 1 && <OfferPlan />}
        {activeBtn === 2 && <ChooseItem />}
      </section>
    </section>
  );
}
