import { baseFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" w-[90%]  h-auto mb-[50px] xl:mb-[80px] relative rounded-[16px] overflow-hidden px-4 xl:px-12 py-14  xl:w-[1128px] mx-auto bg-[linear-gradient(264.34deg,_#f1e8fc47_8.02%,_#F1E8FC_71.05%)]">
      <section className="w-full justify-between  relative flex">
        <section className="text-[#191919] mb-11 xl:mb-0 text-[22px] xl:text-[32px] font-bold">
          <h2>رژیم‌های درمانی</h2>
        </section>
      </section>
      <img
        src={baseFileUrl + "/shapebcircyle.png"}
        alt=""
        className="w-full absolute top-0 -right-[100px]   h-auto "
      />
      <section className="grid h-auto  justify-center z-10 relative   w-full xl:grid-cols-2 gap-5 xl:gap-8 py-11">
        <Item
          display="hidden xl:flex"
          src="/pic (6).png"
          price="350,000"
          title="رژیم ویژه بیماران قلبی و عروق"
        />
        <Item
          display="hidden xl:flex"
          src="/pic (7).png"
          price="350,000"
          title="رژیم درمانی اختلالات کبدی"
        />
        <Item
          display="hidden xl:flex"
          src="/pic (2).png"
          price="350,000"
          title="رژیم درمانی گوارشی"
        />
        <Item
          src="/pic (3).png"
          price="350,000"
          title="رژیم درمانی بیماران کلیوی"
        />
        <Item
          src="/pic (5).png"
          price="350,000"
          title="رژیم درمانی بیماران صرعی"
        />
        <Item
          src="/pic (4).png"
          price="350,000"
          title="رژیم درمان کنترل چربی و کلسترول"
        />
      </section>
    </section>
  );
}
