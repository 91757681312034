import { imagesFileUrl } from "../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex justify-between">
      <span className="text-[14px]">
        <strong className="text-[16px]">124</strong> نفر نظر دادند
      </span>

      <section className="flex gap-1">
        <span>
          <strong>4</strong>/5
        </span>
        <img src={imagesFileUrl + "doctor-profile-star.png"} alt="" />
        <img src={imagesFileUrl + ".png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-star-fill.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-star-fill.png"} alt="" />
        <img src={imagesFileUrl + "doctor-profile-star-fill.png"} alt="" />
      </section>
    </section>
  );
}
