import { imagesFileUrl } from "../../../../../../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="mt-[50px]">
        <section className="flex justify-between mt-[50px]">
          <h3 className="font-bold"> ورزش‌های پیشنهادی مربی </h3>
        </section>

        <section className="flex justify-between mt-6">
          <img src={imagesFileUrl + "checkbox1.png"} className="w-[194px] h-auto" alt="" />
          <img src={imagesFileUrl + "checkbox2.png"} className="w-[194px] hidden xl:block h-auto" alt="" />
          <img src={imagesFileUrl + "checkbox3.png"} className="w-[194px] hidden xl:block h-auto" alt="" />
        </section>
      </section>
    </>
  );
}
