import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import { imagesFileUrl } from "../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-[1124px] w-[90%] mx-auto mt-[104px] flex">
        <span className="text-[#5408A9]">خانه</span>
        <span className="text-[#A6A9BD]">/ برنامه‌های غذایی</span>
      </section>
      <Section1 />
      <section className="xl:w-[1124px]  w-[90%] mx-auto   mt-[106px] ">
        <section className="flex  justify-between ">
          <span className="text-[#191919]font-bold leading-[49.6px] text-[32px] ">
            ویژگی‌ها
          </span>

          <div className="w-8 h-8 xl:hidden flex  mr-[35%] justify-center items-center rounded-full bg-[#0000001F] active:bg-[#F67CB5]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto  "
            />
          </div>
          <div className="w-8 h-8 flex xl:hidden   justify-center items-center rounded-full bg-[#0000001F] active:bg-[#F67CB5]">
            <img
              src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
              alt=""
              className="w-[8px] h-auto rotate-180"
            />
          </div>
        </section>

        <section className=" overflow-x-scroll xl:overflow-x-visible h-auto w-full">
          <Section2 />
        </section>

        <Section3 />
      </section>
    </>
  );
}
