import { svgFileUrl } from "../../../../../../../../config";
import { Button, TextBox } from "../../../../../../../../components/common";
import { useEffect, useState } from "react";
import { useAxiosWithToken } from "../../../../../../../../hooks";
import api from "../../../../../../../../api";
import { NotifyMessage } from "../../../../../../../../enums";
import { notify } from "../../../../../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  activeTab,
  setPackageData,
  packageData,
  clearHeader,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [packageName, setPackageName] = useState("");
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const saveData = () => {
    setLoading(true);
    let params = {
      namePackage: packageName,
      typeOfMeal: activeTab,
    };
    useAxiosWithToken
      .post(api.foodProgramPackage.addFoodProgramPackage, params)
      .then((res) => {
        setPackageData(res.data);

        setLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setPackageName("");
  }, [clearHeader]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid grid-cols-2 px-6 xl:px-0 pb-6  xl:grid-cols-3 gap-2">
      <TextBox
        disabled={packageData}
        value={packageName}
        onChange={(e) => setPackageName(e.target.value)}
      />
      <Button
        loading={loading}
        onClick={saveData}
        disabled={packageName.length < 2 || packageData}
        className="bg-purple xl:px-0  text-white xl:w-[164px] h-[40px] rounded text-xs xl:text-sm flex justify-center xl:gap-1 items-center"
      >
        <img
          src={svgFileUrl + "plus-white.svg"}
          alt=""
          className="w-4 h-4 xl:w-6 xl:h-6"
        />
        ایجاد پکیچ
      </Button>
    </section>
  );
}
