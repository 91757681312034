import { pdf } from "@react-pdf/renderer";
import BodyNessPdf from "../../../../../components/PDF/ImaniPdf";

const MyPDFPage = ({ data }) => {
  const DownloadPDF = async () => {
    const blob = await pdf(<BodyNessPdf data={data} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "example.pdf";
    link.click();
  };
  return (
    <button onClick={DownloadPDF} className="w-[83px] h-[30px] bg-purple text-white rounded-full">
      دانلود
    </button>
  );
};

export default MyPDFPage;
