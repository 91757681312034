import { Fragment, useEffect, useState } from "react";
import Filter from "./components/Filter";
import api from "../../../../../../../api";
import { useSearchParams } from "react-router-dom";
import SetForm from "./components/SetForm";
import { Button, Select } from "../../../../../../../components/common";
import { imagesFileUrl } from "../../../../../../../config";
import { useAxiosWithToken } from "../../../../../../../hooks";
import { notify } from "../../../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setFinalList, finalList }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [level, setLevel] = useState(1);
  const [advancedSetting, setAdvancedSetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [setCount, setSetCount] = useState();
  const [setsList, setSetsList] = useState([]);
  /*  const [month, setMonth] = useState("FIRST");
  const [week, setWeek] = useState("FIRST");
  const [days, setDays] = useState("FIRST"); */
  const [addShow, setAddShow] = useState(false);
  const [exerciseObj, setExerciseObj] = useState({
    requestId: parseInt(searchParams.get("userId")),
    username: searchParams.get("mobileNumber"),
    trainingSystem: "",
    muscleId: "",
    exerciseName: "",
    description: "",
    tavan: "",
    weight: "",
    monthEnum: "FIRST",
    weekEnum: "FIRST",
    dayEnum: "FIRST",
  });
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const setFormData = (obj) => {
    setExerciseObj({ ...exerciseObj, ...obj });
  };
  const generateSets = (count) => {
    let arr = [];
    for (var i = 0; i < count; i++) {
      arr.push({
        id: i + 1,
        setRepeat: 0,
        tempo: 0,
        rir: 0,
        rest: 0,
        repeatHandle: 0,
      });
    }
    setSetsList(arr);
  };
  const saveEx = () => {
    setLoading(true);
    let params = [
      {
        ...exerciseObj,
        sets: setsList,
        setCount: setCount,
      },
    ];
    console.log(params);
    useAxiosWithToken
      .post(api.personalProgram.addPersonalSportProgram, params)
      .then((res) => {
        notify.Success("با موفقیت ذخیره شد");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    /* let arr = [];
        days.forEach((item) => {
      arr.push({ ...exerciseObj });
    });
    setAddShow(false);
    setFinalList([...finalList, ...arr]);
    notify.Success("تمرین ها به لیست تهایی افزوده شد"); */
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    generateSets(setCount);
  }, [setCount]);
  useEffect(() => {
    console.log(setsList);
  }, [setsList]);
  useEffect(() => {
    console.log(exerciseObj, "exerciseObj");
  }, [exerciseObj]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[20px] border border-[#E6E8E9] rounded-[10px] px-[16px] py-[24px]">
      <Filter setFormData={setFormData} setAddShow={setAddShow} exerciseObj={exerciseObj} />
      {addShow && (
        <>
          <section className="w-full  py-[27px] px-[16px] items-center  border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
            <section className="grid grid-cols-1 xl:grid-cols-3 gap-3 mt-[33px]">
              <section>
                <label className="mb-2 text-md block">سیستم تمرینی</label>
                <Select setState={(value) => setFormData({ trainingSystem: value })} api={api.exercise.getExerciseSystem + "?pageNumber=0&pageSize=200"} optionTitle="persianName" optionValue="persianName" title="سیستم تمرینی" />
              </section>
              <section>
                <label className="mb-2 text-md block">دسته بندی عضلات</label>
                <Select setState={(value) => setFormData({ muscleId: value })} api={api.exercise.getMuscles + "?pageNumber=0&pageSize=200"} optionTitle="persianName" optionValue="id" title="دسته بندی عضلات" />
              </section>
              <section>
                <label className="mb-2 text-md block">نام حرکت</label>
                <Select setState={(value) => setFormData({ exerciseName: value })} api={api.exercise.getExercise + `?muscleId=${exerciseObj.muscleId}&pageNumber=0&pageSize=100`} optionTitle="persianTitle" optionValue="persianTitle" title="نام حرکت" />
              </section>
            </section>
            <section className="w-[300px] max-w-full">
              <label className="mb-2 mt-4 text-md block">تعداد ست</label>
              <Select
                setState={(value) => setSetCount(value)}
                title="تعداد ست"
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                  { label: 6, value: 6 },
                  { label: 7, value: 7 },
                  { label: 8, value: 8 },
                  { label: 9, value: 9 },
                  { label: 10, value: 10 },
                ]}
              />
            </section>
          </section>
          {setsList.map((item, index) => (
            <Fragment key={index}>
              <SetForm index={index} setsList={setsList} setSetsList={setSetsList} />
            </Fragment>
          ))}
          <section className="grid grid-cols-1 xl:grid-cols-3 gap-4">
            <section className="py-[19px] px-[16px]  h-[157px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
              <section className="flex justify-between">
                <span className="text-[#3E4151]">تنظیمات پیشرفته</span>
                <section className="flex cursor-pointer" onClick={() => setAdvancedSetting(!advancedSetting)}>
                  <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all ${advancedSetting && "bg-purple"}`}>
                    <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
                  </span>
                </section>
              </section>

              <section className="flex justify-between mt-[29px]">
                <section className="flex cursor-pointer" onClick={() => setLevel(1)}>
                  <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all ${level === 1 && "bg-purple"}`}>
                    <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
                  </span>
                  <label className="text-sm font-normal  mr-2">توان به درصد</label>
                </section>
                <section className="flex cursor-pointer" onClick={() => setLevel(2)}>
                  <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all  ${level === 2 && "bg-purple"}`}>
                    <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
                  </span>
                  <label className="text-sm font-normal mr-2">وزن به کیلوگرم</label>
                </section>
              </section>
              <section className="grid grid-cols-2 gap-3 mt-[10px]">
                <input
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setFormData({ tavan: parseInt(value) });
                    } else {
                      e.target.value = value.replace(/\D/g, "");
                    }
                  }}
                  disabled={!advancedSetting || level === 2}
                  placeholder="توان"
                  type="text"
                  className="border  border-[#EAEAEA] rounded-[10px] disabled:opacity-35"
                />
                <input
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setFormData({ weight: parseInt(value) });
                    } else {
                      e.target.value = value.replace(/\D/g, "");
                    }
                  }}
                  disabled={!advancedSetting || level === 1}
                  placeholder="وزن"
                  type="text"
                  className="border border-[#EAEAEA] disabled:opacity-35"
                />
              </section>
            </section>
          </section>
          <section className="col-span-2 flex items-center   h-[157px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
            <span className="w-[72px] text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4">توضیحات</span>
            <textarea placeholder="توضیح خود را بنویسید" onChange={(e) => setFormData({ description: e.target.value })} className="resize-none text-[14px] text-[#3E4151] border-none w-[98%] h-[99%] m-auto"></textarea>
          </section>
          <Button loading={loading} onClick={() => saveEx()} className="bg-purple m-auto mt-[35px] text-white w-full h-[40px] rounded-md text-sm flex justify-center gap-1 items-center">
            ذخیره حرکت
          </Button>
        </>
      )}
    </section>
  );
}
