import { useState } from "react";

import DietTab from "./components/DietTab";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState("BREAKFAST");

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className=" h-auto mt-[40px] xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
        <section className="w-full h-[80px] flex justify-between items-center xl:rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-8 xl:px-[86px]">
          <button onClick={() => setActiveTab("BREAKFAST")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "BREAKFAST" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
            صبحانه
          </button>
          <button onClick={() => setActiveTab("LUNCH")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "LUNCH" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
            ناهار
          </button>
          <button onClick={() => setActiveTab("DINNER")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "DINNER" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
            شام
          </button>
          <button onClick={() => setActiveTab("SNACK_BEFORE_LUNCH")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "SNACK_BEFORE_LUNCH" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
            میان وعده
          </button>
        </section>
        <section className={`${activeTab === "BREAKFAST" ? "block" : "hidden"}`}>
          <DietTab activeTab="BREAKFAST" />
        </section>
        <section className={`${activeTab === "LUNCH" ? "block" : "hidden"}`}>
          <DietTab activeTab="LUNCH" />
        </section>
        <section className={`${activeTab === "DINNER" ? "block" : "hidden"}`}>
          <DietTab activeTab="DINNER" />
        </section>
        <section className={`${activeTab === "SNACK_BEFORE_LUNCH" ? "block" : "hidden"}`}>
          <DietTab activeTab="SNACK_BEFORE_LUNCH" />
        </section>
      </section>
      {/*  <section className=" hidden xl:block h-auto mt-[40px] p-[24px] bg-white rounded-[16px] shadow-md">
        <section className="text-lg font-bold">برنامه غذایی نهایی</section>
        <DietListView />
      </section> */}
    </>
  );
}
