import { useEffect, useRef } from "react";
import "./style.css";

function Index({ focus, className, onChange, value, type, onKeyUp, shape, placeholder, style, disabled }) {
  const txt = useRef();
  useEffect(() => {
    if (focus) {
      txt.current.focus();
    }
  }, []);
  return (
    <>
      <input ref={txt} disabled={disabled} style={style} type={type} value={value} onKeyUp={onKeyUp} onChange={onChange} placeholder={placeholder} className={`${className} border p-2 border-slate-300 min-h-[40px] w-full rounded-md ${shape === "round" && "round"}`} />
    </>
  );
}

export default Index;
