import { useState, useEffect } from "react";
import { AsyncSelect, Button, ComponentLoading } from "../../../../../../../../../components/common";
import api from "../../../../../../../../../api";
import { useAxiosWithToken } from "../../../../../../../../../hooks";
import { svgFileUrl } from "../../../../../../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ packageId, getDiet, onClose, setFoodList, foodList }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [foodCategory, setFoodCategory] = useState();
  const [foods, setFoods] = useState([]);
  const [foodName, setFoodName] = useState("");
  /*  const [selectedFood, setSelectedFood] = useState(null); */
  const [selectedFoodList, setSelectedFoodList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getFoodsList = () => {
    setListLoading(true);
    useAxiosWithToken
      .get(api.foodsNutrition.searchFoodsNutrition + `?categoryName=${foodCategory?.value}&name=${foodName}&pageNo=0&pageSize=1000`)
      .then((res) => {
        setListLoading(false);
        setFoods(res.data.elements);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  const addFoodToList = (food) => {
    let arr = [...selectedFoodList];
    if (arr.some((el) => el.foodNutritionId === food.id)) {
      arr = arr.filter((el) => el.foodNutritionId !== food.id);
    } else {
      arr.push({
        foodProgramPackageId: packageId,
        foodNutritionId: food.id,
        energyKcal: food.energyKcal,
        mainFoodDescription: food?.mainFoodDescription,
      });
    }
    setSelectedFoodList(arr);
  };
  const saveList = () => {
    setFoodList([...foodList, ...selectedFoodList]);
    onClose();
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getFoodsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodCategory, foodName]);
  useEffect(() => {
    console.log(selectedFoodList);
  }, [selectedFoodList]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="">
      <section>
        <label>دسته بندی</label>
        <AsyncSelect className="mt-2" resElements={true} authentication={true} api={api.categories.searchCategories + "?pageSize=20"} optionTitle="name" optionValue="identifier" state={foodCategory} setState={setFoodCategory} />
      </section>
      <section className="min-h-[300px] p-2 border rounded-[10px] border-slate-300 mt-[20px]">
        <section>
          <section className="block border-[1px] rounded-md border-[#e8e8f7] relative h-[38px] mt-[10px] ">
            <input type="text" className="block rounded-md !border-none h-full w-full border !outline-none bg-[white]" onChange={(e) => setFoodName(e.target.value)} />
            <label className="top-[-10px] text-[12px] right-[9px] bg-white px-[5px] absolute">جستجو</label>
          </section>
          <section className="h-[230px] overflow-scroll relative">
            <ComponentLoading loading={listLoading} />
            {foods.length === 0 && !listLoading && <span className="text-[18px] font-bold text-red-500 block text-center p-3">رکوردی یافت نشد</span>}
            {foods.length > 0 &&
              foods.map((item, Index) => (
                <section key={Index} onClick={() => addFoodToList(item)} className={`font-light flex justify-between text-[14px] ${selectedFoodList.some((el) => el.foodNutritionId === item.id) ? `bg-purple text-white` : `bg-transparent text-black`} p-2 cursor-pointer  transition-all`}>
                  <span> {item.mainFoodDescription}</span>
                  <span className={`font-bold text-sm ${selectedFoodList.some((el) => el.foodNutritionId === item.id) ? `text-white` : `text-purple`}`}>
                    <span>{item.energyKcal} کالری</span>
                    <span className="text-[10px] font-normal mr-1">(به ازای هر صد گرم)</span>
                  </span>
                </section>
              ))}
          </section>
        </section>
      </section>

      <section className="flex justify-between items-center mt-3">
        <section className="font-bold text-sm text-purple">موارد انتخاب شده: {selectedFoodList.length}</section>
        <Button loading={loading} disabled={selectedFoodList.length < 1} onClick={saveList} className="h-[40px] w-[150px]">
          <img src={svgFileUrl + "plus-white.svg"} alt="" />
          <span>افزوردن</span>
        </Button>
      </section>
    </section>
  );
}
