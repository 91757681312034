import { baseFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="homeSection5  h-[631px] xl:hidden flex relative flex-col  items-center  xl:mt-[152px]">
        <img
          src={baseFileUrl + "/images/pic-bg-section5.png"}
          className="w-[364px]  mt-[-43px] mr-[7.75px] mb-[110px]"
          alt=""
        />
        <img
          src={baseFileUrl + "/images/planner.png"}
          className="w-[121.83px] h-[119.89px] absolute top-[55px] right-[32.17px]"
          alt=""
        />
        <img
          src={baseFileUrl + "/images/bell.png"}
          className="w-[66px] h-[69px] absolute top-[13px] left-[69px]"
          alt=""
        />
        <img
          src={baseFileUrl + "/images/watercounter.png"}
          className="w-[121.83px] h-[119.89px] absolute top-[190px] right-[32.17px]"
          alt=""
        />
        <img
          src={baseFileUrl + "/images/bmibmr.png"}
          className="w-[121.83px] h-[119.89px] absolute top-[95.78px] left-[41.21px]"
          alt=""
        />
        <img
          src={baseFileUrl + "/images/lbm.png"}
          className="w-[121.83px] h-[119.89px] absolute top-[223px] left-[35.21px]"
          alt=""
        />
        <section className="text-[28px] px-1 text-[#3E4151] font-semibold flex flex-col items-center tracking-tighter ">
          سـلامت سنــج و محاسبه گرها
          <Link to="/tools">
            <Button className=" text-base w-[138px] h-[44px] rounded-full font-semibold   mt-6">
              بزن بریم
            </Button>
          </Link>
        </section>
      </section>
      <section className="homeSection5 w-[95%] mx-auto  h-[540px] hidden xl:flex justify-between items-center">
        <section className="mr-[111px]">
          <section className="text-[60px] font-semibold">
            سـلامت سنــج و
            <br />
            محاسبه گرها
          </section>
          <Link to="/tools">
            <Button className="transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px]  w-[250px] h-[60px] text-xl rounded-full  mt-10 font-bold">
              بزن بریم
            </Button>
          </Link>
        </section>
        <section className="relative">
          <img
            src={baseFileUrl + "/images/bell.png"}
            className="w-[140px] top-[16%] left-[10%] absolute ml-10 "
            alt=""
          />
          <img
            src={baseFileUrl + "/images/planner.png"}
            className="w-[200px] top-[16%] right-[0%] absolute ml-10 hover:rotate-12 transition-all duration-500"
            alt=""
          />
          <img
            src={baseFileUrl + "/images/watercounter.png"}
            className="w-[200px] top-[48%] right-[0%] absolute ml-10 hover:rotate-12 transition-all duration-500"
            alt=""
          />
          <img
            src={baseFileUrl + "/images/bmibmr.png"}
            className="w-[200px] top-[24%] left-[33%] absolute ml-10 hover:rotate-12 transition-all duration-500"
            alt=""
          />
          <img
            src={baseFileUrl + "/images/lbm.png"}
            className="w-[200px] top-[56%] left-[33%] absolute ml-10 hover:rotate-12 transition-all duration-500"
            alt=""
          />
          <img
            src={baseFileUrl + "/images/heart.png"}
            className="w-[658px] h-[550px] ml-10"
            alt=""
          />
        </section>
      </section>
    </>
  );
}
