import { baseFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ firstName, content, display }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section
      className={`${display} xl:w-[640px]  w-full text-[#3E4151] py-[50px] px-10 h-auto xl:h-[219px] rounded-[10px] bg-white shadow-[0px_3px_12px_1px_#00000012]`}
    >
      <section className="flex justify-between">
        <span className="font-bold">{firstName}</span>
        <img
          src={baseFileUrl + "/Rate-secton5-exer.png"}
          alt=""
          className=" w-[160px] h-[20px]"
        />
      </section>
      <p className="mt-6 text-sm leading-[21.7px] text-justify">{content}</p>
    </section>
  );
}
