//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setType, type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid  grid-cols-1 lg:grid-cols-3 mt-3 gap-1">
      <button
        onClick={() => setType("BREAKFAST")}
        className={`border ${
          type === "BREAKFAST"
            ? "bg-purple text-white"
            : "border-silver text-gray"
        } rounded-md py-2`}
      >
        صبحانه
      </button>
      <button
        onClick={() => setType("SNACK_BEFORE_LUNCH")}
        className={`border ${
          type === "SNACK_BEFORE_LUNCH"
            ? "bg-purple text-white"
            : "border-silver text-gray"
        }  rounded-md py-2`}
      >
        میان وعده صبح
      </button>
      <button
        onClick={() => setType("LUNCH")}
        className={`border ${
          type === "LUNCH" ? "bg-purple text-white" : "border-silver text-gray"
        }  rounded-md py-2`}
      >
        ناهار
      </button>
      <button
        onClick={() => setType("SNACK_BEFORE_DINNER")}
        className={`border ${
          type === "SNACK_BEFORE_DINNER"
            ? "bg-purple text-white"
            : "border-silver text-gray"
        }  rounded-md py-2`}
      >
        میان وعده عصر
      </button>
      <button
        onClick={() => setType("DINNER")}
        className={`border ${
          type === "DINNER" ? "bg-purple text-white" : "border-silver text-gray"
        } rounded-md py-2`}
      >
        شام
      </button>
    </section>
  );
}
