import { baseFileUrl } from "../../../../../config";
import { Button } from "../../../../../components/common";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ src, display, price, title }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getPackageInfo = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.packageInfo.getPackageInfo)
      .then((res) => {
        navigation(`/shopping-cart?amount=${res?.data?.amount}&name=${res.data.name}&id=${res.data.id}`);
      })
      .catch((err) => {});
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`${display} group w-full h-auto transition-all xl:h-[160px] flex bg-white overflow-hidden gap-2 xl:gap-4 py-4 rounded-[10px]  xl:rounded-[20px] border-solid `}>
        <img src={baseFileUrl + src} alt="" className="xl:w-[132px] w-[25%] h-auto" />
        <section className="relative">
          <h3 className={` leading-[27.9px] text-[#3E4151] text-sm xl:text-[18px] font-bold`}>{title}</h3>
          <section className=" w-[324px]  mt-1 text-xs xl:text-sm relative text-[#3E4151]">
            <span className="xl:text-[18px] text-sm font-bold text-[#191919] leading-[27.9px] ml-1 ">{price}</span>
            تومان
            <img src={baseFileUrl + "/Rate-star.png"} alt="" className="absolute top-0 left-0 w-[49px] h-[20px]" />
          </section>
          <section className=" group-hover:opacity-0 opacity-100 flex mt-2 items-start gap-[6px]">
            <span className="text-[#5521B4] bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-[150px]">ویدیوکال</span>
            <span className="text-[#5521B4] bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-full">چت</span>
            {/*  <span className="text-[#5521B4] bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-full">تمرین</span>
            <span className="text-[#5521B4] hidden xl:block bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-full">تغذیه</span> */}
          </section>
          <Button onClick={getPackageInfo} loading={loading} className="text-[#5521B4] bg-[#F7F9FE] w-[192px] mr-auto h-[30px] font-bold text-sm rounded-[150px]">
            دریافت رژیم
          </Button>
        </section>
      </section>
    </>
  );
}
