import { imagesFileUrl } from "../../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ number, activeNumber }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex justify-between items-center w-[45px] h-[13px] mt-[2px] relative">
      {activeNumber === number && (
        <img
          alt=""
          src={imagesFileUrl + "icons/waterArrow.png"}
          className="absolute right-[-30px]"
        />
      )}
      <span
        className="w-[28px] h-[3px] rounded-full"
        style={{
          backgroundColor: activeNumber >= number ? "#0097C1" : "#A6A9BD",
        }}
      ></span>
      <span
        className="font-bold text-[14px]"
        style={{ color: activeNumber >= number ? "#0097C1" : "#A6A9BD" }}
      >
        {number}
      </span>
    </section>
  );
}
