import { useState, useEffect } from "react";
import { Checkbox } from "antd";

import api from "../../../../api";
import { useAxiosWithToken } from "../../../../hooks";
import { Button } from "../../../../components/common";
import { useDietStore } from "../../../../store/dashboard/diet";
import { notify } from "../../../../helper";
import { NotifyMessage } from "../../../../enums";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ foodPlanList, type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore((state) => state.dietInfo);
  const reloadDietAllInfo = useDietStore((state) => state.reloadDietAllInfo);
  const month = dietInfo?.month;
  const week = dietInfo?.week;
  const day = dietInfo?.day;
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(0);
  const [currentPackage, setCurrentPackage] = useState({});
  const [loading, setLoading] = useState(false);
  const [programList, setProgramList] = useState([]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  const handleOnChange = (e) => {
    let programs = [...programList];
    if (e.target.checked) {
      programs.push(e.target.value);
      setProgramList(programs);
      // console.log(programList);
    } else if (e.target.checked === false) {
      setProgramList(programs.filter((el) => el.id !== e.target.value.id));
    }
  };
  useEffect(() => {
    console.log("programList", programList);
  }, [programList]);
  const createFoodCategory = () => {
    let params = [];
    for (let i = 0; i < programList.length; i++) {
      setLoading(true);
      params.push({
        consumedGram: programList[i].consumedGram,
        monthEnum: month,
        weekEnum: week,
        dayEnum: day,
        dairyId: programList[i].id,
        typeOfMealEnum: type,
      });
    }

    useAxiosWithToken
      .put(api.foodProgramPackage.createPersonalProgramPackage, params)
      .then((res) => {
        setLoading(false);
        reloadDietAllInfo();

        notify.Success(NotifyMessage.SUCCESS_ACTION);
        document
          .getElementById("dashBox")
          .scrollTo({ top: 0, left: 0, behavior: "smooth" });
      })
      .catch((e) => setLoading(false));
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    // setCurrentPackage(
    //   foodPlanList[activeTab]?.foodProgramPackages[0]?.foodProgramPackageDetails
    // );
    if (foodPlanList) {
      setCurrentPackage({
        description:
          foodPlanList[activeTab]?.foodProgramPackages[0]?.description,
        foodProgramPackageDetails:
          foodPlanList[activeTab]?.foodProgramPackages[0]
            ?.foodProgramPackageDetails,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  useEffect(() => {
    console.log("food", foodPlanList);
  }, [foodPlanList]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full  mt-8 flex flex-col">
      <div className="flex gap-4">
        {foodPlanList && foodPlanList.length > 0
          ? foodPlanList?.map((item, index) => (
              <button
                onClick={() => setActiveTab(index)}
                className={`${
                  activeTab === index
                    ? "bg-purple text-white"
                    : " text-purple border border-purple"
                } px-3 py-1 rounded-full text-sm  `}
              >
                پکیج {index + 1}
              </button>
            ))
          : ""}
      </div>
      {foodPlanList && (
        <>
          <section className=" grid overflow-x-scroll h-[200px]">
            <section className="w-full flex text-sm items-center px-3 justify-between text-purple h-10 mt-6 bg-[#F1E8FC] rounded-lg">
              <div className="w-[20px]"></div>
              <div className="w-[50px] text-center">ردیف</div>
              <div className="w-[100px] text-center">نام غذا</div>
              <div className="w-[70px] text-center">کالری</div>
              <div className="w-[70px] text-center">پروتئین</div>
              <div className="w-[70px] text-center">کربوهیدرات</div>
              <div className="w-[70px] text-center">چربی</div>
              {/*  <div className="w-[70px] text-center">کلسترول</div> */}
            </section>

            {foodPlanList && foodPlanList.length > 0
              ? currentPackage?.foodProgramPackageDetails?.map(
                  (item, index) => (
                    <section className="w-full flex  text-xs px-3 items-center  justify-between   h-10 mt-6 ">
                      <div className="w-[50px]">
                        <Checkbox
                          value={item}
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                        ></Checkbox>
                      </div>
                      <div className="w-[20px] text-center">{index + 1}</div>
                      <div className="w-[100px] text-center">
                        {item.mainFoodDescription}
                      </div>
                      <div className="w-[70px] text-center">
                        {item.energyKcal.toFixed(2)}
                      </div>
                      <div className="w-[70px] text-center">
                        {item.proteinG.toFixed(2)}
                      </div>
                      <div className="w-[70px] text-center">
                        {item.carbohydrateG.toFixed(2)}
                      </div>
                      <div className="w-[70px] text-center">
                        {item.totalFatG.toFixed(2)}
                      </div>

                      {/*  <div className="w-[70px] text-center">{item.sodiumMg}</div> */}
                    </section>
                  )
                )
              : ""}
          </section>
          <h3 className="mt-4 ">توضیحات پزشک:</h3>
          <section className="w-full mt-1 border p-4 border-purple rounded-lg h-auto">
            {currentPackage?.description}
          </section>
          <Button
            loading={loading}
            onClick={createFoodCategory}
            className="w-[150px] text-sm rounded-full mt-4 justify-self-center self-center h-[40px]"
          >
            انجام شد
          </Button>
        </>
      )}
    </section>
  );
}
