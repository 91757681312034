import { useEffect, useState } from "react";
import MaleIcon from "../../../../../../../../assets/images/home/male1.svg";
import FemaleIcon from "../../../../../../../../assets/images/home/female1.svg";
import NumberPicker from "../../../../../../../../components/common/NumberPicker";

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [gender, setGender] = useState(0);
  const [weight, setWeight] = useState(85);
  const [height, setHeight] = useState(165);
  const [age, setAge] = useState(20);
  const [bmr, setBmr] = useState();
  const [result, setResult] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (age === "") {
      setAge(1);
    }
  }, [age]);
  useEffect(() => {
    if (gender === 0) {
      setBmr(height * 6.25 + weight * 10 - age * 5 - 161);
    }
    if (gender === 1) {
      setBmr(height * 6.25 + weight * 10 - age * 5 + 5);
    }
    setResult(false);
  }, [age, height, weight, gender]);
  return (
    <>
      <section className="w-[95%] mx-auto rounded-[15px] px-[30px] py-[32px] bg-[#F7F9FE] h-auto xl:h-[670px] xl:mt-[44px]">
        <span className="font-bold text-lg block text-center">ورودی اطلاعات</span>
        <section className="w-full">
          <span className="block color-[#3E4151] font-normal text-[16px] mr-2">جنسیت شما</span>
          <section className="w-full flex justify-start gap-3 mt-4">
            <section c onClick={() => setGender(0)} className={`xl:w-[117px] w-full h-[48px] border  border-indigo-200 rounded-[20px] px-5 flex justify-between items-center cursor-pointer ${gender === 0 ? "bg-[#ffeefb]" : "bg-white"}`}>
              <img src={FemaleIcon} className="h-[28px] w-[26px]" alt="" />
              <span>خانم</span>
            </section>
            <section onClick={() => setGender(1)} className={`xl:w-[117px] w-full h-[48px] border  border-indigo-200 rounded-[20px] px-5 flex justify-between items-center cursor-pointer ${gender === 1 ? "bg-[#ffeefb]" : "bg-white"}`}>
              <img src={MaleIcon} className="h-[28px] w-[26px]" alt="" />
              <span>آقا</span>
            </section>
          </section>
          <span className="block color-[#3E4151] mt-[24px] font-normal text-[16px] mr-2">سن شما</span>
          <NumberPicker type="age" setValue={setAge} value={age} />
          <span className="block color-[#3E4151] mt-[24px] font-normal text-[16px] mr-2">وزن شما</span>
          <NumberPicker type="weight" setValue={setWeight} value={weight} />
          <span className="block color-[#3E4151] mt-[24px] font-normal text-[16px] mr-2">قد شما</span>
          <NumberPicker type="height" setValue={setHeight} value={height} />
          <section className="mt-10">
            <section className="text-left mt-10">
              <button onClick={() => setResult(true)} className="w-full h-[39px] rounded-full font-bold text-white bg-purple">
                مشاهده نتایج
              </button>
            </section>
          </section>
          {result && (
            <section className="mt-[34px] text-center">
              <span className="font-bold text-lg">BMR شما:</span>
              <span className="font-bold mr-1 text-lg text-[#8C109A]">{Math.floor(bmr)}</span>
            </section>
          )}
        </section>
      </section>
    </>
  );
}
