import { useState, useEffect } from "react";
import { svgFileUrl } from "../../../../../../../../../config";
import { useAxiosWithToken } from "../../../../../../../../../hooks";
import api from "../../../../../../../../../api";

import {
  ComponentLoading,
  Modal,
} from "../../../../../../../../../components/common";
import { notify, objectToQueryString } from "../../../../../../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  packageList,
  setPackageList,
  setFinalPackageList,
  finalPackageList,
  activeTab,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  /* const [selectedPackage, setSelectedPackage] = useState(); */

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getList = () => {
    setLoading(true);
    let params = {
      typeOfMeal: activeTab,
    };
    useAxiosWithToken
      .get(
        api.foodProgramPackage.getPackageList +
          "?" +
          objectToQueryString(params)
      )
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const setPackage = (selectedPackage) => {
    setModalOpen(false);
    let arr = finalPackageList ? [...finalPackageList] : [];
    if (!arr.some((el) => el.packageId === selectedPackage.id)) {
      let foods = [];
      if (selectedPackage) {
        for (
          var i = 0;
          i < selectedPackage?.foodProgramPackageDetailOutputDTO.length;
          i++
        ) {
          const row =
            selectedPackage.foodProgramPackageDetailOutputDTO[i]
              .foodNutritionValuesOutputDto;
          foods.push({
            dairyId: row.id,
            consumedGram: "",
            mainFoodDescription: row.mainFoodDescription,
            energyKcal: row.energyKcal,
          });
        }
        arr.push({
          packageId: selectedPackage.id,
          typeOfMeal: activeTab,
          packageName: selectedPackage.namePackage,
          description: "",
          dairyListWithConsumedInputDTOS: foods,
        });
      }
      setFinalPackageList(arr);
    } else {
      notify.Error("پکیچ تکراری است");
    }
  };

  //
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*  useEffect(() => {
    setModalOpen(false);
    let arr = finalPackageList ? [...finalPackageList] : [];
    if (!arr.some((el) => el.packageId === selectedPackage.id)) {
      let foods = [];
      if (selectedPackage) {
        for (var i = 0; i < selectedPackage?.foodProgramPackageDetailOutputDTO.length; i++) {
          const row = selectedPackage.foodProgramPackageDetailOutputDTO[i].foodNutritionValuesOutputDto;
          foods.push({ dairyId: row.id, consumedGram: "", mainFoodDescription: row.mainFoodDescription, energyKcal: row.energyKcal });
        }
        arr.push({
          packageId: selectedPackage.id,
          typeOfMeal: activeTab,
          packageName: selectedPackage.namePackage,
          description: "",
          dairyListWithConsumedInputDTOS: foods,
        });
      }
      setFinalPackageList(arr);
    }

   
  }, [selectedPackage]); */
  /*   useEffect(() => {
    console.log("packageList22222", packageList);
  }, [packageList]); */

  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-[90%] mx-auto xl:w-full flex justify-between mt-[35px]">
        {/*<section className="flex justify-center items-center xl:w-[270px]">
        <span className="text-sm xl:text-xl">تعداد روز رژیم</span>
        <button className="xl:w-[117px] xl:px-0 px-1 h-[40px] border border-purple text-purple  rounded-full mr-[16px] flex justify-center items-center">24</button>
      </section> */}
        <section></section>
        <button
          onClick={() => setModalOpen(true)}
          className="bg-purple xl:px-0 px-3 text-white xl:w-[200px] h-[40px] mb-5 xl:mb-0 rounded-full text-sm flex justify-center gap-1 items-center"
        >
          <img
            src={svgFileUrl + "plus-white.svg"}
            alt=""
            className="w-4 h-4 xl:w-6 xl:h-6"
          />
          انتخاب از پکیج آماده
        </button>
      </section>
      <Modal
        open={modalOpen}
        width={600}
        title="انتخاب از پکیج آماده"
        onClose={() => setModalOpen(false)}
      >
        <section className="h-[400px] relative overflow-auto p-3 rounded-md border border-slate-300">
          <ComponentLoading loading={loading} />
          {data.map((item, index) => (
            <section
              onClick={() => setPackage(item)}
              className="bg-slate-100 mb-[4px] p-2 rounded-md hover:bg-slate-200 transition-all cursor-pointer"
            >
              <span className="text-[18px] font-bold text-purple">
                {item?.namePackage}
              </span>
              <section className="flex flex-col gap-1 items-start">
                <span className="text-[12px] w-[100px]">لیست غذاها:</span>
                <section>
                  {item?.foodProgramPackageDetailOutputDTO?.map(
                    (item1, index1) => (
                      <span className="text-[12px]" key={index1}>
                        {
                          item1?.foodNutritionValuesOutputDto
                            ?.mainFoodDescription
                        }
                      </span>
                    )
                  )}
                </section>
              </section>
            </section>
          ))}
        </section>
        {/*  <Button loading={loading} className="mt-4 mr-auto w-[100px]">
          ثبت
        </Button> */}
      </Modal>
    </>
  );
}
