import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveNumber, activeNumber }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const increase = () => {
    if (activeNumber < 10) {
      setActiveNumber(activeNumber + 1);
    }
  };
  const decrease = () => {
    if (activeNumber > 1) {
      setActiveNumber(activeNumber - 1);
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[170px] h-[74px] mt-[65px]">
      <section className="flex justify-between items-center w-full">
        <img
          src={imagesFileUrl + "icons/plus.png"}
          className="cursor-pointer"
          onClick={increase}
          alt=""
        />
        <section className="border border-[#D8D8D8] rounded-[8px] w-[56px] h-[44px] text-[18px] font-bold flex justify-center items-center bg-white">
          {activeNumber}
        </section>
        <img
          src={imagesFileUrl + "icons/minus.png"}
          className="cursor-pointer"
          onClick={decrease}
          alt=""
        />
      </section>
      <section className="font-normal w-full text-center mt-[20px]">{`معادل ${
        activeNumber * 200
      } میلی لیتر`}</section>
    </section>
  );
}
