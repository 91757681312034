import React from "react";
import { imagesFileUrl } from "../../../../../../../../config";
import InfoInputBmr from "./InfoInputBmr";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-[1053px] w-[95%] h-auto xl:h-[1544px] px-0 xl:pr-[36px] xl:pl-[33px] py-5 xl:pt-[44px] mt-5 xl:mt-[30px] xl:mb-[44px] mx-auto bg-[#FFFFFF] text-[#3E4151] leading-[24.8px] shadow-[0px_6px_14px_0px_#0000000f] rounded-[10px] grid xl:grid-cols-[510px_426px] justify-between relative">
        <section className="xl:w-full w-[95%]  order-2 xl:order-none mx-auto h-auto  xl:h-[657px] mt-[40px] xl:mt-[12px] flex flex-col gap-[32px] ">
          <section className="w-[95%] mx-auto h-auto xl:h-[400px]">
            <h2 className=" font-extrabold text-lg">BMR یا متابولیسم پایه:</h2>
            <p className="text-justify mt-[20px]">
              BMR یا سوالی که ممکن است ذهن خیلی‌ها را درگیر کنه، اینه که در طول روز برحسب میزان فعالیت روزانه و هفتگی، چه مقدار کالری باید مصرف کنم تا چاق شوم؟ لاغر شوم؟ یا وزنم رو ثابت نگه دارم؟
              <br />
              یا Basal Metabolic Rate یا متابولیسم پایه میزان کالری هست که بدن نیاز داره تا برای انجام فعالیت‌های پایه‌ای خودش مانند راه رفتن یا حتی نفس کشیدن، گردش خون، تولید سلول‌های خونی، جذب و هضم غذا، سنتز پروتئین و انتقال خون بسوزونه.
            </p>
            <section className="grid grid-cols-1  xl:grid-cols-2 gap-3 mt-2">
              <Link to="/meal-plan">
                <Button className="h-[35px] w-full">دریافت برنامه غذایی</Button>
              </Link>
              <Link to="/exercise-plan">
                <Button className="h-[35px] w-full">دریافت برنامه ورزشی</Button>
              </Link>
            </section>
          </section>

          <section className="w-[95%] mx-auto h-auto xl:h-[75px]">
            <h2 className=" font-bold">محاسبه BMR</h2>
            <p className="text-justify">روش محاسبه bmr از فرمول های مختلفی بدست میاد ، از جمله معادله هریس_بندیکت Harris-Benedict</p>
          </section>

          <section className="w-[95%] mx-auto xl:w-[480px] h-auto xl:h-[290px] flex flex-col gap-[24px]">
            <section className="w-full xl:h-[133px] h-auto py-[24px] px-[32px] bg-[#F6FCFE] border border-solid border-[#B7E9F7] rounded-[10px] ">
              <h2 className="font-bold">آقایان</h2>
              <p className="font-normal mt-[10px]">۸۸.۳۶۲ + (x ۱۳.۳۹۷ وزن به کیلوگرم) + (۴.۷۹۹ x قد به سانتی‌متر) – (۵.۶۷۷ x سن به سال)</p>
            </section>
            <section className="w-full h-auto xl:h-[133px] py-[24px] px-[32px] bg-[#FEF4F980] border border-solid border-[#FCD3E6] rounded-[10px] ">
              <h2 className="font-bold">بانوان</h2>
              <p className="font-normal mt-[10px]">۴۴۷.۵۹۳ + (x ۹.۲۴۷ وزن به کیلوگرم) + (۳.۰۹۸ x قد به سانتی متر) – (۴.۳۳۰ x سن به سال)</p>
            </section>
          </section>
        </section>

        <InfoInputBmr />

        <section className="w-[95%] h-auto xl:h-[505px] order-last xl:order-none flex flex-col xl:col-span-full mx-auto mt-[28px] xl:mb-[310px]">
          <h2 className="w-[95%] mx-auto xl:w-full">و میفلین جوئر (Mifflin-st jeor)</h2>

          <section className="xl:w-[984px] w-[95%] h-auto xl:h-[133px] mx-auto mt-[24px] flex flex-col xl:flex-row gap-[24px]">
            <section className="w-full xl:w-[480px] h-auto xl:h-[133px] py-[24px] px-[32px] bg-[#FEF4F980]  border border-solid border-[#FCD3E6] rounded-[10px] ">
              <h2 className="font-bold">بانوان</h2>
              <p className="font-normal mt-[10px]">BMR =(10 × وزن کیلوگرم) +(6.25 × قد سانتی‌متر) - (5 × سن سال) -161</p>
            </section>
            <section className="xl:w-[480px] w-full h-auto xl:h-[133px] py-[24px] px-[32px] bg-[#F6FCFE] border border-solid border-[#B7E9F7] rounded-[10px] ">
              <h2 className="font-bold">آقایان</h2>
              <p className="font-normal mt-[10px]">BMR =(10 × وزن کیلوگرم) +(6.25 × قد سانتی‌متر) - (5 × سن سال) +5</p>
            </section>
          </section>

          <section className="xl:w-[984px] w-[95%] h-auto xl:h-[50px] mx-auto mt-[24px]">البته این نکته رو هم بگم که همه این فرمول ها یک عدد تقریبی به ما میده که برای هرکس میتونه متفات باشه برای همین در اپلیکیشن بادینس اگر شما پک 1 ماهه خریداری کنید ، برنامه غذایی 2 ماهه دریافت می کنید چون در ماه اول در حال آزمون خطای واقعی نرخ متابولیسم هستیم.</section>

          <section className="xl:w-[984px] w-[95%] h-auto xl:h-[225px] mx-auto mt-[24px]">
            <p>چه مواردی در میزان متابولیسم پایه تاثیرگذار است؟</p>

            <ol className="grid grid-cols-2 justify-between xl:grid-cols-1">
              <li>1- سن</li>
              <li>2- جنسیت</li>
              <li>3- استعداد ژنتیکی</li>
              <li>4- نوع رژیم</li>
              <li>5- عضله</li>
              <li>6- مقدار چربی بدن</li>
              <li>7- داروها</li>
              <li>8- برنامه غذایی</li>
            </ol>
          </section>
        </section>

        <img alt="" className="xl:w-[484.65px] w-full h-auto xl:h-[379px] xl:absolute xl:bottom-[116px] xl:left-[104px] order-last" src={imagesFileUrl + "bg-FeedingStyle.png"} />
      </section>
    </>
  );
}
