import { useEffect, useState } from "react";
import Progress from "./components/Progress";
import SpecialSickness from "./components/SpecialSickness";
import CompletionMedical from "./components/CompletionMedical";
import DocumentUpload from "./components/DocumentUpload";
import { useSearchParams } from "react-router-dom";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const requestProgramId = searchParams.get("requestProgramId");
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  const [specialSicknessList, setSpecialSicknessList] = useState([]);
  const [completionMedicalList, setCompletionMedicalList] = useState([]);
  const [finalAnswer, setFinalAnswer] = useState({});
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  /*   useEffect(() => {
    console.log("spcAnswer", specialSicknessList);
  }, [specialSicknessList]);
  useEffect(() => {
    console.log("compAnswer", completionMedicalList);
  }, [completionMedicalList]); */

  useEffect(() => {
    let obj = {
      answerQuestionsInputDTOS: [...completionMedicalList],
      answerDiseasesInputDTOS: [...specialSicknessList],
      requestProgramId: parseInt(requestProgramId),
    };
    setFinalAnswer(obj);
  }, [completionMedicalList, specialSicknessList]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="pt-10 relative">
      <Progress activeStep={activeTab} setStep={setActiveTab} />
      <section className={`${activeTab === 1 ? `block` : `hidden`}`}>
        <SpecialSickness setSpecialSicknessList={setSpecialSicknessList} setActiveTab={setActiveTab} />
      </section>
      <section className={`${activeTab === 2 ? `block` : `hidden`}`}>
        <CompletionMedical finalAnswer={finalAnswer} setCompletionMedicalList={setCompletionMedicalList} setActiveTab={setActiveTab} />
      </section>
      <section className={`${activeTab === 3 ? `block` : `hidden`}`}>
        <DocumentUpload />
      </section>
    </section>
  );
}
