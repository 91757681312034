import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import HOME_LAYOUT from "../layouts/_Home";
import DASHBOARD_LAYOUT from "../layouts/_Dashboard";
import DOCTOR_DASHBOARD_LAYOUT from "../layouts/_DoctorDashboard";
import COACH_DASHBOARD_LAYOUT from "../layouts/_CoachDashboard";
import OTHER_LAYOUT from "../layouts/_Other";
import Home from "../pages/Home";
import ContactUs from "../pages/Contact-us";
import Dashboard from "../pages/Dashboard";
import Recipe from "../pages/Dashboard/Recipe";
import MedicalPrescription from "../pages/Dashboard/MedicalPrescription";
import RecipeDetailDashboard from "../pages/Dashboard/Recipe/RecipeDetail";
import Diet from "../pages/Dashboard/Diet";
import Ticket from "../pages/Dashboard/Ticket";
import AddTicket from "../pages/Dashboard/AddTicket";
import BasicInfoDashboard from "../pages/Dashboard/BasicInfo";
import TicketChat from "../pages/Dashboard/TicketChat";
import DashboardTools from "../pages/Dashboard/Tools";
import ChooseExercise from "../pages/Exercise";
import ExerciseDetail from "../pages/Exercise/ExerciseDetail";
import SingleExercise from "../pages/Exercise/SingleExercise";
import ExerciseCategory from "../pages/Exercise/Category";
import BasicInfo from "../pages/BasicInfo";
import HomeRecipe from "../pages/Recipe";
import RecipeDetail from "../pages/Recipe/RecipeDetail";
import MyServices from "../pages/MyServices";
import MealPlan from "../pages/MealPlan";
import ExercisePlan from "../pages/ExercisePlan";
import Login from "../pages/Login";
import DashboardSingleExercise from "../pages/Dashboard/SingleExercise";
import DashboardExerciseCategory from "../pages/Dashboard/ExerciseCategory";
import Tools from "../pages/Tools";
import WithoutPlanUser from "../pages/DoctorDashboard/WithoutPlanUser";
import WithoutPlanUser1 from "../pages/CoachDashboard/WithoutPlanUser";
import DoctorAdditionalInfo from "../pages/DoctorDashboard/AdditionalInfo";
import CoachAdditionalInfo from "../pages/CoachDashboard/AdditionalInfo";
import DoctorProfile from "../pages/DoctorProfile";
import CoachProfile from "../pages/CoachProfile-static";
import Team from "../pages/Team";
import FoodAdminList from "../pages/Dashboard/FoodAdminList";
import ExerciseQuestion from "../pages/Questionnaire/ExerciseQuestion";
import SicknessQuestion from "../pages/Questionnaire/SicknessQuestion";
import DoctorFoodPackage from "../pages/DoctorDashboard/FoodPackage";
import Payment from "../pages/Payment";
import ShoppingCart from "../pages/ShoppingCart";
import DatePicker from "../pages/DatePicker";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const dashboard = document.getElementById("dashBox");
    if (dashboard) {
      document.getElementById("dashBox").scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <Routes>
        <Route path="/" element={<HOME_LAYOUT />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/recipe" element={<OTHER_LAYOUT />}>
          <Route index element={<HomeRecipe />} />
          <Route path="/recipe/:id" element={<RecipeDetail />} />
        </Route>
        <Route path="/datePicker" element={<OTHER_LAYOUT />}>
          <Route index element={<DatePicker />} />
        </Route>
        <Route path="/dashboard" element={<DASHBOARD_LAYOUT />}>
          <Route
            index
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/recipe"
            element={
              <PrivateRoute>
                <Recipe />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/exercise-category"
            element={
              <PrivateRoute>
                <DashboardExerciseCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/single-exercise"
            element={
              <PrivateRoute>
                <DashboardSingleExercise />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/recipe/:id"
            element={
              <PrivateRoute>
                <RecipeDetailDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/ticket"
            element={
              <PrivateRoute>
                <Ticket />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/food-admin-list"
            element={
              <PrivateRoute>
                <FoodAdminList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/addticket"
            element={
              <PrivateRoute>
                <AddTicket />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/ticketchat/:id"
            element={
              <PrivateRoute>
                <TicketChat />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/diet"
            element={
              <PrivateRoute>
                <Diet />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/tools"
            element={
              <PrivateRoute>
                <DashboardTools />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/basicinfo"
            element={
              <PrivateRoute>
                <BasicInfoDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/medical-prescription"
            element={
              <PrivateRoute>
                <MedicalPrescription />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/doctor-dashboard" element={<DOCTOR_DASHBOARD_LAYOUT />}>
          <Route path="/doctor-dashboard/without-plan-user" element={<WithoutPlanUser />} />
          <Route path="/doctor-dashboard/additional-info" element={<DoctorAdditionalInfo />} />
          <Route path="/doctor-dashboard/food-package" element={<DoctorFoodPackage />} />
        </Route>
        <Route path="/coach-dashboard" element={<COACH_DASHBOARD_LAYOUT />}>
          <Route path="/coach-dashboard/additional-info" element={<CoachAdditionalInfo />} />
          <Route path="/coach-dashboard/without-plan-user" element={<WithoutPlanUser1 />} />
        </Route>
        <Route path="/exercise" element={<OTHER_LAYOUT />}>
          <Route path="/exercise/choose-exercise" element={<ChooseExercise />} />
          <Route path="/exercise/exercise-detail" element={<ExerciseDetail />} />
          <Route path="/exercise/single-exercise" element={<SingleExercise />} />
          <Route path="/exercise/category" element={<ExerciseCategory />} />
        </Route>
        <Route path="/basicInfo" element={<OTHER_LAYOUT />}>
          <Route
            index
            element={
              <PrivateRoute>
                <BasicInfo />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/login">
          <Route index element={<Login />} />
        </Route>
        <Route path="/contact-us" element={<OTHER_LAYOUT />}>
          <Route index element={<ContactUs />} />
        </Route>
        <Route path="/doctor-profile" element={<OTHER_LAYOUT />}>
          <Route index element={<DoctorProfile />} />
        </Route>
        <Route path="/coach-profile/:id" element={<OTHER_LAYOUT />}>
          <Route index element={<CoachProfile />} />
        </Route>
        <Route path="/tools" element={<OTHER_LAYOUT />}>
          <Route index element={<Tools />} />
        </Route>
        <Route path="/my-services" element={<OTHER_LAYOUT />}>
          <Route index element={<MyServices />} />
        </Route>
        <Route path="/meal-plan" element={<OTHER_LAYOUT />}>
          <Route index element={<MealPlan />} />
        </Route>
        <Route path="/exercise-plan" element={<OTHER_LAYOUT />}>
          <Route index element={<ExercisePlan />} />
        </Route>
        <Route path="/exercise-question" element={<OTHER_LAYOUT />}>
          <Route index element={<ExerciseQuestion />} />
        </Route>
        <Route path="/sickness-question" element={<OTHER_LAYOUT />}>
          <Route index element={<SicknessQuestion />} />
        </Route>
        <Route path="/team-us" element={<OTHER_LAYOUT />}>
          <Route index element={<Team />} />
        </Route>
        <Route path="/callback/payment" element={<OTHER_LAYOUT />}>
          <Route index element={<Payment />} />
        </Route>
        <Route path="/shopping-cart" element={<OTHER_LAYOUT />}>
          <Route index element={<ShoppingCart />} />
        </Route>
      </Routes>
    </>
  );
}
