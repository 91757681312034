import { useEffect, useState } from "react";
import MaleIcon from "../../../../../../assets/images/home/male1.svg";
import FemaleIcon from "../../../../../../assets/images/home/female1.svg";
import WeightPicker from "../components/WeightPicker";
import HeightPicker from "../components/HeightPicker";
export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [gender, setGender] = useState(0);
  const [weight, setWeight] = useState(85);
  const [height, setHeight] = useState(165);
  const [boer, setBoer] = useState();
  const [james, setJames] = useState();
  const [hume, setHume] = useState();
  const [result, setResult] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setResult(false);
    if (gender === 1) {
      setBoer(0.407 * weight + 0.267 * height - 19.2);
      setJames(1.1 * weight - 128 * Math.pow(weight / height, 2));
      setHume(0.3281 * weight + 0.33929 * height - 29.5336);
    } else if (gender === 0) {
      setBoer(0.252 * weight + 0.473 * height - 48.3);
      setJames(1.07 * weight - 148 * Math.pow(weight / height, 2));
      setHume(0.29569 * weight + 0.41813 * height - 43.2933);
    }
  }, [gender, weight, height]);
  return (
    <>
      <section className="w-[90%] xl:w-full rounded-[15px] px-[30px] py-[32px] bg-[#F7F9FE] min-h-[611px] xl:h-[611px] mx-auto mt-5 xl:mt-[44px]">
        <span className="font-bold text-lg block text-center">ورودی اطلاعات</span>
        <section className="w-full h-[85px]">
          <span className="block color-[#3E4151] font-normal text-[16px] mr-2">جنسیت شما</span>
          <section className="w-full flex justify-start gap-3 mt-4">
            <section c onClick={() => setGender(0)} className={`w-[117px] h-[48px] border  border-indigo-200 rounded-[20px] px-5 flex justify-between items-center cursor-pointer ${gender === 0 ? "bg-[#ffeefb]" : "bg-white"}`}>
              <img src={FemaleIcon} className="h-[28px] w-[26px]" alt="" />
              <span>خانم</span>
            </section>
            <section onClick={() => setGender(1)} className={`w-[117px] h-[48px] border  border-indigo-200 rounded-[20px] px-5 flex justify-between items-center cursor-pointer ${gender === 1 ? "bg-[#ffeefb]" : "bg-white"}`}>
              <img src={MaleIcon} className="h-[28px] w-[26px]" alt="" />
              <span>آقا</span>
            </section>
          </section>
          <section className="mt-[24px]">
            <span className="block color-[#3E4151] font-normal text-[16px] mr-2">قد شما</span>
            <HeightPicker height={height} setHeight={setHeight} />
          </section>
          <section className="mt-[24px]">
            <span className="block color-[#3E4151] font-normal text-[16px] mr-2">وزن شما</span>
            <WeightPicker weight={weight} setWeight={setWeight} />
          </section>

          <section className="mt-10">
            <section className="text-left mt-10">
              <button onClick={() => setResult(true)} className="w-full h-[39px] rounded-full font-bold text-white bg-purple">
                مشاهده نتایج
              </button>
            </section>
            {result && (
              <section className=" mt-[34px] justify-end ">
                <table className="w-full">
                  <thead>
                    <tr>
                      {/*    <th className="bg-[#F67CB5] text-white p-2 rounded-tr-2xl ">
                  body fat
                </th> */}
                      <th className="bg-[#F67CB5] text-white p-2 border-r border-white rounded-tr-2xl">lean body mass</th>
                      <th className="bg-[#F67CB5] text-white p-2 rounded-tl-2xl border-r border-white">formula</th>
                    </tr>
                  </thead>
                  <tbody className="text-center text-[14px]">
                    <tr className="bg-white">
                      {/* <td>20%</td> */}
                      <td className="border-r border-[#FFF2EE]">{Math.floor(boer)}</td>
                      <td className="border-r border-[#F67CB5]">Boer</td>
                    </tr>

                    <tr className="bg-[#FEEAF3]">
                      {/* <td>19%</td> */}
                      <td className="border-r border-[#FFF2EE]">{Math.floor(james)}</td>
                      <td className="border-r border-[#F67CB5]">James</td>
                    </tr>
                    <tr className="bg-white">
                      {/* <td>25%</td> */}
                      <td className="border-r border-[#FFF2EE]">{Math.floor(hume)}</td>
                      <td className="border-r border-[#F67CB5]">hume</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
          </section>
        </section>
      </section>
    </>
  );
}
