import React from "react";
import { imagesFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[95%] mx-auto relative flex border border-slate-200 bg-gradient-to-b from-[#F9F5FE] to-[#FFFFFF] mt-[30px]  xl:mt-[110px] h-[600px] xl:h-[527px] overflow-hidden rounded-[50px]">
        <section>
          <img
            className="w-[200px] xl:w-auto xl:h-[416px] absolute top-[250px] xl:top-[-80px] xl:right-[200px]"
            src={imagesFileUrl + "section2dbm.png"}
            alt=""
          />
          <img
            className="h-[100px] xl:h-[200px] absolute top-[370px] right-[-20px] xl:top-[100px] xl:right-[100px]"
            src={imagesFileUrl + "section2watch.png"}
            alt=""
          />
          <img
            className="absolute w-[160px] left-[2%] bottom-[20%] xl:w-[482px] xl:bottom-[0] xl:right-[500px]"
            src={imagesFileUrl + "section2bgdmb.png"}
            alt=""
          />
          <section className="absolute h-[75px] xl:h-auto bottom-[80px] xl:bottom-[80px] w-[90%] right-5 xl:w-[535px] xl:right-[100px] z-20">
            <section className="flex xl:justify-between gap-5 items-center">
              <span className="text-[37px] xl:text-[60px] font-bold">
                فیتنس
              </span>
              <Link to="/exercise-plan" className=" ">
                <Button className="flex xl:hidden  w-[140px] h-[44px] ml-[0px] rounded-[150px]">
                  بزن بریم
                </Button>
              </Link>
            </section>

            <section className="flex flex-col xl:gap-4 justify-center ">
              <span className="font-normal mt-4 text-[14px] xl:w-[391px] xl:text-[22px]">
                آماده ای مربیتو پیدا کنی و یه برنامه حرفه ای شروع کنی؟
              </span>
              <Link to="/exercise-plan" className="">
                <Button className=" xl:flex hidden transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px]  w-[140px] h-[44px] ml-auto rounded-[150px]">
                  بزن بریم
                </Button>
              </Link>
            </section>
          </section>
        </section>
        <section className="absolute left-0">
          <section className="absolute text-right block w-[65%] left-5 xl:w-[60%] xl:right-[200px] top-[40px]">
            <span className="block text-[30px] xl:text-[60px] font-bold">
              برنامه غذایی
            </span>
            <span className="block text-base xl:text-[22px] text-right font-normal">
              برنامه غذایی دقیق و شخصی سازی شده ،فقط برای تو ،همین حالا!
            </span>
            <Link to="/meal-plan" className=" ">
              <Button
                secondary={true}
                className="w-[140px] transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px] h-[44px] mt-2 rounded-[150px] xl:mt-[56px] "
              >
                بزن بریم
              </Button>
            </Link>
          </section>
          <img
            className="h-[577px] hidden xl:block"
            src={imagesFileUrl + "section2vec.png"}
            alt=""
          />
          <img
            className="w-[364px] xl:hidden"
            src={imagesFileUrl + "section2mobilevec.png"}
            alt=""
          />
          <img
            className="w-[150px] xl:w-[300px] absolute left-0 top-[200px] xl:left-[100px] bottom-[-50px]"
            src={imagesFileUrl + "section2salad.png"}
            alt=""
          />
        </section>
      </section>
    </>
  );
}
