import { imagesFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  src,
  setActiveSection,
  activeSection,
  index,
  title,
  text,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        className={`${
          activeSection === index
            ? "border-[#F9A7CE] shadow-[0px_20.5px_44.42px_-11.96px_#5408A947] "
            : "border-[#BB9CDD]"
        } w-[257.75px] flex flex-col items-center justify-between xl:mt-8 h-full border-[1.38px]  px-6 py-[40px] rounded-[20px] border-solid `}
      >
        <section
          className={`${
            activeSection === index ? "bg-[#FEEAF3]" : "bg-[#F1E8FC]"
          } bg-opacity-40 flex justify-center items-center w-[138px] h-[138px] rounded-full`}
        >
          <section
            className={`${
              activeSection === index ? "bg-[#FEEAF3]" : "bg-[#F1E8FC]"
            } flex justify-center items-center w-[114px] h-[114px] rounded-full`}
          >
            <img src={imagesFileUrl + src} alt="" className="" />
          </section>
        </section>
        <h3
          className={`${
            activeSection === index ? "text-[#F02484]  " : "text-[#5408A9]"
          } leading-[34.1px] text-[22px] font-bold`}
        >
          {title}
        </h3>
        <p className="text-sm leading-[21.7px] text-[#3E4151] text-center">
          {text}
        </p>
      </section>
    </>
  );
}
