import { useEffect, useState } from "react";
import Step from "../../../assets/images/basicInfo/step78910L.png";
import Progress from "../components/Progress";
import ActivitySwiper from "../components/ActivitySwiper";
import SwiperInfo from "../components/SwiperInfo";
import ActivitySelector from "../components/ActivitySelector";
import "swiper/css";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, defaultValue, setActivity, activity }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeSlide, setActiveSlide] = useState(defaultValue ? defaultValue : 1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const setMainActivity = (value) => {
    setActivity(value);
    setActiveSlide(value);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <section className="h-[78%] flex flex-col justify-between xl:block">
        <section>
          <span className="block text-center font-bold text-xl xl:text-3xl mt-7">میزان فعالیت شما چقدره؟</span>
          <section className="swiperWrapper">
            <ActivitySwiper setActiveSlide={setMainActivity} setStep={setStep} activeSlide={defaultValue} />
            <SwiperInfo activeSlide={activeSlide} />
            <ActivitySelector
              setState={setMainActivity}
              /*  state={activity} */
            />
          </section>
        </section>
        <button onClick={() => setStep(7)} className="text-[20px] bg-purple  xl:m-auto xl:mt-10 block p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl">
          مرحله بعد
        </button>
      </section>
    </>
  );
}
