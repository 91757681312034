import { useState } from "react";
import Filter from "./components/Filter";
import PackageTab from "./components/PackageTab";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState("BREAKFAST");
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-auto mt-[40px] xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
      <Filter activeTab={activeTab} setActiveTab={setActiveTab} />
      <section className={`${activeTab === "BREAKFAST" ? "block" : "hidden"}`}>
        <PackageTab activeTab="BREAKFAST" />
      </section>
      <section className={`${activeTab === "LUNCH" ? "block" : "hidden"}`}>
        <PackageTab activeTab="LUNCH" />
      </section>
      <section className={`${activeTab === "DINNER" ? "block" : "hidden"}`}>
        <PackageTab activeTab="DINNER" />
      </section>
      <section
        className={`${activeTab === "SNACK_BEFORE_LUNCH" ? "block" : "hidden"}`}
      >
        <PackageTab activeTab="SNACK_BEFORE_LUNCH" />
      </section>
    </section>
  );
}
