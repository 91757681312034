import { useState, useEffect } from "react";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { notify, objectToQueryString } from "../../../../../helper";
import FoodList from "./components/List";
import {
  Button,
  ComponentLoading,
  Modal,
} from "../../../../../components/common";

import { NotifyMessage } from "../../../../../enums";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState("BREAKFAST");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  // ─── Functions ───────────────────────────────────────────────
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getPackage = () => {
    let params = {
      typeOfMeal: activeTab,
    };
    setLoading(true);
    useAxiosWithToken
      .get(
        api.foodProgramPackage.getPackageList +
          "?" +
          objectToQueryString(params)
      )
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((res) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  const showDeleteModal = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };
  const deleteFood = () => {
    setDeleteLoading(true);
    useAxiosWithToken
      .delete(api.foodProgramPackage.deleteFoodProgramPackage + deleteId)
      .then((res) => {
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setDeleteModal(false);
        setDeleteLoading(false);
        getPackage();
      })
      .catch((err) => {
        setDeleteLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-auto mt-[40px] xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
      <section className="w-full h-[80px] mb-8 flex justify-between items-center xl:rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-8 xl:px-[86px]">
        <button
          onClick={() => setActiveTab("BREAKFAST")}
          className={`w-[200px] h-[48px] rounded-full ${
            activeTab === "BREAKFAST"
              ? "bg-[#F1E8FC] text-purple"
              : "text-[#3E4151]"
          } font-semibold text-sm xl:text-xl`}
        >
          صبحانه
        </button>
        <button
          onClick={() => setActiveTab("LUNCH")}
          className={`w-[200px] h-[48px] rounded-full ${
            activeTab === "LUNCH"
              ? "bg-[#F1E8FC] text-purple"
              : "text-[#3E4151]"
          } font-semibold text-sm xl:text-xl`}
        >
          ناهار
        </button>
        <button
          onClick={() => setActiveTab("DINNER")}
          className={`w-[200px] h-[48px] rounded-full ${
            activeTab === "DINNER"
              ? "bg-[#F1E8FC] text-purple"
              : "text-[#3E4151]"
          } font-semibold text-sm xl:text-xl`}
        >
          شام
        </button>
        <button
          onClick={() => setActiveTab("SNACK_BEFORE_LUNCH")}
          className={`w-[200px] h-[48px] rounded-full ${
            activeTab === "SNACK_BEFORE_LUNCH"
              ? "bg-[#F1E8FC] text-purple"
              : "text-[#3E4151]"
          } font-semibold text-sm xl:text-xl`}
        >
          میان وعده
        </button>
      </section>
      <section className="relative">
        <ComponentLoading loading={loading} />
        <FoodList showDeleteModal={showDeleteModal} data={data} />
      </section>

      <Modal
        title="حذف غذا"
        width={400}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <section className="text-center mt-5">
          آیا مایلید پکیج مورد نظر را حذف کنید؟
        </section>
        <section className="flex gap-2 justify-center mt-5">
          <Button onClick={() => setDeleteModal(false)} outlined>
            خیر
          </Button>
          <Button
            loading={deleteLoading}
            onClick={deleteFood}
            className="w-[120px]"
          >
            بله
          </Button>
        </section>
      </Modal>
    </section>
  );
}
