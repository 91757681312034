import { useState, useEffect } from "react";
import Select from "react-select";
import Step from "../../../assets/images/basicInfo/step1L.png";
import Progress from "../components/Progress";
import moment from "moment-jalaali";
import Textbox from "../../../components/common/inputs/Textbox";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  setStep,
  activeStep,
  setBirthDate,
  setFname,
  setLname,
  fName,
  lName,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  const monthes = [
    { value: 1, label: "فروردین" },
    { value: 2, label: "اردیبهشت" },
    { value: 3, label: "خرداد" },
    { value: 4, label: "تیر" },
    { value: 5, label: "مرداد" },
    { value: 6, label: "شهریور" },
    { value: 7, label: "مهر" },
    { value: 8, label: "آبان" },
    { value: 9, label: "آذر" },
    { value: 10, label: "دی" },
    { value: 11, label: "بهمن" },
    { value: 12, label: "اسفند" },
  ];

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [year, setYear] = useState();
  const [years, setYears] = useState([]);
  const [day, setDay] = useState("");
  const [days, setDays] = useState([]);
  const [month, setMonth] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  /*  const [fName, setFname] = useState();
   const [lName, setLname] = useState(); */

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    let myYears = [];
    let myDays = [];
    for (let i = 1333; i <= 1391; i++) {
      myYears.push({ value: i, label: String(i) });
    }
    setYears(myYears);
    for (let i = 1; i <= 31; i++) {
      myDays.push({ value: i, label: String(i) });
    }
    setDays(myDays);
  }, []);
  useEffect(() => {
    let mdate =
      String(year?.value) +
      "-" +
      String(month?.value) +
      "-" +
      String(day?.value);
    let m = moment(mdate, "jYYYY/jM/jD").format("YYYY-MM-DD");
    setBirthDate(m);
    if (year && month && day && fName && lName) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, day, month, fName, lName]);

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <section className="flex flex-col justify-between h-[78%]">
        <section>
          <span className="block text-center font-bold text-xl xl:text-3xl mt-7">
            اطلاعات خودتو وارد کن
          </span>
          <section className="w-[600px] max-w-[70%] m-auto mt-10">
            <section className="grid xl:grid-cols-2 gap-4 text-center">
              <section>
                <Textbox
                  onChange={(e) => setFname(e.target.value)}
                  value={fName}
                  focus={true}
                  shape="round"
                  placeholder="نام خود را وارد کنید"
                />
              </section>
              <section>
                <Textbox
                  onChange={(e) => setLname(e.target.value)}
                  value={lName}
                  shape="round"
                  placeholder="نام خانوادگی خود را وارد کنید"
                />
              </section>
            </section>
            <section className="grid xl:grid-cols-3 gap-4 text-center mt-5">
              <Select
                value={day}
                onChange={setDay}
                isSearchable={false}
                loadingMessage={() => <span>در حال بارگزاری...</span>}
                noOptionsMessage={() => <span>اطلاعاتی موجود نیست</span>}
                options={days}
                placeholder="روز"
              />
              <Select
                value={month}
                onChange={setMonth}
                isSearchable={false}
                loadingMessage={() => <span>در حال بارگزاری...</span>}
                noOptionsMessage={() => <span>اطلاعاتی موجود نیست</span>}
                options={monthes}
                placeholder="ماه"
              />
              <Select
                value={year}
                onChange={setYear}
                isSearchable={false}
                loadingMessage={() => <span>در حال بارگزاری...</span>}
                noOptionsMessage={() => <span>اطلاعاتی موجود نیست</span>}
                options={years}
                placeholder="سال"
              />
            </section>
          </section>
        </section>
        <button
          disabled={btnDisable}
          className="text-[20px] bg-purple xl:m-auto xl:mt-10  p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl mt-10"
          onClick={() => setStep(3)}
        >
          مرحله بعد
        </button>
      </section>
    </>
  );
}
