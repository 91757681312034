import { baseFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ onClick, title, index, activeSection }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {activeSection === 1 && (
        <p className="xl:leading-[21.7px]  leading-5 text-xs text-justify xl:text-sm ">
          الگوی تغذیه اطراف دریای مدیترانه سرشار از میوه ها و سبزیجات، غلات،
          غذاهای دریایی ، آجیل و حبوبات و روغن زیتون است. که غنی از آنتی اکسیدان
          ها و ضد التهاب است. در رژیم مدیترانه ای ، شما گوشت قرمز ، غذاهای شیرین
          و لبنیات (البته بجز مقدار کمی ماست و پنیر)را محدود کرده و یا حذف می
          کنید. خیلی کم : گوشت و شیرینی / هفتگی : مرغ،تخم مرغ ،پنیر و ماست /
          غالبا : 2 تا 3 بار در هفته ماهی و غذاهای دریایی هر روز: وعده های غذایی
          گندوم کامل ، سبزیجات، آجیل ، میوه ها، حبوبات، روغن زیتون ، لوبیا
        </p>
      )}

      {activeSection === 3 && (
        <p className="xl:leading-[21.7px]  leading-5 text-xs text-justify xl:text-sm ">
          ریشه این نوع رژیم به روزه داری سنتی (مسیح،یهود) و روزه داری متداول
          مسلمانان برمیگردد که بهبود سلامت بدن یا مزایای عرفانی از آن استفاده می
          شود. رژیم جنگجو: محدوده ناشتایی 20 ساعت همراه با مصرف میوه، سبزیجات و
          مایعات بدون قند روزه داری 16/8: 16 ساعت عدم مصرف غذا بجز مایعات مجاز و
          8 ساعت مصرف غذا با حفط کسر کالری روزه داری بدون ساختار: کنارگذاشتن
          وعده غذایی در زمان دلخواه رژیم 5 به 2 : مصرف غذا 5 روز در هفته بصورت
          عادی اما سالم خوری! 2 روز باقیمانده روزه داری با مصرف 250 تا 300 کالری
          در روز روزه داری چرخشی: همانند 5 به 2 هست، اما بصورت یک روز در میان
          روزه داری انجام می شود. البته با انعطاف بیشتر در دریافت کالری
        </p>
      )}
      {activeSection === 4 && (
        <p className="xl:leading-[21.7px]  leading-5 text-xs text-justify xl:text-sm ">
          این روزها صحبت از رژیم کتو خیلی زیاده،بریم بررسی کنیم انتخاب این رژیم
          کار درستیه؟کربوهیدرات به شدت کم و چربی زیاد این مدل رژیم غذایی بدن شما
          رو در حالتی از متابولیسم ، بنام کتوسیس قرار میده. کتوسیس با کاهش
          کربوهیدرات در بدن، هورمون انسولین گلوکوز کافی برای سوزوندن و تامین
          انرژی بدن نداره. در این حالت کبد شما وارد عمل میشه تا از یک سوخت
          جایگزین استفاده کنه و اسیدی بنام کتونز ترشح میکنه. تولید اون باعث میشه
          تا به جای گلوکز ، چربی بدن شما بسوزه و به انرزی تبدیل بشه.به همین علت
          در این رژیم از چربی های زیاد استفاده میشه. انواع رژیم کتو : کتوژنیک
          استاندارد کتوژنیک هدفمند کتوژنیک دوره ای کتوژنیک پروتئین بالا
        </p>
      )}
      {activeSection === 5 && (
        <p className="xl:leading-[21.7px]  leading-5 text-xs text-justify xl:text-sm ">
          نتایج پژوهش های زیادی به مقایسه رژیم پالئو و دیگر برنامه های غذایی از
          جمله رژیم مدیترانه ای یا رژیم دیابت پرداخته اند. این پژوهش ها نشان
          دادند رزیم پالئو در مقایسه با رژیم میوه ،سبزیجات ، گوشت بدون چربی
          ،غلات کامل،حبوبات و لبنیات کم چرب مزایایی دارد که عبارتند از : کاهش
          وزن بیشتر ،بهبود تحمل گلوکز ،کنترل بهتر فشار خون ، کاهش چربی خون،کنترل
          بهتر اشتها. با این حال برای بررسی تاثیر بلند مدت رژیم پالئو روی سلامت
          به پژوهش ها و افراد بیشتری نیاز است.
        </p>
      )}
      {activeSection === 6 && (
        <p className="xl:leading-[21.7px]  leading-5 text-xs text-justify xl:text-sm ">
          کربوسایکلینگ به معنی خوردن کربوهیدرات بیشتر در روزهای خاص، که می تواند
          یکی از بهترین برنامه های رژیمی برای کاهش وزن و ساخت عضله به شمار رود،
          چرا که متابولیسم (سوخت و ساز) بدن را به بهترین نحو تحریک می کند. از
          مزایای این رژیم می توان به کمک به جلوگیری از عدم تعادل هورمونی ،
          جلوگیری ار افت سیستم ایمنی ، تنوع غذاهای سالم و دوری از رزیم زدگی و
          جلوگیری از پایین آمدن سوخت و ساز پایه اشاره کرد.
        </p>
      )}
      {activeSection === 7 && (
        <p className="xl:leading-[21.7px]  leading-5 text-xs text-justify xl:text-sm ">
          نتایج پژوهش های زیادی به مقایسه رژیم پالئو و دیگر برنامه های غذایی از
          جمله رژیم مدیترانه ای یا رژیم دیابت پرداخته اند. این پژوهش ها نشان
          دادند رزیم پالئو در مقایسه با رژیم میوه ،سبزیجات ، گوشت بدون چربی
          ،غلات کامل،حبوبات و لبنیات کم چرب مزایایی دارد که عبارتند از : کاهش
          وزن بیشتر ،بهبود تحمل گلوکز ،کنترل بهتر فشار خون ، کاهش چربی خون،کنترل
          بهتر اشتها. با این حال برای بررسی تاثیر بلند مدت رژیم پالئو روی سلامت
          به پژوهش ها و افراد بیشتری نیاز است.
        </p>
      )}
    </>
  );
}
