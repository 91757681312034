import { useState, useEffect } from "react";
import { imagesFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeSection, setActiveSection] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log(activeSection);
  }, [activeSection]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-full w-[1124px] mx-auto flex justify-between mt-8 relative h-auto xl:h-[384px]">
        <div className="w-8 h-8 xl:flex justify-center items-center rounded-full bg-[#0000001F] hidden absolute -top-[20%] xl:top-[50%] xl:-right-[23px] active:bg-[#F67CB5]">
          <img
            src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
            alt=""
            className="w-[8px] h-auto"
          />
        </div>
        <section
          onClick={() => {
            setActiveSection(1);
          }}
        >
          <Item
            activeSection={activeSection}
            index={1}
            src="/wIcon.svg"
            title="کالری شمار روزانه"
            text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
          />
        </section>
        <section
          onClick={() => {
            setActiveSection(2);
          }}
        >
          <Item
            title="برنامه ورزشی"
            activeSection={activeSection}
            index={2}
            src="/dumbbell.svg"
            text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
          />
        </section>

        <section
          onClick={() => {
            setActiveSection(3);
          }}
        >
          <Item
            title="رژیم غذایی"
            activeSection={activeSection}
            index={3}
            src="/diet.svg"
            text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
          />
        </section>
        <section
          onClick={() => {
            setActiveSection(4);
          }}
        >
          <Item
            title="تعیین هدف"
            activeSection={activeSection}
            index={4}
            src="/target.svg"
            text="محاسبه و دریافت کالری مناسب طی روز اصلی ترین روش برای تناسب اندام در دنیاست. البته در کنار آن توجه به میزان دریافت درشت مغذی ها بسیار اهمیت دارد."
          />
        </section>
        <div className="w-8 h-8 hidden xl:flex justify-center items-center rounded-full bg-[#0000001F] absolute xl:top-[50%] xl:-left-[23px] active:bg-[#F67CB5]">
          <img
            src={imagesFileUrl + "/svg/Icon-arrow-r.svg"}
            alt=""
            className="w-[8px] h-auto rotate-180"
          />
        </div>
      </section>
    </>
  );
}
