import { useState, useEffect } from "react";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  title,
  exerciseCapacity,
  setExerciseCapacity,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [day, setDay] = useState("");
  const [hour, setHour] = useState("");
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setExerciseCapacity({ ...exerciseCapacity, description: `${day}&${hour}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, hour]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full">
        <section className="w-full text-[#3E4151] text-xs text-right xl:text-[20px] font-semibold ">
          <span className="text-red-600 ml-2">*</span>
          <span>{title}</span>
        </section>
        <section className="flex gap-[15px] w-full mt-[25px]">
          <section className="w-full">
            <span>روز</span>
            <select
              onChange={(e) => setDay(e.target.value)}
              className="w-full border border-[#DDCEEE] text-xs xl:text-[16px]  outline-none focus:ring-[#A347F1]   h-[40px] xl:h-[56px]  appearance-none  focus:border-[#A347F1] px-6 rounded-xl"
            >
              <option value="">انتخاب کنید...</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </section>
          <section className="w-full">
            <span>ساعت</span>
            <select
              onChange={(e) => setHour(e.target.value)}
              className="border border-[#DDCEEE] text-xs xl:text-[16px] bg-white focus:ring-[#A347F1] appearance-none outline-none w-full h-[40px] xl:h-[56px]   focus:border-[#A347F1] px-3 xl:px-6  rounded-xl"
            >
              <option value="">انتخاب کنید...</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </section>
        </section>
      </section>
    </>
  );
}
