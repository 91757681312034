import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // const [width, height] = useWindowSize();
  // const [imageWidth, setImageWidth] = useState(400);
  /* const blogUrl = "https://tazminmashin.ir/tips/"; */
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [posts, setPosts] = useState([]);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  // useEffect((() => {
  //   // debugger
  //   axios.get(WP_POSTS_URL)
  //     .then((res) => {
  //       let response = res.data;
  //       setPosts(response);
  //     })
  //     .catch((e) => { console.log(e); });
  // })
  //   , []);
  const getData = () => {
    axios
      .get("https://mag.bodyness.ir/wp-json/wp/v2/posts?_embed&per_page=10")
      .then((response) => {
        setPosts(response.data);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    getData();
  }, []);

  /*   const myImageLoader = function ({ src, width, quality }) {
    return `${src?.split("&")[0]}`;
  }; */

  // ─── Functions ──────────────────────────────────────────────────────────────────
  /*   const addNumberToUrl = (url, number = 1) => {
    const parsedUrl = new URL(url);
    const filename = parsedUrl.pathname.split("/").pop();
    const extension = filename?.split(".").pop();
    const newFilename = `${filename?.split(".")[0]}${number}.${extension}`;
    if (filename !== undefined) {
      parsedUrl.pathname = parsedUrl.pathname.replace(filename, newFilename);
    }

    return parsedUrl.toString();
  }; */

  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="mx-auto w-[95%] mt-16 md:mt-[80px] max-w-full">
        <section className="mx-8 text-[28px] font-extrabold">جدیدترین مقالات</section>
        <section className=" mt-6 grid grid-cols-1 grid-rows-1 justify-between justify-items-center gap-6 md:mt-10  md:grid-cols-4">
          {posts?.map((item, index) => (
            <Link key={index} to={item?.guid.rendered} className="group relative w-full  cursor-pointer overflow-hidden rounded-[30px] shadow-lg">
              <img referrerPolicy="no-referrer" alt="" src={item?.yoast_head_json.og_image[0].url} className="w-full" />
              <h3 className="absolute bottom-[-30px] flex h-[80px] w-full flex-col justify-center bg-[#00000061] px-4  text-white transition-all duration-500 group-hover:h-[140px]">
                <span className="block">{item?.title.rendered}</span>
                <span className="text-overflow mt-3 overflow-hidden text-ellipsis text-nowrap text-[12px] font-extralight text-gray-50">{item?.yoast_head_json.og_description}</span>
              </h3>
            </Link>
          ))}
        </section>
      </section>
    </>
  );
}
