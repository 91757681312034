
import React from "react";
import { imagesFileUrl } from '../../../../config'
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="flex flex-col gap-4 xl:gap-8">
        <section className="flex items-start gap-4">
          <img
            alt=" "
            src={imagesFileUrl + "/icons/PhoneCallingAbout.svg"}
            width={48}
            height={48}
            className="h-7 w-7 xl:h-12 xl:w-12"
          />
          <span className="text-base xl:text-xl">تلفن:</span>
          <span className="text-sm xl:text-xl">09352920712</span>
        </section>
        <section className="flex items-start gap-4">
          <img
            alt=""
            src={imagesFileUrl + "icons/Lettercomment.svg"}
            width={48}
            height={48}
            className="h-7 w-7 xl:h-12 xl:w-12"

          />
          <span className="text-base xl:text-xl">ایمیل:</span>
          <span className="text-sm xl:text-xl"> info@bodyness.com</span>
        </section>

        <section className="flex items-start gap-4">
          <img
            alt=" "
            className="h-7 w-7 xl:h-12 xl:w-12"
            src={imagesFileUrl + "icons/PointOnMapcomments.svg"}
            width={48}
            height={48}
          />
          <span className="text-base xl:text-xl">آدرس:</span>
          <span className="text-sm xl:text-xl xl:w-[550px]"> خیابان پاسداران، نیستان نهم پلاک ۳۰۲ طبقه اول</span>
        </section>
      </section>
    </>
  );
}
