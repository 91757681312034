import { pdf } from "@react-pdf/renderer";
import FoodListPdf from "../../../../../components/PDF/FoodListPdf";
import { Button } from "../../../../../components/common";

const MyPDFPage = ({ data, basicInfo }) => {
  const DownloadPDF = async () => {
    const blob = await pdf(<FoodListPdf data={data} basicInfo={basicInfo} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "example.pdf";
    link.click();
  };

  return (
    <Button onClick={DownloadPDF} className="h-[40px] bg-purple py-3 text-white rounded-lg mr-auto">
      دانلود PDF
    </Button>
  );
};

export default MyPDFPage;
