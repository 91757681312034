import { useEffect, useState } from "react";
import ToggleDescription from "./components/ToggleDescription";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { Button } from "../../../../../components/common";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ setActiveTab, setCompletionMedicalList, finalAnswer }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getList = () => {
    useAxiosWithToken
      .get(api.question.getSicknessQuestion)
      .then((res) => {
        setData(res.data);
        let arr = [];
        res.data.forEach((item) => {
          arr.push({
            answer: false,
            description: "",
            questionType: "MEDICINE",
            questionId: item.id,
          });
        });
        setAnswers(arr);
      })
      .catch((err) => {});
  };
  const saveAnswer = () => {
    setLoading(true);
    useAxiosWithToken
      .post(api.question.addAnswer, finalAnswer)
      .then((res) => {
        setLoading(false);
        setActiveTab(4);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    setCompletionMedicalList(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-[90%] w-[1360px] m-auto mt-[20px] mb-[100px]">
      <h2 className="text-center text-[#3E4151] xl:text-black text-[20px] xl:text-[48px] font-bold leading-[21px] xl:leading-[74px]">تکمیل پرونده پزشکی</h2>
      <section className="xl:w-[578px] w-[90%] mx-auto flex flex-col gap-12 justify-center mt-12  items-center xl:h-auto ">
        <h3 className="text-[#666666] text-xs xl:text-[18px] font-semibold xl:font-medium leading-[12px] xl:leading-5 ">چند روز و چند ساعت در هفته امکان ورزش کردن دارید؟</h3>
        {data.map((item) => (
          <section className="w-full">
            <ToggleDescription answers={answers} setAnswers={setAnswers} item={item} label="خیر" active="بلی" />
          </section>
        ))}
        <Button loading={loading} onClick={saveAnswer} className="xl:w-[427px] w-full bg-[#5521B4] xl:text-[20px] rounded-xl  leading-[30.2px] font-bold text-white h-[50px] xl:h-[56px]">
          مرحله بعد
        </Button>
      </section>
    </section>
  );
}
