import { useEffect, useState } from "react";
import { imagesFileUrl } from "../../../config";

import { Link, useParams } from "react-router-dom";
import axios from "axios";
import api from "../../../api";
import { useGlobalContext } from "../../../context/GlobalContext";
import moment from "moment-jalaali";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  let { id } = useParams();
  const { showLoading, hideLoading } = useGlobalContext();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [chatList, setChatList] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getChatList = () => {
    showLoading();
    axios
      .get(api.ticket.searchMessages + `?ticketId=${id}&pageSize=20&pageNo=0`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((res) => {
        hideLoading();
        setChatList(res.data.elements);
      })
      .catch((err) => {});
  };
  const addMessage = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    };
    setLoading(true);
    let params = {
      message: message,
      ticketId: id,
    };
    axios
      .post(api.ticket.addMessage, params, { headers })
      .then((res) => {
        setLoading(false);
        getChatList();
      })
      .catch((res) => {
        setLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[784px] h-auto rounded-[18px] bg-[#FFFFFF] border-[0.81px] border-solid border-[#FFFFFF] box-shadow-[0px_4px_9px_0px_#0000000D]">
      <section className="flex items-center justify-between pt-8  px-[34px] mb-[58px]">
        <span className="text-lg text-[#3E4151] font-bold">ارسال تیکت جدید</span>
        <Link to="/dashboard/ticket" className="text-[#5408A9] text-[12px] flex items-center gap-2">
          بازگشت
          <section className="px-[10.5px] py-2">
            <img src={imagesFileUrl + "dashboard/Vector.png"} className="w-[5px] h-[10px]" alt="" />
          </section>
        </Link>
      </section>
      <section className="xl:w-[703px] h-auto mr-[34px] ml-[47px]">
        <section className="bg-[#F7F9FE] w-full xl:h-[603px] mb-[43px] py-10 xl:py-0 border border-solid border-[#E6E8E9] rounded-[18px]">
          <section className="flex flex-col mx-[10px]  xl:mx-[34px] xl:mt-[50px] ">
            <section className="xl:h-[370px] overflow-auto">
              {chatList.map((item, index) => (
                <section key={index}>
                  {item.role === "USER" && (
                    <>
                      <section className="flex gap-4">
                        <img src={imagesFileUrl + "dashboard/Avatar.png"} className="w-[50px] h-[50px]" alt="" />
                        <section className="w-full ml-[94px] my-[10px] pt-[19px] pb-[37px] rounded-[10px_0px_10px_10px] bg-white shadow-[0px_2px_6px_0px_#0000001F]">
                          <p className="mx-[37.06px]  text-sm leading-[21.7px]">{item?.message}</p>
                        </section>
                      </section>
                      <span className="text-[#A6A9BD] text-xs mb-[14px] mr-[66px]">{moment(item.createdOn).format("jYYYY/jMM/jDD")}</span>
                    </>
                  )}
                  {item.role === "ADMIN" && (
                    <>
                      <section className="flex gap-4">
                        <section className="mb-[10px] w-full  mr-[94px] pt-[19px] pb-[37px] rounded-[0px_10px_10px_10px] bg-[#94DFF4] shadow-[0px_2px_6px_0px_#9D9FA02E]">
                          <p className="mx-[37.06px] text-sm leading-[21.7px]">{item.message}</p>
                        </section>
                        <img src={imagesFileUrl + "dashboard/Avatar2.png"} className="w-[50px] h-[50px]" alt="" />
                      </section>
                      <span className="text-[#3E4151] mb-[40px] text-xs self-end ml-[66px]">
                        <span className="text-[#A6A9BD] ml-2 text-xs">دیروز ، ۲۰:۰۰</span> پشتیبان شماره۲
                      </span>
                    </>
                  )}
                </section>
              ))}
            </section>

            {/*  <section className=" mb-5 border-b border-solid pb-5 border-[#E6E8E9]  flex items-center">
              <span className="leading-[21.7px] ml-">فایل‌های ضمیمه:</span>
              <img
                src={imagesFileUrl + 'svg/Frame pdf.svg'}
                className="w-[24px] h-[32px] mr-[16px]"
                alt=''
              />
              <span className="text-[#3E4151] mr-[6px] text-xs">
                File_2.pdf
              </span>
              <button className="mr-[9px]">
                <img
                  src={imagesFileUrl + 'svg/download 1.svg'}
                  className="w-[13px] h-[13px] "
                  alt=''
                />
              </button>
              <img
                src={imagesFileUrl + 'svg/Frame jpg.svg'}
                className="w-[24px] h-[32px] mr-[19px]"
                alt=''
              />
              <span className="text-[#3E4151] mr-[6px] text-xs">
                File_1.pdf
              </span>
              <button className="mr-[9px]">
                <img
                  src={imagesFileUrl + 'svg/download 1.svg'}
                  className="w-[13px] h-[13px] "
                  alt=''
                />
              </button>
              <img
                src={imagesFileUrl + 'svg/Frame docx.svg'}
                className="w-[24px] h-[32px] mr-[19px]"
                alt=''
              />
              <span className="text-[#3E4151] mr-[6px] text-xs">
                File_3.pdf
              </span>
              <button className="mr-[9px]">
                <img
                  src={imagesFileUrl + 'svg/download 1.svg'}
                  className="w-[13px] h-[13px] "
                  alt=''
                />
              </button>
            </section> */}
            <h3 className="text-sm mb-3">پاسخ</h3>
            <textarea placeholder="اینجا بنویسید" value={message} onChange={(e) => setMessage(e.target.value)} className="w-full h-[69px] text-sm border border-[#E6E8E9] rounded-[5px] pt-4 pr-3 mb-5"></textarea>
            <section className="flex justify-end">
              {/* <button className="items-center w-[133px] text-sm gap-[6px] leading-[26px] flex h-[32px] border border-solid text-[#5408A9] border-[#5408A9] rounded-[5px]">
                <img src={imagesFileUrl + "dashboard/Vector (Stroke).png"} className="w-[11.43px] h-[12.93px] mr-[22.57px]" alt="" />
                انتخاب فایل
              </button> */}

              <button disabled={!message} onClick={addMessage} loading={loading} className="w-[128px] h-[32px] disabled:opacity-50 bg-[#5408A9]  leading-[26px] rounded-[5px] text-white">
                ارسال پاسخ
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
