import { useState } from "react";
import { baseFileUrl } from "../../../../../../../config";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, onChange, value }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [checked, setChecked] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const handleOnChange = (checked, value) => {
    setChecked(checked);
    onChange({ checked: checked, value: value });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        onClick={() => handleOnChange(!checked, value)}
        className={` flex h-auto w-full mt-6 cursor-pointer items-center justify-between px-4 `}
      >
        <section className="flex items-center gap-4">
          <span
            className={`flex h-[18px] w-[18px] items-center justify-center rounded-[5px]  ${
              checked ? "bg-[#700B97]" : "border border-[#700B97]"
            } `}
          >
            {checked ? (
              <img
                src={baseFileUrl + "/images/icons/VectorTick.svg"}
                className="w-[10px] h-[10px] "
                alt=""
              />
            ) : (
              ""
            )}
          </span>
          <section
            className={`flex gap-1 text-xs xl:text-base ${
              checked
                ? "text-[#700B97] dark:text-white"
                : "text-[#222222] dark:text-[#a590ad]"
            }`}
          >
            {title}
          </section>
        </section>
      </section>
    </>
  );
}
