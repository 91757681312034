import { useEffect, useState } from "react";
import { svgFileUrl } from "../../../../../../../../../config";
import { set } from "react-hook-form";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setFormData, exerciseObj, setAddShow }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  /*  const num = [
    { name: "FIRST", index: 1 },
    { name: "FIRST", index: 1 },
  ]; */
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  /*   const changeDays = (day) => {
     if (days.includes(day)) {
      setDays(days.filter((el) => el !== day));
    } else {
      setDays([...days, day]);
    }
    setDays(day);
  }; */
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="flex justify-between">
        <section className="flex justify-center items-center">
          <section className="flex items-center justify-between w-full h-[38px] mr-[24px] gap-2">
            <span className="block text-[#3E4151] W-[100px]">انتخاب ماه:</span>
            <button onClick={() => setFormData({ monthEnum: "FIRST" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "FIRST" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
              ماه اول
            </button>
            <button onClick={() => setFormData({ monthEnum: "SECOND" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "SECOND" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
              ماه دوم
            </button>
          </section>
        </section>
      </section>
      <section className="flex justify-between mt-[20px]">
        <section className="flex justify-center items-center">
          <section className="w-full h-[38px] mr-[24px] gap-2 flex items-center justify-between">
            <span className="block text-[#3E4151] W-[350px]">انتخاب هفته:</span>
            <button onClick={() => setFormData({ weekEnum: "FIRST" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "FIRST" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
              هفته اول
            </button>
            <button onClick={() => setFormData({ weekEnum: "SECOND" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "SECOND" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
              هفته دوم
            </button>
            <button onClick={() => setFormData({ weekEnum: "THIRD" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "THIRD" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
              هفته سوم
            </button>
            <button onClick={() => setFormData({ weekEnum: "FOURTH" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "FOURTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
              هفته چهارم
            </button>
          </section>
        </section>
      </section>
      <section className="flex justify-between">
        <section className="flex justify-center items-center">
          <section className="w-full h-[38px] mt-[20px] mr-[24px] gap-2 flex items-center justify-between">
            <span className="block w-[90px] text-[#3E4151]">انتخاب روز:</span>
            <button onClick={() => setFormData({ dayEnum: "FIRST" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "FIRST" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز اول
            </button>
            <button onClick={() => setFormData({ dayEnum: "SECOND" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "SECOND" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز دوم
            </button>
            <button onClick={() => setFormData({ dayEnum: "THIRD" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "THIRD" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز سوم
            </button>
            <button onClick={() => setFormData({ dayEnum: "FOURTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "FOURTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز چهارم
            </button>
            <button onClick={() => setFormData({ dayEnum: "FIFTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "FIFTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز پنجم
            </button>
            <button onClick={() => setFormData({ dayEnum: "SIXTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "SIXTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز ششم
            </button>
            <button onClick={() => setFormData({ dayEnum: "SEVENTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "SEVENTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
              روز هفتم
            </button>
          </section>
        </section>
      </section>
      <section className="w-full flex justify-end mt-[20px]">
        <button onClick={() => setAddShow(true)} className="bg-purple text-white w-[164px] h-[40px] rounded-full text-sm flex justify-center gap-1 items-center">
          <img src={svgFileUrl + "plus-white.svg"} alt="" />
          افزودن تمرین
        </button>
      </section>
    </>
  );
}
