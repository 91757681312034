import Item from "./components/Item";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:mb-[80px] my-[60px] h-auto xl:mt-[60px]">
      <h3 className="text-center leading-[34.1px] xl:leading-[49.6px] text-[#191919] font-bold text-[22px] xl:text-[32px]">
        رژیم‌های تناسب اندام
      </h3>
      <p className="text-center xl:block hidden leading-[24.8px] mt-8 text-[#3E4151] font-bold text-[16px]">
        با استفاده از ورزش‌های تناسب اندام، به بهترین نتایج در تناسب اندام دست
        یابید.
      </p>
      <section className="grid xl:grid-cols-3 mt-8  xl:mt-[64px] w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <Item
          src="/images/weight.png"
          price="300,000"
          title="کالری شمار روزانه"
          backGround="bg-[#FEF4F9]"
          border="border-[#5521B40D]"
        />
        <Item
          src="/images/cherry.png"
          price="300,000"
          title="رژیم افزایش وزن"
          backGround="bg-[#FEF4F9]"
          border="border-[#5521B40D]"
        />
        <Item
          src="/images/spoon.png"
          price="300,000"
          title="رژیم کاهش وزن"
          backGround="bg-[#F6FCFE]"
          border="border-[#5521B40D]"
        />
        <section className="xl:col-span-3  justify-center xl:flex-row flex-col flex gap-6">
          <section className="w-full order-last xl:order-first">
            <Item
              absolute="xl:top-[25%] xl:left-[3%] xl:absolute"
              flex="xl:flex-row flex-col"
              width="xl:w-[240px]  w-full h-auto"
              src="/images/pic-10.png"
              price="300,000"
              title="رژیم بارداری"
              backGround="bg-[#F9F5FE]"
              border="border-[#5521B40D]"
            />
          </section>
          <section className="w-full">
            <Item
              absolute="xl:top-0 xl:left-0 xl:absolute"
              flex="xl:flex-row flex-col"
              width="xl:w-[240px] w-full h-auto"
              src="/images/pic 2.png"
              price="300,000"
              title="رژیم شیردهی"
              backGround="bg-[#FEF4F9]"
              border="border-[#5521B40D]"
            />
          </section>
        </section>
        <Item
          src="/images/pic-1.png"
          price="300,000"
          title="رژیم تثبیت وزن"
          backGround="bg-[#F9F5FE]"
          border="border-[#5521B40D]"
        />
        <Item
          src="/images/teenager.png"
          price="300,000"
          title="رژیم نوجوانان"
          backGround="bg-[#F6FCFE]"
          border="border-[#5521B40D]"
        />
        <Item
          src="/images/Athletes.png"
          price="300,000"
          title="رژیم ورزشکاران"
          backGround="bg-[#F9F5FE]"
          border="border-[#5521B40D]"
        />
      </section>
    </section>
  );
}
