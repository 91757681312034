import { Select } from "../../../../../../../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setFormData, index, setSetsList, setsList }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const changeSetsList = (value, objTitle) => {
    let arr = [...setsList];
    arr[index][objTitle] = value;
    setSetsList(arr);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-2 xl:grid-cols-5 gap-3 mt-[33px]">
        <section>
          <label className="mb-2 text-md block">تکرار</label>
          <input
            type="number"
            onChange={(e) => {
              const value = e.target.value;
              if (value <= 30) {
                changeSetsList(parseInt(e.target.value), "setRepeat");
              } else if (value > 30) {
                changeSetsList(parseInt(30), "setRepeat");
              } else if (value.length === 0) {
                changeSetsList(0, "setRepeat");
              }
            }}
            min={0}
            max={30}
            value={setsList[index].setRepeat}
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          />
          {/*   <Select
            title="تکرار"
          
            setState={(value) => changeSetsList(parseInt(value), "setRepeat")}
            value={setsList[index].value}
            options={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
              { label: 9, value: 9 },
              { label: 10, value: 10 },
              { label: 11, value: 11 },
              { label: 12, value: 12 },
              { label: 13, value: 13 },
              { label: 14, value: 14 },
              { label: 15, value: 15 },
              { label: 16, value: 16 },
              { label: 17, value: 17 },
              { label: 18, value: 18 },
              { label: 19, value: 19 },
              { label: 20, value: 20 },
              { label: 21, value: 21 },
              { label: 22, value: 22 },
              { label: 23, value: 23 },
              { label: 24, value: 24 },
              { label: 25, value: 25 },
              { label: 26, value: 26 },
              { label: 27, value: 27 },
              { label: 28, value: 28 },
              { label: 29, value: 29 },
              { label: 30, value: 30 },
            ]}
          /> */}
        </section>
        <section>
          <label className="mb-2 text-md block">tempo</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "tempo")} min={0} max={30} value={setsList[index].tempo} className="border w-full border-[#EAEAEA] rounded-[10px]" />
          {/* <input
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setFormData({ tempo: parseInt(value) });
              } else {
                e.target.value = value.replace(/\D/g, "");
              }
            }}
            type="text"
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          /> */}
        </section>
        <section>
          <label className="mb-2 text-md block">rir</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "rir")} min={0} max={30} value={setsList[index].rir} className="border w-full border-[#EAEAEA] rounded-[10px]" />
          {/*  <input
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setFormData({ rir: parseInt(value) });
              } else {
                e.target.value = value.replace(/\D/g, "");
              }
            }}
            type="text"
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          /> */}
        </section>
        <section>
          <label className="mb-2 text-md block">زمان استراحت (ثانیه)</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "rest")} min={0} value={setsList[index].rest} className="border w-full border-[#EAEAEA] rounded-[10px]" />
          {/* <input
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setFormData({ rest: parseInt(value) });
              } else {
                e.target.value = value.replace(/\D/g, "");
              }
            }}
            type="text"
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          /> */}
        </section>
        <section>
          <label className="mb-2 text-md block">تکرار دستی</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "repeatHandle")} min={0} value={setsList[index].repeatHandle} className="border w-full border-[#EAEAEA] rounded-[10px]" />
          {/*  <input
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setFormData({ repeatHandle: parseInt(value) });
              } else {
                e.target.value = value.replace(/\D/g, "");
              }
            }}
            type="text"
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          /> */}
        </section>
      </section>
    </>
  );
}
