import Header from "./components/Header";
import Description from "./components/Description";
import Video from "./components/Video";
import Rating from "./components/Rating";
import Expertise from "./components/Expertise";
import Activity from "./components/Activity";
import Document from "./components/Document";
import FoodPackage from "./components/FoodPackage";
import Gallery from "./components/Gallery";
import { certFileUrl, certificateFileUrl, imagesFileUrl } from "../../config";
import { useParams } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  let { id } = useParams();

  let coachNames = [
    {
      id: 1,
      name: "آقای زیوری",
      img: imagesFileUrl + "coach/zivari-p.png",
      description: (
        <>
          <p></p>
        </>
      ),
    },
    {
      id: 2,
      name: "خانم حسینی",
      img: imagesFileUrl + "coach/hosseini-p.png",
      description: (
        <>
          <p>من بیتا حسینی هستم، مربی فیتنس و بدنسازی با بیش از 12 سال سابقه مربیگری و دارای مدرک بین‌المللی در فیتنس و حرکات اصلاحی. هدف من در این مسیر، ایجاد انگیزه، ترویج فرهنگ سالم زیستی و کمک به داشتن لایف استایلی سالم و زیبا برای همه افراد است.</p>
          <p>اولویت اصلی من در حرفه‌ام، تاکید بر قدرت برتر بدنی و ذهنی و ارتقای آمادگی جسمانی در تمام سنین است. باور دارم که این موارد، همراه با حس خوب و انرژی مثبت، می‌تواند تغییرات بزرگی در کیفیت زندگی افراد ایجاد کند.</p>
          <p>برای دستیابی به این هدف، همواره در حال یادگیری و به‌روزرسانی دانش و مهارت‌های خود هستم. روش‌های من در طراحی تمرینات و برنامه‌های تغذیه، بر اساس شرایط فیزیکی، اقلیمی و حتی ژنتیکی هر فرد تنظیم می‌شوند تا تجربه‌ای کاملاً شخصی‌سازی‌شده و موثر را ارائه کنم.</p>
          <p>پکیج‌های تمرینی من، چه به صورت حضوری و چه آنلاین، به گونه‌ای طراحی می‌شوند که همیشه جذاب و متنوع باشند. این تنوع نه تنها از تمرین‌زدگی جلوگیری می‌کند، بلکه باعث پیشرفت مستمر و ایجاد تغییرات قابل مشاهده در شاگردانم می‌شود.</p>
          <p>برای افرادی که به دنبال تجربه‌ای خاص‌تر هستند، پکیج‌های VIP طراحی کرده‌ام که شامل امتیازات و برنامه‌های جذاب‌تر است و تاثیرگذاری تمرینات را چند برابر می‌کند.</p>
          <p>افرادی که به جمع ارتش فیتنس من می‌پیوندند، سفری هیجان‌انگیز را آغاز می‌کنند که در آن، گام‌به‌گام همراهشان خواهم بود تا به اهدافشان برسند. امیدوارم شما هم به این گروه بپیوندید و همراه من، مسیر داشتن زندگی سالم و بدنی قدرتمند را تجربه کنید.</p>
        </>
      ),
    },
    /*  {
      id: 3,
      name: "خانم گنجی",
      img: imagesFileUrl + "coach/ganji-p.png",
      description: "",
    }, */
    {
      id: 4,
      name: "خانم تارویردی",
      img: imagesFileUrl + "coach/tarverdi-p.png",
      docs: [certificateFileUrl + "tarvirdi-1.jpg", certificateFileUrl + "tarvirdi-2.jpg", certificateFileUrl + "tarvirdi-3.jpg"],
      description: (
        <>
          <p>متولد ۲۱ تیر ۱۳۶۹ و فارغ‌التحصیل رشته فیزیک، با افتخار ۱۲ سال تجربه در حوزه ورزشی را با خود همراه دارم. به‌عنوان مربی و راهنمای کسانی که سلامتی و زیبایی بدن را جزو اولویت‌های زندگی خود می‌دانند، تلاش می‌کنم تا مسیری هموار برای دستیابی به این اهداف فراهم کنم. این مسیر، نیازمند علم و تجربه کافی است و من با تمام توان، مخاطبانم را با انگیزه و انرژی بیشتر تا رسیدن به مقصد همراهی می‌کنم.</p>
          <p>تمرین اصولی و بدون نگرانی از آسیب‌های احتمالی، یکی از اولویت‌های اصلی من است. آموزش تکنیک‌های صحیح و اجرای درست حرکات ورزشی، مهم‌ترین بخش از کار من محسوب می‌شود.</p>
          <p>
            <span>سوابق من شامل:</span>
            <br />
            <p>دریافت مدارک تخصصی از فدراسیون‌های مرتبط (پرورش اندام و همگانی) ریاست کمیته کار با دستگاه در هیأت آمادگی جسمانی شمیرانات فعالیت در باشگاه‌ها و مجموعه‌های ورزشی معتبر و عالی‌رتبه تهران ورزش برای من همیشه با عشق و تعهد همراه بوده است. سبک زندگی‌ام به‌عنوان یک مربی و ورزشکار، نه تنها حرفه‌ای بلکه منبع الهام من برای تشویق دیگران است. با من می‌توانید تجربه‌ای لذت‌بخش، علمی و ایمن از تمرینات ورزشی داشته باشید. امیدوارم بتوانم همراه مناسبی برای شما در این مسیر باشم.</p>
          </p>
        </>
      ),
    },
    /*   { id: 5, name: "آقای کریمی", img: imagesFileUrl + "coach/karimi-p.png", description: "" }, */
    {
      id: 5,
      name: "آقای کریمی",
      img: imagesFileUrl + "coach/karimi-p.png",
      docs: [certificateFileUrl + "karimi-1.jpg", certificateFileUrl + "karimi-2.jpg", certificateFileUrl + "karimi-3.jpg"],
      description: (
        <>
          <p>من محمد کریمی زند هستم، مربی حرفه‌ای بدنسازی و تمرینات ورزشی با بیش از 10 سال تجربه در باشگاه‌های مختلف، از جمله باشگاه ورزشی یوجیم. در طول این مدت، با بیش از 300 نفر در اهداف متنوعی مانند کاهش وزن، تقویت عضلات، و افزایش حجم همکاری داشته‌ام و تلاش کرده‌ام بهترین مسیر را برای رسیدن به نتایج مطلوب برای شاگردانم فراهم کنم.</p>
          <p>دارای مدرک تخصصی در تغذیه پیشرفته ورزشی از سازمان معتبر NSCA، مدرک کارشناسی ارشد در فیزیولوژی ورزشی، و دانشجوی دکتری بیومکانیک هستم. این دانش به من این امکان را می‌دهد که برنامه‌های تمرینی و تغذیه‌ای را بر اساس جدیدترین یافته‌های علمی طراحی کنم، به طوری که متناسب با نیازهای فردی ورزشجویان باشد.</p>
          <p>تجربه کاری من شامل همکاری با افراد مختلف، از سالمندان گرفته تا ورزشکاران حرفه‌ای، است. اولویت من همیشه این بوده که با ارائه تمرینات دقیق و شخصی‌سازی‌شده و راهنمایی‌های تغذیه‌ای کاربردی، شاگردانم را در مسیر دستیابی به اهدافشان همراهی کنم.</p>
          <p>با باور به پیشرفت مستمر، همیشه در تلاش هستم که از جدیدترین متدهای علمی و عملی در حوزه ورزش و تغذیه استفاده کنم تا بتوانم عملکرد و سلامت افراد را بهبود بخشم. اگر به دنبال دستیابی به اهداف ورزشی خود هستید، من آماده‌ام تا همراه و راهنمای شما در این مسیر باشم.</p>
        </>
      ),
    },
    {
      id: 6,
      name: "آقای محسن زاده",
      img: imagesFileUrl + "coach/mohsenzadeh-p.png",
      docs: [certificateFileUrl + "mohsenzadeh-1.jpg", certificateFileUrl + "mohsenzadeh-2.jpg"],
      description: (
        <>
          <p>من مهدی محسن‌زاده هستم، مربی، محقق و مترجم، با مدرک کارشناسی ارشد فیزیولوژی ورزشی از دانشگاه شهید بهشتی. هدف اصلی من در مسیر حرفه‌ای، کمک به توسعه همه‌جانبه قابلیت‌های جسمانی و ارتقای جنبه‌های مختلف سلامتی برای شاگردانم است.</p>
          <p>در برنامه‌های ورزشی که طراحی می‌کنم، همیشه تلاش دارم با استفاده از شیوه‌های نوین تمرینی مانند کراس‌فیت، تجربه‌ای جذاب و لذت‌بخش از تمرین برای ورزشجویان فراهم کنم. این رویکرد نه تنها انگیزه و جذابیت بیشتری به تمرینات می‌دهد، بلکه دستاوردهای سلامتی و ورزشی آن‌ها را به شکل چشم‌گیری افزایش می‌دهد.</p>
          <p>وجه تمایز اصلی من در مربیگری، تاکید بر ارائه برنامه‌هایی به‌روز، دقیق و شخصی‌سازی‌شده است. تمریناتی که با من انجام می‌دهید، مطابق با جدیدترین متدهای علمی دنیا طراحی شده‌اند. این ادعا را می‌توان در چاپ مقالات علمی من در ژورنال‌های معتبر و ترجمه ۸ عنوان کتاب در زمینه‌های روز دنیا مانند کراس‌فیت و موبیلیتی مشاهده کرد.</p>
          <p>هدف من، نه فقط بهبود عملکرد جسمانی شاگردان، بلکه ایجاد تغییرات مثبت و پایدار در سبک زندگی آن‌ها است. امیدوارم بتوانم در این مسیر همراه شما باشم و تجربه‌ای متفاوت و موثر از ورزش و سلامتی برایتان به ارمغان بیاورم.</p>
        </>
      ),
    },
    //     { id: 6, name: "آقای محسن زاده", img: imagesFileUrl + "coach/mohsenzadeh-p.jpg",description:"من مهدی محسن زاده مربی، محقق و مترجم هستم .کارشناس ارشد فیزیولوژی ورزشی دانشگاه بهشتی"+
    // "اون چیزی که در‌نگاه من از ورزش مهمه و در تمامی پکیج ها و برنامه های ورزشی لحاظ میکنم  توسعه همه جانبه قابلیت های جسمانی همراه با ارتقاء جنبه‌های مختلف سلامتی است."
    // +"به علاوه با استفاده از شیوه‌های نوین تمرینی همچون کراس‌فیت سعی در جذاب‌تر و لذت بخش‌تر کردن تمرین برای ورزشجوها و افزایش دستاورد های سلامتی و ورزشی آن‌ها دارم."
    // "وجه‌ تمایزی که میتونی زمانی که با من تمرین میکنی حس کنی اینه که همه چیز به روز و دقیق و متناسبِ قراره با جدید ترین متد دنیا تمرین کنی، گواه این موضوع هم چاپ مقاله علمی در ژورنال‌های معتبر به علاوه ترجمه ۸ عنوان کتاب از به روز ترین موضوعات روز دنیا نظیر کراس‌فیت و موبیلیتی هست" },
    {
      id: 7,
      name: "خانم مهدیه نظری",
      img: imagesFileUrl + "coach/nazari-p.jpg",
      docs: [certificateFileUrl + "nazari-1.jpg", certificateFileUrl + "nazari-2.jpg", certificateFileUrl + "nazari-3.jpg", certificateFileUrl + "nazari-4.jpg"],
      description: "",
    },
  ];
  let selectdCoach = coachNames.find((el) => el.id === parseInt(id));
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[1130px] max-w-full min-h-[900px]  pt-[20px] mb-[40px] px-[32px] xl:m-auto xl:px-0">
      <Header name={selectdCoach.name} img={selectdCoach.img} />
      <section className="grid grid-cols-1 xl:grid-cols-3 gap-[64px] mt-[200px]">
        <section className="xl:col-span-2">
          <Description name={selectdCoach.name} description={selectdCoach.description} />
          <FoodPackage />
          <Video />
          <Gallery />
        </section>
        <section>
          <Rating />
          <Expertise />
          <Activity />
          <Document docs={selectdCoach?.docs} />
          {/* <section className="mt-[32px]">
            <img src={imagesFileUrl + "doctor-profile-package.png"} alt="" />
          </section> */}
        </section>
      </section>
    </section>
  );
}
