import moment from "moment-jalaali";
import { baseFileUrl } from "../../../../config";
import { useState } from "react";
import PDFDownload from "./PDFDownload";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [detailShow, setDetailShow] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  // ─── Functions ──────────────────────────────────────────────────────────────────
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-1 xl:grid-cols-4 gap-1 mt-[12px] border border-[#E8E7EB] h-[78px] xl:h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span>نسخه</span>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section>{moment(rowData?.createdOn).format("jYYYY/jMM/jDD")}</section>
        <section className="flex justify-center items-center gap-2">
          <button onClick={() => setDetailShow(!detailShow)}>
            <img className="w-[20px] rotate-90" src={baseFileUrl + "/arrowRight.png"} alt="" />
          </button>
        </section>
        <section>
          <PDFDownload data={rowData} />
        </section>
      </section>
      {detailShow && (
        <section className="w-full bg-slate-100 min-h-[100px] rounded-lg">
          <section className="grid grid-cols-4 py-2 text-xs">
            <span>نام دارو</span>
            <span>نوع دارو</span>
            <span>تعداد</span>
            <span>توضیحات</span>
          </section>
          {rowData?.medicalPrescriptionDetails?.map((item, index) => (
            <section key={index} className="grid grid-cols-4 py-2 font-semibold text-xs text-purple">
              <span>{item.drugName}</span>
              <span>{item.drugType}</span>
              <span>{item.count}</span>
              <span>{item.description}</span>
            </section>
          ))}
        </section>
      )}
    </>
  );
}
