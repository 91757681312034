import { Link, useLocation } from "react-router-dom";
import { imagesFileUrl, svgFileUrl } from "../../../../config";
import MenuItem from "./components/MenuItem";
import Dashboard from "./svg/Dashboard";
import Profile from "./svg/Profile";
import Target from "./svg/Target";
import Recipe from "./svg/Recipe";
import Ticket from "./svg/Ticket";
import Exit from "./svg/Exit";
import { isCoach, isDoctor } from "../../../../helper";
import { Fragment } from "react";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const location = useLocation();
  const menuItems = [
    {
      title: "داشبورد",
      link: "/dashboard",
      active: location.pathname === "/dashboard",
      icon: Dashboard,
    },
    {
      title: "پروفایل",
      link: "/dashboard/basicInfo",
      active: location.pathname.substring(11) === "basicInfo",
      icon: Profile,
    },
    {
      title: "رسپی",
      link: "/dashboard/recipe",
      active: location.pathname.substring(11, 17) === "recipe",
      icon: Recipe,
    },
    {
      title: "نسخ پزشکی",
      link: "/dashboard/medical-prescription",
      active: location.pathname.substring(11) === "medical-prescription",
      icon: Recipe,
    },
    {
      title: "ارسال تیکت",
      link: "/dashboard/ticket",
      active: location.pathname.substring(11) === "ticket",
      icon: Ticket,
    },
    { title: "بازگشت به سایت", link: "/", icon: Target },
    { title: "خروج", mode: "logOut", icon: Exit },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="fixed left-0 bottom-0 w-full h-[64px] bg-white shadow-[0px_-1px_9px_0px_#5408A926] rounded-t-[20px] z-[1000] px-[10%] flex justify-between xl:hidden ">
        <Link to="/dashboard/basicInfo" className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuPeople.svg"} alt="" className="w-[18px] h-[18px]" />
          پروفایل
        </Link>

        <Link className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuTarget.svg"} alt="" className="w-[18px] h-[18px]" />
          هدف
        </Link>
        <Link className="flex w-[56px] h-[56px] cursor-pointer  bg-[#5408A9] mt-[-18px] rounded-full shadow-[0px_14px_23px_-6px_#5408A980] justify-center items-center">
          <img src={svgFileUrl + "mobileMenu.svg"} alt="" className="w-[20px] h-[20px]" />
        </Link>
        <Link className="flex flex-col justify-center cursor-pointer  items-center">
          <img src={svgFileUrl + "mobileMenuSport.svg"} alt="" className="w-[18px] h-[18px]" />
          ورزش
        </Link>
        <Link to="/dashboard/recipe" className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuKnife.svg"} alt="" className="w-[18px] h-[18px]" />
          آشپزی
        </Link>
      </section>
      <aside className="w-[199px] hidden xl:block">
        <Link to="/">
          <header className="flex w-[160px] h-[36px] justify-between items-center pr-7">
            <section className="w-[60px] h-[60px] p-1 bg-white rounded-[6px] ml-1">
              <img src={imagesFileUrl + "logoWhite.png"} alt="" />
            </section>
            <span className="font-bold text-[15px] mr-1">Bodyness</span>
          </header>
        </Link>
        <nav className="mt-10">
          {menuItems.map((item) => (
            <Fragment key={Index}>
              <MenuItem title={item.title} link={item.link} active={item.active} Icon={item.icon} mode={item.mode} />
            </Fragment>
          ))}
          {isDoctor() && (
            <Link className="p-2 rounded-md block text-white pr-4 bg-purple mt-5" to="/doctor-dashboard/food-package">
              داشبورد دکتر
            </Link>
          )}
          {isCoach() && (
            <Link className="p-2 rounded-md block text-white pr-4 bg-purple mt-2" to="/coach-dashboard/without-plan-user">
              داشبورد مربی
            </Link>
          )}
          {/* {isDoctor() && <MenuItem title="داشبورد دکتر" link={"/doctor-dashboard/food-package"} active={location.pathname === "/doctor-dashboard/food-package"} Icon={Dashboard} />}
          {isCoach() && <MenuItem title="داشبورد مربی" link={"/coach-dashboard/additional-info"} active={location.pathname === "/coach-dashboard/additional-info"} Icon={Dashboard} />} */}
        </nav>
        <section className="w-full mt-20 relative">
          <img src={imagesFileUrl + "dashboard/app.png"} alt="" />
          <section className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-center">
            <section className="text-center">
              <span className="block text-white text-[22px] font-normal">راهنمای آموزش</span>
              <span className="block text-white text-[22px] font-bold">اپلیکیشن</span>
            </section>
            <section className="w-[105px] h-[105px] bg-white rounded-[6px] p-1 mt-4">
              <img src={imagesFileUrl + "logoWhite.png"} alt="" />
            </section>
            <button className="w-[99px] h-[32px] text-sm font-bold text-[#191919] mt-6 cursor-pointer rounded-full bg-gradient-to-r from-[#FAFF00] to-[#FF6523]">بزن بریم</button>
          </section>
        </section>
      </aside>
    </>
  );
}
