import Item from "./components/Item";
import { imagesFileUrl } from "../../../../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const generateActivity = (value) => {
    switch (value) {
      case "NONE":
        return "بدون تحرک";
      case "VERY_LOW":
        return "خیلی کم";
      case "LOW":
        return "کم";
      case "MEDIUM":
        return "متوسط";
      case "ACTIVE":
        return "زیاد";
      case "VERY_ACTIVE":
        return "حرفه ای";
      default:
      // code block
    }
  };
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:flex grid grid-cols-3 gap-3 mt-[10px]">
      <Item textColor="#191919" value={(data?.gender === "FEMALE" && "زن") || (data?.gender === "MALE" && "مرد")} title="جنسیت" color="#191919" imgUrl={imagesFileUrl + "svg/ai-gender.svg"} />
      <Item textColor="#191919" title="سن" value={data?.age} color="#0097C1" imgUrl={imagesFileUrl + "svg/ai-age.svg"} />
      <Item textColor="#191919" title="وزن شروع" value={data?.kgWeight} color="#FA6900" imgUrl={imagesFileUrl + "svg/ai-weight.svg"} />
      <Item textColor="#191919" title="هدف" value={data?.goal === "LOOSE_WEIGHT" ? "کاهش وزن" : data?.goal === "GAIN_WEIGHT" ? "افزایش وزن" : data?.goal === "MAINTAIN" ? "تثبیت وزن" : ""} color="#ED1C24" imgUrl={imagesFileUrl + "svg/ai-target.svg"} />
      <Item textColor="#191919" title="قد" value={data?.cmHeight} color="#5408A9" imgUrl={imagesFileUrl + "svg/ai-height.svg"} />
      <Item textColor="#191919" value={generateActivity(data?.activityEnum)} title="سطح فعالیت" color="#F02484" imgUrl={imagesFileUrl + "svg/ai-activity.svg"} />
      <Item textColor="#191919" title="BMI" value={Math.round(data?.bmi)} color="#006334" imgUrl={imagesFileUrl + "svg/ai-bmi.svg"} />
      <Item textColor="#191919" title="وزن هدف" value={data?.goalKgWeight} color="#B32F9F" imgUrl={imagesFileUrl + "svg/ai-target-weight.svg"} />
    </section>
  );
}
