import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setTools, tools }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[50]">
      <span className="block text-center font-bold text-[22px] ">انتخاب تجهیزات</span>
      <section className="w-[324px] m-auto">
        <section className="grid grid-cols-2 xl:grid-cols-3 content-center items-center gap-3 mt-[12px]">
          <section onClick={() => setTools(3)} className={`w-[100px] h-[100px] m-auto cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${tools === 3 ? "border-[#4CC9EC]" : "border-[#E6E8E9]"}`}>
            <img src={imagesFileUrl + "icons/damble1.png"} className="w-[68px] " alt="" />
            <span className="text-sm font-bold ">دمبل</span>
          </section>
          <section onClick={() => setTools(6)} className={`w-[100px] h-[100px] m-auto  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${tools === 6 ? "border-[#4CC9EC]" : "border-[#E6E8E9]"}`}>
            <img src={imagesFileUrl + "icons/cable.png"} className="w-[68px] " alt="" />
            <span className="text-sm font-bold ">سیم کش</span>
          </section>
          <section onClick={() => setTools(4)} className={`w-[100px] h-[100px]  m-auto cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${tools === 4 ? "border-[#4CC9EC]" : "border-[#E6E8E9]"}`}>
            <img src={imagesFileUrl + "icons/halter.png"} className="w-[68px]" alt="" />
            <span className="text-sm font-bold">هالتر</span>
          </section>
          <section onClick={() => setTools(2)} className={`w-[100px] h-[100px] m-auto  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${tools === 2 ? "border-[#4CC9EC]" : "border-[#E6E8E9]"}`}>
            <img src={imagesFileUrl + "icons/bodyWeight.png"} className="w-[68px]" alt="" />
            <span className="text-sm font-bold ">وزن بدن</span>
          </section>
          <section onClick={() => setTools(7)} className={`w-[100px] h-[100px] m-auto  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${tools === 7 ? "border-[#4CC9EC]" : "border-[#E6E8E9]"}`}>
            <img src={imagesFileUrl + "icons/foam-roller.png"} className="w-[68px]" alt="" />
            <span className="text-sm font-bold ">فوم غلطک</span>
          </section>
          <section onClick={() => setTools(8)} className={`w-[100px] h-[100px] m-auto  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-[#4CC9EC] hover:shadow-xl transition-all ${tools === 8 ? "border-[#4CC9EC]" : "border-[#E6E8E9]"}`}>
            <img src={imagesFileUrl + "icons/roller.png"} className="w-[68px]" alt="" />
            <span className="text-sm font-bold ">غلطک</span>
          </section>
        </section>
      </section>
    </section>
  );
}
