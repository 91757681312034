import { useState } from "react";
import { imagesFileUrl } from "../../../../../config";
import Counter from "./components/Counter";
import Ruler from "./components/Ruler";
import Button from "../../../../../components/common/Button/";

import api from "../../../../../api";
import { useDietStore } from "../../../../../store/dashboard/diet";
import { useAxiosWithToken } from "../../../../../hooks";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore((state) => state.dietInfo);
  const reloadDietAllInfo = useDietStore((state) => state.reloadDietAllInfo);
  const month = dietInfo?.month;
  const week = dietInfo?.week;
  const day = dietInfo?.day;
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeNumber, setActiveNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const addWater = () => {
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: localStorage.token,
    // };
    setLoading(true);
    let params = {
      consumedWater: activeNumber * 200,
      monthEnum: month,
      weekEnum: week,
      dayEnum: day,
    };
    useAxiosWithToken
      .put(api.water.addWaterToProgram, params)
      .then((res) => {
        setLoading(false);
        reloadDietAllInfo();
      })
      .catch((res) => {
        setLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className='w-full py-[34px] px-[33px] border border-[#B7E9F7] bg-[#EEFAFE] rounded-[18px] m-auto bg-[url("/public/images/dashboard/toolsbg.png")]'>
      <span className="font-normal">
        تعداد لیوان آب مصرفی امروز خود را مشخص کنید.
      </span>
      <section className="xl:w-[450px]  grid grid-cols-1 xl:grid-cols-2 gap-16 m-auto xl:mt-[30px]">
        <section>
          <Counter
            activeNumber={activeNumber}
            setActiveNumber={setActiveNumber}
          />
        </section>
        <section className="hidden xl:flex items-center">
          <section className=" w-[45px]">
            <Ruler activeNumber={activeNumber} />
          </section>
          <img
            src={imagesFileUrl + "/dashboard/glassWater.png"}
            className="w-[99px] h-[168px]"
            alt=""
          />
        </section>
      </section>
      <section className="ltr">
        <Button
          type="primary"
          onClick={addWater}
          round
          loading={loading}
          className="w-[100px] mt-12 bg-purple text-white rounded-full font-normal text-sm mr-auto xl:mt-4"
        >
          ذخیره
        </Button>
      </section>
    </section>
  );
}
