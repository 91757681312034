import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAxiosWithToken } from "../../../../hooks";
import api from "../../../../api";
import Select from "../../../../components/common/inputs/Select";
import Textbox from "../../../../components/common/inputs/Textbox";
import Button from "../../../../components/common/Button";
import { notify } from "../../../../helper";
import { NotifyMessage } from "../../../../enums";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ closeModal, rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const saveTicket = (data) => {
    let params = {
      language: "PERSIAN",
      enable: true,
      editable: false,
      foodCode: 80000070000,
      categoryName: data?.categoryName,
      nationCode: 99,
      mainFoodDescription: data?.mainFoodDescription,
      wweiaCategoryNumber: 5502,
      wweiaCategoryDescription: "کیک و کیک",
      energyKcal: data?.energyKcal,
      proteinG: data?.proteinG,
      carbohydrateG: 32.09,
      sugarsTotalG: 19.19,
      fiberTotalDietaryG: 0.7,
      totalFatG: data?.totalFatG,
      fattyAcidsTotalSaturatedG: 8.071,
      fattyAcidsTotalMonounsaturatedG: 6.016,
      fattyAcidsTotalPolyunsaturatedG: 1.145,
      cholesterolMg: 29.0,
      retinolMcg: 102.0,
      vitaminARaeMcg: 104.0,
      caroteneAlphaMcg: 0.0,
      caroteneBetaMcg: 18.0,
      cryptoxanthinBetaMcg: 0.0,
      lycopeneMcg: 0.0,
      luteinZeaxanthinMcg: 0.0,
      thiaminMg: 0.074,
      riboflavinMg: 0.112,
      niacinMg: 0.732,
      vitaminB6Mg: 0.026,
      folicAcidMcg: 10.0,
      folateFoodMcg: 6.0,
      folateDfeMcg: 22.0,
      folateTotalMcg: 16.0,
      cholineTotalMg: 9.7,
      vitaminB12Mcg: 0.11,
      vitaminB12AddedMcg: 0.0,
      vitaminCMg: 0.2,
      vitaminDMcg: 0.4,
      vitaminEAlphaTocopherolMg: 0.47,
      vitaminEAddedMg: 0.0,
      vitaminKPhylloquinoneMcg: 2.6,
      calciumMg: 43.0,
      phosphorusMg: 49.0,
      magnesiumMg: 7.0,
      ironMg: 0.63,
      zincMg: 0.24,
      copperMg: 0.031,
      seleniumMcg: 2.2,
      potassiumMg: 76.0,
      sodiumMg: 178.0,
      caffeineMg: 0.0,
      theobromineMg: 0.0,
      alcoholG: 0.0,
      g_4_0: 0.185,
      g_6_0: 0.154,
      g_8_0: 0.096,
      g_10_0: 0.226,
      g_12_0: 0.262,
      g_14_0: 0.891,
      g_16_0: 3.628,
      g_18_0: 1.793,
      g_16_1: 0.248,
      g_18_1: 5.615,
      g_20_1: 0.051,
      g_22_1: 0.001,
      g_18_2: 1.027,
      g_18_3: 0.06,
      g_18_4: 0.0,
      g_20_4: 0.019,
      g_20_5N3: 0.002,
      g_22_5N3: 0.005,
      g_22_6n3: 0.0,
      waterG: 48.11,
    };
    setLoading(true);
    useAxiosWithToken
      .post(api.foodsNutrition.addFoodNutrition, params)
      .then((res) => {
        setLoading(false);
        closeModal();

        notify.Success(NotifyMessage.SUCCESS_ACTION);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const editTicket = (data) => {
    let params = {
      language: rowData?.language,
      enable: rowData?.enable,
      editable: rowData?.editable,
      foodCode: rowData?.foodCode,
      categoryName: rowData?.categoryName,
      nationCode: rowData?.nationCode,
      mainFoodDescription: data?.mainFoodDescription,
      wweiaCategoryNumber: rowData?.wweiaCategoryNumber,
      wweiaCategoryDescription: rowData?.wweiaCategoryDescription,
      energyKcal: data?.energyKcal,
      proteinG: data?.proteinG,
      carbohydrateG: data?.carbohydrateG,
      sugarsTotalG: rowData?.sugarsTotalG,
      fiberTotalDietaryG: rowData?.fiberTotalDietaryG,
      totalFatG: data?.totalFatG,
      fattyAcidsTotalSaturatedG: rowData?.fattyAcidsTotalSaturatedG,
      fattyAcidsTotalMonounsaturatedG: rowData?.fattyAcidsTotalMonounsaturatedG,
      fattyAcidsTotalPolyunsaturatedG: rowData?.fattyAcidsTotalPolyunsaturatedG,
      cholesterolMg: rowData?.cholesterolMg,
      retinolMcg: rowData?.retinolMcg,
      vitaminARaeMcg: rowData?.vitaminARaeMcg,
      caroteneAlphaMcg: rowData?.caroteneAlphaMcg,
      caroteneBetaMcg: rowData?.caroteneBetaMcg,
      cryptoxanthinBetaMcg: rowData?.cryptoxanthinBetaMcg,
      lycopeneMcg: rowData?.lycopeneMcg,
      luteinZeaxanthinMcg: rowData?.luteinZeaxanthinMcg,
      thiaminMg: rowData?.thiaminMg,
      riboflavinMg: rowData?.riboflavinMg,
      niacinMg: rowData?.niacinMg,
      vitaminB6Mg: rowData?.vitaminB6Mg,
      folicAcidMcg: rowData?.folicAcidMcg,
      folateFoodMcg: rowData?.folateFoodMcg,
      folateDfeMcg: rowData?.folateDfeMcg,
      folateTotalMcg: rowData?.folateTotalMcg,
      cholineTotalMg: rowData?.cholineTotalMg,
      vitaminB12Mcg: rowData?.vitaminB12Mcg,
      vitaminB12AddedMcg: rowData?.vitaminB12AddedMcg,
      vitaminCMg: rowData?.vitaminCMg,
      vitaminDMcg: rowData?.vitaminDMcg,
      vitaminEAlphaTocopherolMg: rowData?.vitaminEAlphaTocopherolMg,
      vitaminEAddedMg: rowData?.vitaminEAddedMg,
      vitaminKPhylloquinoneMcg: rowData?.vitaminKPhylloquinoneMcg,
      calciumMg: rowData?.calciumMg,
      phosphorusMg: rowData?.phosphorusMg,
      magnesiumMg: rowData?.magnesiumMg,
      ironMg: rowData?.ironMg,
      zincMg: rowData?.zincMg,
      copperMg: rowData?.copperMg,
      seleniumMcg: rowData?.seleniumMcg,
      potassiumMg: rowData?.potassiumMg,
      sodiumMg: rowData?.sodiumMg,
      caffeineMg: rowData?.caffeineMg,
      theobromineMg: rowData?.theobromineMg,
      alcoholG: rowData?.alcoholG,
      g_4_0: rowData?.g_4_0,
      g_6_0: rowData?.g_6_0,
      g_8_0: rowData?.g_8_0,
      g_10_0: rowData?.g_10_0,
      g_12_0: rowData?.g_12_0,
      g_14_0: rowData?.g_14_0,
      g_16_0: rowData?.g_16_0,
      g_18_0: rowData?.g_18_0,
      g_16_1: rowData?.g_16_1,
      g_18_1: rowData?.g_18_1,
      g_20_1: rowData?.g_20_1,
      g_22_1: rowData?.g_22_1,
      g_18_2: rowData?.g_18_2,
      g_18_3: rowData?.g_18_3,
      g_18_4: rowData?.g_18_4,
      g_20_4: rowData?.g_20_4,
      g_20_5N3: rowData?.g_20_5N3,
      g_22_5N3: rowData?.g_22_5N3,
      g_22_6n3: rowData?.g_22_6n3,
      waterG: rowData?.waterG,
    };
    setLoading(true);
    useAxiosWithToken
      .put(api.foodsNutrition.editFoodNutrition + rowData?.id, params)
      .then((res) => {
        setLoading(false);
        closeModal();

        notify.Success(NotifyMessage.SUCCESS_ACTION);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const onSubmit = (data) => {
    if (rowData) {
      editTicket(data);
    } else {
      saveTicket(data);
    }
  };
  useEffect(() => {
    setValue("mainFoodDescription", rowData?.mainFoodDescription);
    setValue("energyKcal", rowData?.energyKcal);
    setValue("proteinG", rowData?.proteinG);
    setValue("carbohydrateG", rowData?.carbohydrateG);
    setValue("totalFatG", rowData?.totalFatG);
    setValue("categoryName", {
      value: rowData?.categoryName,
      label: rowData?.categoryName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {!rowData && (
          <section>
            <span>دسته بندی</span>
            <Controller
              control={control}
              name="categoryName"
              rules={{
                required: "دسته بندی اجباری است",
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  setState={onChange}
                  optionValue="identifier"
                  state={value}
                  optionTitle="name"
                  api={
                    api.categories.searchCategories +
                    "?pageNumber=0&pageSize=30"
                  }
                />
              )}
            />
            {errors.categoryName && (
              <span className="text-[red] font-normal">
                {errors.categoryName.message}
              </span>
            )}
          </section>
        )}

        <section className="grid grid-cols-2 gap-4 mt-4">
          <section>
            <span>عنوان غذا</span>
            <Controller
              control={control}
              name="mainFoodDescription"
              rules={{
                required: " نام غذا اجباری است",
              }}
              render={({ field: { onChange, value } }) => (
                <Textbox className="p-0" onChange={onChange} value={value} />
              )}
            />
            {errors.mainFoodDescription && (
              <span className="text-[red] font-normal">
                {errors.mainFoodDescription.message}
              </span>
            )}
          </section>
          <section>
            <span>میزان کالری</span>
            <Controller
              control={control}
              name="energyKcal"
              rules={{
                required: "میزان کالری اجباری است",
              }}
              render={({ field: { onChange, value } }) => (
                <Textbox className="p-0" onChange={onChange} value={value} />
              )}
            />
            {errors.energyKcal && (
              <span className="text-[red] font-normal">
                {errors.energyKcal.message}
              </span>
            )}
          </section>
          <section>
            <span>میزان پروتئین</span>
            <Controller
              control={control}
              name="proteinG"
              rules={{
                required: "میزان پروتئین اجباری است",
              }}
              render={({ field: { onChange, value } }) => (
                <Textbox className="p-0" onChange={onChange} value={value} />
              )}
            />
            {errors.proteinG && (
              <span className="text-[red] font-normal">
                {errors.proteinG.message}
              </span>
            )}
          </section>
          <section>
            <span>میزان کربوهیدرات</span>
            <Controller
              control={control}
              name="carbohydrateG"
              rules={{
                required: "میزان کربوهیدرات اجباری است",
              }}
              render={({ field: { onChange, value } }) => (
                <Textbox className="p-0" onChange={onChange} value={value} />
              )}
            />
            {errors.carbohydrateG && (
              <span className="text-[red] font-normal">
                {errors.carbohydrateG.message}
              </span>
            )}
          </section>
          <section>
            <span>میزان چربی</span>
            <Controller
              control={control}
              name="totalFatG"
              rules={{
                required: "میزان چربی اجباری است",
              }}
              render={({ field: { onChange, value } }) => (
                <Textbox className="p-0" onChange={onChange} value={value} />
              )}
            />
            {errors.totalFatG && (
              <span className="text-[red] font-normal">
                {errors.totalFatG.message}
              </span>
            )}
          </section>
          <section className="flex justify-end pt-[40px] items-center">
            <Button
              type="submit"
              loading={loading}
              className="w-[128px] h-[32px] bg-[#5408A9] leading-[26px] rounded-[5px] text-white"
            >
              {rowData ? "ویرایش" : "ذخیره"}
            </Button>
          </section>
        </section>
      </form>
    </>
  );
}
