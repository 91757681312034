//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ img, title }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[118px] h-[160px]">
      <section className="w-[117px] h-[117px] bg-white rounded-[20px] flex justify-center items-center cursor-pointer transition-all hover:bg-purple hover:shadow-2xl border border-[white]">
        <img src={img} alt="" />
      </section>
      <span className="text-sm font-bold text-white text-center block w-full mt-[24px]">
        {title}
      </span>
    </section>
  );
}
