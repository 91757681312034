"use client";
import { useState, useEffect } from "react";
import Select from "react-select";
import "./style.css";

import { useAxios, useAxiosWithToken } from "../../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ dark, api, setState, state, search, optionTitle, optionValue, condition, placeHolder, authentication, resElements, isMulti, className }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  /* const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ]; */
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setLoading(true);

    if (authentication) {
      useAxiosWithToken
        .get(api)
        .then((res) => {
          setLoading(false);
          let response;
          if (resElements) {
            response = res.data.elements;
          } else {
            response = res.data;
          }

          let arr = [];
          response.forEach((item) => {
            arr.push({
              value: item[optionValue],
              label: item[optionTitle],
            });
          });
          setData(arr);
        })
        .catch((e) => {
          setLoading(false);
          // alert("Error !!!!!!")
        });
    } else {
      useAxios
        .get(api)
        .then((res) => {
          setLoading(false);
          let response = res.data;
          let arr = [];
          response.forEach((item) => {
            arr.push({
              value: item[optionValue],
              label: item[optionTitle],
            });
          });
          setData(arr);
        })
        .catch((e) => {
          setLoading(false);
          // alert("Error !!!!!!")
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, optionTitle, optionValue, condition]);
  useEffect(() => {
    if (!isMulti) {
      setState(data[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <Select
      isMulti={isMulti}
      options={data}
      isLoading={loading}
      onChange={setState}
      className={className}
      isRtl={true}
      value={state}
      placeholder={placeHolder ? placeHolder : "انتخاب کنید..."}
      isSearchable={search}
      loadingMessage={() => <span>در حال بارگزاری...</span>}
      noOptionsMessage={() => <span>اطلاعاتی موجود نیست</span>}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "",
          primary: "#2C8EE8",
        },
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "#2C8EE8" : "#D1D1D1",
          backgroundColor: dark ? "#2f2f2f80" : "white",
          width: "100% !important",
          minHeight: 48,
          overflow: "hidden",
          borderRadius: 8,
          color: "#666666",
          fontSize: 12,

          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        }),
        /*   option: (provided) => ({
            ...provided,
            backgroundColor: dark ? "black" : "white",
            color: dark ? "white" : "black",
          }), */
        singleValue: (provided) => ({
          ...provided,
          color: dark ? "white" : "black",
        }),
      }}
    />
  );
}
