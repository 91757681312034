"use client";
import { useState } from "react";
import { baseFileUrl } from "../../../../config";
import QuestionBox from "./components/QestionBoxState";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  const [activeTab, setActiveTab] = useState(0);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid xl:w-[1134px] w-[90%] relative mb-[50px] mt-[50px] xl:mt-0 xl:mb-[80px] mx-auto  justify-items-center gap-6  xl:p-[60px]">
        <img
          src={baseFileUrl + "/BG-light-question.png"}
          alt=""
          className="xl:w-[575.74px] absolute xl:-left-[100px] xl:-bottom-[100px] h-auto"
        />
        <h1 className="xl:text-[40px] text-center w-full  font-bold text-[#191919]">
          سوالات متداول
        </h1>
        <section className="grid  w-full gap-4">
          <QuestionBox
            question="چگونه می‌توانم رژیم غذایی مناسب خود را انتخاب کنم؟"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabIndex={1}
          />
          <QuestionBox
            question="آیا می‌توانم با استفاده از برنامه‌های غذایی بادینس وزن کم کنم؟"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabIndex={2}
          />
          <QuestionBox
            question="چه نوع غذایی باید در رژیم غذایی من گنجانده شود؟"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabIndex={3}
          />
          <QuestionBox
            question="چگونه می‌توانم میزان کالری مصرفی خود را کنترل کنم؟"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabIndex={4}
          />
          <QuestionBox
            question="آیا رژیم غذایی شما برای افرادی با شرایط خاص مانند دیابت یا فشار خون بالا مناسب است؟"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabIndex={5}
          />
          <QuestionBox
            question="چگونه می‌توانم انگیزه خود را برای پایبندی به رژیم غذایی حفظ کنم؟"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabIndex={6}
          />
        </section>
      </section>
    </>
  );
}
