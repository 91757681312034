import Routes from "./routes/index";
import Loading from "./components/common/Loading";
import TopLoader from "./components/TopLoader";
import { Toaster } from "react-hot-toast";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
function App() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Loading />
      <TopLoader />
      <Toaster />
      <main className="bodyRtl">
        <Routes />
      </main>
    </>
  );
}

export default App;
