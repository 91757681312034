import axios from "axios";
import { notify } from "../helper";
import { NotifyMessage } from "../enums";

const useAxiosWithToken = axios.create();
useAxiosWithToken.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token;
  return config;
});
useAxiosWithToken.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.code === "ERR_NETWORK") {
      notify.Error(NotifyMessage.NETWORK_ERROR);
    }
    if (error) {
      if (error?.response?.status === 401) {
        window.location.href = "/login";
        localStorage.clear();
      } else if (error?.response?.status === 500) {
        notify.Error(NotifyMessage.GLOBAL_ERROR);
      } else if (error?.response?.status === 400) {
        notify.Error(NotifyMessage.GLOBAL_ERROR);
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
  }
);
export default useAxiosWithToken;
