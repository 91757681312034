import { useEffect, useRef } from "react";
import "./style.css";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({
  setMuscle,
  style,
  onClick,
  setFront11,
  setFront12,
  setFront22,
  setFront16,
  setFront7,
  back22,
  back7,
  back16,
  back11,
  back12,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  const n2_1 = useRef();
  const n2_2 = useRef();
  const n7_1 = useRef();
  const n7_2 = useRef();
  const n8_1 = useRef();
  const n8_2 = useRef();
  const n11_1 = useRef();
  const n11_2 = useRef();
  const n12_1 = useRef();
  const n12_2 = useRef();
  const n16_1 = useRef();
  const n16_2 = useRef();
  const n20_1 = useRef();
  const n20_2 = useRef();
  const n20_3 = useRef();
  const n20_4 = useRef();
  const n22_1 = useRef();
  const n22_2 = useRef();
  const n23 = useRef();
  const n24 = useRef();
  const n25_1 = useRef();

  const n26_1 = useRef();
  const n26_2 = useRef();
  const n27_1 = useRef();
  const n27_2 = useRef();
  const n28_1 = useRef();
  const n28_2 = useRef();
  const n28_3 = useRef();
  const n28_4 = useRef();
  const n29_1 = useRef();
  const n29_2 = useRef();
  const n30_1 = useRef();
  const n30_2 = useRef();
  const n31_1 = useRef();
  const n31_2 = useRef();
  const n32 = useRef();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const n2Enter = () => {
    n2_1.current.classList.add("activeMuscle");
    n2_2.current.classList.add("activeMuscle");
  };
  const n2Leave = () => {
    n2_1.current.classList.remove("activeMuscle");
    n2_2.current.classList.remove("activeMuscle");
  };
  const n7Enter = () => {
    setFront7(true);
    n7_1.current.classList.add("activeMuscle");
    n7_2.current.classList.add("activeMuscle");
  };
  const n7Leave = () => {
    setFront7(false);
    n7_1.current.classList.remove("activeMuscle");
    n7_2.current.classList.remove("activeMuscle");
  };
  const n8Enter = () => {
    n8_1.current.classList.add("activeMuscle");
    n8_2.current.classList.add("activeMuscle");
  };
  const n8Leave = () => {
    n8_1.current.classList.remove("activeMuscle");
    n8_2.current.classList.remove("activeMuscle");
  };

  const n22Enter = () => {
    setFront22(true);
    n22_1.current.classList.add("activeMuscle");
    n22_2.current.classList.add("activeMuscle");
  };
  const n22Leave = () => {
    setFront22(false);
    n22_1.current.classList.remove("activeMuscle");
    n22_2.current.classList.remove("activeMuscle");
  };
  const n23Enter = () => {
    n23.current.classList.add("activeMuscle");
  };
  const n23Leave = () => {
    n23.current.classList.remove("activeMuscle");
  };
  const n24Enter = () => {
    n24.current.classList.add("activeMuscle");
  };
  const n24Leave = () => {
    n24.current.classList.remove("activeMuscle");
  };
  const n25Enter = () => {
    n25_1.current.classList.add("activeMuscle");
  };
  const n25Leave = () => {
    n25_1.current.classList.remove("activeMuscle");
  };
  const n32Enter = () => {
    n32.current.classList.add("activeMuscle");
  };
  const n32Leave = () => {
    n32.current.classList.remove("activeMuscle");
  };
  const n31Enter = () => {
    n31_1.current.classList.add("activeMuscle");
    n31_2.current.classList.add("activeMuscle");
  };
  const n31Leave = () => {
    n31_1.current.classList.remove("activeMuscle");
    n31_2.current.classList.remove("activeMuscle");
  };
  const n11Enter = () => {
    setFront11(true);
    n11_1.current.classList.add("activeMuscle");
    n11_2.current.classList.add("activeMuscle");
  };
  const n11Leave = () => {
    setFront11(false);
    n11_1.current.classList.remove("activeMuscle");
    n11_2.current.classList.remove("activeMuscle");
  };
  const n12Enter = () => {
    setFront12(true);
    n12_1.current.classList.add("activeMuscle");
    n12_2.current.classList.add("activeMuscle");
  };
  const n12Leave = () => {
    setFront12(false);
    n12_1.current.classList.remove("activeMuscle");
    n12_2.current.classList.remove("activeMuscle");
  };
  const n16Enter = () => {
    setFront16(true);
    n16_1.current.classList.add("activeMuscle");
    n16_2.current.classList.add("activeMuscle");
  };
  const n16Leave = () => {
    setFront16(false);
    n16_1.current.classList.remove("activeMuscle");
    n16_2.current.classList.remove("activeMuscle");
  };
  const n20Enter = () => {
    n20_1.current.classList.add("activeMuscle");
    n20_2.current.classList.add("activeMuscle");
    n20_3.current.classList.add("activeMuscle");
    n20_4.current.classList.add("activeMuscle");
  };
  const n20Leave = () => {
    n20_1.current.classList.remove("activeMuscle");
    n20_2.current.classList.remove("activeMuscle");
    n20_3.current.classList.remove("activeMuscle");
    n20_4.current.classList.remove("activeMuscle");
  };

  const n26Enter = () => {
    n26_1.current.classList.add("activeMuscle");
    n26_2.current.classList.add("activeMuscle");
  };
  const n26Leave = () => {
    n26_1.current.classList.remove("activeMuscle");
    n26_2.current.classList.remove("activeMuscle");
  };
  const n27Enter = () => {
    n27_1.current.classList.add("activeMuscle");
    n27_2.current.classList.add("activeMuscle");
  };
  const n27Leave = () => {
    n27_1.current.classList.remove("activeMuscle");
    n27_2.current.classList.remove("activeMuscle");
  };
  const n28Enter = () => {
    n28_1.current.classList.add("activeMuscle");
    n28_2.current.classList.add("activeMuscle");
    n28_3.current.classList.add("activeMuscle");
    n28_4.current.classList.add("activeMuscle");
  };
  const n28Leave = () => {
    n28_1.current.classList.remove("activeMuscle");
    n28_2.current.classList.remove("activeMuscle");
    n28_3.current.classList.remove("activeMuscle");
    n28_4.current.classList.remove("activeMuscle");
  };
  const n29Enter = () => {
    n29_1.current.classList.add("activeMuscle");
    n29_2.current.classList.add("activeMuscle");
  };
  const n29Leave = () => {
    n29_1.current.classList.remove("activeMuscle");
    n29_2.current.classList.remove("activeMuscle");
  };
  const n30Enter = () => {
    n30_1.current.classList.add("activeMuscle");
    n30_2.current.classList.add("activeMuscle");
  };
  const n30Leave = () => {
    n30_1.current.classList.remove("activeMuscle");
    n30_2.current.classList.remove("activeMuscle");
  };
  useEffect(() => {
    if (back11) {
      n11Enter();
    } else {
      n11Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back11]);
  useEffect(() => {
    if (back12) {
      n12Enter();
    } else {
      n12Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back12]);
  useEffect(() => {
    if (back22) {
      n22Enter();
    } else {
      n22Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back22]);
  useEffect(() => {
    if (back16) {
      n16Enter();
    } else {
      n16Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back16]);
  useEffect(() => {
    if (back7) {
      n7Enter();
    } else {
      n7Leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back7]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <svg
        version="1.1"
        id="womanBack"
        x="0px"
        y="0px"
        viewBox="0 0 1162.2 1692.28"
        onClick={onClick}
        style={style}
      >
        <path
          class="st0"
          d="M728.83,1658.89c-9.61-3.76-24.05-20.31-27.78-36.87c-3.73-16.55-14.6-20.84-13.71-30.99
	c0.57-6.49-1.59-13.33-3.24-22.38c0.71-6.31,2.51-14.33,5.15-26.94c6.08-29.05,20.94-89.17,27.7-145.25
	c6.76-56.07-14.52-122.28-40.7-122.28c-26.18,0-40.7,131.73-40.7,131.73c16.21,77.69,8.79,126.74,9.13,140.52
	c0.34,13.78-3.03,28.15-3.03,28.15c-3.72,10.35-3.13,24.66,0,34.67c3.13,10.02-13.86,47.96,1,59.45c7.25,5.6,16.76,8.93,26.24,9.33
	c-0.32,0.01-0.63,0.06-0.95,0.06c13.86,0,47.45,0,57.18,0C743.24,1678.12,738.45,1662.62,728.83,1658.89z"
        />
        <path
          class="st1"
          d="M644.1,1390.03c0,0,29.97,131.54,15.12,206.61c-6.94,35.12-27.13,52.04-23.68,68.9
	c2.81,13.77,44.91,17.83,51.54,5.31c6.63-12.52-8.52-17.23-10.7-70.62c-2.18-53.39,0.5-148.36,35.37-208c0,0,4.84-9.68,3.83-24.38
	c-1.01-14.69-15.74-78.28-40.57-78.79S644.1,1390.03,644.1,1390.03z"
        />
        <path
          class="st0 n29"
          ref={n29_2}
          onMouseEnter={n29Enter}
          onMouseLeave={n29Leave}
          onClick={() => setMuscle(29)}
          d="M579.1,836.48c0,0,0.2,54.68,15.07,122.23c14.86,67.56,20.27,120.25,17.56,166.19
	c-2.7,45.94,18.4,96.15,18.4,96.15s29.15-19.14,42.66-83.99c0-0.16-1.02-3.77-2.28-10.39c-1.37-7.17-2.34-17.47-3.23-29.38
	c-1.22-16.3-1.4-36.2-0.17-56.14c3.4-55.05,5.23-101.76,6.68-130.89c0.89-17.95-0.46-28.33-0.46-28.33l-5.61,1.27l-5.24,0.87
	l-5.64,0.59l-2.15,0.13l-3.12,0.08l-2.88-0.02l-3.44-0.17l-2.89-0.27l-2.68-0.35l-2.52-0.43l-2.04-0.42l-2.63-0.64l-3.77-1.13
	l-3.24-1.2l-2.84-1.23l-2.14-1l-2.01-0.97l-1.68-0.85l-1.62-0.84l-1.51-0.77l-1.1-0.65l-1.56-0.89l-1.12-0.66l-1.08-0.66l-1.14-0.71
	l-1.4-0.91l-1.3-0.87l-1.04-0.72l-1.06-0.76l-1.32-0.98l-1.56-1.21l-1.03-0.83l-1.2-1.01l-1.2-1.05l-0.95-0.87l-0.97-0.92
	l-0.86-0.85l-0.9-0.92l-0.93-1l-0.88-0.98l-0.79-0.92l-0.79-0.95l-0.9-1.14l-0.79-1.04l-0.94-1.3l-0.85-1.24l-0.9-1.4l-1-1.67
	l-0.43-0.74l-0.48-0.88l-0.64-1.22l-0.48-0.96l-0.53-1.12l-0.51-1.13l-0.54-1.27l-0.52-1.3L579.1,836.48z"
        />
        <path
          class="st2 n28"
          ref={n28_3}
          onMouseEnter={n28Enter}
          onMouseLeave={n28Leave}
          onClick={() => setMuscle(28)}
          d="M673.32,881.93c2.23,15.47-0.43,53.56-3.25,97.07c-0.75,11.53-1.16,23.45-1.79,35.43
	c-0.92,17.62-1.62,35.36-1.7,52.21c-0.13,27.24,1.36,52.15,6.21,70.42c17.56,66.21,38.41,102.69,38.41,102.69
	c-7.32-74.31,16.48-95.03,31.46-165.67c0,0,0.91-10.08,1.33-21.67c0.34-9.62,1.4-22.79,2.03-45.28c0.4-14.43,0.8-29.67,0.99-44.91
	c0.08-6.15,0.05-12.29,0.02-18.39c-0.03-9.85-0.33-19.57-0.6-29.11c-0.24-8.4-0.76-16.73-1.4-24.71c-0.41-5.04-0.74-9.89-1.47-14.69
	c-2.52-16.61-4.98-30.73-10.58-37.14c0,0-7.99,13.21-18.31,21.97C694.21,877.5,673.32,881.93,673.32,881.93z"
        />
        <path
          class="st0 n28"
          ref={n28_4}
          onMouseEnter={n28Enter}
          onMouseLeave={n28Leave}
          onClick={() => setMuscle(28)}
          d="M741.94,816.27c-3.29,11.82-8.96,21.9-8.96,21.9c24.37,28.43,9.68,235.91,9.68,235.91
	c26.5-122.92,21.63-206.79,8.13-256.72c-3.38-12.48-6.27-21.45-6.4-22.07c0,0,0.18,3.41-0.41,9.11
	C743.64,807.67,743.21,811.7,741.94,816.27z"
        />
        <path
          class="st0"
          d="M946.16,972.74c2.9,16.14-37.02,18.95-52.42,0.78c-14.93-17.61-29.96-54.18-30.87-56.45
	c4.41,7.32,10.4,13.54,18.2,16.82c19.85,8.37,51.42-5.85,48.92-23.01C942.38,944.06,944.01,960.86,946.16,972.74z"
        />
        <path
          class="st0"
          d="M963.6,889.99c-12.4-3.73-19.62-5.98-31.7-17.87c-8.28-8.14-45.42-21.46-33.72-17.2
	c0.43,0.16-10.2,3.38-15.84,5.34c-3.07,1.19-5.4,2.14-7.65,3.06c-1.53,0.62-3.13,1.24-4.8,2.01c-2.82,1.31-5.81,3.52-9.84,6.77
	l-7.01,7.42l-0.17,2.54c0.13,9.76,3.18,23.62,9.95,34.89c0,0,0.01,0.03,0.05,0.11c4.41,7.32,10.4,13.54,18.2,16.82
	c19.85,8.37,51.42-5.85,48.92-23.01c-0.84-5.69-3.71-12.5-7.82-19.43c6.97-2.67,17.79,4.29,32.82,10.18
	C970.01,907.53,976.01,893.71,963.6,889.99z"
        />
        <path
          class="st2 n11"
          ref={n11_1}
          onMouseEnter={n11Enter}
          onMouseLeave={n11Leave}
          onClick={() => setMuscle(11)}
          d="M877.19,787.55c5.17,31.83,20.99,67.38,20.99,67.38s-12.35,4.03-16.47,5.57c-3.17,1.19-5.95,2.39-11.88,4.84
	c0,0-4.27-10.43-6.37-20.44c-2.93-13.96-14.71-43.47-44.06-107.76c-12.24-26.81-21.05-49.92-24.88-69.68
	c-2.87-14.82-1.21-26.31-1.21-26.31c2.71-7.91,4.68-14.41,5.74-19.4c0.66-3.13,1.43-6.64,1.94-10.47
	c1.64-12.33,1.29-26.34,1.29-26.34C863.14,664.14,868.72,735.37,877.19,787.55z"
        />
        <path
          class="st0 n12"
          ref={n12_2}
          onMouseEnter={n12Enter}
          onMouseLeave={n12Leave}
          onClick={() => setMuscle(12)}
          d="M762.8,612.58c0.87,1.09,0.87,1.09,0.87,1.09c-11.64,29.9,3.72,81.67,16.74,111.36
	c16.3,37.14,70.49,109.63,72.6,154.69c6.91-9.5,16.81-14.38,16.81-14.38c-0.24-0.57-1.83-4.64-2.93-7.97
	c-0.67-2.02-1.17-3.82-1.28-4.19c-1.69-5.53-2.84-12.2-4.96-18.39c-6.39-18.74-14.35-37.27-22.66-56.21
	c-22.85-52.11-49.25-100.99-44.7-137.43c0,0-5.15-3.39-11.66-8.59c-8.04-6.41-9.04-7.33-14.12-12.71
	C764.39,616.54,763.54,614.48,762.8,612.58z"
        />
        <path
          class="st3"
          d="M771.18,623.63c6.75,6.84,22.12,17.52,22.12,17.52s7.67-19.03,8.82-41.37c0.41-8.03-1.08-29.61-2.27-31.77
	c-7.41-13.35-16.02-23.49-26.4-29.25c-12.12-6.73-22.19-1.18-25.83,0.7c0,0,2.22,13.79,3.65,21.92c1.98,11.29,5.69,27.8,8.6,39.53
	c1.69,6.82,2.28,9.14,2.93,11.67C763.76,616.35,768.24,620.65,771.18,623.63z"
        />
        <path
          class="st2 n30"
          ref={n30_2}
          onMouseEnter={n30Enter}
          onMouseLeave={n30Leave}
          onClick={() => setMuscle(30)}
          d="M747.62,539.46c0,0-1.6-12.47-2.72-24.04c-0.78-8.11-0.81-15.14-0.86-24.41c-0.05-8.06,0.68-15.5,1.62-22.77
            c0.68-5.29,1.33-10.51,2.56-15.76c4.42-18.97,0.79-29.5,0.79-29.5s2.2,0.64,8.33,7.26c1.25,1.36,4.89,5.95,6.14,7.47
            c4.74,5.74,9.32,11.87,12.57,16.94c1.58,2.46,3.17,4.69,4.42,6.53c2.34,3.44,3.79,5.57,3.79,5.57s22.09,28.05,15.58,101.29
            C799.86,568.02,776.64,521.96,747.62,539.46z"
        />
        <path
          class="st0 n31"
          ref={n31_1}
          onMouseEnter={n31Enter}
          onMouseLeave={n31Leave}
          onClick={() => setMuscle(31)}
          d="M737.7,416.95c4.84,1.71,11.31,6.02,11.31,6.02c7.95,34.18-23.72,43.77,13.79,189.62
	c0,0-70.3-81.08-56.29-151.55l2.43-15.5l3.92-20.24l2.28-9.46l2-7.3l4.98,1.51l2.53,0.95l2.57,1.15l4.63,2.27L737.7,416.95z"
        />
        <path
          class="st2 n32"
          ref={n32}
          onMouseEnter={n32Enter}
          onMouseLeave={n32Leave}
          onClick={() => setMuscle(32)}
          d="M588.1,579.77c-7,10.23-13.1,16.1-13.1,16.1s-4.88-5.87-11.88-16.1c0.85,7.77,4.7,66.31-38.39,97.14
	c0,0,10.58,15.08,24.06,35.19c3.17,4.73,5.67,9.7,8.55,15.13c4.88,9.18,8.22,21.23,10.54,30.85c5.05,20.92,5.05,47.81,5.05,47.81
	h1.59c0,0-0.3-24.38,4.86-47.35c3.35-14.9,9.74-32.37,18.5-45.19c1.69-2.47,5.55-8.45,7.14-10.78c5.88-8.62,10.7-15.09,13.85-19.84
	c3.43-5.17,5.39-8.05,5.39-8.05c-4.38-3.14-8.31-6.63-11.69-10.3c-2.94-3.18-5.44-6.49-7.77-9.83c-3.54-5.08-6.24-10.46-8.52-15.76
	c-4.33-10.07-6.54-20.21-7.7-29.2C586.66,594.76,587.75,583.04,588.1,579.77z"
        />
        <path
          class="st0"
          d="M575,331.45c0.22,0,0.43,0,0.65,0c0.22,0,0.43,0,0.65,0H575z"
        />
        <path
          class="st0 n25"
          ref={n25_1}
          onMouseEnter={n25Enter}
          onMouseLeave={n25Leave}
          onClick={() => setMuscle(25)}
          d="M575,595.87c0,0-5.06-5.89-11.88-16.1c-8.19-12.27-18.83-30.52-24.7-51.42c-3.66-13.06-5.35-21.14-5.74-44.05
	c-0.18-10.22-0.33-15.41-0.85-24.03c-0.11-1.75-0.32-4-0.45-5.76c-0.55-7.7-1.7-14.95-2.89-23.29c-1.33-9.28-2.77-19.58-4.28-26.27
	c-1.62-7.19-6.64-21.09-11.5-31.37c-6.88-14.52-15.04-21.81-15.04-21.81c-7.24,14.52-14.37,25.31-22.61,32.93
	c-10.99,10.16-19.87,15.98-27.91,18.79c-5.14,1.8-8.27,2.77-11.61,3.71c-3.3,0.93-5.23,1.34-5.23,1.34
	c0.93,3.17,2.57,9.1,4.44,17.56c0.47,2.12,0.84,4.56,1.35,6.97c1.41,6.7,2.79,14.48,4.17,23.68c1.03,6.84,2.11,14.16,3.03,22.37
	c2.28,20.5,10.23,47.34,15.83,72.56c6.89,28.37,11.84,52.71,15.56,73.26c0,0,0.04,13.5-3.27,22.28c0,0-1.61,5.74-4.97,14.84
	c5.24-3.29,10.72-4.8,15.78-5.93c9.39-2.1,18.99,1.76,26.84,6.69c11.84,7.44,15.66,14.1,15.66,14.1
	c17.14-12.26,26.2-28.62,32.03-44.83c8.63-24.03,6.88-47.63,6.37-52.32C569.93,590.05,575,595.87,575,595.87s6.61-6.4,13.1-16.1
	c-0.45,4.15-2.09,21.8,2.74,42.34c2.22,9.46,5.96,19.53,11.79,29.06c5.23,8.57,12.12,16.7,21.63,23.51c0,0,3.15-4.18,9.81-8.94
	c3.29-2.35,7.29-4.83,11.59-6.85c9.67-4.55,21.72-5.24,39.14,5.35c-5.09-18.49-6.85-25.86-6.85-25.86
	c-3.67-19.65,1.58-28.14,10.9-71.94c0,0,4.27-26.73,4.66-27.04c4.6-21.4,8.78-43,10.69-60.18c4.16-37.38,10.41-61.82,12.93-70.67
	l-7.54-1.94l-6.94-2.11c0,0-3.24-0.97-8.04-3.48c-2.22-1.15-4.74-2.76-7.48-4.52c-4.4-2.83-8.64-5.74-13.95-11.04
	c-4.59-4.58-9.44-10.46-14.09-17.31c-3.19-4.71-6.34-10.47-9.33-16.39c0,0-14.35,13.52-21.55,44.54
	c-3.95,16.99-6.65,37.39-8.44,58.59c-1.4,16.53-0.97,34.95-2.19,49.9c-0.61,7.49-3.36,19.21-6.21,28.09
	c-1.44,4.49-4.3,11.92-6.33,16.47c-2.72,6.1-4.5,9.41-6.98,14.11c-3.77,7.16-7.1,11.51-9.97,16.3
	C583.54,587.39,575,595.87,575,595.87z"
        />
        <path
          class="st4"
          d="M575.62,192.22c-22.14,0-31.97,8.64-31.97,8.64c0,54.76,1.17,66.19-23.89,85.16
	c-25.06,18.97-35.53,33.34-69.14,35.61l50.5,29.79c23.64,23.87,31.08,81.97,31.75,140.75s42.75,103.97,42.75,103.97
	s42.07-45.2,42.75-103.97c0.68-58.77,7.77-116.53,31.41-140.4l2.22-2.23l2-1.88l2.06-1.82l2.25-1.89l1.73-1.24l2.02-1.57l3.55-2.77
	l2.55-1.86l2.49-1.92l4.28-2.81l2.84-1.85l2.92-1.68l3.86-2.08l2.25-1.04l2.7-1.02l3.82-1.1l3.49-0.7l2.83-0.35l2.45-0.24l3.65-0.21
	c-32.2-9.07-47.63-17.75-71.75-38.91c-23.63-20.73-26.38-26.99-26.38-81.75C607.58,200.86,597.76,192.22,575.62,192.22z"
        />
        <path
          class="st2 n20"
          ref={n20_1}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M630.13,1221.05c4.05,29.5-32.3,86.25,5.4,184.88c0,0,36.54-30.45,41.39-108.68c0,0-0.82-3.86-1.67-12.79
	c-0.42-4.4-0.69-9.76-1.02-13.79c-1.13-13.71,0.78-31.53,0.78-31.53c-4.04-31.84-14.02-45.28-19.7-50.68l-2.35,4.37l-1.98,3.5
	l-1.61,2.61l-2.59,3.95l-2.93,4.04l-2.2,2.75l-1.95,2.3l-2.36,2.66l-3.16,3.02l-1.95,1.71L630.13,1221.05z"
        />
        <path
          class="st2 n20"
          ref={n20_2}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M687.8,1185.56c0,0-45.51,95.58,28.81,214.73c0,0,33.81-59.54-5.4-160.54l-4.39-8.46l-3.22-6.57l-2.57-5.79
	l-3.21-7.61l-2.71-6.62l-3.3-8.32L687.8,1185.56z"
        />
        <g>
          <path
            class="st5 n27"
            ref={n27_1}
            onMouseEnter={n27Enter}
            onMouseLeave={n27Leave}
            onClick={() => setMuscle(27)}
            d="M691.71,669.29c-6.91-5.79-19.11-13.34-31.7-13.71c-15.76-0.47-33.65,15.63-36.27,19.74
		c-22.29,34.93-42.64,50.86-48.09,107.68c-4.74,49.37,4.28,76.88,47.88,96.4c46.13,20.66,126.46-18.84,120.55-90.94
		C737.25,705.23,717.51,690.9,691.71,669.29z"
          />
        </g>
        <path
          class="st6"
          d="M-432.65,1559.18c0,0,12.97-4.69,28.38,6.49c15.41,11.18,27.91,28.95,17.39,44.08
	c-10.53,15.13-31.58,38.16-40.79,52.63c0,0-30.92-9.21-28.95-57.9C-454.65,1555.8-432.65,1559.18-432.65,1559.18z"
        />
        <path
          class="st6"
          d="M-486.06,1429.31c0,0,18.9,129.12,12.33,160.7c-6.58,31.58-24.34,64.47-9.21,80.26
	c15.13,15.79,46.71,5.92,55.26-7.89c8.55-13.82-19.08-15.13-17.11-43.42c1.97-28.29,13.16-71.05,31.58-111.19
	c18.42-40.13,31.15-84.9,31.15-84.9s-8.03-25.73-50.84-25.68C-475.71,1397.24-486.06,1429.31-486.06,1429.31z"
        />
        <path
          class="st7"
          d="M-445.23,1275.52c0,0,4.99,4.33,9.42,17.11c0,0,2.48,7.46,4.1,13.59c0,0-0.08,17.94-0.27,39.1
	c0,0-0.26,20.4-0.71,34.74c0,0-0.4,8.31-0.33,14.96c0.06,5.52-0.76,10.92-1.3,12.75c-6.52,22.37-32.19,32.24-39.42,34.87
	c-7.23,2.63-22.37-26.32-20.39-60.53c1.97-34.21,10.41-65.68,15.13-103.95c0,0,0.87-3.79,4.31-5.72
	C-474.69,1272.44-457.83,1262.53-445.23,1275.52z"
        />
        <path
          class="st7"
          d="M-391.49,1272.9c11.18,26.97,26.32,118.42,13.82,142.77c-12.5,24.34-26.97,18.42-38.82,12.5
	c-11.84-5.92-17.59-19.75-16.45-42.11c0.38-7.4,0.85-31.73,1.07-51.45c0.18-15.76,0.16-28.4,0.16-28.4
	c1.92-9.23,4.01-21.34,4.01-21.34c3.77-18.19,21.73-15.75,21.73-15.75C-399.21,1269.65-391.49,1272.9-391.49,1272.9z"
        />
        <path
          class="st6"
          d="M-525.05,1022.55c0,0,7.63,45.55,8.93,73.66c1.42,30.75,7.64,72.46,7.64,72.46c1.75,11.08,4.3,24.64,4.3,24.64
	c12.25,45.17,25.18,84.84,25.18,84.84c12.63-20.64,20.53-33.95,31.2-63.46c9.17-29.07,11.76-40.6,10.96-70.39
	c-1.21-44.66,0.12-73.57-9.3-131.86c0,0-6.96-43.75-23.59-68.29l-31.63-6.14l-12.74,28.98
	C-514.11,967.01-525.44,998.31-525.05,1022.55z"
        />
        <path
          class="st7"
          d="M-469.73,944.16c0,0,26.92,34.65,30.21,133.34c3.29,98.69,1.97,126.32,48.03,195.4c0,0-10.97-64.72-3.29-96.05
	c8.68-35.4,12.82-69.74,12.82-69.74c3.99-41.54-0.68-91.57-0.68-91.57c-4.52-48.44-16.09-77.51-16.09-77.51
	C-441.48,946.98-469.73,944.16-469.73,944.16z"
        />
        <path
          class="st6"
          d="M-390.82,847.5c0,0,12.24,19.38,17.41,102.93c5.18,83.55-7.76,147.7-7.76,147.7
	c2.28-56.01-1.16-95.09-8.75-129.57c-2.66-10.08-5.13-20.82-8.89-30.51c-3.7-10.55,0.24-34.64,0.24-34.64l4.42-28.71l2.5-18.74
	L-390.82,847.5z"
        />
        <line class="st8" x1="-547.84" y1="182" x2="-547.84" y2="866.31" />
        <line class="st8" x1="-548.44" y1="182" x2="-548.44" y2="866.31" />
        <path
          class="st7"
          d="M-648.97,714.51c0,0,14.25-10.7,25.93-20.31c0,0,26.9-22.84,49.06-49.33c19.97-23.86,25.84-43.44,25.84-43.44
	s4.84,18.09,26.77,44.97c20.92,25.64,47.7,48.38,47.7,48.38c11.52,9.62,26.36,19.85,26.36,19.85s-71.89-13.1-95.38,94.55
	c-5.55,25.42-5.78,55.45-5.78,55.45s-0.27-30.13-5.69-55.22c-16.11-74.64-57.21-92.09-81.41-95.37
	C-643.7,712.94-648.97,714.51-648.97,714.51z"
        />
        <path
          class="st8"
          d="M-491.42,249.21c-1.76,11.02-112.84,0-112.84,0c-5.55-31.09-5.85-54.56-5.85-54.56s-16.01,3.84-22.31-27.25
	c-6.41-31.6,14.06-29.74,14.06-29.74s-6.57-20.01,0.3-50.91c6.87-30.9,36.88-51.62,70.22-51.62c33.32,0,63.35,20.72,70.22,51.62
	c6.85,30.9,0.28,50.91,0.28,50.91s20.49-1.87,14.08,29.74c-6.3,31.09-22.31,27.25-22.31,27.25S-487.21,222.88-491.42,249.21z"
        />
        <path
          class="st9"
          d="M-474.12,73.24c-0.23-3.06-2.61-9.39-3.26-11.14c-3.76-10.16-11.98-19.71-43.5-30.41
	c-52.07-17.67-87.19,19.86-87.19,19.86c-12.95,3.95-12.6,4.98-18.6,13.55c-4.86,6.94-5.17,22.59-3.27,28.63l10.01,36.94l1.83,13.95
	c3.95,9.79,5.94,12.9,9.86,26.34c2.78,9.53,7.41,24.86,12.86,32.57c5.14,7.29,18.67,8.73,42.96,8.73c30.53,0,46.61-3.58,51.33-10.44
	c4.11-5.98,10.28-17.48,14.57-31.71c3.16-10.48,9.53-21.52,11.46-32.36l1.89-21.36l-1.39-25.71
	C-473.31,85.45-473.72,78.53-474.12,73.24z"
        />
        <path
          class="st6"
          d="M-594.92,938.02c0,0,22.04,38.82,23.68,84.21c0,0,12.51-63.82,13.82-128.95L-594.92,938.02z"
        />
        <path
          class="st8"
          d="M-501.36,938.02c0,0-22.04,38.82-23.68,84.21c0,0-12.51-63.82-13.82-128.95L-501.36,938.02z"
        />
        <path
          class="st10"
          d="M-547.84,866.31c-0.43,26.97,10.95,57.24,46.48,71.71c35.53,14.47,102.63,0,102.63,0
	c-6.58-20.98,8.86-65.3,8.88-110.43c0.02-47.34-12.91-75.79-26.96-91.34c0,0-11.82-7.89-16.43-11.34
	c-6.55-4.9-14.07-10.29-14.07-10.29c-34.83-4.92-67.41,21.63-81.4,52.78C-545.59,805.01-547.28,830.93-547.84,866.31z"
        />
        <path
          class="st10"
          d="M-549.28,866.31c0.43,26.97-10.95,57.24-46.48,71.71c-35.53,14.47-102.63,0-102.63,0
	c6.58-20.98-8.86-65.3-8.88-110.43c-0.02-47.34,12.91-75.79,26.96-91.34c0,0,11.82-7.89,16.43-11.34
	c6.55-4.9,14.07-10.29,14.07-10.29c34.83-4.92,67.41,21.63,81.4,52.78C-551.53,805.01-549.85,830.93-549.28,866.31z"
        />
        <path
          class="st6"
          d="M-370.58,466.02c0,0-8.73-14.55-54.45-4.72c0,0-28.15,4.47-56.74,22.13c0,0,3.23-23.04,7.92-43.25
	c0,0,3.15-13.62,10.9-29.96c0,0,13.84-25.44,25.05-36.06c0,0,1.94,3.05,5.26,7.94c6.59,9.73,19.26,27.38,37.88,46.78
	c8.27,8.62,16.84,16.9,28.38,26.33C-366.39,455.22-367.08,460.49-370.58,466.02z"
        />
        <path
          class="st11"
          d="M-419.6,623.33c-5.73,22.98,4.48,24.98,7.87,61.41c2.96,31.82-5.22,51.33-5.22,51.33
	s-108.59-69.88-130.89-134.64c0,0,12.86-14.15,32.85-47.82c11.03-18.57,20.76-42.96,33.12-70.29c0,0,19.85-13.18,49.22-20.49
	c53.57-13.32,61.93,3.15,61.93,3.15c0,91.97-29.65,111.49-39.03,130.73C-414.32,606.09-417.73,615.11-419.6,623.33z"
        />
        <path
          class="st12"
          d="M-292.33,494.79c-35.64-11.19-50.56-22.82-61.48-30.28c-45.25-30.93-79-82.67-79-82.67
	s30.65-48.36,67.39-41.01S-261.35,364.79-292.33,494.79z"
        />
        <path
          class="st12"
          d="M-222.27,662.15l-19.47-12.52l-11.46-3.64l-11.57,2.21l-11.53,9.74l-6.12,9.63
	c-28.6-19.2-52.48-26.35-52.48-26.35c2.27-9.37,2.03-18.27,1.86-28.66c-0.36-21.77-3.56-39.76,10.41-50.66
	c0,0,22.92-0.49,30.29,1.96c3.5,1.17,9.93,8.01,16.94,14.85c4.9,4.77,11.19,11.11,19,18.89c0.83,0.83,3.78,3.78,3.78,3.78
	c7.15,5.68,12.59,11,17.66,16.92c8.32,9.72,13.71,18.7,18.13,27.61c6.14,12.38,9.59,26.27,11.01,32.96L-222.27,662.15z"
        />
        <path
          class="st13"
          d="M-335.12,640.05c10.67,3.38,32.56,12.56,52.48,26.35c9.13-18.01,20.86-20.66,20.86-20.66
	c7.63-2.16,12.3-1.22,20.04,2.55c0,0,19.72,8.87,35.99,29.1c-7.29-36.07-26.21-61.01-46.99-77.3c0,0-11.02-11.16-15.85-15.84
	c-12.6-12.21-19.52-20.08-23.97-21.56c-10.6-2.53-23.49-1.98-30.29-1.96c-7.29,5.35-10.27,14.12-10.81,24.47
	c-0.52,5.03,0.36,20.78,0.53,35.34C-333.02,628.93-333.8,635.15-335.12,640.05z"
        />
        <g>
          <path
            class="st6"
            d="M-211.52,853.15c3.49-21.23,42.09-17,42.09-17c17.41,8.97,38.99,12.89,52.15,26.28
		c13.16,13.39,21.08,16.02,34.73,20.38c13.66,4.35,8.5,19.5-9.23,17.5c-17.75-2.01-26.87-5.78-38.17-10.13l0,0c0,0,0,0,0,0.01
		c14.52,31.62,22.2,55.45,26.51,83.87c0.31,18.36-31.41,15.87-43.89,10.06c-23.82-10.06-41.26-53.7-51.92-66.78
		C-209.81,903.23-215,874.38-211.52,853.15z"
          />
        </g>
        <path class="st6" d="M-115.87,924.4c0,0-26.97,15.43-75.22,5.58" />
        <path class="st6" d="M-129.95,890.18c0,0-4.31-9.52-5.55-11.84" />
        <path
          class="st6"
          d="M-209.2,847.09c0,0-28.81-60.46-55.7-79.01c-26.89-18.55-69.14-91.85-69.99-126.88
	c0,0,79.97,24.04,113.01,95.04c33.04,71,35.45,100.08,35.45,100.08L-209.2,847.09z"
        />
        <path
          class="st7"
          d="M-187.1,836.4c0,0-17.85-75.75-47.36-121.58c0,0-16.57-26.89-47.95-47.26c0,0,14.75-32.54,40.68-17.93
	c0,0,18.68,7.87,36.22,29.26c12.61,15.38,19.91,41.31,23.05,66.64c6.91,55.77,19.52,93.68,19.52,93.68L-187.1,836.4z"
        />
        <path
          class="st6"
          d="M-475.73,724.29c7.82-9.87,15.17-12.15,28.42-9.66c0,0,9.21,6.86,15.13,11.04
	c5.51,4.12,15.37,10.59,15.37,10.59c11.63,12.55,16.91,28.56,18.74,33.61c6.36,19.1,8.22,40.41,8.22,57.26
	c-0.14,6.55-0.16,12.89-0.8,19.21c-21.59-52.08-60.17-70.26-74.29-81.68C-480.56,752.12-480.38,730.35-475.73,724.29z"
        />
        <path
          class="st14 n26"
          ref={n26_1}
          onMouseEnter={n26Enter}
          onMouseLeave={n26Leave}
          onClick={() => setMuscle(26)}
          d="M653.21,661.79c4.5-3.93,16.35-6.23,24.22-1.65c0,0,10.57,5.18,17.44,11.79c7.09,5.14,17.33,15.91,17.33,15.91
	c11.63,12.55,16.69,25.46,18.53,30.52c4.06,9.37,7.79,26.35,10.1,41.4c0.68,4.43,1.17,8.68,1.65,12.5c0.44,4.04,0.97,9.2,1.37,14.21
	c0.26,3.22,0.53,6.2,0.52,8.82c-21.59-52.08-73.53-69.69-87.65-81.1C641.12,701.64,642.93,670.14,653.21,661.79z"
        />
        <path
          class="st7"
          d="M-398.28,344.64c16.63-5.71,28.24-4.74,33.6-3.66c7.27,1.46,20.93,3.66,39.7,13.16c0,0,23.08,10.6,33.63,38.82
	c0,0,9.62,23.59,6.3,57.87c-2.63,27.14-7.29,43.97-7.29,43.97c-13.41-4.5-19-6.65-24.89-9.17c-2.95-1.26-5.73-2.46-10.44-4.89
	c3.16-17.29-3.19-47.38-17.52-74.28C-375.13,350.22-398.28,344.64-398.28,344.64z"
        />
        <path
          class="st15"
          d="M-398.28,344.64c-9.62,3.42-17.73,9.95-17.73,9.95c-8.65,6.84-14.94,12.04-21.89,19.57
	c0,0,18.55,29.54,40.19,51.65c17.11,18.54,26.01,25.11,39.41,35.56c10.32,8.05,19.66,13.84,30.65,19.36
	c3.77-26.91-12.8-69.14-29.4-94.99C-367.34,370.37-382.82,350.13-398.28,344.64z"
        />
        <path
          class="st16 n7"
          ref={n7_1}
          onMouseEnter={n7Enter}
          onMouseLeave={n7Leave}
          onClick={() => setMuscle(7)}
          d="M684.54,326.18c12.18-6.11,25.82-4.55,30.95-3.91c6.74,0.84,13.55,1.59,21.58,4.8c0,0,19.93,7.93,29.25,41.09
	c0,0,2.48,8.24,5.25,18.55c2.37,8.81,5.92,20.7,8.66,36.25c4.33,24.64,3.65,43.17,3.65,43.17c-7.4-10.72-11.86-17.82-16.51-23.54
	c-5.94-7.31-12.16-16.24-18.36-19.63c-0.37-2.11-0.81-4.04-1.28-5.76c-2.74-10-4.19-15.85-13.5-33.48
	c-3.68-6.97-7.67-13.12-11.15-18.53c-10.02-15.6-18.55-25.16-25.09-30.69C689.31,327.18,684.54,326.18,684.54,326.18z"
        />
        <path
          class="st17 n8"
          ref={n8_1}
          onMouseEnter={n8Enter}
          onMouseLeave={n8Leave}
          onClick={() => setMuscle(8)}
          d="M684.54,326.18c-8.46,4.29-16.39,10.34-16.39,10.34c-6.02,4.68-9.85,7.26-13.72,10.75
	c-1.47,1.33-3.06,2.83-4.66,4.5c0,0,13.15,30.16,37.37,44.73c16.93,11.7,28.39,9.64,40.93,16.07c9.54,4.89,12.3,4.64,20.94,10.4
	c-5.09-25.54-16.26-42.04-26.42-58.56C712.88,349.99,699.15,331.34,684.54,326.18z"
        />
        <path
          class="st18 n16"
          ref={n16_1}
          onMouseEnter={n16Enter}
          onMouseLeave={n16Leave}
          onClick={() => setMuscle(16)}
          d="M579.1,836.48c-0.34,4.07,1.48,29.89,1.7,33.91c0,0,2.57,27.43,5.97,48.85c2.29,18.57,13.93,66.11,17.66,92.69
	c-5.28-26.22-6.85-111.14,7.03-138.65C596.18,864.61,584.37,852.26,579.1,836.48z"
        />
        <path
          class="st19"
          d="M-482.07,483.44c-11.92,28.31-19.13,44.17-27.08,61.55c-12.25,20.81-25.66,41.8-38.99,56.44
	c-11.66-14.05-18.45-23.74-29.45-41.78c-12.46-18.1-22.43-45.47-28.34-57.07c-5.55-12.26-6.29-13.31-8.88-19.14
	C-549.99,483.25-546.89,483.62-482.07,483.44z"
        />
        <path
          class="st20 n24"
          ref={n24}
          onMouseEnter={n24Enter}
          onMouseLeave={n24Leave}
          onClick={() => setMuscle(24)}
          d="M619.78,454.89c-0.64,10.45-1.02,19.84-1.29,28.03c-0.22,6.75-0.18,13.13-0.62,19.08
	c-0.24,3.18-0.75,6.18-1.21,9.15c-1.21,7.75-3.17,15.04-5.27,21.73c-3.46,10.71-8.32,21.57-13.76,31.41
	c-2.86,5.17-6.48,10.93-9.52,15.47c-4.66,6.96-9.07,11.82-13.1,16.1c-4.88-5.61-9.21-12-13.79-18.92
	c-5.29-8-10.28-16.96-14.07-25.27c-1.68-4.19-3.6-8.05-4.95-11.87c-5.48-15.53-9.31-28.9-9.43-52.94
	c-0.52-15.06-0.61-24.81-1.39-32.35C573.88,454.29,577.27,455.11,619.78,454.89z"
        />
        <path
          class="st10"
          d="M-540.14,274.51h-16.71c-7.23,28.29-15.05,39.92-30.73,49.4c-14.22,8.6-30.04,13.42-41.75,17.22
	c-22.42,7.28-50.93,13.45-50.93,13.45c11.51,8.93,12.83,10.23,21.89,19.57c9.21,9.49,17.26,22.87,17.26,22.87
	c15.31,23.19,21.75,49.82,26.6,86.4c0,0,44.81-0.25,64.43-0.5c17.46-0.22,68.31,0.5,68.31,0.5c5.92-40,10.59-63.32,28.14-88.74
	c0,0,5.82-10.41,15.82-20.66c6.43-6.59,11.42-11.81,21.77-19.45c0,0-14.93-1.49-37.22-8.72c0,0-44.58-11.16-65.57-28.21
	c0,0-3.46-2.29-7.75-8.69C-531.18,302.1-536.24,290.67-540.14,274.51z"
        />
        <path
          class="st2 23"
          ref={n23}
          onMouseEnter={n23Enter}
          onMouseLeave={n23Leave}
          onClick={() => setMuscle(23)}
          d="M579.53,269.91h-11.63c-0.36,5.47-1,10.19-1.77,14.49c-1.61,8.95-3.96,15.62-6.7,20.23
	c-3.36,5.63-6.5,8.57-8.04,10.07c-1.49,1.45-3.41,2.84-4.28,3.62c-1.39,1.24-3.41,3.22-5.02,4.4c-2.74,2-5.42,3.41-7.92,4.62
	c-2.46,1.19-4.75,2.2-7.2,3.15c-1.59,0.62-3.58,1.61-5.38,2.15c-2.95,0.87-6.1,1.84-9.41,2.75c-5.63,1.56-11.48,3.08-16.27,4.03
	c-6.04,1.2-10.35,1.7-10.35,1.7c7.37,6.34,14.1,11.53,19.77,19.7c5.74,8.26,11.04,20.46,11.04,20.46
	c4.17,10.16,7.28,19.28,9.36,30.96c1.38,7.78,2.39,16.09,3.59,25.3c0.76,5.89,1.7,11.15,2.08,16.96l88.4,0.39
	c1.98-19.54,3.43-35.06,6.6-50.55c1.91-9.34,4.55-18.47,7.98-27.29c0,0,3.46-9.14,9.55-18.22c1.53-2.28,3.58-4.67,5.87-7.07
	c3.72-3.91,7.8-7.41,12.28-10.62c0,0-15.51-1.97-29.87-6.64c0,0-12.66-3.51-22.63-9.08c-3.7-2.07-7.01-4.89-9.22-7.1
	c0,0-2.33-1.62-5.15-4.46c-2.99-3.01-6.55-7.39-8.92-12.54c-1.88-4.08-3.88-10.3-5.16-18.33
	C580.48,279.06,579.94,274.74,579.53,269.91z"
        />
        <path
          class="st11"
          d="M-366.39,455.22c-1.21,6.96-4.2,10.8-4.2,10.8c-0.3,9.48,0,17.58-1.57,35.58c-2.43,27.69-9.88,36.68-6.1,54.94
	c3.36,31.83,14.77,56.56,43.37,84.66c2.48-8.37,1.94-23.56,1.94-23.56c0.36-21.24-5.19-44.13,10.34-55.76
	c-1.34-6.21-2.87-13.15-4.09-19.03c-3.59-10.29-8.85-28.21-13.1-38.47C-347.69,484.3-353.45,471.23-366.39,455.22z"
        />
        <path
          class="st7"
          d="M-366.39,455.22c16.83,19.94,25.3,45.01,34.07,70.06c3.92,9.88,5.61,17.58,9.7,36.61
	c8.01-0.36,23.18-0.01,30.29,1.96c4.8,2.05,11.09,8.96,19.38,17.2c10.63,10.58,18.73,18.72,18.73,18.72
	c-2.02-15.94-6.26-48.44-9.32-64.43c-8.84-22.01-11.92-24.77-28.62-40.42c-8.53-3.12-20.75-7.08-35.33-14.06
	C-341.15,474.33-359.05,460.79-366.39,455.22z"
        />
        <path class="st21" d="M-261.81,657.49" />
        <path
          class="st6"
          d="M-515.69,319.38c8.18,5.87,22.45,13.05,45.35,20.75c26.37,7.83,34.5,10,54.33,14.46
	c16.98-12.68,31.6-14.06,40.88-14.37c-22.8-23.26-65.3-49.72-86.92-63.46L-515.69,319.38z"
        />
        <path
          class="st7"
          d="M-545.75,183.42c0.97,21.25,2.69,37.08,3.17,47.93c0.42,16.7,0.73,23.25,1.82,35.2
	c0.83,7.66,4.01,19.85,8.02,28.85c6.71,15.06,11.97,20.17,17.04,23.98c0,0,53.64-42.33,53.64-42.62
	c-17.32-11.5-24.39-19.21-27.47-22.6c-9.72-11.88-12.99-18.15-16.54-35.01c-0.64-3.16-1.97-11.75-5.75-21.62
	C-518.38,183.93-537.74,183.7-545.75,183.42z"
        />
        <path
          class="st6"
          d="M-580.15,319.38c-8.18,5.87-22.45,13.05-45.35,20.75c-26.37,7.83-34.5,10-54.33,14.46
	c-16.98-12.68-31.6-14.06-40.88-14.37c22.8-23.26,65.3-49.72,86.92-63.46L-580.15,319.38z"
        />
        <path
          class="st7"
          d="M-550.09,183.42c-0.97,21.25-2.69,37.08-3.17,47.93c-0.42,16.7-0.73,23.25-1.82,35.2
	c-0.83,7.66-4.01,19.85-8.02,28.85c-6.71,15.06-11.97,20.17-17.04,23.98c0,0-53.64-42.33-53.64-42.62
	c17.32-11.5,24.39-19.21,27.47-22.6c9.72-11.88,12.99-18.15,16.54-35.01c0.64-3.16,1.97-11.75,5.75-21.62
	C-577.46,183.93-558.1,183.7-550.09,183.42z"
        />
        <path
          class="st14 n22"
          ref={n22_1}
          onMouseEnter={n22Enter}
          onMouseLeave={n22Leave}
          onClick={() => setMuscle(22)}
          d="M600.34,318.33c5.7,5.61,9.59,8.1,25.52,14.32c17.36,5.14,22.7,6.57,36.2,8.5c2.84-2.19,5.68-4.44,8.68-6.57
	c4.51-3.21,9.24-6.17,13.79-8.41c1.78-0.88,3.64-1.6,5.43-2.2c2.25-0.76,5.28-1.46,8.3-1.88c1.07-0.15,2.2-0.22,3.22-0.3
	c1.61-0.12,3.05-0.2,4.24-0.17c-37.86-9.74-57.31-26.14-72.37-39.27L600.34,318.33z"
        />
        <path
          class="st16 n2"
          ref={n2_2}
          onMouseEnter={n2Enter}
          onMouseLeave={n2Leave}
          onClick={() => setMuscle(2)}
          d="M576.15,191.95c0.67,20.31,1.87,35.43,2.21,45.8c0.29,15.96,0.51,22.22,1.27,33.63
	c0.58,7.32,2.21,19.18,5.59,27.57c3.99,9.91,10.71,16.05,15.12,19.38c0,0,33.01-35.71,33.01-35.98
	C620.17,271.22,617.29,267.43,614,261c-3-6.71-7.43-14.93-7-35.6c-0.45-3.02-0.11-14.79-0.04-24.81
	C597.04,192.82,581.73,192.22,576.15,191.95z"
        />
        <path
          class="st0"
          d="M418.61,1658.89c9.61-3.76,24.05-20.31,27.78-36.87c3.73-16.55,14.6-20.84,13.71-30.99
	c-0.57-6.49,1.59-13.33,3.24-22.38c-0.71-6.31-2.51-14.33-5.15-26.94c-6.08-29.05-20.94-89.17-27.7-145.25
	c-6.76-56.07,14.52-122.28,40.7-122.28c26.18,0,40.7,131.73,40.7,131.73c-16.21,77.69-8.79,126.74-9.13,140.52
	c-0.34,13.78,3.03,28.15,3.03,28.15c3.72,10.35,3.13,24.66,0,34.67c-3.13,10.02,13.86,47.96-1,59.45
	c-7.25,5.6-16.76,8.93-26.24,9.33c0.32,0.01,0.63,0.06,0.95,0.06c-13.86,0-47.45,0-57.18,0
	C404.2,1678.12,408.99,1662.62,418.61,1658.89z"
        />
        <path
          class="st1"
          d="M503.34,1390.03c0,0-29.97,131.54-15.12,206.61c6.94,35.12,27.13,52.04,23.68,68.9
	c-2.81,13.77-44.91,17.83-51.54,5.31c-6.63-12.52,8.52-17.23,10.7-70.62s-0.5-148.36-35.37-208c0,0-4.84-9.68-3.83-24.38
	c1.01-14.69,15.74-78.28,40.57-78.79S503.34,1390.03,503.34,1390.03z"
        />
        <path
          class="st0 n29"
          ref={n29_1}
          onMouseEnter={n29Enter}
          onMouseLeave={n29Leave}
          onClick={() => setMuscle(29)}
          d="M568.34,836.48c0,0-0.2,54.68-15.07,122.23c-14.86,67.56-20.27,120.25-17.56,166.19
	c2.7,45.94-18.4,96.15-18.4,96.15s-29.15-19.14-42.66-83.99c0-0.16,1.02-3.77,2.28-10.39c1.37-7.17,2.34-17.47,3.23-29.38
	c1.22-16.3,1.4-36.2,0.17-56.14c-3.4-55.05-5.23-101.76-6.68-130.89c-0.89-17.95,0.46-28.33,0.46-28.33l5.61,1.27l5.24,0.87
	l5.64,0.59l2.15,0.13l3.12,0.08l2.88-0.02l3.44-0.17l2.89-0.27l2.68-0.35l2.52-0.43l2.04-0.42l2.63-0.64l3.77-1.13l3.24-1.2
	l2.84-1.23l2.14-1l2.01-0.97l1.68-0.85l1.62-0.84l1.51-0.77l1.1-0.65l1.56-0.89l1.12-0.66l1.08-0.66l1.14-0.71l1.4-0.91l1.3-0.87
	l1.04-0.72l1.06-0.76l1.32-0.98l1.56-1.21l1.03-0.83l1.2-1.01l1.2-1.05l0.95-0.87l0.97-0.92l0.86-0.85l0.9-0.92l0.93-1l0.88-0.98
	l0.79-0.92l0.79-0.95l0.9-1.14l0.79-1.04l0.94-1.3l0.85-1.24l0.9-1.4l1-1.67l0.43-0.74l0.48-0.88l0.64-1.22l0.48-0.96l0.53-1.12
	l0.51-1.13l0.54-1.27l0.52-1.3L568.34,836.48z"
        />
        <path
          class="st2 n28"
          ref={n28_1}
          onMouseEnter={n28Enter}
          onMouseLeave={n28Leave}
          onClick={() => setMuscle(28)}
          d="M474.12,881.93c-2.23,15.47,0.43,53.56,3.25,97.07c0.75,11.53,1.16,23.45,1.79,35.43
	c0.92,17.62,1.62,35.36,1.7,52.21c0.13,27.24-1.36,52.15-6.21,70.42c-17.56,66.21-38.41,102.69-38.41,102.69
	c7.32-74.31-16.48-95.03-31.46-165.67c0,0-0.91-10.08-1.33-21.67c-0.34-9.62-1.4-22.79-2.03-45.28c-0.4-14.43-0.8-29.67-0.99-44.91
	c-0.08-6.15-0.05-12.29-0.02-18.39c0.03-9.85,0.33-19.57,0.6-29.11c0.24-8.4,0.76-16.73,1.4-24.71c0.41-5.04,0.74-9.89,1.47-14.69
	c2.52-16.61,4.98-30.73,10.58-37.14c0,0,7.99,13.21,18.31,21.97C453.23,877.5,474.12,881.93,474.12,881.93z"
        />
        <path
          class="st0 n28"
          ref={n28_2}
          onMouseEnter={n28Enter}
          onMouseLeave={n28Leave}
          onClick={() => setMuscle(28)}
          d="M405.5,816.27c3.29,11.82,8.96,21.9,8.96,21.9c-24.37,28.43-9.68,235.91-9.68,235.91
	c-26.5-122.92-21.63-206.79-8.13-256.72c3.38-12.48,6.27-21.45,6.4-22.07c0,0-0.18,3.41,0.41,9.11
	C403.8,807.67,404.23,811.7,405.5,816.27z"
        />
        <path
          class="st0"
          d="M201.29,972.74c-2.9,16.14,37.02,18.95,52.42,0.78c14.93-17.61,29.96-54.18,30.87-56.45
	c-4.41,7.32-10.4,13.54-18.2,16.82c-19.85,8.37-51.42-5.85-48.92-23.01C205.06,944.06,203.43,960.86,201.29,972.74z"
        />
        <path
          class="st0"
          d="M183.84,889.99c12.4-3.73,19.62-5.98,31.7-17.87c8.28-8.14,45.42-21.46,33.72-17.2
	c-0.43,0.16,10.2,3.38,15.84,5.34c3.07,1.19,5.4,2.14,7.65,3.06c1.53,0.62,3.13,1.24,4.8,2.01c2.82,1.31,5.81,3.52,9.84,6.77
	l7.01,7.42l0.17,2.54c-0.13,9.76-3.18,23.62-9.95,34.89c0,0-0.01,0.03-0.05,0.11c-4.41,7.32-10.4,13.54-18.2,16.82
	c-19.85,8.37-51.42-5.85-48.92-23.01c0.84-5.69,3.71-12.5,7.82-19.43c-6.97-2.67-17.79,4.29-32.82,10.18
	C177.44,907.53,171.43,893.71,183.84,889.99z"
        />
        <path
          class="st2 n11"
          ref={n11_2}
          onMouseEnter={n11Enter}
          onMouseLeave={n11Leave}
          onClick={() => setMuscle(11)}
          d="M270.25,787.55c-5.17,31.83-20.99,67.38-20.99,67.38s12.35,4.03,16.47,5.57c3.17,1.19,5.95,2.39,11.88,4.84
	c0,0,4.27-10.43,6.37-20.44c2.93-13.96,14.71-43.47,44.06-107.76c12.24-26.81,21.05-49.92,24.88-69.68
	c2.87-14.82,1.21-26.31,1.21-26.31c-2.71-7.91-4.68-14.41-5.74-19.4c-0.66-3.13-1.43-6.64-1.94-10.47
	c-1.64-12.33-1.29-26.34-1.29-26.34C284.3,664.14,278.72,735.37,270.25,787.55z"
        />
        <path
          class="st0 n12"
          ref={n12_1}
          onMouseEnter={n12Enter}
          onMouseLeave={n12Leave}
          onClick={() => setMuscle(12)}
          d="M384.64,612.58c-0.87,1.09-0.87,1.09-0.87,1.09c11.64,29.9-3.72,81.67-16.74,111.36
	c-16.3,37.14-70.49,109.63-72.6,154.69c-6.91-9.5-16.81-14.38-16.81-14.38c0.24-0.57,1.83-4.64,2.93-7.97
	c0.67-2.02,1.17-3.82,1.28-4.19c1.69-5.53,2.84-12.2,4.96-18.39c6.39-18.74,14.35-37.27,22.66-56.21
	c22.85-52.11,49.25-100.99,44.7-137.43c0,0,5.15-3.39,11.66-8.59c8.04-6.41,9.04-7.33,14.12-12.71
	C383.05,616.54,383.9,614.48,384.64,612.58z"
        />
        <path
          class="st3"
          d="M376.26,623.63c-6.75,6.84-22.12,17.52-22.12,17.52s-7.67-19.03-8.82-41.37c-0.41-8.03,1.08-29.61,2.27-31.77
	c7.41-13.35,16.02-23.49,26.4-29.25c12.12-6.73,22.19-1.18,25.83,0.7c0,0-2.22,13.79-3.65,21.92c-1.99,11.29-5.69,27.8-8.6,39.53
	c-1.69,6.82-2.28,9.14-2.93,11.67C383.68,616.35,379.2,620.65,376.26,623.63z"
        />
        <path
          class="st2 n30"
          ref={n30_1}
          onMouseEnter={n30Enter}
          onMouseLeave={n30Leave}
          onClick={() => setMuscle(30)}
          d="M399.82,539.46c0,0,1.6-12.47,2.72-24.04c0.78-8.11,0.81-15.14,0.86-24.41c0.05-8.06-0.68-15.5-1.62-22.77
	c-0.68-5.29-1.33-10.51-2.56-15.76c-4.42-18.97-0.79-29.5-0.79-29.5s-2.2,0.64-8.33,7.26c-1.25,1.36-4.89,5.95-6.14,7.47
	c-4.74,5.74-9.32,11.87-12.57,16.94c-1.58,2.46-3.17,4.69-4.42,6.53c-2.34,3.44-3.79,5.57-3.79,5.57s-22.09,28.05-15.58,101.29
	C347.59,568.02,370.8,521.96,399.82,539.46z"
        />
        <path
          class="st0 n31"
          ref={n31_2}
          onMouseEnter={n31Enter}
          onMouseLeave={n31Leave}
          onClick={() => setMuscle(31)}
          d="M409.75,416.95c-4.84,1.71-11.31,6.02-11.31,6.02c-7.95,34.18,23.72,43.77-13.79,189.62
	c0,0,70.3-81.08,56.29-151.55l-2.43-15.5l-3.92-20.24l-2.28-9.46l-2-7.3l-4.98,1.51l-2.53,0.95l-2.57,1.15l-4.63,2.27L409.75,416.95
	z"
        />
        <path
          class="st2 n20"
          ref={n20_3}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M517.31,1221.05c-4.05,29.5,32.3,86.25-5.4,184.88c0,0-36.54-30.45-41.39-108.68c0,0,0.82-3.86,1.67-12.79
	c0.42-4.4,0.69-9.76,1.02-13.79c1.13-13.71-0.78-31.53-0.78-31.53c4.04-31.84,14.02-45.28,19.7-50.68l2.35,4.37l1.98,3.5l1.61,2.61
	l2.59,3.95l2.93,4.04l2.2,2.75l1.95,2.3l2.36,2.66l3.16,3.02l1.95,1.71L517.31,1221.05z"
        />
        <path
          class="st2 n20"
          ref={n20_4}
          onMouseEnter={n20Enter}
          onMouseLeave={n20Leave}
          onClick={() => setMuscle(20)}
          d="M459.64,1185.56c0,0,45.51,95.58-28.81,214.73c0,0-33.81-59.54,5.4-160.54l4.39-8.46l3.22-6.57l2.57-5.79
	l3.21-7.61l2.71-6.62l3.3-8.32L459.64,1185.56z"
        />
        <g>
          <path
            class="st5 n27"
            ref={n27_2}
            onMouseEnter={n27Enter}
            onMouseLeave={n27Leave}
            onClick={() => setMuscle(27)}
            d="M455.73,669.29c6.91-5.79,19.11-13.34,31.7-13.71c15.76-0.47,33.65,15.63,36.27,19.74
		c22.29,34.93,42.64,50.86,48.09,107.68c4.74,49.37-4.28,76.88-47.88,96.4c-46.13,20.66-126.46-18.84-120.55-90.94
		C410.2,705.23,429.93,690.9,455.73,669.29z"
          />
        </g>
        <path
          class="st14 n26"
          ref={n26_2}
          onMouseEnter={n26Enter}
          onMouseLeave={n26Leave}
          onClick={() => setMuscle(26)}
          d="M494.23,661.79c-4.5-3.93-16.35-6.23-24.22-1.65c0,0-10.57,5.18-17.44,11.79
	c-7.09,5.14-17.33,15.91-17.33,15.91c-11.63,12.55-16.69,25.46-18.53,30.52c-4.06,9.37-7.79,26.35-10.1,41.4
	c-0.68,4.43-1.17,8.68-1.65,12.5c-0.44,4.04-0.97,9.2-1.37,14.21c-0.26,3.22-0.53,6.2-0.52,8.82c21.59-52.08,73.53-69.69,87.65-81.1
	C506.32,701.64,504.51,670.14,494.23,661.79z"
        />
        <path
          class="st16 n7"
          ref={n7_2}
          onMouseEnter={n7Enter}
          onMouseLeave={n7Leave}
          onClick={() => setMuscle(25)}
          d="M462.91,326.18c-12.18-6.11-25.82-4.55-30.95-3.91c-6.74,0.84-13.55,1.59-21.58,4.8
	c0,0-19.93,7.93-29.25,41.09c0,0-2.48,8.24-5.25,18.55c-2.37,8.81-5.92,20.7-8.66,36.25c-4.33,24.64-3.65,43.17-3.65,43.17
	c7.4-10.72,11.86-17.82,16.51-23.54c5.94-7.31,12.16-16.24,18.36-19.63c0.37-2.11,0.81-4.04,1.28-5.76
	c2.74-10,4.19-15.85,13.5-33.48c3.68-6.97,7.67-13.12,11.15-18.53c10.02-15.6,18.55-25.16,25.09-30.69
	C458.13,327.18,462.91,326.18,462.91,326.18z"
        />
        <path
          class="st17 n8"
          ref={n8_2}
          onMouseEnter={n8Enter}
          onMouseLeave={n8Leave}
          onClick={() => setMuscle(24)}
          d="M462.91,326.18c8.46,4.29,16.39,10.34,16.39,10.34c6.02,4.68,9.85,7.26,13.71,10.75
	c1.47,1.33,3.06,2.83,4.66,4.5c0,0-13.15,30.16-37.37,44.73c-16.93,11.7-28.39,9.64-40.93,16.07c-9.54,4.89-12.3,4.64-20.94,10.4
	c5.09-25.54,16.26-42.04,26.42-58.56C434.57,349.99,448.29,331.34,462.91,326.18z"
        />
        <path
          class="st18 n16"
          ref={n16_2}
          onMouseEnter={n16Enter}
          onMouseLeave={n16Leave}
          onClick={() => setMuscle(23)}
          d="M568.34,836.48c0.34,4.07-1.48,29.89-1.7,33.91c0,0-2.57,27.43-5.97,48.85
	c-2.29,18.57-13.93,66.11-17.66,92.69c5.28-26.22,6.85-111.14-7.03-138.65C551.26,864.61,563.08,852.26,568.34,836.48z"
        />
        <path
          class="st14 n22"
          ref={n22_2}
          onMouseEnter={n22Enter}
          onMouseLeave={n22Leave}
          onClick={() => setMuscle(22)}
          d="M547.11,318.33c-5.7,5.61-9.59,8.1-25.52,14.32c-17.36,5.14-22.7,6.57-36.2,8.5
	c-2.84-2.19-5.68-4.44-8.68-6.57c-4.51-3.21-9.24-6.17-13.79-8.41c-1.78-0.88-3.64-1.6-5.43-2.2c-2.25-0.76-5.28-1.46-8.3-1.88
	c-1.07-0.15-2.2-0.22-3.22-0.3c-1.61-0.12-3.05-0.2-4.24-0.17c37.86-9.74,57.31-26.14,72.37-39.27L547.11,318.33z"
        />
        <path
          class="st16 n2"
          ref={n2_1}
          onMouseEnter={n2Enter}
          onMouseLeave={n2Leave}
          onClick={() => setMuscle(2)}
          d="M571.29,191.95c-0.67,20.31-1.87,35.43-2.21,45.8c-0.29,15.96-0.51,22.22-1.27,33.63

	c-0.58,7.32-2.21,19.18-5.59,27.57c-3.99,9.91-10.71,16.05-15.12,19.38c0,0-33.01-35.71-33.01-35.98
	c13.18-11.12,16.06-14.91,19.35-21.34c3-6.71,7.43-14.93,7-35.6c0.45-3.02,0.11-14.79,0.04-24.81
	C550.41,192.82,565.71,192.22,571.29,191.95z"
        />
        <path
          class="st6"
          d="M-663.63,1559.18c0,0-12.97-4.69-28.38,6.49c-15.41,11.18-27.91,28.95-17.39,44.08
	c10.53,15.13,31.58,38.16,40.79,52.63c0,0,30.92-9.21,28.95-57.9C-641.63,1555.8-663.63,1559.18-663.63,1559.18z"
        />
        <path
          class="st6"
          d="M-610.22,1429.31c0,0-18.9,129.12-12.33,160.7c6.58,31.58,24.34,64.47,9.21,80.26
	c-15.13,15.79-46.71,5.92-55.26-7.89c-8.55-13.82,19.08-15.13,17.11-43.42c-1.97-28.29-13.16-71.05-31.58-111.19
	c-18.42-40.13-31.15-84.9-31.15-84.9s8.03-25.73,50.84-25.68C-620.58,1397.24-610.22,1429.31-610.22,1429.31z"
        />
        <path
          class="st7"
          d="M-651.05,1275.52c0,0-4.99,4.33-9.42,17.11c0,0-2.48,7.46-4.1,13.59c0,0,0.08,17.94,0.27,39.1
	c0,0,0.26,20.4,0.71,34.74c0,0,0.4,8.31,0.33,14.96c-0.06,5.52,0.76,10.92,1.3,12.75c6.52,22.37,32.19,32.24,39.42,34.87
	c7.23,2.63,22.37-26.32,20.39-60.53c-1.97-34.21-10.41-65.68-15.13-103.95c0,0-0.87-3.79-4.31-5.72
	C-621.6,1272.44-638.45,1262.53-651.05,1275.52z"
        />
        <path
          class="st7"
          d="M-704.79,1272.9c-11.18,26.97-26.32,118.42-13.82,142.77c12.5,24.34,26.97,18.42,38.82,12.5
	c11.84-5.92,17.59-19.75,16.45-42.11c-0.38-7.4-0.85-31.73-1.07-51.45c-0.18-15.76-0.16-28.4-0.16-28.4
	c-1.92-9.23-4.01-21.34-4.01-21.34c-3.77-18.19-21.73-15.75-21.73-15.75C-697.07,1269.65-704.79,1272.9-704.79,1272.9z"
        />
        <path
          class="st6"
          d="M-571.23,1022.55c0,0-7.63,45.55-8.93,73.66c-1.42,30.75-7.64,72.46-7.64,72.46
	c-1.75,11.08-4.3,24.64-4.3,24.64c-12.25,45.17-25.18,84.84-25.18,84.84c-12.63-20.64-20.53-33.95-31.2-63.46
	c-9.17-29.07-11.76-40.6-10.96-70.39c1.21-44.66-0.12-73.57,9.3-131.86c0,0,6.96-43.75,23.59-68.29l31.63-6.14l12.74,28.98
	C-582.17,967.01-570.84,998.31-571.23,1022.55z"
        />
        <path
          class="st7"
          d="M-626.55,944.16c0,0-26.92,34.65-30.21,133.34c-3.29,98.69-1.97,126.32-48.03,195.4c0,0,10.97-64.72,3.29-96.05
	c-8.68-35.4-12.82-69.74-12.82-69.74c-3.99-41.54,0.68-91.57,0.68-91.57c4.52-48.44,16.09-77.51,16.09-77.51
	C-654.8,946.98-626.55,944.16-626.55,944.16z"
        />
        <path
          class="st6"
          d="M-705.47,847.5c0,0-12.24,19.38-17.41,102.93c-5.18,83.55,7.76,147.7,7.76,147.7
	c-2.28-56.01,1.16-95.09,8.75-129.57c2.66-10.08,5.13-20.82,8.89-30.51c3.7-10.55-0.24-34.64-0.24-34.64l-4.42-28.71l-2.5-18.74
	L-705.47,847.5z"
        />
        <path
          class="st6"
          d="M-725.7,466.02c0,0,8.73-14.55,54.45-4.72c0,0,28.15,4.47,56.74,22.13c0,0-3.23-23.04-7.92-43.25
	c0,0-3.15-13.62-10.9-29.96c0,0-13.84-25.44-25.05-36.06c0,0-1.94,3.05-5.26,7.94c-6.59,9.73-19.26,27.38-37.88,46.78
	c-8.27,8.62-16.84,16.9-28.38,26.33C-729.9,455.22-729.2,460.49-725.7,466.02z"
        />
        <path
          class="st6"
          d="M-676.68,623.33c5.73,22.98-4.48,24.98-7.87,61.41c-2.96,31.82,5.22,51.33,5.22,51.33
	s108.59-69.88,130.89-134.64c0,0-12.86-14.15-32.85-47.82c-11.03-18.57-20.76-42.96-33.12-70.29c0,0-19.85-13.18-49.22-20.49
	c-53.57-13.32-61.93,3.15-61.93,3.15c0,91.97,29.65,111.49,39.03,130.73C-681.97,606.09-678.55,615.11-676.68,623.33z"
        />
        <path
          class="st12"
          d="M-803.95,494.79c35.64-11.19,50.56-22.82,61.48-30.28c45.25-30.93,79-82.67,79-82.67s-30.65-48.36-67.39-41.01
	S-834.93,364.79-803.95,494.79z"
        />
        <path
          class="st22"
          d="M-874.01,662.15l19.47-12.52l11.46-3.64l11.57,2.21l11.53,9.74l6.12,9.63c28.6-19.2,52.48-26.35,52.48-26.35
	c-2.27-9.37-2.03-18.27-1.86-28.66c0.36-21.77,3.56-39.76-10.41-50.66c0,0-22.92-0.49-30.29,1.96c-3.5,1.17-9.93,8.01-16.94,14.85
	c-4.9,4.77-11.19,11.11-19,18.89c-0.83,0.83-3.78,3.78-3.78,3.78c-7.15,5.68-12.59,11-17.66,16.92
	c-8.32,9.72-13.71,18.7-18.13,27.61c-6.14,12.38-9.59,26.27-11.01,32.96L-874.01,662.15z"
        />
        <path
          class="st13"
          d="M-761.17,640.05c-10.67,3.38-32.56,12.56-52.48,26.35c-9.13-18.01-20.86-20.66-20.86-20.66
	c-7.63-2.16-12.3-1.22-20.04,2.55c0,0-19.72,8.87-35.99,29.1c7.29-36.07,26.21-61.01,46.99-77.3c0,0,11.02-11.16,15.85-15.84
	c12.6-12.21,19.52-20.08,23.97-21.56c10.6-2.53,23.49-1.98,30.29-1.96c7.29,5.35,10.27,14.12,10.81,24.47
	c0.52,5.03-0.36,20.78-0.53,35.34C-763.26,628.93-762.48,635.15-761.17,640.05z"
        />
        <g>
          <path
            class="st6"
            d="M-884.76,853.15c-3.49-21.23-42.09-17-42.09-17c-17.41,8.97-38.99,12.89-52.15,26.28
		c-13.16,13.39-21.08,16.02-34.73,20.38c-13.66,4.35-8.5,19.5,9.23,17.5c17.75-2.01,26.87-5.78,38.17-10.13l0,0c0,0,0,0,0,0.01
		c-14.52,31.62-22.2,55.45-26.51,83.87c-0.31,18.36,31.41,15.87,43.89,10.06c23.82-10.06,41.26-53.7,51.92-66.78
		C-886.47,903.23-881.28,874.38-884.76,853.15z"
          />
        </g>
        <path class="st6" d="M-980.41,924.4c0,0,26.97,15.43,75.22,5.58" />
        <path class="st6" d="M-966.34,890.18c0,0,4.31-9.52,5.55-11.84" />
        <path
          class="st6"
          d="M-887.08,847.09c0,0,28.81-60.46,55.7-79.01c26.89-18.55,69.14-91.85,69.99-126.88
	c0,0-79.97,24.04-113.01,95.04c-33.04,71-35.45,100.08-35.45,100.08L-887.08,847.09z"
        />
        <path
          class="st7"
          d="M-909.18,836.4c0,0,17.85-75.75,47.36-121.58c0,0,16.57-26.89,47.95-47.26c0,0-14.75-32.54-40.68-17.93
	c0,0-18.68,7.87-36.22,29.26c-12.61,15.38-19.91,41.31-23.05,66.64c-6.91,55.77-19.52,93.68-19.52,93.68L-909.18,836.4z"
        />
        <path
          class="st6"
          d="M-620.56,724.29c-7.82-9.87-15.17-12.15-28.42-9.66c0,0-9.21,6.86-15.13,11.04
	c-5.51,4.12-15.37,10.59-15.37,10.59c-11.63,12.55-16.91,28.56-18.74,33.61c-6.36,19.1-8.22,40.41-8.22,57.26
	c0.14,6.55,0.16,12.89,0.8,19.21c21.59-52.08,60.17-70.26,74.29-81.68C-615.73,752.12-615.91,730.35-620.56,724.29z"
        />
        <path
          class="st7"
          d="M-698,344.64c-16.63-5.71-28.24-4.74-33.6-3.66c-7.27,1.46-20.93,3.66-39.7,13.16c0,0-23.08,10.6-33.63,38.82
	c0,0-9.62,23.59-6.3,57.87c2.63,27.14,7.29,43.97,7.29,43.97c13.41-4.5,19-6.65,24.89-9.17c2.95-1.26,5.73-2.46,10.44-4.89
	c-3.16-17.29,3.19-47.38,17.52-74.28C-721.15,350.22-698,344.64-698,344.64z"
        />
        <path
          class="st15"
          d="M-698,344.64c9.62,3.42,17.73,9.95,17.73,9.95c8.65,6.84,14.94,12.04,21.89,19.57c0,0-18.55,29.54-40.19,51.65
	c-17.11,18.54-26.01,25.11-39.41,35.56c-10.32,8.05-19.66,13.84-30.65,19.36c-3.77-26.91,12.8-69.14,29.4-94.99
	C-728.94,370.37-713.47,350.13-698,344.64z"
        />
        <path
          class="st6"
          d="M-729.9,455.22c1.21,6.96,4.2,10.8,4.2,10.8c0.3,9.48,0,17.58,1.57,35.58c2.43,27.69,9.88,36.68,6.1,54.94
	c-3.36,31.83-14.77,56.56-43.37,84.66c-2.48-8.37-1.94-23.56-1.94-23.56c-0.36-21.24,5.19-44.13-10.34-55.76
	c1.34-6.21,2.87-13.15,4.09-19.03c3.59-10.29,8.85-28.21,13.1-38.47C-748.6,484.3-742.83,471.23-729.9,455.22z"
        />
        <path
          class="st7"
          d="M-729.9,455.22c-16.83,19.94-25.3,45.01-34.07,70.06c-3.92,9.88-5.61,17.58-9.7,36.61
	c-8.01-0.36-23.18-0.01-30.29,1.96c-4.8,2.05-11.09,8.96-19.38,17.2c-10.63,10.58-18.73,18.72-18.73,18.72
	c2.02-15.94,6.26-48.44,9.32-64.43c8.84-22.01,11.92-24.77,28.62-40.42c8.53-3.12,20.75-7.08,35.33-14.06
	C-755.14,474.33-737.23,460.79-729.9,455.22z"
        />
        <path class="st21" d="M-834.47,657.49" />
        <path class="st3" d="M499.14,832.71" />
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    class="st9"
                    d="M468.68,117.57c-1.49-23.8,4.28-47.87,16.74-69.55c4.93-8.58,11.04-16.93,19.85-23.26
						c9.19-6.59,21.21-10.54,33.52-11.39c12.08-0.83,20.63,2.04,30.91,6.59c0.96,0.43,1.97,0.89,3.08,0.88
						c1.05,0,2.01-0.42,2.92-0.83c7.81-3.52,16.11-7.35,25.07-6.89c5.06,0.26,9.85,1.89,14.31,3.79
						c29.21,12.43,48.07,37.15,54.55,62.96c3.76,14.97,3.38,29.99,1.47,45.11c-1.78,14.17-7.15,27.94-8.1,42.3
						c-0.85,12.8,1.12,25.96,8.16,37.49c3.14,5.15,7.34,10.14,7.87,15.84c0.27,2.89-0.64,6.08-3.49,7.89
						c-1.9,1.21-4.38,1.64-6.55,2.51c-3.43,1.38-6.08,3.94-7.13,6.88c-1.08,3.04-0.57,6.52-2.82,9.12c-3.83,4.43-10.27,4-16.18,4.65
						c-7.85,0.86-15.48,2.62-23.01,4.54c-14.72,3.75-29.33,8.45-44.91,9.31c-11.73,0.64-23.66-0.99-34.44-4.71
						c-6.79-2.34-13.25-5.53-20.52-6.6c-5.81-0.85-12.07-0.35-17.35-2.46c-8.61-3.44-10.97-11.67-17.58-16.69
						c-7.1-5.39-16.8-8.05-16.8-17.24c0.3-3.08,2.2-5.88,3.76-8.71c7.2-13.03,7.56-27.83,5.51-41.97
						c-2.05-14.14-6.38-28.03-8.16-42.2L468.68,117.57z"
                  />
                  <path
                    class="st23"
                    d="M468.58,117.58c-1.41-21.8,3.37-43.83,13.08-63.37c6.41-13.01,15.01-25.7,27.9-33.05
						c15.91-8.98,36-11.85,53.24-5.14c1.72,0.64,3.42,1.33,5.1,2.04c1.3,0.53,3.45,1.68,4.74,1.76c1.32,0.03,3.38-1.1,4.64-1.64
						c13.06-6.11,23.66-8.71,37.44-2.57c27.22,11.15,48.72,35.38,55.84,64c3.59,14.24,3.36,29.13,1.57,43.6
						c-1.61,14.59-7.09,28.31-8.39,42.7c-0.97,10.74,0.11,21.81,4.32,31.8c1.38,3.33,3.15,6.47,5.15,9.49
						c3.73,5.64,9.36,13.79,4.17,20.22c-2.57,2.89-6.72,2.85-9.84,4.67c-9.47,5.13-2.86,13.06-11.23,17.52
						c-3.3,1.74-7.11,1.87-10.73,2.18c-7.21,0.57-14.33,2.05-21.37,3.76c-28.83,7.84-56.12,16.71-85.69,5.45
						c-6.84-2.51-13.47-5.52-20.59-6.14c-3.55-0.37-7.22-0.35-10.9-1c-11.23-1.73-13.98-9.98-20.99-16.93
						c-2.63-2.4-5.83-4.13-8.86-6.03c-7.66-4.52-12.66-10.73-7.2-19.38c8.17-12.77,9.48-28.47,7.46-43.23
						C475.19,151.31,469.56,134.77,468.58,117.58L468.58,117.58z M468.78,117.56c1.11,17.19,6.7,33.67,9.18,50.66
						c2.04,13.77,1.41,28.59-5.37,41.08c-1.33,2.56-3.47,5.69-3.67,8.58c0.02,7.45,8.03,10.95,13.71,14.54
						c10.09,5.72,12.05,17.94,24.68,19.66c3.48,0.58,7.11,0.62,10.78,0.99c3.69,0.36,7.32,1.29,10.78,2.48
						c3.47,1.18,6.83,2.59,10.21,3.83c13.5,4.95,28.19,6.61,42.51,4.87c21.58-2.48,42.13-11.41,63.9-13.17
						c3.55-0.28,7.27-0.4,10.47-2.06c5.13-2.56,4.21-6.79,5.68-11.44c1.66-4.52,6.32-6.89,10.68-8.04c6.02-1.53,7.63-7.1,5.51-12.54
						c-1.21-3.36-3.31-6.35-5.3-9.38c-4.15-6.01-7.1-12.86-8.66-20.01c-5.47-22.29,4.09-43.02,6.67-64.6
						c0.81-7.19,1.26-14.41,1.03-21.61c-0.21-36.37-24.17-70.23-57.69-83.84c-14.5-6.49-24.7-2.69-38.27,3.61
						c-0.87,0.37-1.92,0.75-3.08,0.69c-1.98-0.16-3.72-1.24-5.45-1.93c-4.93-2.11-10.02-3.98-15.29-4.94
						c-10.51-1.9-21.62-0.38-31.71,3.06c-10.24,3.45-19.31,9.83-26.1,18.26C476.18,59.12,467.12,88.69,468.78,117.56L468.78,117.56z
						"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  class="st24"
                  d="M573.13,20.61c11.19-2.99,26.11-4.23,37.25-0.48c3.87,1.3,7.31,3.29,10.56,5.41
					C643.1,40,657.42,61.12,664.01,83.28c6.59,22.16,5.85,45.37,1.54,67.88"
                />
                <path
                  class="st23"
                  d="M573.12,20.56c11.5-3.18,23.99-4.45,35.68-1.37c5.9,1.57,11.01,4.98,15.89,8.49
					c11.29,8.29,20.99,18.74,28.46,30.59c17.56,27.38,19.24,61.81,12.46,92.9c2.38-15.53,3.86-31.8,1.94-47.64
					c-2.59-23.69-12.5-46.89-29.51-63.81c-5.62-5.67-11.98-10.56-18.73-14.75c-3.37-2.1-6.93-3.89-10.74-4.94
					C597.04,16.81,584.52,18.24,573.12,20.56L573.12,20.56z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M574.03,20.8c15.11-2.93,31.37,1.99,42.92,10.26c11.55,8.27,19.06,19.49,25.1,30.87
					c6.26,11.79,11.23,24.14,12.7,36.89c2.01,17.58-2.7,35.24-2.04,52.89c0.66,17.51,6.72,36.14,17.5,51.41"
                />
                <path
                  class="st23"
                  d="M574.02,20.75c19.28-3.9,39.77,4.23,52.9,18.51c6.67,7.15,11.69,15.6,16.12,24.21
					c4.41,8.64,8.28,17.62,10.53,27.1c4.83,19.09-0.4,38.75-0.42,58c0.11,19.35,6.24,38.52,17.09,54.53
					c-11.5-15.33-17.93-35.19-18.12-54.53c-0.06-9.76,1.35-19.41,2.14-29.05c2.27-20.68-1.79-37.63-11.53-55.89
					c-8.69-17.08-21.73-33.08-40.23-39.72C593.51,20.43,583.4,19.42,574.02,20.75L574.02,20.75z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M669.52,230.66c-0.17-9.9-5.49-19.27-11.51-27.94s-12.89-17.09-16.67-26.52
					c-6.59-16.44-2.81-33.38-1.64-50.17c1.19-17.1,2.23-34.12-1.42-51.12c-4.14-19.28-13.03-43.12-37.27-51.9"
                />
                <path
                  class="st23"
                  d="M669.02,230.67c-0.22-9.64-5.17-18.36-10.43-26.18c-8.05-11.75-18.18-23.31-20.5-37.92
					c-2.56-14.39,0.55-28.93,1.36-43.28c1.47-19.12,1.82-38.61-3.72-57.16c-2.59-9.22-6.34-18.22-12.11-25.92
					c-5.67-7.78-13.6-13.77-22.61-17.19c9.06,3.24,17.23,9.19,22.95,16.94c5.88,7.7,9.74,16.73,12.43,25.99
					c5.74,18.56,5.34,38.25,3.94,57.41c-0.77,14.36-3.84,28.74-1.27,43.04c2.36,14.49,12.39,25.74,20.39,37.51
					c5.35,7.98,10.36,16.87,10.57,26.75C670.02,230.65,669.02,230.67,669.02,230.67L669.02,230.67z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M573.44,20.58c-4.99,4.97-7.18,11.88-9.11,17.94c-2.29,7.18-3.96,14.48-5.16,21.82
					c-2.36,14.42-2.94,28.99-2.87,43.52c0.09,17.7,1.15,35.66,8.03,52.49c3.91,9.56,9.64,18.62,13.16,28.28
					c4.89,13.43,5.31,27.88,1.22,41.48c-1.99,6.62-5.04,13.11-5.44,19.9c-0.4,6.79,2.5,14.25,9.78,17.83"
                />
                <path
                  class="st23"
                  d="M573.48,20.61c-3.75,3.83-5.86,8.85-7.54,13.85c-6.87,20.19-8.91,41.61-9.34,62.82
					c-0.19,10.63-0.12,21.31,1.22,31.87c1.28,10.54,3.89,20.96,8.41,30.59c4.49,9.65,10.22,18.81,13.19,29.13
					c2.99,10.3,3.41,21.36,1.15,31.85c-1.57,7.85-5.48,15.05-6.75,22.95c-1.41,7.78,1.92,16.53,9.26,20.11
					c-4.82-1.86-8.54-7-9.62-12.16c-1.8-8.04,1.75-15.91,4.14-23.43c6.04-17.97,3.65-37.11-5.08-53.76
					c-4.75-9.5-9.78-19.07-12.25-29.49c-2.71-10.35-3.56-21.05-3.98-31.68c-0.66-21.27-0.39-42.82,4.6-63.63
					c1.21-5.2,2.79-10.31,4.55-15.34C567.23,29.39,569.57,23.95,573.48,20.61L573.48,20.61z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M572.78,20.93c-16.94,8.79-28.05,22.33-36.14,36.79c-13.29,23.75-17.12,50.96-9.42,76.3
					c2.73,8.99,6.79,17.71,9.48,26.71c2.61,8.73,3.92,17.67,4.7,26.62c1.52,17.57,0.99,35.25-1.59,52.74
					c-0.99,6.75-2.22,13.92,1.4,20.08"
                />
                <path
                  class="st23"
                  d="M572.81,20.98c-28.03,14.94-46.35,48.59-48.81,79.7c-0.76,10.75,0.17,21.67,3.15,32.07
					c2.93,10.43,7.7,20.28,10.51,30.76c2.81,10.5,4.08,21.35,4.71,32.18c0.6,10.85,0.31,21.74-0.75,32.55
					c-0.5,5.4-1.35,10.79-2.15,16.13c-0.74,5.29-0.96,10.98,1.79,15.77c-1.54-1.98-2.24-5.06-2.54-7.68
					c-0.28-2.72-0.09-5.46,0.23-8.16c0.66-4.89,1.51-11.32,1.91-16.14c1.43-16.17,1.25-32.49-0.57-48.62
					c-0.64-5.37-1.57-10.72-2.96-15.95c-4.31-15.63-12.62-30.24-13.91-46.68C518.99,80.89,539.79,36.95,572.81,20.98L572.81,20.98z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M571.95,21.37c-10.43-3.6-22.02-5.19-33.31-4.16c-11.3,1.03-22.26,4.75-30.66,10.83
					c-3.72,2.69-6.91,5.81-10.01,8.96c-8.78,8.92-17.09,18.39-21.4,29.18"
                />
                <path
                  class="st23"
                  d="M571.79,21.85c-18.16-6.31-39.02-6.48-56.49,2.08c-8.84,4.19-15.63,11.42-22.26,18.42
					c-6.58,7.09-12.77,14.8-16.47,23.83c3.62-9.06,9.75-16.85,16.28-24c3.31-3.56,6.65-7.09,10.24-10.39
					c10.79-10.04,25.66-14.74,40.21-15.22c9.76-0.31,19.56,1.21,28.82,4.34L571.79,21.85L571.79,21.85z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M572.16,20.72c-19.01-3.98-39.05,3.81-53.56,14.34c-29.37,21.33-43.46,55.13-35.9,86.16
					c2.42,9.93,6.88,19.55,8.27,29.61c2.41,17.38-4.52,34.8-14.3,50.47c-4.73,7.59-10.27,16.32-5.98,24.07"
                />
                <path
                  class="st23"
                  d="M572.15,20.77c-34.61-6.33-72.36,22.79-85.17,53.7c-0.5,1.33-1.69,4.56-2.16,5.84
					c-1.35,4.51-2.66,9.46-3.16,14.16c-1.26,8.87-0.59,18.03,1.47,26.73c2.21,8.76,5.85,17.06,7.73,25.94
					c3.59,16.65-2,33.7-10.2,48.11c-4.57,8.92-15.1,19.59-9.91,30.09c0,0-0.09,0.05-0.09,0.05c-4.36-7.9,1.07-16.53,5.18-23.32
					c4.37-7.05,8.24-14.45,11.01-22.27c6.43-18.21,4.67-30.64-1.62-48.39c-1.26-3.93-2.59-8-3.32-12.08
					c-2.58-12.24-1.75-25.22,1.98-37.15c0.58-2.28,2-5.62,2.82-7.85c1.03-2.14,2.4-5.49,3.62-7.51c2.48-4.79,5.83-9.67,9.1-13.97
					c0.8-0.9,3.24-3.87,4.04-4.79c1.03-1.09,3.32-3.43,4.35-4.51C524.45,27.55,548.87,15.45,572.15,20.77L572.15,20.77z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M575.41,19.74c-16.9,31.92-15.39,67.83-13.6,102.44c0.33,6.45,0.67,12.91,1.84,19.3
					c4.17,22.83,18.84,44.37,18.19,67.43"
                />
                <path
                  class="st23"
                  d="M575.85,19.98c-15.45,28.91-15.71,62.67-14.17,94.65c0.43,8.08,0.57,16.19,1.71,24.2
					c2.38,16.1,9.89,30.76,14.61,46.21c2.42,7.72,3.99,15.77,3.83,23.88c0.12-8.11-1.48-16.14-3.94-23.84
					c-4.8-15.43-12.4-30.08-14.86-46.19c-1.19-8.03-1.37-16.15-1.84-24.23c-1.73-32.07-1.59-66.03,13.78-95.14
					C574.97,19.51,575.85,19.98,575.85,19.98L575.85,19.98z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M549.71,263.38c-7.39-9.58-8.43-21.35-8.38-32.58c0.03-7.5,0.47-14.99,1.29-22.46"
                />
                <path
                  class="st23"
                  d="M549.32,263.69c-5.88-7.69-7.86-17.51-8.13-26.98c-0.25-9.48,0.34-18.96,1.44-28.36
					c-0.93,9.41-1.35,18.9-0.94,28.34c0.43,9.33,2.53,18.95,8.42,26.39C550.11,263.08,549.32,263.69,549.32,263.69L549.32,263.69z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M571.07,21.29c-7.67-1.62-16,0.26-22.63,3.73s-11.8,8.41-16.62,13.42c-15.59,16.2-28.77,34.6-32.21,54.81
					c-3.2,18.78,2.18,37.71,4.79,56.55c3.05,22.06,2.28,44.46-2.28,66.36c-1.78,8.54-4.14,17.09-3.81,25.74
					c0.11,2.89,0.65,5.76,2.75,8.2c1.95,2.27,5.47,2.47,8.73,2.75c4.61,0.4,9.18,1.09,13.65,2.08"
                />
                <path
                  class="st23"
                  d="M570.97,21.77c-19.56-3.64-33.65,10.67-45.44,24.12c-16.24,18.24-28.6,40.18-26.44,65.33
					c1.19,18.17,6.28,35.88,7.01,54.11c1.04,18.19-0.61,36.51-4.63,54.27c-1.6,8.07-6.26,24.39-0.07,30.78
					c2.27,2.13,5.63,2.14,8.55,2.44c4.53,0.4,9.04,1.1,13.48,2.08c-5.92-1.43-12-1.77-18.01-2.56c-6.93-1.03-7.57-9.2-7.13-14.83
					c0.34-6.08,1.7-12.02,2.99-17.94c3.95-17.75,5.53-36.05,4.44-54.21c-0.79-18.24-5.95-35.91-7.2-54.1
					c-2.27-25.31,10.11-47.51,26.39-65.9c11.98-13.78,26.25-28.22,46.26-24.57C571.18,20.8,570.97,21.77,570.97,21.77L570.97,21.77z
					"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M521.64,253.86c-3.99-11.95,1.34-24.42,4.35-36.54c4.84-19.44,3.64-39.81-3.46-58.8"
                />
                <path
                  class="st23"
                  d="M521.17,254.02c-3.88-12.04,1.27-24.27,4.29-35.87c5.21-19.56,4.09-40.65-2.92-59.63
					c7.22,18.96,8.55,40.11,3.52,59.79c-1.95,7.82-4.73,15.53-5.3,23.59c-0.32,3.99,0.12,8.03,1.35,11.81L521.17,254.02
					L521.17,254.02z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M677.16,227.17c-0.11-12.26-8.74-23.32-17.07-33.65"
                />
                <path
                  class="st23"
                  d="M676.66,227.17c-0.15-13.05-8.96-23.78-16.57-33.65c7.95,9.77,17.1,20.41,17.57,33.64
					C677.66,227.17,676.66,227.17,676.66,227.17L676.66,227.17z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M479.27,231.03c-2.24-5.66-0.97-11.85,1.58-17.42c2.55-5.57,6.34-10.73,9.3-16.17
					c7.9-14.49,10.14-31.7,5.25-47.05c-5.35-16.77-11.66-33.6-12.69-50.99c-0.29-4.81-0.16-9.64,0.51-14.43"
                />
                <path
                  class="st23"
                  d="M478.8,231.21c-2.44-6.18-0.72-13.06,2.11-18.76c2.83-5.76,6.69-10.86,9.57-16.48
					c5.83-11.18,8.41-24.16,6.68-36.72c-0.83-6.28-3.14-12.25-5.03-18.31c-3.89-12.08-7.73-24.3-9.09-36.96
					c-0.71-6.31-0.73-12.72,0.17-19.01c-0.86,6.3-0.8,12.7-0.05,19c1.44,12.64,5.36,24.82,9.32,36.86c1.93,6.03,4.28,12,5.17,18.35
					c1.83,12.66-0.68,25.78-6.51,37.13c-2.88,5.71-6.71,10.84-9.45,16.52c-2.69,5.52-4.33,12.13-1.97,18.01
					C479.73,230.85,478.8,231.21,478.8,231.21L478.8,231.21z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M572.06,20.98c-17.32,13.13-25.47,32.19-29.54,50.93c-7.83,36.07-2.59,73.86,14.95,107.71
					c5.91,11.41,13.32,22.79,13.83,35.12c0.61,14.6-8.41,29.99-0.74,43.26"
                />
                <path
                  class="st23"
                  d="M572.36,21.38c-17.09,12.69-26.24,33.21-30.07,53.7c-6.24,31.02-2.48,63.81,9.92,92.87
					c2.07,4.85,4.35,9.6,6.81,14.27c4.88,9.28,10.55,18.57,12.05,29.13c2.41,15.56-8.68,31.86-0.5,46.66
					c-8.23-14.76,2.77-31.11,0.32-46.63c-1.54-10.53-7.23-19.76-12.14-29.01c-7.5-13.96-13.22-28.9-16.49-44.42
					c-6.37-30.77-4.77-64.55,9.29-93.05c4.88-9.41,11.71-17.88,20.2-24.31C571.76,20.58,572.36,21.38,572.36,21.38L572.36,21.38z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M572.78,20.85c-12.31,12.62-17.91,26.89-20.48,42.72s-1.59,31.87-0.61,47.81"
                />
                <path
                  class="st23"
                  d="M573.14,21.2c-17.51,17.12-22.63,42.49-22.35,66.18c0,8.01,0.45,16.01,0.9,24
					c-0.53-7.99-1.06-15.98-1.15-24c-0.54-23.85,4.42-49.45,21.89-66.88C572.42,20.5,573.14,21.2,573.14,21.2L573.14,21.2z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M575.63,19.59c24.5,6.04,39.86,26.21,43.96,46.31c4.11,20.11-0.75,40.58-4.17,60.77
					c-1.5,8.87-2.72,17.91-0.79,26.72c3.19,14.56,14.57,26.99,23.06,40.14c7.03,10.88,12.19,22.51,15.31,34.49
					c1.9,7.31,3.04,14.92,0.95,22.2"
                />
                <path
                  class="st23"
                  d="M575.65,19.54c21.66,4.83,39.11,23.07,43.96,44.61c2.44,10.77,2.11,21.9,0.83,32.74
					c-1.31,10.87-3.43,21.62-5.16,32.41c-1.78,10.69-2.15,21.95,2.44,32.01c4.38,10.02,11.35,18.59,17.49,27.58
					c6.3,8.98,11.46,18.8,15.09,29.16c3.4,10.24,6.84,21.47,3.72,32.19c2.48-10.6-0.77-21.82-4.4-31.94
					c-5.43-15.6-15.25-28.88-24.68-42.25c-4.56-6.82-8.8-14.13-10.63-22.23c-1.81-8.1-0.91-16.52,0.44-24.59
					c1.78-10.8,4.16-21.49,5.35-32.36C624.92,62.22,610.73,29.81,575.65,19.54L575.65,19.54z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M576.12,19.83c16.82,9.71,26.81,25.58,30.61,41.82c3.81,16.24,2.03,32.97-0.86,49.33
					c-2.82,15.97-6.59,32.73,0.54,47.83c3.3,7,9.25,13.11,11.35,20.43c2.35,8.19,3.73,16.53,4.42,24.91
					c1.41,17.09,0.03,34.23-1.36,51.3"
                />
                <path
                  class="st23"
                  d="M576.15,19.78c24.36,13.54,34.92,40.1,32.92,67.1c-0.56,10.5-2.46,20.86-4.26,31.19
					c-1.74,10.25-3.26,20.86-1.11,31.15c0.18,1.13,0.67,2.66,0.95,3.78c0.38,1.02,0.87,2.68,1.34,3.65
					c2.91,7.39,8.71,13.02,11.63,20.51c8.08,25.26,5.7,52.3,3.26,78.27c1.42-25.7,3.82-52.81-4.24-77.9
					c-3.04-7.87-9.61-14.21-12.16-22.41c-5.87-17.25,0.29-35.33,2.59-52.63C612.12,69.93,605.59,38.17,576.15,19.78L576.15,19.78z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M574.62,19.83c18.2,19.97,28.05,44.67,27.62,69.32c-0.25,14.86-4.11,29.54-5.05,44.38
					c-0.48,7.56-0.15,15.37,3.58,22.34c2.41,4.5,6.12,8.43,9.6,12.43c1.54,1.77,3.36,3.55,4.56,5.48c1.29,2.06,1.95,4.34,2.55,6.58
					c1.48,5.57,2.68,11.18,3.6,16.83"
                />
                <path
                  class="st23"
                  d="M574.65,19.81c22.88,24.45,32.31,55.5,25.99,88.46c-1.69,11.77-4.29,23.64-3.14,35.57
					c0.43,3.94,1.46,7.82,3.27,11.32c1.78,3.53,4.36,6.58,6.94,9.6c2.52,3.12,5.5,5.8,7.66,9.22c1.97,3.54,2.6,7.56,3.58,11.43
					c0.81,3.86,1.76,7.98,2.1,11.77c-0.69-3.92-1.55-7.81-2.48-11.68c-1.01-3.79-1.69-7.83-3.66-11.26
					c-4.6-6.33-11.03-11.55-14.7-18.8c-3.63-7.26-3.74-15.62-3.05-23.53c1.29-15.85,5.31-31.51,4.68-47.47
					C600.89,60.22,590.31,37.84,574.65,19.81L574.65,19.81z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M574.47,20.99c16.28,46.01,2.65,95.41,11.45,142.69c2.94,15.77,8.34,32.04,2.81,47.37
					c-2.23,6.17-6.14,11.86-9.18,17.81c-2.98,5.83-5.13,11.94-6.39,18.15"
                />
                <path
                  class="st23"
                  d="M574.52,20.98c12.01,31.98,8.3,66.85,8.32,100.29c0.06,14.36,1.05,28.96,3.69,43.07
					c4.04,19.78,9.88,37.75-2.01,56.31c-2.4,4.18-4.91,8.29-6.81,12.71c-2.02,4.33-3.36,9.24-4.6,13.66
					c0.89-4.74,2.27-9.4,4.14-13.85c1.85-4.48,4.31-8.66,6.63-12.86c11.37-18.39,5.53-36.19,1.71-55.78
					c-5.53-31-1.77-62.57-3.24-93.81c-0.12-4.21-0.54-10.18-0.86-14.4C580.42,44.44,577.9,32.2,574.52,20.98L574.52,20.98z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M574.61,21.45c9.25,16.18,13.7,33.87,15.47,51.58c1.77,17.71,0.91,35.51-0.05,53.26
					c-1,18.49-1.95,37.75,7.5,54.66"
                />
                <path
                  class="st23"
                  d="M575.04,21.2c17.29,30.15,17.5,66.04,15.46,99.76c-0.72,13.57-1.93,27.29,0.42,40.76
					c1.15,6.72,3.34,13.25,6.61,19.24c-3.3-5.97-5.54-12.5-6.73-19.21c-3.42-20.26,0.12-40.84,0.01-61.23
					c0.46-27.02-2.87-55.1-16.64-78.82C574.18,21.7,575.04,21.2,575.04,21.2L575.04,21.2z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M574.58,21.14c-2.75,30.06-3.73,60.22-2.93,90.35c0.49,18.57,1.69,37.27,7.2,55.32
					c3.01,9.85,7.3,19.54,8.34,29.64c1.38,13.48-3.21,27.11-11.46,38.94"
                />
                <path
                  class="st23"
                  d="M574.63,21.15c-2.34,36.33-4.42,72.85-1.81,109.21c0.74,9.07,1.99,18.11,4.02,26.96
					c1.94,8.92,5.35,17.36,8.04,26.08c2.8,8.71,3.94,18.14,2.02,27.17c-1.74,9.01-5.8,17.44-11.13,24.85
					c4.68-7.53,8.95-16.03,10.42-24.98c1.73-8.96,0.46-18.11-2.29-26.74c-2.75-8.67-6.11-17.23-7.97-26.17
					c-3.98-17.88-4.19-36.25-4.61-54.46C570.7,75.81,571.32,48.04,574.63,21.15L574.63,21.15z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M598.59,261.03c-3.49-12.61,3.64-25.31,7.73-37.81c3.15-9.6,4.53-19.54,4.58-29.46
					c0.03-4.95-0.29-9.97-2.26-14.66c-1.79-4.27-4.89-8.11-7.33-12.17c-7.19-11.94-8.57-26.02-3.81-38.7"
                />
                <path
                  class="st23"
                  d="M598.54,261.05c-3.44-11.45,1.94-23.05,5.87-33.62c5.28-13.32,7.54-28.11,5.52-42.34
					c-1.29-8.79-8.32-14.95-11.68-22.9c-4.84-10.61-5.21-23.19-0.8-33.97c-1.52,5.34-2.72,11.28-2.31,16.99
					c0.43,8.61,3.43,16.91,8.25,24.03c3.21,4.77,6.59,9.85,7.32,15.72c1.48,14.38-0.81,29.12-5.52,42.76
					C601.28,238.3,596.09,249.68,598.54,261.05L598.54,261.05z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M498.07,249.53c-4.72-3.82-5.66-9.62-5.73-14.97c-0.12-9.66,1.81-19.33,5.68-28.48"
                />
                <path
                  class="st23"
                  d="M497.76,249.92c-4.72-3.81-5.87-10.21-5.73-15.95c0.05-9.58,2.17-19.11,5.99-27.88
					c-3.61,8.82-5.52,18.35-5.36,27.88c0,5.51,1.15,11.63,5.73,15.18C498.39,249.14,497.76,249.92,497.76,249.92L497.76,249.92z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M634.3,252.9c2.34-17.42,1.88-35.09-1.38-52.42"
                />
                <path
                  class="st23"
                  d="M633.81,252.84c2.5-17.32,2.2-35.07-0.89-52.35c3.42,17.22,4.07,35.01,1.88,52.48L633.81,252.84
					L633.81,252.84z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M571.13,21.37c-7.58,0.19-14.88,2.73-20.84,6.46c-5.96,3.72-10.67,8.57-14.61,13.72"
                />
                <path
                  class="st23"
                  d="M571.15,21.87c-14.2,0.13-27.05,8.58-35.46,19.68c8.23-11.41,21.01-20.2,35.43-20.68
					C571.12,20.87,571.15,21.87,571.15,21.87L571.15,21.87z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M574.99,20.51c7.65-0.4,15.27,1.55,21.97,4.52c20.11,8.93,32.12,26.61,36.43,44.58
					c4.32,17.97,1.98,36.48-0.38,54.68"
                />
                <path
                  class="st23"
                  d="M574.96,20.01c23.22-0.64,44.84,16.02,54.16,36.64c9.83,21.19,6.92,45.25,3.89,67.64
					c2.86-22.37,5.61-46.38-4.34-67.43c-9.34-20.27-30.79-36.63-53.66-35.85C575.01,21.01,574.96,20.01,574.96,20.01L574.96,20.01z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M475.42,200.6c9.38-16.11,12.91-34.33,10.08-51.94"
                />
                <path
                  class="st23"
                  d="M474.99,200.35c9.2-15.37,13.26-33.9,10.51-51.69c3.09,17.82-0.67,36.53-9.64,52.19
					C475.86,200.85,474.99,200.35,474.99,200.35L474.99,200.35z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M641.95,178c3.07,13.44,13.1,25.27,17.34,38.51c2.97,9.24,2.34,19.63,0.7,29.08"
                />
                <path
                  class="st23"
                  d="M642,177.99c2.6,8.59,7.14,16.37,11.11,24.37c2.73,5.33,5.77,10.57,7.31,16.42
					c2.34,8.76,1.59,18.09-0.37,26.82c0,0-0.1-0.02-0.1-0.02c2.08-16.29,1.13-28.57-7.15-43.06
					C648.47,194.83,643.17,186.77,642,177.99L642,177.99z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M518.51,53.65c-12.65,16.31-15.72,36.23-14.95,55.37c0.77,19.14,5.07,38.11,5.89,57.25
					c0.73,17.18-1.36,34.43-6.21,51.18c-3.31,11.45-7.81,23.86-1.61,34.52"
                />
                <path
                  class="st23"
                  d="M518.55,53.68c-15.62,20.8-16.31,48.16-13.29,73c1.96,16.84,5.26,33.62,4.74,50.66
					c-0.29,12.76-2.18,25.48-5.61,37.77c-1.15,4.1-2.5,8.12-3.59,12.2c-1.64,6.08-2.75,12.49-1.37,18.72
					c0.46,2.06,1.27,4.02,2.25,5.9l-0.09,0.05c-4.59-7.48-3.6-16.72-1.49-24.85c2.16-8.21,4.7-16.27,6.18-24.63
					c1.55-8.31,2.5-16.73,2.78-25.18c1.12-25.52-5.93-50.52-5.99-76C503.23,84.52,507.62,66.57,518.55,53.68L518.55,53.68z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M556.55,264.47c-9.43-9.85-13.37-22.9-10.58-35.07"
                />
                <path
                  class="st23"
                  d="M556.19,264.81c-8.78-9.28-13.35-22.86-10.22-35.42c-2.77,12.52,2.05,25.79,10.94,34.72
					C556.91,264.12,556.19,264.81,556.19,264.81L556.19,264.81z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M617.9,256.63c1.68-21.61,3.35-43.32,0.9-64.88"
                />
                <path
                  class="st23"
                  d="M617.4,256.59c1.84-21.55,3.69-43.25,1.39-64.84c2.63,21.55,1.11,43.34-0.4,64.92
					C618.4,256.67,617.4,256.59,617.4,256.59L617.4,256.59z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M650.37,80.5c8.22,17.24,10.99,38.57,8.49,56.78c-2.76,20.1-0.16,40.66,7.65,59.91"
                />
                <path
                  class="st23"
                  d="M650.42,80.48c9.27,17.8,10.92,38.61,8.54,58.28c-2.12,19.76,0.7,39.79,7.6,58.41c0,0-0.09,0.04-0.09,0.04
					c-8.08-18.2-10.91-38.8-8.05-58.51c0.73-4.91,1.22-9.86,1.15-14.82C659.35,109.02,655.91,94.07,650.42,80.48L650.42,80.48z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M567.86,23.76c-17.42,13.82-29.39,31.66-35.91,50.52c-6.52,18.86-7.74,38.72-5.44,58.19"
                />
                <path
                  class="st23"
                  d="M567.88,23.79c-29.43,24.71-42.38,55.33-42.14,93.49c0.04,5.06,0.34,10.13,0.81,15.18
					c-0.91-4.81-1.14-10.14-1.35-15.17C523.85,81.97,539.32,44.99,567.88,23.79L567.88,23.79z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M565.83,19.37c-9.44-0.6-19.18-0.49-28.05,2.15c-7.36,2.19-13.77,6.01-19.59,10.19
					c-18.25,13.13-31.94,31.02-34.16,50.49"
                />
                <path
                  class="st23"
                  d="M565.83,19.4c-14.05-0.66-28.65,0.47-40.88,8c-15.88,9.82-29.8,23.91-37.18,41.23
					c-1.85,4.29-2.93,9.11-3.78,13.57c0.86-9.44,4.58-18.5,9.81-26.36c9.4-14.25,27.63-29.2,43.88-34.61
					C546.72,18.54,556.66,18.35,565.83,19.4L565.83,19.4z"
                />
              </g>
              <g>
                <path
                  class="st24"
                  d="M531.72,47.33c-9.94,14.08-15.2,30.05-17.04,46.13c-1.84,16.08-0.34,32.3,2.61,48.27"
                />
                <path
                  class="st23"
                  d="M531.72,47.33c-16.65,23.75-20.05,54.1-16.23,82.27c0.49,4.06,1.1,8.11,1.8,12.14
					c-0.78-4.02-1.47-8.05-2.04-12.11C511.15,101.45,514.52,70.82,531.72,47.33L531.72,47.33z"
                />
              </g>
            </g>
          </g>
          <g class="st25">
            <path
              class="st26"
              d="M523.02,43.24c-6.19,6.58-11.05,13.89-15.66,21.24c-3.3,5.26-6.51,10.6-8.58,16.25
			c-3.79,10.35-3.63,21.36-2.28,32.09c-0.14-12.29,2.99-24.6,9.14-35.89c2.61-4.79,5.75-9.38,9-13.92
			c10.22-14.28,21.86-29.57,38.75-39.4c-6.68,1.43-11.57,5.07-16.58,8.64C531.9,35.75,527.01,38.99,523.02,43.24z"
            />
            <path
              class="st26"
              d="M634.82,86.13c0.22,6.34,0.15,12.71-0.84,19.01c-0.86,5.44-2.41,10.8-3.49,16.21
			c-2.93,14.67-2.38,29.77,1.61,44.28c-2.6-16.1-2-32.53,1.78-48.48c1.48-6.26,3.45-12.47,4.15-18.81c0.47-4.33,0.34-8.69,0.2-13.04
			c-0.12-3.69-0.25-7.39-0.78-11.06c-1.79-12.2-8.23-23.95-18.26-33.3c3.88,7.27,9.46,13.45,12.02,21.26
			C633.77,69.99,634.54,78.1,634.82,86.13z"
            />
            <path
              class="st26"
              d="M543.59,140.17c-1.53-4.04-1.02-9.4-1.42-13.61c-0.44-4.63-0.82-9.26-1.06-13.9
			c-0.5-9.4-0.48-18.83,0.62-28.2c0.61-5.22,1.61-10.39,2.92-15.52c-6.38,18.28-9.34,36.19-5.66,55.14
			C540.05,129.51,541.58,134.9,543.59,140.17z"
            />
            <path
              class="st26"
              d="M582.65,119.77c1.37-27.71,2.75-55.48,0.65-83.16c-1.62,10.51-1.24,21.21-1.31,31.8
			c-0.07,11.01,0,22.02,0.2,33.02C582.3,107.53,582.65,113.66,582.65,119.77z"
            />
            <path
              class="st26"
              d="M559.54,147.54c-3.88-21.11-7.78-42.33-7.27-63.65c-2.79,16.24-2.07,32.95,2.21,49.02
			C555.76,137.76,558.65,142.69,559.54,147.54z"
            />
            <path
              class="st26"
              d="M653.98,130.12c0.59-4.55,1.18-9.1,1.77-13.65c0.45-3.5,0.91-7,1.02-10.52c0.3-9.16-1.75-18.37-5.99-26.89
			C653.7,95.98,657.07,112.93,653.98,130.12z"
            />
            <path
              class="st26"
              d="M480.68,91.74c0.49-8.59,4.91-18.03,8.84-25.95c4.21-8.49,9.68-16.46,16.04-24.04
			C490.44,55.2,481.7,73.85,480.68,91.74z"
            />
            <path
              class="st26"
              d="M620.48,79.78c-0.15-12.82-3.76-25.23-9.93-37.04C620.9,53.38,620.33,67.13,620.48,79.78z"
            />
            <path
              class="st26"
              d="M514.9,94.44c-0.17-13.98,6.04-27.54,13.92-40.04C517.49,65.34,514.74,80.69,514.9,94.44z"
            />
            <path
              class="st26"
              d="M614.54,140.81c-2.7-19.4,10.98-38.78,6.55-57.98c2.06,8.85-1.77,19.22-3.68,27.87
			C615.21,120.62,613.18,130.72,614.54,140.81z"
            />
            <path
              class="st26"
              d="M665.74,84.18c-2.4-16.44-11.36-32.24-25.27-44.57c2.42,2.14,4.05,5.89,6.05,8.43
			c2.89,3.68,5.35,7.62,7.69,11.53C658.94,67.49,662.8,75.74,665.74,84.18z"
            />
            <path
              class="st26"
              d="M517.18,32.35c4.26-3.68,9.9-6.12,15.44-8.5c4.53-1.94,9.09-3.8,14.27-4.28c-5.6-0.72-11.27,1.51-16.02,3.65
			C525.53,25.64,521.31,28.79,517.18,32.35z"
            />
            <path
              class="st26"
              d="M579.93,199.79c0.94-6.61-1.1-13.3-4.3-19.46c-1.58-3.04-3.43-5.97-5.37-8.87
			c-1.72-2.58-3.33-6.04-5.77-8.19c2.89,6.05,7.42,11.65,10.31,17.76C577.38,186.51,580.77,193.83,579.93,199.79z"
            />
            <path
              class="st26"
              d="M602.37,219.17c-3.66,5.29-8.53,10.16-10.67,15.95c-1.95,5.27-1.42,11.1,1.45,16.1
			c-0.96-7.36,1.83-14.68,4.92-21.66c1.5-3.38,3.08-6.74,4.96-10c2.45-4.22,5.41-8.27,7.41-12.64c5.78-12.66,2.44-27.67-8.44-38
			c1.25,1.19,1.88,3.52,2.65,4.96c0.77,1.45,1.84,2.7,2.62,4.11c1.65,3,2.24,6.73,2.71,9.96c0.98,6.82,0.28,13.79-2.05,20.4
			C606.59,212.11,604.73,215.75,602.37,219.17z"
            />
            <path
              class="st26"
              d="M520.71,200.86c-2.04,8.62-6.24,16.89-7.53,25.6c-0.72,4.88-0.52,9.85,0.62,14.68
			c0.23-2.38,0.7-4.74,1.4-7.06c-0.09,3.05,0.7,6.11,2.28,8.88c0.14-3.3,0.29-6.59,0.43-9.89c0.29-6.56,0.58-13.16,2.32-19.57
			c1.19-4.39,3.05-8.65,4.21-13.04c0.88-3.32,1.36-6.7,1.63-10.09c1.06-13.01-0.84-26.16-5.59-38.64c0,7.62,1.89,15.21,1.91,22.85
			C522.41,183.38,522.76,192.17,520.71,200.86z"
            />
            <path
              class="st26"
              d="M651.26,243.54c5.51-6.26,5.91-14.6,3.79-22.05c-2.93-10.31-9.01-20.43-15.36-29.65
			c4.85,7.98,7.53,16.63,9.82,25.26c1.15,4.34,2.13,8.43,2.34,12.88c0.11,2.33,0.11,4.67,0,7.01
			C651.79,238.46,652.32,242.33,651.26,243.54z"
            />
            <path
              class="st26"
              d="M488.74,199.39c-4.22,5.77-8.59,12.08-7.58,18.71c0.03-4.04,2.2-7.88,4.74-11.38
			c2.54-3.51,5.49-6.85,7.34-10.62c1.88-3.84,2.53-7.97,3.17-12.06C495.52,189.66,492.24,194.6,488.74,199.39z"
            />
            <path
              class="st26"
              d="M530.88,249.27c-0.69-13.48,9.35-26.61,6.4-39.9c1.36,6.11-1.1,12.05-3.37,17.77
			C531.09,234.26,529.02,241.84,530.88,249.27z"
            />
            <path
              class="st26"
              d="M556.68,239.46c0.51,6.03,2.71,12.13,7.53,16.8c-3.38-11.31-1.07-23.18-2.29-34.77
			c-0.89-8.48-3.67-16.76-6.43-24.99c2.34,6.96,2.48,14.76,2.6,21.91C558.2,225.47,556.08,232.38,556.68,239.46z"
            />
            <path
              class="st26"
              d="M668.91,148.17c3.96-15.46,5.06-31.63,0.29-46.95c1.67,5.37,1.45,11.68,1.62,17.16
			c0.21,6.33,0.02,12.66-0.55,18.97C669.94,140.97,669.49,144.57,668.91,148.17z"
            />
            <path
              class="st26"
              d="M486.05,161.74c0.46-5.51,0.28-11.05-0.53-16.53c-0.64-4.28-1.66-8.51-2.5-12.77
			c-2.25-11.4-3.16-22.97-2.72-34.5c-0.08,2.06-1.18,4.11-1.41,6.16c-0.22,1.87-0.32,3.82-0.42,5.72
			c-0.23,4.21,0.03,8.42,0.57,12.61c1.07,8.42,3.25,16.71,4.93,25.06C484.9,152.2,485.7,156.96,486.05,161.74z"
            />
            <path
              class="st26"
              d="M608.67,59.42c-1.62-10.11-9.52-19.79-15.29-28.65C601.88,38.99,606.97,48.81,608.67,59.42z"
            />
            <path
              class="st26"
              d="M538.57,55.44c2.31-5.47,7.14-10.9,11.39-15.52c4.52-4.92,9.69-9.41,15.36-13.5
			C553.08,34.05,543.57,43.6,538.57,55.44z"
            />
            <path
              class="st26"
              d="M678.18,224.44c0.89-8.28-3.33-16.49-9.48-23.22c2.07,3.71,4.59,7.26,6.28,11.08
			C676.71,216.22,677.79,220.31,678.18,224.44z"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
