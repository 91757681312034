import { imagesFileUrl, svgFileUrl } from "../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px] p-4">
      <section className="w-full  rounded-[40px] py-[24px] px-[34px] border border-[#E6E8E9] flex justify-center items-center relative">
        <img
          className="w-full rounded-[40px] "
          src={imagesFileUrl + "dashboard/singleExercise.jpg"}
          alt=""
        />
        <img
          className="w-[158px]  absolute bottom-[-75px] left-10 z-10"
          src={imagesFileUrl + "dashboard/bigMuscleIcon.png"}
          alt=""
        />
      </section>
      <h3 className="font-bold text-[32px] mt-[64px]">اسم تمرین</h3>
      <section className="w-full flex justify-between items-center mt-[24px]">
        <section className="w-[171px] h-[24px] flex justify-between items-center">
          <section className="w-[100px] h-[24px] flex justify-between items-center">
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + "timer1.svg"} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">
                20:00
              </span>
            </section>
            <span className="block h-[12px] w-[1px] bg-[#E6E8E9]"></span>
            <section className="flex justify-center items-center">
              <img src={svgFileUrl + "damble.svg"} alt="" />
              <span className="block h-[12px] text-xs font-normal mr-1">
                دمبل
              </span>
            </section>
          </section>
          <button className="bg-[#FEEAF3] rounded-full w-[55px] h-[24px] text-xs font-normal text-[#F02484]">
            پیشرفته
          </button>
        </section>
      </section>

      <section className="w-full py-[60px] px-[70px] min-h-[281px] h-auto  mt-[41px] rounded-[20px] shadow-[0px_6px_26px_-4px_#5408a92e]">
        <span className="text-[22px] font-bold">توضیحات تمرین</span>
        <ul className="mt-[32px] list-decimal leading-6">
          <li>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
            استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است چاپ و با استفاده از طراحان گرافیک است
            چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
          </li>
          <li className="mt-2">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
            استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است چاپ و با استفاده از طراحان گرافیک است
            چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
          </li>
        </ul>
      </section>
      <section className="w-full py-[60px] px-[20px] mt-[50px]  ">
        <span className="text-[22px] font-bold ">تمرینات مشابه</span>
        <section className="w-full  flex  flex-wrap justify-between mt-[55px]">
          <Item
            img={imagesFileUrl + "dashboard/zumba.png"}
            muscle={imagesFileUrl + "dashboard/zumbaIcon.png"}
            duration="20:00"
            level={1}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
          <Item
            img={imagesFileUrl + "dashboard/zumba2.png"}
            muscle={imagesFileUrl + "dashboard/zumba2Icon.png"}
            duration="20:00"
            level={2}
            description="توضیحات تمرین و حرکات که باید چه کاری انجام بدهد. توضیحات تمرین
          و حرکات که باید چه کاری انجام بدهد."
          />
        </section>
      </section>
    </section>
  );
}
