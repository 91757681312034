import { useState } from "react";
import Info from "./components/Info";
import ExerciseProgram from "./components/ExerciseProgram";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[753px] h-[80px] flex justify-between items-center rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-4  xl:px-[86px]">
        <button onClick={() => setActiveTab(1)} className={`xl:w-[200px]  w-[123px] h-10 xl:h-[48px] rounded-full  ${activeTab === 1 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
          اطلاعات تکمیلی
        </button>
        <button onClick={() => setActiveTab(2)} className={`xl:w-[200px]  w-[146px] h-10 xl:h-[48px] rounded-full  ${activeTab === 2 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
          تنظیم برنامه تمرینی
        </button>
      </section>

      {activeTab === 1 && <Info />}
      {activeTab === 2 && <ExerciseProgram />}
    </>
  );
}
