import { useEffect, useState } from "react";
import { baseFileUrl, svgFileUrl } from "../../../../config";
import Chart from "../components/CircleChart";
import { useParams } from "react-router-dom";
/* import FoodBox from "../components/FoodBox"; */
import { useGlobalContext } from "../../../../context/GlobalContext";
import axios from "axios";
import api from "../../../../api";
import AddFoodModal from "./components/AddFoodModal";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  let { id } = useParams();
  const { showLoading, hideLoading } = useGlobalContext();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [recipeInfo, setRecipeInfo] = useState({});
  const [ingredients, setIngredients] = useState([]);
  const [addFoodModal, setAddFoodModal] = useState(false);
  const [recipe, setRecipe] = useState([]);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  const getRecipeInfo = () => {
    showLoading();
    axios
      .get(`${api.recipe.searchRecipe}?id=${id}`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((res) => {
        hideLoading();
        setRecipeInfo(res?.data?.elements[0]);
        setIngredients(res?.data?.elements[0].ingredients.split("&"));
        setRecipe(res?.data.elements[0].recipe.split("&"));
      })
      .catch((e) => {
        hideLoading();
      });
  };
  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {
    getRecipeInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeModal = () => {
    setAddFoodModal(false);
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full m-auto shadow-md rounded-[18px] bg-white pb-[50px]">
      <section className="flex flex-col xl:flex-row justify-between items-center">
        <section className="xl:pr-[30px]">
          <h1 className="font-bold text-[23px] mt-12">
            {recipeInfo?.foodName}
          </h1>
          <section className="mb-5 xl:mb-0 flex xl:justify-start items-center w-full xl:w-[271px]">
            <img
              src={baseFileUrl + "/images/icons/clock.png"}
              className="w-[16px] h-[16px] inline-block"
              alt=""
            />
            <span className="mr-2">{recipeInfo?.duration}</span>
            <span className="block w-[2px] h-[33px] mr-2 bg-[#E6E8E9]"></span>

            <section className="self-center text-[14px] w-[60px] text-left">
              <img
                src={svgFileUrl + "/restaurant1.svg"}
                className="w-[16px] h-[16px] inline-block"
                alt=""
              />
              <span className="mr-2">{recipeInfo?.calories}</span>
              <section className="text-textGray">دریافتی</section>
            </section>
            {/*  <span className="block w-[2px] h-[33px] mr-2 bg-[#E6E8E9]"></span>
            <img src={svgFileUrl + "/users.svg"} className="w-[16px] h-[16px] mr-2 inline-block" alt="" />
            <span className="mr-2">برای دو نفر</span> */}
          </section>
          <section className="w-[271px] bg-[ghostwhite] h-[80px] p-5 flex justify-between items-center rounded-[20px] border border-[#E6E8E9] mt-4">
            <section>
              <Chart
                color="#4CC9EC"
                title="کربوهیدرات"
                value={recipeInfo?.carbohydrate}
              />
            </section>
            <section>
              <Chart color="#F02484" title="چربی" value={recipeInfo?.fat} />
            </section>
            <section>
              <Chart
                color="#7639BA"
                title="پروتئین"
                value={recipeInfo?.protein}
              />
            </section>
          </section>
          <button
            onClick={() => setAddFoodModal(true)}
            className="w-[100%] flex justify-center items-start bg-white text-purple border border-purple mt-3 p-3 px-5 rounded-md"
          >
            <img
              className="self-center ml-2"
              src={svgFileUrl + "plus.svg"}
              alt=""
            />
            <span>افرودن به برنامه غذایی</span>
          </button>
        </section>
        <section className="order-first xl:order-last w-full">
          <section className="xl:w-[360px] flex justify-center items-center pt-0 w-full h-[360px] xl:h-[415px] m-auto bg-[#FEEAF3] rounded-br-full rounded-bl-full">
            <img
              className="w-[300px] h-[300px] rounded-full"
              src={recipeInfo?.photoUrl}
              alt=""
            />
          </section>
          {/* <section className="flex w-[506px] h-[112px] justify-between mt-12 items-center">
            <button className="w-[44px] h-[44px] bg-zinc-200 rounded-full flex justify-center items-center">
              <img src={svgFileUrl + "arrowWhite.svg"} className="rotate-180" alt="" />
            </button>
            <img src={imagesFileUrl + "tinySalad1.png"} alt="" />
            <img src={imagesFileUrl + "tinySalad2.png"} alt="" />
            <img src={imagesFileUrl + "tinySalad3.png"} alt="" />

            <button className="w-[44px] h-[44px] bg-[#BB9CDD] rounded-full flex justify-center items-center">
              <img src={svgFileUrl + "arrowWhite.svg"} alt="" />
            </button>
          </section> */}
        </section>
      </section>

      <section className="bg-white w-full h-auto xl:w-full min-h-[500px]  rounded-[20px] mt-[180px] p-[1px] mb-12">
        <section className=" w-[90%] h-auto  flex-col justify-center items-center max-w-full bg-[#F9F5FE] rounded-[15px] m-auto mt-[-100px] relative p-12">
          {/* <img src={imagesFileUrl + "/Ellipse-1.png"} alt="" className="absolute left-[30px] top-0" />
          <img src={imagesFileUrl + "/Ellipse-2.png"} alt="" className="absolute left-[120px] top-0" />
          <img src={imagesFileUrl + "/Ellipse-3.png"} alt="" className="absolute left-[200px] top-0" /> */}
          <section className="text-[22px] w-full font-bold text-start">
            مواد لازم :
          </section>
          <section className="flex justify-between items-center mt-3">
            <ul className="list-disc grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-2">
              {ingredients.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </section>
        </section>
        <section className="p-[32px] xl:p-12">
          <h3 className="text-[22px] font-bold">توضیحات دستور پخت</h3>
          <ul className="list-decimal mt-8 leading-8">
            {recipe.map((item, index) => (
              <li className="mr-5 xl:mr-10" key={index}>
                {item}
              </li>
            ))}
            {/*   <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>
            <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>
            <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. ساختگی با تولید سادگی نامفهوم اختگی با تولید سادگی از صنعت چاپ و با استفاده اختگی با تولید سادگی از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.</li>
         */}
          </ul>
        </section>
        <section></section>
      </section>

      {/*  <h3 className="text-[22px] font-bold">رژیم غذایی مشابه</h3>
      <section className="mt-[100px] grid xs:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-x-11 mb-[100px]">
        <FoodBox image={imagesFileUrl + 'saladGreen.png'} />
        <FoodBox image={imagesFileUrl + 'saladFish.png'} />
        <FoodBox image={imagesFileUrl + 'saladEgg.png'} />
        <FoodBox image={imagesFileUrl + 'saladAvocado.png'} />
      </section> */}
      <AddFoodModal
        open={addFoodModal}
        rowData={recipeInfo}
        closeModal={closeModal}
      />
    </section>
  );
}
