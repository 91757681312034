import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[741px] max-w-full flex justify-between mb-[20px] mt-[64px]">
      <section className="w-[235px] h-[97px] bg-purple rounded-[12px]  relative">
        <img
          src={imagesFileUrl + "dashboard/dietShape1.png"}
          className="absolute z-0"
          alt=""
        />
        <img
          src={imagesFileUrl + "dashboard/miniPlate.png"}
          className="absolute z-1 top-[-18px] left-2"
          alt=""
        />
        <span className="text-white font-bold text-[22px] absolute bottom-[22px] right-[20px]">
          برنامه غذایی
        </span>
      </section>
      <section className="w-[235px] h-[97px] bg-[#FEF4F9]  rounded-[12px] relative">
        <img
          src={imagesFileUrl + "dashboard/dietShape2.png"}
          className="absolute z-0"
          alt=""
        />
        <img
          src={imagesFileUrl + "dashboard/damble.png"}
          className="absolute z-1 top-[-18px] left-2"
          alt=""
        />
        <span className="text-[#F02484] font-bold text-[22px] absolute bottom-[22px] right-[20px]">
          برنامه ورزشی
        </span>
      </section>
      <section className="w-[235px] h-[97px] bg-[#F6FCFE] rounded-[12px]  relative">
        <img
          src={imagesFileUrl + "dashboard/dietShape3.png"}
          className="absolute z-0"
          alt=""
        />
        <img
          src={imagesFileUrl + "dashboard/blob.png"}
          className="absolute z-1 top-[-18px] left-2"
          alt=""
        />
        <span className="text-[#4CC9EC] font-bold text-[22px] absolute bottom-[22px] right-[20px]">
          مصرف آب امروز
        </span>
      </section>
    </section>
  );
}
