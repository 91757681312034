import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  title,
  chartColor,
  chartBackColor,
  percent,
  goal,
  remain,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const props = {
    percent: remain > goal ? 100 : Math.ceil(percent), // is require
    colorSlice: chartColor,
    colorCircle: "#E6E8E9",
    fontWeight: 400,
    size: 90,
    stroke: 8,
    strokeBottom: 8,
    speed: 200,
    rotation: -90,
    round: true,
    number: false,
  };
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[90px] h-[156px]  flex flex-col justify-between items-center">
      <span className="text-sm font-bold" style={{ color: `${chartColor}` }}>
        {title}
      </span>
      <section className="relative">
        <section
          className="w-[65px] h-[65px] rounded-full absolute top-[13px] left-[12px] flex flex-col justify-center items-center"
          style={{ backgroundColor: `${chartBackColor}` }}
        >
          <span className="font-bold text-xl">
            {remain ? Math.ceil(remain) : 0}
          </span>
          <span className="font-normal text-[11px]">
            از
            <span className="mx-[2px]">{goal ? Math.floor(goal) : "خطا"}</span>
            گرم
          </span>
        </section>
        <CircularProgressBar {...props} />
      </section>
      <section className="w-[55px] h-[30px] text-[12px] text-center font-normal">
        {goal < remain ? (
          <span>بیش از حد مجاز</span>
        ) : (
          <>
            <span className="font-bold ml-1">
              {goal ? Math.floor(goal - remain) : "خطا"}
            </span>
            <span>گرم باقی مانده</span>
          </>
        )}
      </section>
    </section>
  );
}
