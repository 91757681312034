import { useState, useEffect } from "react";
import { svgFileUrl } from "../../../../../../config";
import { useBasicInfoStore } from "../../../../../../store/dashboard/basicInfo";
import { baseFileUrl } from "../../../../../../config";
import Modal from "../../../../../../components/common/Modal";
import { useSearchParams } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const [searchParams] = useSearchParams();
  const userImage = searchParams.get("profileImage");
  // ─── States ─────────────────────────────────────────────────────────────────────

  const [imageList, setImageList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  let arrImage = [];
  // ─── Functions ──────────────────────────────────────────────────────────────────

  useEffect(() => {
    for (let i = 0; i < data?.medicalAndSportDocumentOutputDTOS?.length; i++) {
      arrImage.push(data?.medicalAndSportDocumentOutputDTOS[i]?.documentImageUrl);
    }
    setImageList(arrImage.length > 0 && arrImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    console.log(imageList);
  }, []);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  //

  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section>
      <section className="xl:h-[140px]  h-auto w-full flex flex-col xl:flex-row xl:gap-5">
        <section className="flex  xl:mb-4 w-[30%] gap-6 xl:h-auto xl:justify-center items-center xl:w-[270px]">
          <img className="rounded-full w-[100px]" alt="" src={userImage ? userImage : `${baseFileUrl}${basicInfo?.gender === "MALE" ? `/images/icons/man-avatar.png` : `/images/icons/woman-avatar.png`}`} />

          <section className="flex flex-col mb-1">
            <span className="font-semibold">{data?.userOutputDTO?.firstName + " " + data?.userOutputDTO?.lastName}</span>
          </section>
        </section>

        <section className={` xl:w-full h-auto xl:h-[123px] xl:mt-0 mt-9 border px-[14px] py-[28px] border-[#E6E8E9] relative rounded-[10px]`}>
          <span className="xl:w-[111px] w-[94px] text-[#3E4151] flex justify-center items-center text-[13px] xl:text-base font-bold absolute top-[-12px] bg-white  right-4">مدارک پزشکی</span>
          <section className={` flex gap-4 `}>
            {imageList?.length > 0 ? imageList?.map((item, index) => (index < 9 ? <img onClick={() => window.open(item)} className="h-[72px] rounded-lg cursor-pointer" src={item} alt="" /> : "")) : <span className="w-full text-center mt-4 text-purple">مدارکی وجود ندارد</span>}
            {imageList?.length > 8 && (
              <button onClick={openModal} className="xl:w-[72px] w-[56px] h-[56px] xl:h-[72px] rounded-[10px] flex justify-center items-center border border-[#E6E8E9]">
                <img src={svgFileUrl + "/moredoc.svg"} alt="" className="w-[34px] xl:w-[48px]" />
              </button>
            )}
          </section>
        </section>
      </section>
      <Modal width={600} open={modalOpen} title=" گالری تصاویر" onClose={closeModal}>
        <section className="grid grid-cols-5 p-2 overflow-hidden gap-4">
          {imageList?.length > 0 ? (
            imageList?.map((item, index) => (
              <div className="w-full h-full">
                <img className="hover:scale-110 transition-all  hover:opacity-100 opacity-80 w-auto h-full rounded-lg" src={item} alt="" />
              </div>
            ))
          ) : (
            <span className="w-full text-center mt-4 text-purple">مدارکی وجود ندارد</span>
          )}
        </section>
      </Modal>
    </section>
  );
}
