import React from "react";
import { imagesFileUrl } from "../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ name, img }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-[170px] relative">
      <img className="h-[109px] xl:h-full" src={imagesFileUrl + "doctor-profile-banner.png"} alt="" />
      <section className="absolute grid grid-cols-1 xl:grid-cols-2 justify-between items-center top-[20px] xl:top-[90px] w-full">
        <section className="flex flex-col xl:flex-row items-center">
          <section className="w-[180px]  h-[200px] flex justify-center items-center xl:mr-[30px] overflow-hidden rounded-full border-[2px] border-purple bg-purple ">
            <img className="w-[160px] " src={img} alt="" />
          </section>
          <section className="mt-[20px] xl:mt-10 mr-[10px]">
            <section className="flex items-center gap-2">
              <img src={imagesFileUrl + "doctor-profile-check.png"} className=" w-[17px] h-[17px]" alt="" />
              <span className="font-bold text-[22px] block">{name}</span>
            </section>
            <span className="block text-center font-semibold">مربی ورزشی</span>
          </section>
        </section>
        {/*  <section className="gap-2 mt-[23px] xl:mt-[44px] flex justify-center xl:justify-end xl:pl-2">
          <img
            src={imagesFileUrl + "doctor-profile-twitter.png"}
            className="w-[25px] h-[25px]"
            alt=""
          />
          <img
            src={imagesFileUrl + "doctor-profile-facebook.png"}
            className="w-[25px] h-[25px]"
            alt=""
          />
          <img
            src={imagesFileUrl + "doctor-profile-youtube.png"}
            className="w-[25px] h-[25px]"
            alt=""
          />
          <img
            src={imagesFileUrl + "doctor-profile-instagram.png"}
            className="w-[25px] h-[25px]"
            alt=""
          />
        </section> */}
      </section>
    </section>
  );
}
