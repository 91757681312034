import { Button, Modal } from "../../../../../../../../../components/common";
import { svgFileUrl } from "../../../../../../../../../config";
import ListItem from "./components/ListItem";
import { useEffect, useState } from "react";
import AddDescriptionModal from "./components/AddDescriptionModal";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ data, finalPackageList, setFinalPackageList, deleteFromPackage }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [addDescModal, setAddDescModal] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const handleChange = (value) => {
    let arr = [...finalPackageList];
    arr.forEach((element, index) => {
      if (element?.packageId === data?.packageId) {
        arr[index].description = value;
      }
    });
    setFinalPackageList(arr);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="mt-[20px]  w-[90%] mx-auto border xl:w-full border-[#E6E8E9] rounded-[10px] px-[16px] py-[24px]">
        <section className="flex justify-between">
          <span className="font-bold rounded-[5px] bg-[#F9F5FE]  w-auto p-3 text-sm xl:text-base xl:h-[26px]  flex items-center justify-center  text-purple text-center">{data?.packageName}</span>
          <img src={svgFileUrl + "trash.svg"} alt="" className="cursor-pointer" onClick={() => deleteFromPackage(data?.packageId)} />
        </section>
        {data?.dairyListWithConsumedInputDTOS?.map((item, index) => (
          <ListItem item={item} packageId={data?.packageId} index={index} finalPackageList={finalPackageList} setFinalPackageList={setFinalPackageList} />
        ))}
        <Button onClick={() => setAddDescModal(!addDescModal)} secondary className="mr-auto mt-4">
          انتخاب از لیست توضیحات
        </Button>
        <textarea value={data.description} onChange={(e) => handleChange(e.target.value)} placeholder="توضیحات کلی خود را وارد کنید" className="xl:w-full block w-[90%] mx-auto py-[27px] px-[16px] items-center h-[157px] xl:h-[94px] border mt-[10px] border-[#E6E8E9] relative rounded-[10px]"></textarea>
      </section>
      <Modal width={800} onClose={() => setAddDescModal(false)} open={addDescModal} title="انتخاب از لیست توضیحات">
        <AddDescriptionModal onClose={() => setAddDescModal(false)} setDesc={handleChange} />
      </Modal>
    </>
  );
}
