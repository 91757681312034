import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ state, setState }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[169px]  m-auto">
      <span className="block text-center font-bold text-[22px] m-auto">
        انتخاب جنسیت
      </span>
      <section className="flex justify-between items-center w-[150px] m-auto mt-[24px]">
        <section
          className="flex cursor-pointer"
          onClick={() => setState("MALE")}
        >
          <label>آقا</label>
          <span
            className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full mr-2 ${
              state === "MALE" && "bg-purple"
            }`}
          >
            <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
          </span>
        </section>
        <section
          className="flex cursor-pointer"
          onClick={() => setState("FEMALE")}
        >
          <label>خانم</label>
          <span
            className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full mr-2 ${
              state === "FEMALE" && "bg-purple"
            }`}
          >
            <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
          </span>
        </section>
      </section>
    </section>
  );
}
