import { useState, useEffect } from "react";
import RadioButton from "./RadioButton";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, exerciseHistory, setExerciseHistory }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeButton, setActiveButton] = useState(false);

  const [time, setTime] = useState("");
  const [duration, setDuration] = useState("");
  const [sportField, setSportField] = useState("");

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    setExerciseHistory({
      ...exerciseHistory,
      description: `${sportField}&${time}&${duration}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, duration, sportField]);
  useEffect(() => {
    setExerciseHistory({ ...exerciseHistory, answer: activeButton });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeButton]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" w-full">
      <RadioButton
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        text={title}
        label="ندارم"
      />
      {activeButton && (
        <>
          <section className="w-full flex mt-4 gap-[11px]">
            <section className="relative">
              <span>رشته ورزشی</span>
              <input
                onChange={(e) => setSportField(e.target.value)}
                className="border text-xs xl:text-base border-[#DDCEEE] leading-[12px] xl:leading-[20px] outline-none w-full h-[40px] xl:h-[56px] placeholder:text-[#A6A9BD]  focus:border-[#A347F1] pl-3 pr-4 xl:pr-6 xl:pl-6 rounded-xl"
                placeholder="مثلا فوتبال و..."
              />
            </section>
            <section className="relative">
              <span>چه زمانی</span>
              <input
                onChange={(e) => setTime(e.target.value)}
                className="border text-xs xl:text-base border-[#DDCEEE] leading-[12px] xl:leading-[20px] outline-none w-full h-[40px] xl:h-[56px] placeholder:text-[#A6A9BD]  focus:border-[#A347F1] pl-3 pr-4 xl:pr-6 xl:pl-6 rounded-xl"
                placeholder="مثلا 3 سال پیش"
              />
            </section>
            <section className="relative">
              <span>چه مدت</span>
              <input
                onChange={(e) => setDuration(e.target.value)}
                className="border text-xs xl:text-base border-[#DDCEEE] leading-[12px] xl:leading-[20px] outline-none w-full h-[40px] xl:h-[56px] placeholder:text-[#A6A9BD]  focus:border-[#A347F1] pl-3 pr-4 xl:pr-6 xl:pl-6 rounded-xl"
                placeholder="مثلا 2 ماه"
              />
            </section>
          </section>
        </>
      )}
    </section>
  );
}
