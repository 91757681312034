import { baseFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:h-[180px] mx-auto relative">
      <section className="xl:w-[1128px] w-[90%]  mx-auto pt-6 flex">
        <span className="text-[#5408A9]">خانه</span>
        <span className="text-[#A6A9BD]">/ تیم ما</span>
      </section>
      <img src={baseFileUrl + "/images/hero-2.png"} alt="" className="absolute hidden xl:block xl:top-0 left-0 -z-10 w-full " />

      <h2 className="xl:text-[50px] text-[28px] text-center   leading-[46.5px] xl:leading-[77.5px] font-bold text-[#191919]">تیم بادینس</h2>
      <span className="block text-center text-[16px] mt-[20px] font-semibold">با افتخار اعضای حرفه‌ای تیم ما را معرفی می‌کنیم؛ هرکدام با تخصص و اشتیاق خود در مسیر موفقیت ما گام برمی‌دارند.</span>
    </section>
  );
}
