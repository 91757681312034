import { useState } from "react";
import { baseFileUrl } from "../../../../../config";
import { Button } from "../../../../../components/common";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { useNavigate } from "react-router-dom";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  src,
  height,
  title,
  backGround,
  flex,
  border,
  price,
  width,
  absolute,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getPackageInfo = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.packageInfo.getPackageInfo)
      .then((res) => {
        navigation(
          `/shopping-cart?amount=${res?.data?.amount}&name=${res.data.name}&id=${res.data.id}`
        );
      })
      .catch((err) => {});
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        className={`${
          flex ? flex : "flex-col"
        }  ${backGround} ${border} flex  relative  transition-all hover:shadow-lg hover:shadow-[#DDCEEE] hover:translate-y-[-10px]   overflow-hidden border  p-6  rounded-[20px] border-solid `}
      >
        <section>
          <h3 className={` leading-[34.1px]  text-[22px] font-bold`}>
            {title}
          </h3>
          <section className="my-6 flex text-sm items-start gap-[6px]">
            <span className="text-[#5521B4] bg-white tracking-tighter px-[6px] py-2 font-bold text-xs rounded-[150px]">
              پکیج یک ماهه
            </span>
            <span className="text-[#5521B4] bg-white px-[6px] py-2 tracking-tighter font-bold text-xs rounded-full">
              پکیج شش ماهه
            </span>
            <span className="text-[#5521B4] bg-white px-[6px] py-2 tracking-tighter font-bold text-xs rounded-[150px]">
              پکیج یک ساله
            </span>
          </section>

          <section className="font-bold text-[#191919]">
            <span className="text-[24px] leading-[37.2px] mb-5">{price}</span>
            تومان
          </section>
          <section className="flex justify-between">
            <button className="text-[#5521B4] h-10 font-bold text-sm leading-[16px] ">
              مشاهده بیشتر
            </button>
            <Button
              loading={loading}
              onClick={getPackageInfo}
              className="rounded-full z-10 transition-all hover:shadow-lg hover:shadow-[#A6A9BD] hover:translate-y-[-4px]  h-[40px] mr-10"
            >
              خرید رژیم
            </Button>
          </section>
        </section>
        <img
          src={baseFileUrl + src}
          alt=""
          className={` ${absolute}  ${width ? width : "h-full w-full mt-6"}`}
        />
      </section>
    </>
  );
}
