import Header from "./components/Header";
import UserInfo from "./components/UserInfo";
import ExerciseInfo from "./components/ExerciseInfo";
import SpecialSickness from "./components/SpecialSickness";
import SpecialSicknessDtl from "./components/SpecialSicknessDtl";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAxiosWithToken } from "../../../../../hooks";
import { ComponentLoading } from "../../../../../components/common";
import api from "../../../../../api";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const mobileNumber = searchParams.get("mobileNumber");
  const requestId = searchParams.get("userId");

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  const getDataInfo = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.userInformation.getAdditionalInformation + `?mobileNumber=${mobileNumber}&requestProgramId=${requestId}`)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {
    getDataInfo();
  }, []);
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="min-h-[400px] mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md relative">
      <ComponentLoading loading={loading} />
      {data && (
        <>
          <Header data={data} />
          <UserInfo data={data?.userOutputDTO} />
          <SpecialSickness data={data} />
          <SpecialSicknessDtl data={data} />
          {data?.answerOutputDTOs?.length > 0 && <ExerciseInfo data={data} />}
        </>
      )}
    </section>
  );
}
