//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
import { useEffect, useState } from "react";
import { Button, TextBox } from "../../../../../components/common";
import { svgFileUrl } from "../../../../../config";
import { useSearchParams } from "react-router-dom";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { notify } from "../../../../../helper";
export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [versionList, setVersionList] = useState([]);
  const [loading, setLoading] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  const addVersion = () => {
    setVersionList((prev) => [
      ...prev,
      {
        /*   requestId: parseInt(searchParams.get("userId") || "0"),
        username: searchParams.get("mobileNumber"), */
        drugName: "",
        drugType: "",
        count: "",
        description: "",
      },
    ]);
  };
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const changeVersionList = (index, objTitle, value) => {
    let arr = [...versionList];
    arr[index][objTitle] = value;
    setVersionList(arr);
  };
  const saveVersion = () => {
    let params = [
      {
        requestId: parseInt(searchParams.get("userId")),
        username: searchParams.get("mobileNumber"),
        /*  signature: "ljadsljadsljk", */
        firstName: data?.userOutputDTO?.firstName,
        lastName: data?.userOutputDTO?.lastName,
        medicalPrescriptionDetails: [...versionList],
      },
    ];
    setLoading(true);
    useAxiosWithToken
      .post(api.doctor.addMedicalPrescription, params)
      .then((res) => {
        setLoading(false);
        setVersionList([]);
        notify.Success("نسخه با موفقیت افزوده شد");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" h-auto mt-[40px] xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
      <section className="flex">
        <Button onClick={addVersion} className="bg-purple xl:px-0 px-3 mr-auto text-white xl:w-[200px] h-[40px] mb-5 xl:mb-0 rounded-full text-sm flex justify-center gap-1 items-center">
          <img src={svgFileUrl + "plus-white.svg"} alt="" className="w-4 h-4 xl:w-6 xl:h-6" />
          افزودن نسخه
        </Button>
      </section>
      <section>
        <section className="flex gap-2 items-center bg-purple mr-3 w-[300px] rounded-tl-full text-white p-2 font-semibold">
          <span>نسخه</span>
          <span>{data?.userOutputDTO?.gender === "MALE" ? "آقای" : "خانم"}</span>
          <span>{data?.userOutputDTO?.firstName + " " + data?.userOutputDTO?.lastName}</span>
        </section>
        <section className="border border-slate-200 min-h-8 rounded-md">
          {versionList.map((item, index) => (
            <section>
              <section className="grid grid-cols-1 gap-4 xl:grid-cols-5   p-3">
                <section>
                  <span>شکل دارو:</span>
                  <TextBox onChange={(e) => changeVersionList(index, "drugType", e.target.value)} alt="" />
                </section>
                <section>
                  <span>نام دارو:</span>
                  <TextBox onChange={(e) => changeVersionList(index, "drugName", e.target.value)} alt="" />
                </section>
                <section>
                  <span>تعداد :</span>
                  <TextBox onChange={(e) => changeVersionList(index, "count", e.target.value)} alt="" />
                </section>
                <section className="col-span-2">
                  <span>توضیحات:</span>
                  <TextBox onChange={(e) => changeVersionList(index, "description", e.target.value)} alt="" />
                </section>
              </section>
            </section>
          ))}
        </section>
      </section>

      {versionList.length > 0 && (
        <section className="flex mt-10">
          <Button loading={loading} onClick={saveVersion} className="bg-purple xl:px-0 px-3 mr-auto text-white xl:w-[200px] h-[40px] mb-5 xl:mb-0 rounded-full text-sm flex justify-center gap-1 items-center">
            ذخیره
          </Button>
        </section>
      )}
    </section>
  );
}
