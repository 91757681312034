export const baseFileUrl = window.location.origin;
export const imagesFileUrl = baseFileUrl + "/images/";
export const svgFileUrl = baseFileUrl + "/images/svg/";
export const certificateFileUrl = baseFileUrl + "/images/coachCert/";
////////////////////////////////////////////////////////////////////////////////
export const mainBaseUrl = "https://api.bodyness.ir/bodyness/";
/* export const devBaseUrl = "https://api.bodyness.ir/bodyness/"; */
/* export const devBaseUrl = "http://192.168.18.110:8084/bodyness/"; */
export const devBaseUrl = "http://192.168.18.55:8084/bodyness/";
/* export const devBaseUrl = "http://192.168.11.111:8084/bodyness/"; */
