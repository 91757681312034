import { useState } from "react";
import { imagesFileUrl } from "../../../../../../config";
import Chart from "react-apexcharts";
import Select from "react-select";
import Loading from "../../../../../../components/common/componentLoading";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ loading }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  let yearChart = {
    series: [
      {
        data: [80, 64, 87, 74, 63, 43, 80, 64, 80, 74, 63, 49],
      },
    ],
    options: {
      tooltip: {
        enabled: false,
      },
      chart: {
        height: "175px",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#F1E8FC",
        "#F1E8FC",
        "#7639BA",
        "#F1E8FC",
        "#F1E8FC",
        "#00BA34",
        "#F1E8FC",
        "#F1E8FC",
        "#F1E8FC",
        "#F1E8FC",
        "#F1E8FC",
        "#F1E8FC",
      ],
      plotOptions: {
        bar: {
          columnWidth: "60%",
          distributed: true,
          borderRadius: 4,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + " کیلو ";
        },
        offsetY: -20,
        style: {
          fontFamily: "YekanBakh",
          fontSize: "10px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [
          "ماه دوازدهم",
          "ماه یازدهم",
          "ماه دهم",
          "ماه نهم",
          "ماه هشتم",
          "ماه هفتم",
          "ماه ششم",
          "ماه پنچم",
          "ماه چهارم",
          "ماه سوم",
          "ماه دوم",
          "ماه اول",
        ],

        labels: {
          offsetY: 40,
          style: {
            fontFamily: "YekanBakh",
            fontSize: "10px",
            colors: ["#304758"],
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };
  let weekChart = {
    series: [
      {
        data: [63, 43],
      },
    ],
    options: {
      tooltip: {
        enabled: false,
      },
      chart: {
        height: "175px",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#F1E8FC", "#7639BA"],
      plotOptions: {
        bar: {
          columnWidth: "80%",
          distributed: true,
          borderRadius: 4,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + " کیلو ";
        },
        offsetY: -20,
        style: {
          fontFamily: "YekanBakh",
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: ["هفته دوم", "هفته اول"],

        labels: {
          style: {
            fontFamily: "YekanBakh",
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };
  const date = [
    { value: 1, label: "هفتگی" },
    { value: 2, label: "ماهانه" },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [chartMode, setChartMode] = useState(date[0]);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" h-[301px] bg-white rounded-[18px] mt-[38px] xl:mt-0 shadow-md py-8  relative overflow-hidden">
      <Loading loading={loading} />
      <img
        src={imagesFileUrl + "dashboard/shape2.png"}
        className="absolute top-0 right-1 z-0"
        alt=""
      />

      <section className="z-10 relative w-[90%] mx-auto  flex justify-between items-center">
        <span className="text-lg font-bold">نمودار وزن</span>
        <section className="w-auto h-[32px] relative">
          {/* <button className="w-[78px] h-[32px] flex justify-around relative items-center rounded-full border px-2 border-[#DDCEEE] font-medium text-sm text-[#986BCB]">
            <span>{chartMode === 1 ? 'هفتگی' : 'ماهانه'}</span>
            <img src={svgFileUrl + 'arrowDown.svg'} alt="" />
          </button> */}
          <Select
            value={chartMode}
            onChange={setChartMode}
            options={date}
            placeholder="روز"
            isSearchable={false}
            className="w-[150px]"
          />
        </section>
      </section>
      <section className="mx-auto w-[350px]">
        {chartMode.value === 1 ? (
          <Chart
            options={weekChart.options}
            series={weekChart.series}
            type="bar"
            width="350"
          />
        ) : chartMode.value === 2 ? (
          <Chart
            options={yearChart.options}
            series={yearChart.series}
            type="bar"
            width="100%"
          />
        ) : (
          ""
        )}
      </section>
    </section>
  );
}
