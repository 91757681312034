import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseFileUrl, imagesFileUrl, svgFileUrl } from "../../../config";
import ProfileBtn from "../ProfileBtn";
import MobileMenu from "./components/MobileMenu";

import { Button } from "../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ mode }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [menuClass, setMenuClass] = useState(
    mode === "dark" ? "#5606a8" : mode === "light" ? "#FFFFFF26" : ""
  );
  const [menuOpen, setMenuOpen] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const listenScrollEvent = (e) => {
    if (window.scrollY > 0) {
      setMenuClass("#5606a8");
    } else {
      setMenuClass(
        mode === "dark" ? "#5606a8" : mode === "light" ? "#FFFFFF26" : ""
      );
    }
  };
  /*   useEffect(() => {
    console.log(scrollYPosition);
  }, [scrollYPosition]); */

  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section
        className={`hidden fixed xl:flex items-center w-full left-0 top-0 h-[80px] transition-all duration-500 z-[1000]`}
        style={{ backgroundColor: menuClass }}
      >
        <section className="container mx-auto">
          <section className="grid grid-cols-6">
            <Link className="flex justify-start items-center" to="/">
              <img
                src={baseFileUrl + "/images/logo-new.png"}
                className="h-[80px] mr-3"
                alt=""
              />
            </Link>
            <section className="col-span-4">
              <nav className="flex justify-center gap-8 items-center xl:h-full 2xl:h-[80px] text-[18px]">
                <Link
                  to="/"
                  className="text-white hover:underline font-normal "
                >
                  خانه
                </Link>
                <section className="relative group  h-full flex justify-center items-center">
                  <button className="text-white  font-normal flex items-center gap-1">
                    <span>خدمات ما</span>

                    <img
                      src={svgFileUrl + "arrowDownWhite.svg"}
                      alt=""
                      className=""
                    />
                  </button>
                  <section className="absolute hidden w-[200px] group-hover:flex rounded-lg  flex-col gap-5 shadow-lg top-[80px] p-3 bg-white">
                    <Link
                      to="/exercise-plan"
                      className="text-purple hover:bg-slate-100 transition-all px-2 rounded-lg font-normal flex items-center gap-2"
                    >
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                      مربیان بادینس
                    </Link>
                    <Link
                      to="/meal-plan"
                      className="text-purple hover:bg-slate-100 transition-all px-2 rounded-lg font-normal flex items-center gap-2"
                    >
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                      برنامه غذایی
                    </Link>
                    <Link
                      to="/exercise/choose-exercise"
                      className="text-purple hover:bg-slate-100 transition-all px-2 rounded-lg font-normal flex items-center gap-2"
                    >
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                      حرکات ورزشی
                    </Link>
                    <Link
                      to="/tools"
                      className="text-purple font-normal hover:bg-slate-100 transition-all px-2 rounded-lg flex items-center gap-2"
                    >
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                      سلامت سنج ها
                    </Link>
                    <Link
                      to="/recipe"
                      className="text-purple font-normal hover:bg-slate-100 transition-all px-2 rounded-lg flex items-center gap-2"
                    >
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                      رسپی ها
                    </Link>
                  </section>
                </section>

                {/*  <Link to="/exercise-plan" className="text-white font-normal  ">
                  برنامه ورزشی
                </Link>
                <Link to="/meal-plan" className="text-white font-normal ">
                  برنامه غذایی
                </Link>
                <Link to="/exercise/choose-exercise" className="text-white font-normal ">
                  حرکات ورزشی
                </Link>
                <Link to="/tools" className="text-white font-normal">
                  سلامت سنج ها
                </Link>
                <Link to="/recipe" className="text-white font-normal">
                  رسپی ها
                </Link> */}
                {/*  <Link to="/my-services" className="text-white font-normal">
                  خدمات ما
                </Link> */}
                <Link
                  to="https://mag.bodyness.ir/"
                  className="text-white  hover:underline font-normal"
                >
                  مجله
                </Link>
                <Link
                  to="/team-us"
                  className="text-white hover:underline font-normal"
                >
                  تیم ما
                </Link>
                <Link
                  to="/contact-us"
                  className="text-white  hover:underline font-normal"
                >
                  تماس با ما
                </Link>
                {/*  <button onClick={() => window.scrollTo({ top: 1200, left: 0, behavior: "smooth" })} to="" className="text-white font-normal text-[14px] mr-7">
                  حرکات ورزشی
                </button> */}
                {/*   <Link to="" className="text-white font-normal text-[18px] mr-7">
                  درباره ی ما
                </Link> */}
              </nav>
            </section>
            <section className="flex ml-4  justify-end items-center">
              {localStorage.firstName ? (
                <ProfileBtn mode="firstName" />
              ) : localStorage.mobileNumber ? (
                <ProfileBtn mode="mobile" />
              ) : (
                <Link to="/login" className="">
                  <Button light={true} className=" rounded-full">
                    ورود / ثبت نام
                  </Button>
                </Link>
              )}
            </section>
          </section>
        </section>
      </section>
      {/* --Menu class-- */}
      <section
        className={`grid grid-cols-3 px-[18px] fixed xl:hidden items-center w-full left-0 top-0 h-[64px] transition-all duration-500 z-[1000]`}
        style={{ backgroundColor: menuClass }}
      >
        <img
          onClick={() => setMenuOpen(!menuOpen)}
          src={svgFileUrl + "menuWhite.svg"}
          className="mobileMenuBtn cursor-pointer"
          alt=""
        />
        <Link to="/">
          <img
            className="h-[60px]"
            src={imagesFileUrl + "logo-new.png"}
            alt=""
          />
        </Link>
        <MobileMenu open={menuOpen} setOpen={setMenuOpen} />
      </section>
    </>
  );
}
