import { useState } from "react";
import Header from "./components/Header";
import PackageList from "./components/PackageList";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ activeTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [packageData, setPackageData] = useState();
  const [clearHeader, setClearHeader] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const ClearHeader = () => {
    setClearHeader(!clearHeader);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Header packageData={packageData} clearHeader={clearHeader} setPackageData={setPackageData} activeTab={activeTab} />
      {packageData && <PackageList clearHeader={ClearHeader} setPackageData={setPackageData} packageData={packageData} />}
    </>
  );
}
