import { useState, useEffect } from "react";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step7 from "./steps/Step7";
import Step8 from "./steps/Step8";
import Step9 from "./steps/Step9";
import Step10 from "./steps/Step10";
import Swal from "sweetalert2";
import axios from "axios";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import api from "../../api";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  const { showLoading, hideLoading } = useGlobalContext();

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeStep, setActiveStep] = useState(1);
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [height, setHeight] = useState(220);
  const [weight, setWeight] = useState(35);
  const [goalWeight, setGoalWeight] = useState(35);
  const [bodyFat, setBodyFat] = useState();
  const [goal, setGoal] = useState();
  const [activity, setActivity] = useState(1);
  const [diet, setDiet] = useState();
  const [lastDiet, setLastDiet] = useState();
  const [resistanceExercises, setResistanceExercises] = useState();
  const [fname, setFname] = useState();
  const [lName, setLname] = useState();

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    /* console.log("height:", height);
    console.log("goalWeight:", goalWeight);
    console.log("wieght:", weight); */
    /*     console.log('gender:', gender);
    console.log('birthdate:', birthDate);
    console.log('height:', height); */
    /*  console.log('wieght:', weight);
    console.log('goalWeight:', goalWeight); */
    /*     console.log('bodyFat:', bodyFat);
    console.log('goal:', goal);
    console.log('activity:', activity);
    console.log('diet:', diet);
    console.log('last diet:', lastDiet);
    console.log('resistanceExercises:', resistanceExercises); */
    /*  if (!localStorage.firstName) {
      navigation("/login");
    } */
  }, [gender, birthDate, height, weight, bodyFat, goal, activity, diet, lastDiet, resistanceExercises, goalWeight]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const generateBodyFatLevel = (value) => {
    switch (value) {
      case 1:
        return "VERY_LOW";
      case 2:
        return "LOW";
      case 3:
        return "MEDIUM";
      case 4:
        return "ABOVE_MEDIUM";
      case 5:
        return "HIGH";
      case 6:
        return "VERY_HIGH";
      default:
      // code block
    }
  };
  const generateActivity = (value) => {
    switch (value) {
      case 1:
        return "NONE";
      case 2:
        return "VERY_LOW";
      case 3:
        return "LOW";
      case 4:
        return "MEDIUM";
      case 5:
        return "ACTIVE";
      case 6:
        return "VERY_ACTIVE";
      default:
      // code block
    }
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.token,
  };
  const saveBasicInfo = () => {
    showLoading();
    let params = {
      id: localStorage.userId,
      firstName: fname,
      lastName: lName,
      mobileNumber: localStorage.mobileNumber,
      kgWeight: weight,
      cmHeight: height,
      gender: gender,
      bodyFatLevel: generateBodyFatLevel(bodyFat),
      goal: goal,
      goalKgWeight: goalWeight,
      birthDate: birthDate,
      activityEnum: generateActivity(activity),
      dietBeforeEnum: diet,
      lastDietEnum: lastDiet,
      resistanceExercises: resistanceExercises,
      lossPerWeekEnum: "GRAM_500",
      typeBodybuildingEnum: "CUT",
      duration: 12,
    };
    axios
      .put(api.user.updateUser + localStorage.userId, params, { headers })
      .then((res) => {
        hideLoading();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "اطلاعات شما با موفقیت ثبت شد",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          navigation("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        hideLoading();
      });
  };
  useEffect(() => {
    if (weight === goalWeight) {
      setGoal("MAINTAIN");
    } else if (weight > goalWeight) {
      setGoal("LOOSE_WEIGHT");
    }
    if (weight < goalWeight) {
      setGoal("GAIN_WEIGHT");
    }
  }, [weight, goalWeight]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="bg-white w-full h-screen">
      {/*  <section className="basicInfoWrapperMobile p-5 md:hidden">
        {activeStep === 1 ? (
          <MobileStep1 setStep={setActiveStep} />
        ) : activeStep === 2 ? (
          <MobileStep2 setStep={setActiveStep} />
        ) : activeStep === 3 ? (
          <MobileStep3 setStep={setActiveStep} />
        ) : activeStep === 4 ? (
          <MobileStep4 setStep={setActiveStep} />
        ) : activeStep === 5 ? (
          <MobileStep5 setStep={setActiveStep} />
        ) : activeStep === 6 ? (
          <MobileStep6 setStep={setActiveStep} />
        ) : activeStep === 7 ? (
          <MobileStep7 setStep={setActiveStep} />
        ) : activeStep === 8 ? (
          <MobileStep8 setStep={setActiveStep} />
        ) : activeStep === 9 ? (
          <MobileStep9 setStep={setActiveStep} />
        ) : activeStep === 10 ? (
          <MobileStep10 setStep={setActiveStep} />
        ) : (
          ""
        )}
      </section> */}
      <section className="basicInfoWrapper p-5  mt-20">
        {activeStep === 1 ? (
          <Step1 setStep={setActiveStep} activeStep={activeStep} setGender={setGender} />
        ) : activeStep === 2 ? (
          <Step2 setStep={setActiveStep} activeStep={activeStep} setBirthDate={setBirthDate} setFname={setFname} setLname={setLname} fName={fname} lName={lName} />
        ) : activeStep === 3 ? (
          <Step3 setStep={setActiveStep} activeStep={activeStep} setHeight={setHeight} defaultValue={height} />
        ) : activeStep === 4 ? (
          <Step4 setStep={setActiveStep} activeStep={activeStep} setWeight={setWeight} weight={weight} goalWeight={goalWeight} height={height} setGoalWeight={setGoalWeight} setGoal={setGoal} defaultValue={weight} defaultGoalValue={goalWeight} />
        ) : activeStep === 5 ? (
          <Step5 setStep={setActiveStep} activeStep={activeStep} setBodyFat={setBodyFat} defaultValue={bodyFat} />
        ) : activeStep === 6 ? (
          <Step7 setStep={setActiveStep} activeStep={activeStep} defaultValue={activity} setActivity={setActivity} activity={activity} />
        ) : activeStep === 7 ? (
          <Step8 setStep={setActiveStep} activeStep={activeStep} defaultValue={diet} setDiet={setDiet} saveBasicInfo={saveBasicInfo} />
        ) : activeStep === 8 ? (
          <Step9 setStep={setActiveStep} activeStep={activeStep} setLastDiet={setLastDiet} defaultValue={lastDiet} />
        ) : activeStep === 9 ? (
          <Step10 setStep={setActiveStep} activeStep={activeStep} setResistanceExercises={setResistanceExercises} defaultValue={resistanceExercises} saveBasicInfo={saveBasicInfo} />
        ) : (
          ""
        )}
      </section>
    </section>
  );
}
