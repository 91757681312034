// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ text, label, activeButton, setActiveButton }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" w-full flex justify-between items-center">
      <h3 className="text-[#3E4151] text-xs xl:text-[20px]  font-semibold">
        {text}
      </h3>
      <section className="flex items-center gap-4 tracking-[0.5%] text-[#6B7280] text-sm xl:text-[20px] leading-[14px] xl:leading-5 font-semibold xl:font-medium">
        <div
          onClick={() => setActiveButton(!activeButton)}
          className={`${
            activeButton ? "bg-[#5521B4]" : "bg-[#DDCEEE]"
          } xl:w-[56px] w-[35px] relative h-5 xl:h-8 rounded-full`}
        >
          <div
            className={`${
              activeButton ? "right-1" : "xl:right-7 right-[17px]"
            } transition-all duration-300 w-[15px] h-[15px] xl:w-6 xl:h-6 bg-white rounded-full absolute top-[50%] translate-y-[-50%]`}
          ></div>
        </div>
        {label}
      </section>
    </section>
  );
}
