import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const Item = ({ children }) => {
  return <span className="bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center">{children}</span>;
};
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="min-h-[400px] bg-[#F1E8FC] rounded-[10px] mt-[64px] relative p-[1px]">
      <section className="w-full absolute top-0 z-0">
        <img className="m-auto" src={imagesFileUrl + "doctor-profile-pattern.png"} alt="" />
      </section>
      <section className="z-[1] relative mt-[64px] px-[28px] pb-[28px]">
        <section className="w-full">
          <section className="flex gap-4 w-[150%] xl:w-full">
            <button className="w-[237px] h-[40px] rounded-full bg-white border border-[#DDCEEE] shadow-[0px_13px_20px_-11.96px_#5408A933] text-purple">دریافت برنامه ورزشی عادی</button>
            <button className="w-[147px] h-[40px] rounded-full bg-white border border-[#DDCEEE] shadow-[0px_13px_20px_-11.96px_#5408A933] ">دریافت برنامه ورزشی VIP</button>
          </section>
        </section>

        <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
          این پکیج شامل برنامه غذایی کامل جهت رسیدن به تناسب اندام می‌باشد.
          <ul className="list-disc pr-[15px] mt-[10px]">
            <li>یک برنامه غذایی خوب باید متعادل، متنوع و متناسب با نیازهای فردی باشد. این برنامه باید شامل تمامی گروه‌های غذایی اصلی باشد تا بدن بتواند تمام مواد مغذی مورد نیاز خود را دریافت کند. نکات کلیدی یک برنامه غذایی خوب عبارتند از:</li>
            <li>تنوع: مصرف انواع مختلف مواد غذایی از هر گروه غذایی، شامل میوه‌ها، سبزیجات، غلات کامل، پروتئین‌های کم‌چرب (مانند ماهی، مرغ، تخم‌مرغ، حبوبات و مغزها) و لبنیات کم‌چرب یا جایگزین‌های آن.</li>
            <li>تعادل: توزیع مناسب کالری و مواد مغذی در طول روز به طوری که انرژی مورد نیاز بدن تامین شود و از دریافت بیش از حد یا کم‌تر از حد مواد مغذی خاص جلوگیری شود</li>
          </ul>
        </section>
      </section>
      <section className="relative mt-[32px] px-[28px] pb-[28px]">
        <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
          <span className="text-[#3E4151] font-bold">این بسته برای چه افرادی مناسب است:</span>
          <ul className="list-disc pr-[15px] mt-[10px]">
            <li>افراد با تحرک کم</li>
            <li>افراد گیاه خوار</li>
          </ul>
          <span className="text-[#3E4151] font-bold mt-[50px] block">ویژگی‌های بسته VIP</span>
          <section className="flex gap-3 flex-wrap mt-[20px]">
            <Item>ویدئو کال</Item>
            <Item>مکمل</Item>
            <Item>تمرین</Item>
            <Item>تغذیه</Item>
          </section>
          <section className="flex items-center  mt-[23px] gap-2">
            <img src={imagesFileUrl + "doctor-profile-timer.png"} alt="" />
            <span className="font-bold text-[#3E4151] block">مدت زمان برنامه غذایی: ۴۵ روز</span>
          </section>
        </section>
      </section>
      <section className="relative mt-[32px] px-[28px] pb-[28px]">
        <section className="rounded-[10px] border border-[#F7F9FE] bg-white mt-[32px] p-[28px] text-[14px] text-[#3E4151]">
          <section className="flex items-center justify-between">
            <span className="font-bold">قیمت بسته:</span>
            <section className="flex items-center gap-2">
              <span className="text-[24px] font-bold">2,500,000</span>
              <span className="font-bold">تومان</span>
            </section>
          </section>
          <section className="text-left">
            <button className="w-[188px] rounded-full mt-[32px] h-[30px] bg-purple text-white">خرید پکیچ</button>
          </section>
        </section>
      </section>
    </section>
  );
}
